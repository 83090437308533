<template>
  <div>
    <a class="btn text-themed go-back" @click="goBack()">{{ $t("flexben.general.go_back") }}</a>
    <div class="row mb-3">
      <div class="col-12 col-md-6 flexben-page-title">
        <h1>{{ (formAction==='create') ? $t('flexben.offer_category.new') : $t('flexben.offer_category.edit') }}</h1>
      </div>
      <div class="col-12 col-md-6 d-inline-flex">
        <span class="ml-auto">
          <button class="btn btn-delete px-4 text-danger" @click="modalAct('delete')"
                  v-if="formAction==='update'">
          <font-awesome-icon icon="trash-alt"/>
          {{ $t('flexben.general.delete') }}
        </button>
        <button class="btn btn-themed px-4" @click="modalAct('form_submit')">{{ $t('flexben.general.btn.save') }}
        </button>
        </span>
      </div>
    </div>
    <div class="row mt-1" v-if="checkNotEmptyObj(errorsBag)">
      <div class="col-12">
        <div class="alert alert-danger alert-dismissible mb-0">
          <button type="button" class="close" data-dismiss="alert" @click="closeErrorButton = false">&times;</button>
          <form-error-list :errorBag="errorsBag"></form-error-list>
        </div>
      </div>
    </div>
    <div class="container-bg-white mt-3" :key="formKey">
      <div class="row p-3">
        <div class="col-12 d-inline-flex align-content-center mb-3">
          <h3 class="my-0 py-1 pl-0">{{ $t('flexben.general.details') }}</h3>
        </div>

        <div class="col-12 col-md-4 col-xl-4 d-flex align-items-start mb-1">
          <div class="image-container cursor-pointer d-flex align-items-center mx-auto" @click="$refs.image_input.click()">
            <div class="image-changed" v-if="imageChanged">{{$t("flexben.general.image_changed")}}</div>
            <img class="img-fluid"
                 :src="(formData.image!==null) ? image_preview : formData.image_url"/>
            <div class="upload-image-middle-container">
              <div class="upload-image-text">{{$t("flexben.general.upload_image")}}</div>
            </div>
            <input type="file" class="hide" ref="image_input" accept=".png,.jpeg,.jpg,.svg,.jfif" @change="setImageFile($event)">
          </div>
        </div>
        <div class="col-12 col-md-8 col-xl-8 mb-2">
          <div class="row">
            <div class="col-md-6 form-group" v-for="(obj, index) in formData.name" :key="index">
              <label for="group_name" class="form-fields-required col-form-label pt-0">{{ $t("flexben.offer_category.table.name")
                }} - {{obj.lang_code}}</label>
              <input id="group_name" type="text" class="form-control"
                     v-model="obj.value"
                     :class="{'border-danger':(Object.keys(errorsBag).includes('name|'+obj.lang_code))}">
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 form-group">
              <label for="company" class="col-form-label pt-0">{{ $t("flexben.categories.filters.company") }}</label>
              <select class="form-control" v-model="formData.company" id="company">
                <option :value="null" selected>-</option>
                <option v-for="(company) in options.companies" :key="company.id" :value="company.id">

                  {{company.name}}
                </option>
              </select>
            </div>

          </div>
        </div>
        <div class="col-12 mt-3" v-if="formAction==='update'">
          <div class="row">
            <div class="col-12">
              <h3 class="my-0 py-1 pl-0">{{ $t("flexben.offer_category.assigned_offers") }}</h3>
            </div>
            <div class="col-12 mt-1">
              <ul v-if="formData.offers.length>0">
                <li v-for="offer in formData.offers" :key="'of_'+offer.id">{{offer.name[locale]}}</li>
              </ul>
              <span v-if="formData.offers.length===0">{{ $t("flexben.offer_category.no_assigned_offers") }}</span>
            </div>

          </div>
        </div>



      </div>
    </div>
    <div class="col-12 d-inline-flex pr-0 my-3">
        <span class="ml-auto">
          <button class="btn btn-delete px-4 text-danger" @click="modalAct('delete')"
                  v-if="formAction==='update'">
          <font-awesome-icon icon="trash-alt"/>
          {{ $t('flexben.general.delete') }}
        </button>
        <button class="btn btn-themed px-4" @click="modalAct('form_submit')">{{ $t('flexben.general.btn.save') }}
        </button>
        </span>
    </div>
    <confirm-modal :openModal="modalOpen"
                   :modalAction="modalAction"
                   :formSuccess="modalFormSuccess"
                   :endMessage="modalEndMessage"
                   :modalExtraMsg="modalExtraMsg"
                   :canContinue="modalCanContinue"
                   @closeModal="closeModal"
                   @confirmAction="confirmAction"/>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import ConfirmModal from "@/components/flexben/offer_category/modals/ConfirmModal";
import FormErrorList from "@/components/fragments/FormErrorList";

export default {
  name: "OfferCategoryDetail",
  components: {
    "confirm-modal": ConfirmModal,
    "form-error-list": FormErrorList
  },
  props: {
    formAction: {
      type: String,
      required: true,
    },
    detailId: {
      type: Number,
    },
  },
  data() {
    return {
      httpService: httpFlexbenService.offerCategoriesService,
      initialLoading: true,
      loading: false,
      loaderError: false,
      locale: i18n.locale,
      formKey: 0,

      options: {
        available_languages: [],
        companies: []
      },
      formData: {
        id: null,
        name: [],
        image: null,
        offers: [],
        image_url: httpFlexbenService.globalVariablesService.baseImagePreview,
        company_name: null,
        company: null
      },
      imageChanged: false,
      image_preview: null,
      isFormModified: false,
      errorsBag: {},
      modalOpen: false,
      modalFormSuccess: false,
      modalAction: '',
      modalEndMessage: '',
      modalExtraMsg: '',
      modalCanContinue: '',
    }
  },
  watch: {
    formData: {
      deep: true,
      handler: function () {
        this.isFormModified = true;
      }
    }
  },
  created() {
    this.toggleParentLoading(true);
    if (this.formAction === 'update' && this.detailId !== null) {
      this.getDetails();
    } else if (this.formAction === 'create') {
      this.getOptions();
    } else {
      this.$emit('clearForm')
    }
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  mounted() {
    this.isFormModified = false;
  },
  methods: {
    getDetails() {
      this.httpService.details(this.detailId)
        .then(function (response) {
          this.formData.id = response.data.item.id;
          for (let langCode in response.data.item.name) {
            this.formData.name.push({
              lang_code: langCode,
              value: response.data.item.name[langCode]
            });
          }
          this.formData.image_url = response.data.item.image_url;
          this.formData.offers = response.data.item.offers;
          this.formData.company = response.data.item.company_id;
          this.formData.company_name = response.data.item.company_name;
          this.options.companies = response.data.companies;
          // this.options.available_languages = response.data.options.available_languages;
          this.toggleParentLoading(false);
        }.bind(this))
        .catch(function (error) {
          this.toggleParentLoading(true, true);
        }.bind(this))
        .then(function () {
          this.isFormModified = false;
        }.bind(this));
    },
    getOptions() {
      this.httpService.createOptions({company: this.companyId})
        .then(function (response) {
          this.options.available_languages = response.data.available_languages;
          for (let index in response.data.available_languages) {
            this.formData.name.push({
              lang_code: response.data.available_languages[index],
              value: ''
            });
          }
          this.formData.company = this.companyId ? this.companyId : null;
          this.options.companies = response.data.companies;
          this.toggleParentLoading(false);
        }.bind(this))
        .catch(function (error) {
        }.bind(this))
        .then(function () {
          this.isFormModified = false;
        }.bind(this));
    },
    goBack() {
      if (this.isFormModified) {
        this.modalAct('go_back')
      } else {
        this.$emit('clearForm');
      }
    },
    toggleBenefitId(benefitId) {
      if (this.formData.benefit_ids.includes(benefitId)) {
        let benefitIndex = this.formData.benefit_ids.indexOf(benefitId);
        this.formData.benefit_ids.splice(benefitIndex, 1);
      } else {
        this.formData.benefit_ids.push(benefitId);
      }
    },
    modalAct(action) {
      this.modalAction = action;
      if (action === 'delete' && this.formData.offers.length > 0) {
        this.modalExtraMsg = this.$t('flexben.offer_category.modal.extra_delete', {count_offer: this.formData.offers.length});
        this.modalCanContinue = false;
      } else {
        this.modalCanContinue = true;
      }
      this.modalOpen = true;
    },
    confirmAction(action) {
      if (action === 'go_back') {
        this.closeModal();
        this.isFormModified = false;
        this.goBack();
      }
      if (action === 'form_submit') {
        this.toggleParentLoading(true);
        this.modalOpen = false;
        if (this.modalAction === 'form_submit') {
          if (this.formAction === 'update') {
            this._updateItem();
          } else {
            this._createItem();
          }
        } else if (this.modalAction === 'delete') {
          this._deleteItem();
        }
      }
      if (action === 'end') {
        this.closeModal();
        if (this.modalFormSuccess) {
          this.isFormModified = false;
          this.$emit('clearForm', true);
        }
      }
    },
    _createItem() {
      this.errorsBag = {};
      this.toggleParentLoading(true);
      this.httpService.create(this._prepareFormData())
        .then(function (response) {
          this.modalFormSuccess = true;
          this.modalAction = 'end';
          this.toggleParentLoading(false);
          this.modalOpen = true;
        }.bind(this))
        .catch(function (error) {
          this.modalFormSuccess = false;
          if (error.response.status === 400) {
            this.errorsBag = error.response.data.error_fields;
            this.toggleParentLoading(false);
          } else if (500 <= error.response.status && error.response.status < 600) {
            this.toggleParentLoading(true, true);
          }
        }.bind(this));
    },
    _updateItem() {
      this.errorsBag = {};
      this.toggleParentLoading(true);
      this.httpService.update(this.formData.id, this._prepareFormData())
        .then(function (response) {
          this.modalFormSuccess = true;
          this.modalAction = 'end';
          this.toggleParentLoading(false);
          this.modalOpen = true;
        }.bind(this))
        .catch(function (error) {
          this.modalFormSuccess = false;
          if (error.response.status === 400) {
            this.errorsBag = error.response.data.error_fields;
            this.toggleParentLoading(false);
          } else if (500 <= error.response.status && error.response.status < 600) {
            this.toggleParentLoading(true, true);
          }
        }.bind(this));
    },
    _deleteItem() {
      this.errorsBag = {};
      if (this.modalCanContinue) {
        this.httpService.delete(this.formData.id)
          .then(function (response) {
            this.modalFormSuccess = true;
            this.modalAction = 'end';
            this.toggleParentLoading(false);
            this.modalOpen = true;
          }.bind(this))
          .catch(function (error) {
            this.modalFormSuccess = false;
            if (error.response.status === 400) {
              this.errorsBag = error.response.error_fields;
              this.toggleParentLoading(false);
            } else if (500 <= error.response.status && error.response.status < 600) {
              this.toggleParentLoading(true, true);
            }
          }.bind(this));
      } else {
        this.toggleParentLoading(true, true);
      }
    },
    closeModal() {
      this.modalAction = '';
      this.modalOpen = false;
    },
    toggleParentLoading(toggle, loaderError = false) {
      this.$emit('triggerLoading', toggle, loaderError);
    },
    setImageFile() {
      this.errorsBag = {};
      if (this.$refs.image_input.accept.indexOf(this.$refs.image_input.files[0].type.split('/')[1]) > 0) {

        let reader = new FileReader();
        reader.readAsDataURL(this.$refs.image_input.files[0]);
        reader.onload = evt => {
          let img = new Image();
          img.onload = () => {
            if (img.width !== img.height) {
              this.errorsBag = {image: 'image_square'};
            } else if (img.width !== 500 || img.height !== 500) {
              this.errorsBag = {image: 'image_500_500'};
            } else {
              this.formData.image = this.$refs.image_input.files[0];
              this.imageChanged = true;
              if (typeof this.formData.image !== "undefined") {
                this.image_preview = URL.createObjectURL(this.formData.image);
              }
            }
          }
          img.src = evt.target.result;
        }
      } else {
        this.errorsBag = {image: 'image_type_supported'}
      }
    },
    _prepareFormData() {
      let formData = new FormData();
      formData.append('image', this.formData.image);
      let name = {},
        index = 0;
      for (index in this.formData.name) {
        name[this.formData.name[index].lang_code] = this.formData.name[index].value;
      }
      formData.append('name', JSON.stringify(name));
      formData.append('company', this.formData.company);
      return formData;
    }
  }
}
</script>
<style scoped src="@/assets/styles/flexben.css"></style>

