<template>
  <div>
    <a class="btn text-themed go-back" @click="goBack()">{{ $t("flexben.general.go_back") }}</a>
    <div class="row">
      <div class="col-12 col-md-6 flexben-page-title">
        <h1>{{ (formAction==='create') ? $t('flexben.benefit_groups.new') : $t('flexben.benefit_groups.edit') }}</h1>
      </div>
      <div class="col-12 col-md-6 d-inline-flex">
        <span class="ml-md-auto">
        <button class="btn btn-delete text-danger" @click="modalAct('delete')"
                v-if="formAction==='update' && !formData.is_default">
          <font-awesome-icon icon="trash-alt"/>
          {{ $t('flexben.general.delete') }}
        </button>
          <button class="btn btn-themed px-4" @click="modalAct('form_submit')">{{ $t('flexben.general.btn.save') }}
        </button>
        </span>
      </div>
    </div>
    <div class="row mt-1" v-if="checkNotEmptyObj(errorsBag)">
      <div class="col-12">
        <div class="alert alert-danger alert-dismissible mb-0">
          <button type="button" class="close" data-dismiss="alert" @click="closeErrorButton = false">&times;</button>
          <form-error-list :errorBag="errorsBag"></form-error-list>
        </div>
      </div>
    </div>
    <div class="container-bg-white">
      <div class="row p-3">
        <div class="col-12">
          <h3 class="mt-0 pt-0 pl-0 mb-1">{{ $t('flexben.general.details') }}</h3>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mt-2">
          <div>
            <span class="col-form-label">{{ $t("flexben.benefit_groups.fields.type") }}:</span>&nbsp;
            <span>{{ formData.is_default ? $t("flexben.benefit_groups.types.default") : $t("flexben.benefit_groups.types.not_default") }}</span>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-8 mt-2">
          <div class="">
            {{ $t("flexben.benefit_groups.fields.benefit_type") }}:
          </div>
          <div class="form-check" v-for="(benefit_type) in benefit_types" :key="benefit_type">
            <input class="form-check-input cursor-pointer" type="radio" :id="'benefit_type_'+benefit_type"
                   v-model.number="formData.benefit_type" :value="benefit_type"
                   :disabled="formAction==='update'"
                   checked>
            <label class="form-check-label cursor-pointer" :for="'benefit_type_'+benefit_type">
              {{ $t("flexben.benefit_groups.benefit_types."+benefit_type) }}
            </label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mt-2">
          <div class="form-group">
            <label for="group_name" class="form-fields-required col-form-label">{{
              $t("flexben.benefit_groups.fields.group")
              }}</label>
            <input id="group_name" type="text" class="form-control" v-model="formData.name"
                   :class="{'border-danger':(Object.keys(errorsBag).includes('group_name'))}">
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mt-2">
          <div class="form-group" v-if="formAction==='update'">
            <label for="group_ode" class="col-form-label">{{ $t("flexben.benefit_groups.fields.code") }}</label>
            <input id="group_ode" type="text" class="form-control" :value="formData.code" disabled>
          </div>
        </div>
      </div>
    </div>
    <flexible-benefits v-if="formData.benefit_type===1"
                       :currentLang="currentLang"
                       :formAction="formAction"
                       :benefits="benefits"
                       :formData="formData"></flexible-benefits>

    <fixed-benefits v-if="formData.benefit_type===2"
                    :currentLang="currentLang"
                    :formAction="formAction"
                    :benefits="benefits"
                    :formData="formData"></fixed-benefits>

    <div class="row my-3">
      <div class="col-12 d-inline-flex">
        <span class="ml-md-auto">
        <button class="btn btn-delete text-danger" @click="modalAct('delete')"
                v-if="formAction==='update' && !formData.is_default">
          <font-awesome-icon icon="trash-alt"/>
          {{ $t('flexben.general.delete') }}
        </button>
          <button class="btn btn-themed px-4" @click="modalAct('form_submit')">{{ $t('flexben.general.btn.save') }}
        </button>
        </span>
      </div>
    </div>
    <ScrollToTopBtn/>
    <confirm-modal :openModal="modalOpen"
                   :modalAction="modalAction"
                   :formSuccess="modalFormSuccess"
                   :endMessage="modalEndMessage"
                   @closeModal="closeModal"
                   @confirmAction="confirmAction"/>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import ConfirmModal from "@/components/flexben/benefit_groups/modals/ConfirmModal";
import FormErrorList from "@/components/fragments/FormErrorList";
import ScrollToTopBtn from "@/components/fragments/ScrollToTopBtn";
import FlexibleBenefits from "./partials/FlexibleBenefits";
import FixedBenefits from "./partials/FixedBenefits";

export default {
  name: "BenefitGroupDetail",
  components: {
    ScrollToTopBtn,
    "confirm-modal": ConfirmModal,
    "form-error-list": FormErrorList,
    "flexible-benefits": FlexibleBenefits,
    "fixed-benefits": FixedBenefits,
  },
  props: {
    companyId: {
      type: Number,
      // required: true,
    },
    formAction: {
      type: String,
      required: true,
    },
    groupId: {
      type: Number,
      // required: true,
    },
  },
  data() {
    return {
      httpService: httpFlexbenService.benefitGroup,
      initialLoading: false,
      loading: false,
      loaderError: false,
      currentLang: i18n.locale,

      benefits: {
        legislated: [],
        non_legislated: [],
        fixed_benefits: [],
      },
      formData: {
        id: null,
        company: null,
        benefit_type: null,
        name: '',
        code: '',
        is_default: false,
        benefit_ids: [],
      },
      benefit_types: [],
      isFormModified: false,
      errorsBag: {},
      modalOpen: false,
      modalFormSuccess: false,
      modalAction: '',
      modalEndMessage: '',
      readMoreBenId: null,
    }
  },
  watch: {
    formData: {
      deep: true,
      handler: function (data) {
        this.isFormModified = true;
      }
    },
    'formData.benefit_type': {
      deep: true,
      handler: function (data) {
        if (this.formAction === 'create') {
          this.formData.benefit_ids = [];
        }
      }
    },
  },
  created() {
    this.toggleParentLoading(true);
    if (this.formAction === 'update' && this.groupId !== null) {
      this.getGroup();
    } else if (this.formAction === 'create') {
      this.getCompanyOptions();
    } else {
      this.$emit('clearForm')
    }
  },
  mounted() {
    this.isFormModified = false;
  },
  methods: {
    getGroup() {
      this.httpService.details(this.groupId)
        .then(function (response) {
          this.formData.id = response.data.group.id;
          this.formData.name = response.data.group.name;
          this.formData.code = response.data.group.code;
          this.formData.benefit_type = response.data.group.benefit_type;
          this.formData.is_default = response.data.group.is_default;
          this.formData.benefit_ids = response.data.group.benefit_ids;
          this.formData.company = this.companyId;
          this.benefits.legislated = response.data.company_options.legislated_benefits;
          this.benefits.non_legislated = response.data.company_options.non_legislated_benefits;
          this.benefits.fixed_benefits = response.data.company_options.fixed_benefits;
          this.benefit_types = response.data.company_options.benefit_types;

          this.toggleParentLoading(false);

        }.bind(this))
        .catch(function (error) {
          this.toggleParentLoading(true, true);
        }.bind(this))
        .then(function () {
          this.isFormModified = false;
          this.initialLoading = true;
        }.bind(this));
    },
    getCompanyOptions() {
      this.httpService.createOptions({company: this.companyId})
        .then(function (response) {
          this.benefits.legislated = response.data.company_options.legislated_benefits;
          this.benefits.non_legislated = response.data.company_options.non_legislated_benefits;
          this.benefits.fixed_benefits = response.data.company_options.fixed_benefits;
          this.benefit_types = response.data.company_options.benefit_types;
          this.formData.company = this.companyId;
          this.toggleParentLoading(false);
        }.bind(this))
        .catch(function (error) {

        }.bind(this))
        .then(function () {
          this.isFormModified = false;
        }.bind(this));
    },
    goBack() {
      if (this.isFormModified) {
        this.modalAct('go_back')
      } else {
        this.$emit('clearForm');
      }
    },

    modalAct(action) {
      this.modalAction = action;
      this.modalOpen = true;
    },
    confirmAction(action) {
      if (action === 'go_back') {
        this.closeModal();
        this.isFormModified = false;
        this.goBack();
      }
      if (action === 'form_submit') {
        this.toggleParentLoading(true);
        this.modalOpen = false;
        if (this.modalAction === 'form_submit') {
          if (this.formAction === 'update') {
            this._updateGroup();
          } else {
            this._createGroup();
          }
        } else if (this.modalAction === 'delete') {
          this._deleteGroup();
        }
      }
      if (action === 'end') {
        this.closeModal();
        if (this.modalFormSuccess) {
          this.isFormModified = false;
          this.$emit('clearForm', true);
        }
      }
    },
    closeModal() {
      this.modalAction = '';
      this.modalOpen = false;
    },
    toggleParentLoading(toggle, loaderError = false) {
      this.$emit('triggerLoading', toggle, loaderError);
    },
    _updateGroup() {
      this.errorsBag = {};
      this.httpService.update(this.formData.id, this.formData)
        .then(function (response) {
          this.modalFormSuccess = true;
          this.modalAction = 'end';
          this.toggleParentLoading(false);
          this.modalOpen = true;
        }.bind(this))
        .catch(function (error) {
          this.modalFormSuccess = false;
          if (error.response.status === 400) {
            this.errorsBag = error.response.data.error_fields;
            this.toggleParentLoading(false);
          } else if (500 <= error.response.status && error.response.status < 600) {
            this.toggleParentLoading(true, true);
          }
        }.bind(this));
    },
    _deleteGroup() {
      this.errorsBag = {};
      this.httpService.delete(this.formData.id)
        .then(function (response) {
          this.modalFormSuccess = true;
          this.modalAction = 'end';
          this.toggleParentLoading(false);
          this.modalOpen = true;
        }.bind(this))
        .catch(function (error) {
          this.modalFormSuccess = false;
          if (error.response.status === 400) {
            this.errorsBag = error.response.error_fields;
            this.toggleParentLoading(false);
          } else if (500 <= error.response.status && error.response.status < 600) {
            this.toggleParentLoading(true, true);
          }
        }.bind(this));
    },
    _createGroup() {
      this.errorsBag = {};
      this.httpService.create(this.formData)
        .then(function (response) {
          this.modalFormSuccess = true;
          this.modalAction = 'end';
          this.toggleParentLoading(false);
          this.modalOpen = true;
        }.bind(this))
        .catch(function (error) {
          this.modalFormSuccess = false;
          if (error.response.status === 400) {
            this.errorsBag = error.response.data.error_fields;
            this.toggleParentLoading(false);
          } else if (500 <= error.response.status && error.response.status < 600) {
            this.toggleParentLoading(true, true);
          }
        }.bind(this));
    }
  }
}
</script>
