<template>
  <b-tab :title="$t('flexben.clients.benefits.partners')" :key="tabIndex"
         :class="'container-bg-white pt-2'">
    <div>
      <div class="row pt-2 px-3">
        <div class="col-12">
          <span class="text-20 text-bold">{{$t('flexben.clients.benefits.partners')}}</span>
        </div>
      </div>
      <div class="row mt-4 mx-1">
        <div class="col-12">
          <div class="container-fluid px-0 m-0">
            <div class="table-responsive hover-table">
              <table class="table no-min-width">
                <thead>
                <tr>
                  <th>{{ $t("flexben.benefit_groups.detail.ben_name") }}</th>
                  <th class="d-none d-md-table-cell">{{ $t("flexben.benefit_groups.detail.description") }}</th>
                  <th v-if="isAdmin" class="w-50-p"></th>
                </tr>
                </thead>


                <tbody v-if="benefits.length > 0">
                <tr v-for="(benefit, index) in benefits" :key="index"
                    class="trhover">
                  <td class="d-flex align-items-center">
                    <img class="flx-table-image mr-2" :src="benefit.image_url" loading="lazy"/>
                    <span :style="{backgroundColor: benefit.color}"
                          class="color-bullet mr-2"></span> {{benefit.name }}
                  </td>
                  <td class="d-none d-md-table-cell links-container">
                    <div class="text-ellipsis-40" :ref="'benefit_details_'+benefit.id"
                         v-html="benefit.description[locale].substring(0, 100) + '....'">
                    </div>
                    <br>
                    <a class="text-themed btn-link cursor-pointer"

                       v-if="benefit.description[locale].length >100"
                       @click="readMore(benefit.id)">
                      {{ $t('flexben.general.'+(benefit.id===readMoreBenId ? 'read_less': 'read_more')) }}
                    </a>
                  </td>
                  <td v-if="isAdmin">
                    <button class="btn btn-themed-o" @click="openModal(benefit.id)">{{ $t('flexben.clients.dynamic_translations.btn') }}</button>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td colspan="2" class="text-center trhover gri-cell">
                    {{
                    $t("flexben.benefit_groups.no_benefits_available",{type:$t('flexben.benefit_groups.benefit_type_flexible')})
                    }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <partner-benefits-tanslations-modal :ref="'partner_benefit_modal'"
                                          v-if="isAdmin"
                                          :clientId="clientId"
                                          @clearBenefitId="clearBenefitId"
                                          @toggleLoading="toggleLoading"
      ></partner-benefits-tanslations-modal>
    </div>
  </b-tab>
</template>

<script>
import {BTab} from "bootstrap-vue";
import i18n from "@/plugins/language/i18n";
import PartnerBenefitsTranslationsModal from "../modals/PartnerBenefitsTranslationsModal";

export default {
  name: "TabPartnerBenefits",
  components: {
    "b-tab": BTab,
    'partner-benefits-tanslations-modal': PartnerBenefitsTranslationsModal
  },
  props: {
    benefits: {
      type: Array,
      default: function () {
        return []
      },
      required: true
    },
    tabIndex: {
      required: true
    },
    isAdmin: {
      type: Boolean,
      default: false,
      required: true
    },
    clientId: {
      type: Number,
      required: true
    },
  },
  data: function () {
    return {
      locale: i18n.locale,
      readMoreBenId: null,
      translationsBenefitId: null
    };
  },
  methods: {
    readMore(benefitId) {
      if (this.readMoreBenId !== benefitId &&
        this.readMoreBenId !== null &&
        !this.$refs['benefit_details_' + this.readMoreBenId][0].classList.contains('text-ellipsis-40')) {
        this.$refs['benefit_details_' + this.readMoreBenId][0].classList.add('text-ellipsis-40');
      }

      if (this.$refs['benefit_details_' + benefitId][0].classList.contains('text-ellipsis-40')) {
        this.$refs['benefit_details_' + benefitId][0].classList.remove('text-ellipsis-40');
      } else {
        this.$refs['benefit_details_' + benefitId][0].classList.add('text-ellipsis-40');
      }
      this.readMoreBenId = (this.readMoreBenId === benefitId) ? null : benefitId;
    },
    openModal(benefitId) {
      this.translationsBenefitId = benefitId;
      this.$refs['partner_benefit_modal'].openModal(this.translationsBenefitId);
    },
    clearBenefitId() {
      this.translationsBenefitId = null;
    },
    toggleLoading(loading, loaderError = false) {
      this.$emit('toggleLoading', loading, loaderError)
    },
  }
}
</script>
