<template>
  <div class="container-bg-white mt-4">
    <div class="row px-3 pt-3">
      <div class="col-12">
        <h3 class="my-0 py-1 pl-0">{{$t("flexben.beneficiaries.titles."+type)}}</h3>
      </div>
      <div class="col-12 px-3" v-if="checkNotEmptyObj(errorBag)">
        <FormErrorList :errorBag="errorBag"></FormErrorList>
      </div>
      <div class="col-md-6 col-xl-4 form-group">
        <label for="total_budget" class="col-form-label">{{ $t('flexben.import.table_headings.total_budget') }}
          (Lei)</label>
        <input type="number" id="total_budget" v-model="budgetData.total_budget"
               step="0.01"
               :class="{'border-danger':checkInErrorBag('total_budget')}"
               class="form-control w-100">
      </div>
      <div class="col-md-6 col-xl-4 form-group">
        <label for="budget_expiration_date" class="col-form-label">{{
          $t('flexben.import.table_headings.expiration_date')
          }}</label>
        <div class="d-inline-flex w-100">
          <font-awesome-icon icon="calendar-alt" class="my-auto mr-2 text-24 flx-calendar-icon"/>
          <input v-if="disableInputDate" type="text" disabled class="form-control"
                 :value="budgetData.budget_expiration_date ? budgetData.budget_expiration_date.to.format('DD/MM/YYYY') : null">
          <datepicker v-else
                      :format="dateFormat"
                      v-model="budgetData.budget_expiration_date"
                      :inputClass="'form-control-d m-0 w-100 background-white'"
                      :class="{'w-100':true, 'border-danger':checkInErrorBag('budget_expiration_date')}"
                      :disabledDates="disableUntil"
                      id="budget_expiration_date"></datepicker>
          <img  v-if="!disableInputDate"
                class="cursor-pointer ml-2" alt="x"
               src="@/assets/images/flexben/table-remove-button.svg"
               @click="budgetData.budget_expiration_date=null"/>
        </div>
      </div>
      <div class="col-md-6 col-xl-4 form-group">
        <label for="budget_last_update" class="col-form-label">{{ $t('flexben.import.table_headings.updated_at')
          }}</label>
        <div class="d-inline-flex w-100">
          <font-awesome-icon icon="calendar-alt" class="my-auto mr-2 text-24 flx-calendar-icon"/>
          <input type="text" id="budget_last_update" disabled class="form-control w-100 m-0"
                 :value="moment(budgetData.updated_at,'DD/MM/YYYY')">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import FormErrorList from "@/components/fragments/FormErrorList";
import moment from "moment";

export default {
  name: "BeneficiaryBudget",
  components: {
    FormErrorList,
    Datepicker
  },
  props: {
    budgetData: {
      type: Object,
      required: true,
    },
    errorBag: {
      default: function () {
        return {}
      },
    },
    disableUntil: {
      type: Object,
      required: true,
    },
    lockedDate: {
      type: Object,
      required: false,
      default: null
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    disableInputDate: function () {
      return !!this.lockedDate;
    }
  },
  created() {
    if (this.lockedDate) {
      this.budgetData.budget_expiration_date = this.lockedDate;
    }
  },
  data() {
    return {
      dateFormat: 'dd/MM/yyyy',
    }
  },
  methods: {
    moment(date, format) {
      if (date === null) {
        return '-';
      }
      return moment(date).format(format);
    },
    checkInErrorBag(field) {
      if (!this.checkNotEmptyObj(this.errorBag)) {
        return false;
      }

      let objKeys = Object.keys(this.errorBag);
      return objKeys.includes(field);
    }
  }
}
</script>
