<template>
  <div>
    <div class="row mt-4 mx-1">
      <div class="col-12">
        <span class="text-28 text-bold">{{$t("flexben.clients.partial.configuration_for_top_ups")}}</span>
      </div>
    </div>
    <div class="row mx-1 my-3">
      <div class="col-12 ">
        <span class="text-24">{{$t("flexben.clients.partial.top_up_orders")}}</span>
      </div>
    </div>
    <div class="row mx-1" v-if="isUpDejun">
      <div class="col-12 col-md-6 col-xl-4">
        <label
          :for="'top_up_last_top_up_day'+benefitId" class="col-form-label">{{$t("flexben.clients.partial.last_day_of_the_month_allowing")}}</label>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <label
          :for="'top_up_last_top_up_hour_'+benefitId" class="col-form-label">{{$t("flexben.clients.partial.last_hour_of_the_month_allowing")}}</label>
      </div>
    </div>
    <div class="row mx-1 mb-3" v-if="isUpDejun">
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <select class="form-control" :id="'top_up_last_top_up_day'+benefitId"
                  v-model="configuration.last_top_up_day">
            <option v-for="day of generateRange(1,this.getLastDayCurrentMonth)" :value="day" :key="day">{{ day }}</option>
          </select>

        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <select class="form-control" :id="'top_up_last_top_up_hour_'+benefitId"
                  v-model="configuration.last_top_up_hour">
            <option v-for="day of generateRange(1,24)" :value="day" :key="day">{{ day }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mx-1" v-if="clientSettings.top_up_live" >
      <hr>
      <div class="col-4 mb-3">
        <div class="d-flex">
          <label :for="'top_up_virtual_toggle'+benefitId" class="mb-0 mr-4 mr-2 col-form-label p-0">
            {{$t("flexben.clients.partial.top_up_live")}}
          </label>
          <b-form-checkbox :id="'top_up_virtual_toggle'+benefitId"
                           switch
                           v-model="configuration.top_up_live.active">
          </b-form-checkbox>
        </div>
      </div>

      <div class="col-4 col-md-4 col-xl-4">
        <div class="form-group">
          <label :for="'top_live_last_day'+benefitId" class="col-form-label pt-0">{{
            $t("flexben.clients.partial.top_up_live_last_day")
            }}</label>
          <select class="form-control" :id="'top_live_last_day'+benefitId"
                  :disabled="!configuration.top_up_live.active"
                  v-model="configuration.top_up_live.last_top_up_day"
                  @change="changeTopLiveLastInvoiceDay($event)">
            <option v-if="this.initialValues.lastTopUpDay < this.getCurrentDay">{{ this.initialValues.lastTopUpDay }}</option>
            <option v-for="day of generateRange(this.getCurrentDay,this.getLastDayCurrentMonth)" :value="day" :key="day">{{ day }}</option>
          </select>
        </div>
      </div>

      <div class="col-4 col-md-4 col-xl-4">
        <div class="form-group">
          <label :for="'top_live_last_day_invoice'+benefitId" class="col-form-label pt-0">{{
            $t("flexben.clients.partial.top_up_live_last_day_invoice")
            }}</label>
          <select class="form-control" :id="'top_live_last_day_invoice'+benefitId" :disabled="!configuration.top_up_live.active" v-model="configuration.top_up_live.last_top_up_day_invoice">
            <option v-if="this.showInitialInvoiceDaysValuesConditions">{{ this.initialValues.lastTopUpDayInvoice }}</option>
            <option v-for="day of generateRange(getInvoiceNextDayTopUpLive(configuration.top_up_live.last_top_up_day),this.getLastDayCurrentMonthInvoiceDay)" :value="day" :key="day" >{{ day }}</option>
            <option v-if="this.showInitialInvoiceDaysValuesConditionsOne" value="1">1</option>
          </select>
        </div>
      </div>

      <hr>
    </div>

    <div class="row mx-1">
      <div class="col-12 mb-3">
        <div class="d-flex">
          <label :for="'top_up_auto_toggle'+benefitId" class="mb-0 mr-4 mr-2 col-form-label p-0">
            {{$t("flexben.clients.partial.toggle_direct_top_up")}}
          </label>
          <b-form-checkbox :id="'top_up_auto_toggle'+benefitId"
                           switch
                           v-model="configuration.top_up.auto">
          </b-form-checkbox>
        </div>
      </div>
    </div>
    <div class="row mx-1">
      <div class="col-md-6 col-xl-4">
        <div class="form-group">
          <label :for="'top_up_days_nr'+benefitId" class="col-form-label pt-0">{{$t("flexben.clients.partial.number_of_days_per_month")}}</label>
          <select :id="'top_up_days_nr'+benefitId"
                  class="form-control"
                  v-model="configuration.top_up.days_nr"
                  :disabled="!configuration.top_up.auto"
                  @change="removeDaysVal('top_up')">
            <option :value="1">1</option>
            <option :value="2">2</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="form-group">
          <label :for="'top_up_place_order_day_one'+benefitId" class="col-form-label pt-0">{{
            $t("flexben.clients.partial.select_first_day_auto_order")
            }}</label>
          <select name="top_up_place_order_day_one" :id="'top_up_place_order_day_one'+benefitId" class="form-control"
                  :disabled="(!configuration.top_up.auto || configuration.top_up.days_nr < 1)"
                  v-model="configuration.top_up.place_order_day_one">
            <option :value="item" v-for="item in daysDropdownValues('top_up',1)" :key="item">{{ item }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="form-group">
          <label :for="'top_up_place_order_day_two'+benefitId" class="col-form-label pt-0">{{
            $t("flexben.clients.partial.select_second_day_auto_order")
            }}</label>
          <select name="top_up_place_order_day_one" :id="'top_up_place_order_day_two'+benefitId" class="form-control"
                  :disabled="(!configuration.top_up.auto || configuration.top_up.days_nr < 2)"
                  v-model="configuration.top_up.place_order_day_two">
            <option :value="item" v-for="item in daysDropdownValues('top_up',2)" :key="item">{{ item }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row mx-1" v-if="isUpDejun && isAdmin" >
      <div class="col-12 my-3">
          <span class="text-24">
            {{$t("flexben.clients.partial.timesheets_upload_config")}}
          </span>
      </div>
      <div class="col-12 mb-3">
        <div class="d-flex">
          <label :for="'multiple_timesheets_upload'+benefitId"
                 class="d-inline-block mb-0 mr-4 mr-2 col-form-label p-0">
            {{$t("flexben.clients.partial.toggle_multiple_uploading_timesheets")}}
          </label>
          <b-form-checkbox :id="'multiple_timesheets_upload'+benefitId" class="d-inline-block"
                           switch
                           v-model="configuration.multiple_upload_timesheet.active">
          </b-form-checkbox>
        </div>
      </div>

      <div class="col-12 mb-3">
        <div class="d-flex">
          <label :for="'multiple_timesheets_single_order'+benefitId"
                 class="d-inline-block mb-0 mr-4 mr-2 col-form-label p-0">
            {{$t("flexben.clients.partial.toggle_multiple_timesheet_single_order")}}
          </label>
          <b-form-checkbox :id="'multiple_timesheets_single_order'+benefitId" class="d-inline-block"
                           switch
                           v-model="configuration.multiple_upload_timesheet.single_auto_request">
          </b-form-checkbox>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {BFormCheckbox} from "bootstrap-vue";

export default {
  components: {
    "b-form-checkbox": BFormCheckbox,
  },
  name: "AutoPlaceOrderSettings",
  props: {
    isUpDejun: {
      type: Boolean,
      require: false,
      default: false
    },
    isAdmin: {
      type: Boolean,
      require: false,
      default: false
    },
    configuration: {
      type: Object,
      require: true,
      default: function () {
        return {}
      }
    },
    benefitId: {
      type: Number,
      require: true,
      default: 0
    },
    clientSettings: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
  },
  data: () => ({
    initialValues: {
      lastTopUpDay: null,
      lastTopUpDayInvoice: null,
      lastTopUpDayInvoiceChanged: false,
    }
  }),
  watch: {
    'configuration.top_up_live.active': {
      handler(val) {
        if (val == true) {
          this.configuration.top_up.auto = !this.configuration.top_up_live.active;
        }
      }
    },
    'configuration.top_up.auto': {
      handler(val) {
        if (val == true) {
          this.configuration.top_up_live.active = !this.configuration.top_up.auto;
        }
      }
    },
  },
  computed: {
    getCurrentDay: function () {
      let date = new Date();
      return date.getDate();
    },
    getLastDayCurrentMonth: function () {
      let date = new Date();
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return lastDay.getDate();
    },
    getLastDayCurrentMonthInvoiceDay: function () {
      if (this.configuration.top_up_live.last_top_up_day_invoice === 1) {
        return 1;
      }
      let date = new Date();
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return lastDay.getDate();
    },
    showInitialInvoiceDaysValuesConditions: function () {
      return (this.initialValues.lastTopUpDayInvoice < this.getCurrentDay && this.initialValues.lastTopUpDayInvoice != 1 && this.configuration.top_up_live.last_top_up_day_invoice != 1 && this.initialValues.lastTopUpDayInvoice);
    },
    showInitialInvoiceDaysValuesConditionsOne: function () {
      return (this.initialValues.lastTopUpDayInvoiceChanged === false && this.configuration.top_up_live.last_top_up_day !== this.getLastDayCurrentMonth) || (this.initialValues.lastTopUpDayInvoiceChanged === false && this.initialValues.lastTopUpDayInvoice === 1);
    },
  },
  methods: {
    removeDaysVal(key) {
      if (key === 'top_up') {
        if (this.configuration.top_up.days_nr === 1) {
          this.configuration.top_up.place_order_day_two = null;
        }
      }
    },
    daysDropdownValues: function (key, day) {
      let arr = [];
      let config = {...this.configuration};
      let elem;
      for (elem = 1; elem <= 31; elem++) {
        if (day === 1 &&
          (key === 'top_up' && elem === config.top_up.place_order_day_two)
        ) {
          continue;
        }
        if (day === 2 &&
          (key === 'top_up' && elem === config.top_up.place_order_day_one)
        ) {
          continue;
        }
        arr.push(elem);
      }
      return arr;
    },
    getInvoiceNextDayTopUpLive(value) {
      let defaultInvoiceDay = parseInt(value) + 1;
      let invoiceDay = defaultInvoiceDay > this.getLastDayCurrentMonth ? 1 : defaultInvoiceDay;
      if (invoiceDay < this.getCurrentDay && invoiceDay !== 1) {
        return this.getCurrentDay + 1;
      }
      return invoiceDay;
    },
    changeTopLiveLastInvoiceDay(event) {
      this.configuration.top_up_live.last_top_up_day_invoice = this.getInvoiceNextDayTopUpLive(event.target.value);
      this.configuration.last_top_up_day = event.target.value;
      this.initialValues.lastTopUpDayInvoiceChanged = this.configuration.top_up_live.last_top_up_day_invoice === 1;
    },
  },
  mounted() {
     this.initialValues.lastTopUpDay = this.configuration.top_up_live.last_top_up_day;
     this.initialValues.lastTopUpDayInvoice = this.configuration.top_up_live.last_top_up_day_invoice === 1 ? null : this.configuration.top_up_live.last_top_up_day_invoice;
  },

}
</script>
