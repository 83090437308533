<template>
  <b-modal id="modal-1"
           ref="status_modal"
           :hide-header-close="true"
           headerClass="border-0 text-center pb-0"
           bodyClass="modal-body no-border background-white text-center "
           footerClass="modal-footer border-top-0"
           centered>
    <template v-slot:modal-header>
      <h3 v-if="status === 'go_back'"
          key="modal_header">
        {{$t("flexben.beneficiaries.modal.the_information_has_been_changed")}}
      </h3>
      <h3 v-else-if="status!==null && status !== 'go_back'" class="m-0 mx-auto"
          key="modal_header">{{ $t('flexben.general.warning') }}</h3>
      <h3 v-else-if="status===null" class="m-0 mx-auto"
          key="modal_header">{{ message.error ? $t('flexben.general.status_type.error') :
        $t('flexben.general.status_type.success') }}</h3>
    </template>
    <template v-slot:default>
      <div v-show="message.show"
           class="text-center text-16 text-bold" :class="{'text-danger':message.error}">{{ message.text }}
      </div>
      <template v-if="status==='delete'">
        <p class="text-center">{{$t("flexben.beneficiaries.modal.this_will_remove_the_beneficiary_from."+(plural ?
          1 : 0),{count:userCount})}} <span class="text-bold">{{ companyName }}</span>
        </p>
        <p class="text-center">{{$t("flexben.beneficiaries.modal.any_available_budget."+(plural ? 1 : 0))}}</p>
        <p class="text-center">{{$t("flexben.beneficiaries.modal.this_action_cannot_be_reverted")}}</p>
        <div class="text-center">
          <label for="last_employment_date" class="bold w-50 mx-auto">{{
            $t('flexben.import.table_headings.last_employment_date') }}:</label>
          <br>
          <div class="row">
            <div class="col-12 col-md-6 mx-auto">
              <div class="d-inline-flex form-group mb-0">
                <font-awesome-icon icon="calendar-alt" class="mt-2 mr-2 flx-calendar-icon"/>
                <datepicker :format="dateFormat"
                            :inputClass="'form-control-d m-0 w-100 background-white'"
                            :class="'w-100'"
                            v-model="lastEmploymentDate"
                            :disabledDates="disableUntil"
                            id="last_employment_date"></datepicker>
              </div>
            </div>
          </div>
        </div>

      </template>
      <template v-if="status==='suspend'">
        <p class="text-center"
           v-html="$t('flexben.beneficiaries.modal.this_will_suspend_the_recipient_from.'+(plural ? 1 : 0), {companyName:companyName, count:userCount})"></p>
        <p class="text-center">{{$t("flexben.beneficiaries.modal.you_will_still_be_able")}}</p>
        <p class="text-center">{{$t("flexben.beneficiaries.modal.during_suspension_the_beneficiary."+(plural ? 1 :
          0)) }}</p>
        <p class="text-center mb-0">{{$t("flexben.beneficiaries.modal.this_action_can_be_reverted_at_any_time")}}</p>
      </template>
      <template v-if="status==='activate'">
        <p class="text-center mb-0"
           v-html="$t('flexben.beneficiaries.modal.this_will_reactivate_the_beneficiary.'+(plural ? 1 : 0), {companyName: companyName})"></p>
      </template>
      <template v-if="status==='toInvited'">
        <p class="text-center mb-0"
           v-html="$t('flexben.beneficiaries.modal.this_will_change_status_to_invited')"></p>
      </template>
      <template v-if="status==='reActivate'">
        <p class="text-center mb-0"
           v-html="$t('flexben.beneficiaries.modal.this_will_reactivate_user')"></p>
      </template>
      <template v-if="status==='reinvite'">
        <p class="text-center mb-0"
           v-html="$t('flexben.beneficiaries.modal.an_email_invitation_will', {companyName: companyName})">
        </p>
        <p class="text-center mb-0">{{$t("flexben.beneficiaries.modal.if_you_have_changed_the_email")}}</p>
      </template>
      <template v-if="status==='form_submit'">
        <p class="text-center mb-0"> {{$t("flexben.beneficiaries.modal.are_you_sure_you_want_to_save_the_changes")}}</p>
      </template>

    </template>
    <template v-slot:modal-footer>
      <button v-if="status === null"
              type="button" @click="closeModal()"
              class="btn save-btn float-right m-auto px-3">
        {{$t("flexben.general.btn.continue")}}
      </button>
      <template v-if="status !== null">
        <button type="button" @click="closeModal()" class="btn px-3 mx-auto text-themed">
          {{$t("flexben.general.btn.cancel")}}
        </button>
        <button type="button" @click="confirm()" class="btn btn-themed mx-auto px-3">
          {{$t("flexben.clients.modal.confirm")}}
        </button>
      </template>

    </template>
  </b-modal>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import {BModal} from "bootstrap-vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  name: "StatusChangeModal",
  components: {
    'b-modal': BModal,
    Datepicker
  },
  props: {
    // plural: {
    //   type: Boolean,
    //   default: false,
    //   required: false
    // },
  },
  data: function () {
    return {
      httpService: httpFlexbenService.clients.translations,
      activeBenefit: null,
      benefitTranslations: {},
      message: {
        show: false,
        error: false,
        text: null,
      },
      status: null,
      companyName: null,
      lastEmploymentDate: null,
      dateFormat: 'dd.MM.yyyy',
      disableUntil: {to: moment().toDate()},
      plural: false,
      userCount: 1,
    };
  },
  methods: {
    openModal(companyName, status, userCount = 1) {
      this._resetMessage();
      this.status = status;
      this.lastEmploymentDate = null;
      this.plural = userCount > 1;
      this.userCount = userCount;
      this.companyName = companyName;
      let userRole = this.flxUserLevel().userLevel;
      let disableUntil = moment();
      if (userRole === 'client'){
        disableUntil.add(48, 'hours');
      }
      this.disableUntil = {to:disableUntil.toDate()};
      this.$refs['status_modal'].show();
    },
    closeModal() {
      this.$refs['status_modal'].hide();
    },
    confirm() {
      if (this.status === 'delete' && this.lastEmploymentDate === null) {
        this.setMessage('Alegeti data de stergere!', true)
        return;
      }
      this.$emit('confirmAction', this.lastEmploymentDate);
      // this.$refs['status_modal'].hide();
    },
    setMessage(text, error = false, clearStatus = false) {
      if (clearStatus) {
        this.status = null;
      }
      this.message.show = true;
      this.message.error = error;
      this.message.text = text;

    },
    _resetMessage() {
      this.message = {
        show: false,
        error: false,
        text: null,
      };
    }
  }
}
</script>
