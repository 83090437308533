<template>
  <div>
    <div v-if="!initialLoading">
      <div class="row">
        <div class="col-12 col-md-6 flexben-page-title d-flex flex-row align-items-center">
          <h1 class="mr-2 mb-0">{{ requestData.benefit.name }}</h1>
          <span class="flx-type-badge">{{ $t("flexben.benefit.sub_types."+requestData.benefit_sub_type) }}</span>
          <span v-if="requestData.for_demo" class="text-danger text-18 text-bold ml-2"> - Companie DEMO</span>
        </div>
        <div class="col-12 col-md-6 text-right">
          <button class="btn text-danger" @click="openRejectModal()">
            <font-awesome-icon icon="trash" class="mr-1"/>
            {{ $t("flexben.requests.btn.reject_request") }}
          </button>
        </div>
      </div>
      <div v-if="errorPlacingOrder" class="my-3">
        <div class="alert alert-danger alert-dismissible mb-0 text-danger">
          <a type="button" class="close btn" data-dismiss="alert" @click="errorPlacingOrder = false">&times;</a>
          {{ $t("flexben.general.error_ups") }}
        </div>
      </div>
      <div class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-12">
            <h3 class="m-0 p-0">{{ $t("flexben.requests.title_placed_at",{number:requestData.id}) }} {{
              moment(requestData.placed_at, 'DD/MM/YYYY HH:mm') }}</h3>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">

            <label class="col-form-label">{{ $t('flexben.requests.fields.value') }}</label>
            <div class="form-control disabled">{{ requestData.total_value }}</div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.benefit.world_class_fields.subscription_periods') }}</label>
            <div class="form-control disabled">
              {{
              $t('flexben.benefit.subscription_periods.'+requestData.world_class_order_request.subscription_period_code)
              }}
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.benefit.world_class_fields.location') }}</label>
            <div class="form-control disabled">
              {{requestData.world_class_order_request.location_name}}
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.benefit.world_class_fields.level') }}</label>
            <div class="form-control disabled">
              {{requestData.world_class_order_request.level}}
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.benefit.world_class_fields.is_local_price') }}</label>
            <div class="form-control disabled">
              {{ $t('flexben.general.'+(requestData.world_class_order_request.is_local_price? 'yes' : 'no')) }}
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.benefit.world_class_fields.subscription_start_day') }}</label>
            <div class="form-control disabled">
              {{ moment(requestData.world_class_order_request.subscription_start_day,'DD/MM/YYYY') }}
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.reports.world_class.fields.phone') }}</label>
            <div class="form-control disabled">
              {{ requestData.world_class_order_request.phone_number }}
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.reports.world_class.fields.email') }}</label>
            <div class="form-control disabled">
              {{ requestData.world_class_order_request.email }}
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.reports.world_class.fields.first_name') }}</label>
            <div class="form-control disabled">
              {{ requestData.world_class_order_request.first_name }}
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.reports.world_class.fields.last_name') }}</label>
            <div class="form-control disabled">
              {{ requestData.world_class_order_request.last_name }}
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.flexbenFormFields.birth_date') }}</label>
            <div class="form-control disabled">
              {{ requestData.world_class_order_request.birth_date ?
              moment(requestData.world_class_order_request.birth_date, 'DD/MM/YYYY') : '-' }}
            </div>
          </div>
        </div>
      </div>
      <RequestBenefitDynamicFields :requestData="requestData"/>
      <RequestEmployeeInfo :employee="requestData.company_employee"/>
      <div class="row mt-3">
        <div class="col-12 text-right">
          <button class="btn text-danger" @click="openRejectModal()">
            <font-awesome-icon icon="trash" class="mr-1"/>
            {{ $t("flexben.requests.btn.reject_request") }}
          </button>
        </div>
      </div>
    </div>
    <ConfirmMassPlaceOrderModal @placeOrder="placeOrder" ref="confirm_po_modal"/>
    <RejectModal :rejectData="rejectData"
                 :showDisableTopUps="false"
                 @confirmAction="rejectRequests()"
                 ref="reject-modal"/>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import RejectModal from "@/components/flexben/requests/modals/RejectModal";
import RequestBenefitDynamicFields
  from "@/components/flexben/requests/not_legislated/partials/RequestBenefitDynamicFields";
import RequestEmployeeInfo from "@/components/flexben/requests/not_legislated/partials/RequestEmployeeInfo";
import ConfirmMassPlaceOrderModal from "@/components/flexben/requests/modals/ConfirmMassPlaceOrderModal";

export default {
  name: "SubscriptionRequestDetails",
  components: {
    ConfirmMassPlaceOrderModal,
    RejectModal,
    RequestBenefitDynamicFields,
    RequestEmployeeInfo
  },
  props: {
    detailsId: {
      required: true,
      type: Number
    }
  },
  data: function () {
    return {
      initialLoading: true,
      loading: false,
      loaderError: false,
      httpService: httpFlexbenService.requests,
      requestData: {},
      benefitDynamicFormFields: [],
      rejectData: {
        ids: [],
        reject_reason: ''
      },
      errorPlacingOrder: false
    };
  },
  created() {
    this.rejectData.ids.push(this.detailsId);
    this._getRequestDetails();
  },
  methods: {
    goBack(withReRender = false) {
      this.$emit('hideDetails', withReRender)
    },
    openRejectModal() {
      this.$refs['reject-modal'].openModal();
    },
    rejectRequests() {
      this.loading = true;
      this.$refs['reject-modal'].closeModal();
      let payload = {...this.rejectData};

      this.httpService.rejectRequests(payload)
        .then((response) => {
          this.loading = false;
          this.goBack(true);
        })
        .catch((error) => {
          if (500 <= error.response.status && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false;
          }
        });
    },
    placeOrder() {
      this.$refs['confirm_po_modal'].closeModal();
      this.loading = true;
      this.errorPlacingOrder = false;
      this.httpService.orderRequests({
        company_id: this.companyId,
        ids: [this.detailsId]
      })
        .then(function (response) {
          if (response.data.benefit_ids_problems.length !== 0) {
            this.errorPlacingOrder = true;
          } else {
            this.goBack(true);
          }
          this.loading = false;
        }.bind(this))
        .catch(function (error) {
          this.loaderError = false;
        }.bind(this))
    },
    confirmPlaceOrder() {
      this.$refs['confirm_po_modal'].openModal('order');
    },
    _getRequestDetails() {
      this.loading = true;
      this.httpService.getRequestDetails(this.detailsId)
        .then((response) => {
          this.requestData = response.data.details;
          this.loading = false;
          this.initialLoading = false;
        })
        .catch((error) => {
          this.goBack();
        })
    },
  }
}
</script>
