<template>
  <b-modal data-backdrop="static" data-keyboard="false"
           v-model="modalShow"
           size="lg" centered
           :no-close-on-backdrop="true"
           :no-close-on-esc="true">
    <template v-slot:modal-header="{ close }" class="'no-border'">
      <h5>{{ (modalAction === 'edit') ? $t('flexben.configuration.table.edit_config') :
        $t('flexben.configuration.table.new_config') }}</h5>
    </template>
    <template v-if="this.itemHasError">
      <FormErrorList :errorBag="itemErrors"></FormErrorList>
    </template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group mb-0">
            <label class="col-form-label" for="name">{{$t("flexben.configuration.parameter_name")}}</label>
            <input v-model="activeItem.name" :disabled="modalAction === 'edit'" class="form-control py-0"
                   id="name"/>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mb-0">
            <label class="col-form-label" for="select_type">{{$t("flexben.general.type")}}</label>
            <select v-model="activeItem.type" class="form-control w-100" id="select_type"
                    :disabled="modalAction === 'edit'">
              <option value="percent">% ( {{$t("flexben.configuration.percent")}} )</option>
              <option value="numeric">& ( numeric )</option>
              <option value="text">* ( text )</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mt-3">
          <h3 class="p-0 my-0">{{$t("flexben.general.current_values")}}</h3>
          <div class="form-group mb-0">
            <label class="col-form-label" for="current_value">{{$t("flexben.configuration.current_value")}}</label>
            <input v-model="activeItem.value"
                   :disabled="modalAction === 'edit'"
                   class="form-control py-0 w-100"
                   id="current_value"/>
          </div>
          <div class="form-group mb-0">
            <label class="col-form-label" for="start_use_date">{{$t("flexben.general.valid_from")}}</label>
            <input type="date" v-model="activeItem.start_use_date"
                   :disabled="modalAction === 'edit'"
                   class="form-control py-0 w-100" id="start_use_date"/>
          </div>
        </div>
        <div class="col-md-6" v-show="modalAction === 'edit'">
          <div class="d-inline-flex w-100 mt-3">
            <h3 class="float-left my-0">{{$t("flexben.configuration.new_value")}}</h3>
            <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                 @click="clearFuture()"/>
          </div>
          <div class="form-group mb-0">
            <label for="future_value" class="col-form-label">{{$t("flexben.configuration.new_value")}}</label>
            <input v-model="activeItem.future_values.value" class="form-control py-0 w-100"
                   id="future_value"/>
          </div>
          <div class="form-group mb-0">
            <label for="future_start_date" class="col-form-label">{{$t("flexben.general.valid_from")}}</label> <br>
            <div class="d-inline-flex w-100">
              <label for="future_start_date" class="">
                <font-awesome-icon icon="calendar-alt" class="text-24 mt-1 mr-2 flx-calendar-icon"/>
              </label>
              <datepicker id="future_start_date"
                          v-model="activeItem.future_values.start_use_date"
                          :format="dateFormat"
                          :wrapper-class="'w-100'"
                          :inputClass="'form-control m-0 w-100 background-white'"
                          :disabledDates="disabledBeforeToday"
                          :clearButton="false"></datepicker>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer="{ ok, cancel }" :class="'no-border'">
      <button class="btn" @click="closeModal()">
        {{ $t("flexben.general.btn.cancel") }}
      </button>
      <button class="btn save-btn" @click="saveItem()">
        {{ $t("flexben.general.btn.save") }}
      </button>
    </template>
  </b-modal>
</template>

<script>
  import {BModal} from "bootstrap-vue";
  import FormErrorList from "@/components/fragments/FormErrorList";
  import Datepicker from "vuejs-datepicker";

  export default {
    name: "ConfigurationEditModal",
    components: {
      FormErrorList,
      "b-modal": BModal,
      'datepicker': Datepicker
    },
    props: {
      activeItem: {
        type: Object,
        require: true,
        default: function () {
          return {
            id: null,
            start_use_date: "",
            value: '',
            name: '',
            type: '',
            future_values: {
              id: null,
              start_use_date: null,
              value: null
            },
          };
        }
      },
      modalAction: {
        required: true,
        type: String,
        default: 'edit',
      },
      itemHasError: {
        required: true,
        type: Boolean,
        default: false,
      },
      itemErrors: {
        required: true,
        type: Object,
        default: function () {
          return {};
        },
      },
      modalShow: {
        required: true,
        type: Boolean,
        default: true
      },
    },
    data: function () {
      return {
        dateFormat: 'dd/MM/yyyy',
        disabledBeforeToday: {
          to: new Date()
        },
      };
    },
    methods: {
      saveItem() {
        this.$emit('saveItem');
      },
      closeModal() {
        this.$emit('closeModal');
      },
      clearFuture() {
        this.$emit('clearFuture');
      },
    }
  }
</script>
