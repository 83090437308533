<template>
  <div class="container-bg-white mt-3">
    <div class="row p-3">
      <div class="col-12">
        <h3 class="my-0 py-1 pl-0">{{$t("flexben.general.details")}}</h3>
      </div>
      <div class="col-12" v-if="checkNotEmptyObj(errorBag)">
        <FormErrorList :errorBag="errorBag"></FormErrorList>
      </div>
      <div class="col-12 mt-2"></div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="first_name" class="form-fields-required col-form-label">
          {{ $t('flexben.import.table_headings.first_name') }}</label>
        <input type="text" maxlength="255" id="first_name" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('first_name') || checkInErrorBag('name_mix')}"
               v-model="formData.first_name">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="last_name" class="form-fields-required col-form-label">
          {{ $t('flexben.import.table_headings.last_name') }}</label>
        <input type="text" maxlength="255" id="last_name" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('last_name') || checkInErrorBag('name_mix')}"
               v-model="formData.last_name">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="invitation_email" class="col-form-label" :class="{'form-fields-required':formData.invitation_channel === 'email'}">
          {{ $t('flexben.import.table_headings.invitation_email') }}</label>
        <input type="text" maxlength="255" id="invitation_email" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('invitation_email')}"
               :disabled="status==='active'"
               v-model="formData.invitation_email">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="phone_number" class=" col-form-label" :class="{'form-fields-required':formData.invitation_channel === 'sms'}">
          {{ $t('flexben.import.table_headings.phone_number') }}</label>
        <input type="text" maxlength="255" id="phone_number" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('phone_number')}"
               :disabled="status==='active'"
               v-model="formData.phone_number">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="cnp" class="col-form-label" :class="{'form-fields-required':formData.invitation_channel === 'email'}">{{ $t('flexben.import.table_headings.cnp') }}</label>
        <input type="text" maxlength="255" id="cnp" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('cnp')}"
               :disabled="status==='active'"
               v-model="formData.cnp">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="invitation_channel" class="form-fields-required col-form-label">
          {{ $t('flexben.import.table_headings.invitation_channel') }}</label>
        <select name="invitation_channel" id="invitation_channel"
                class="form-control w-100 m-0"
                :class="{'border-danger':checkInErrorBag('invitation_channel')}"
                :disabled="status==='active'"
                v-model="formData.invitation_channel">
          <option v-for="(item, index) of form_options.invitation_channels"
                  :key="index"
                  :value="item">{{ item }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
import FormErrorList from "@/components/fragments/FormErrorList";

export default {
  name: "OfferBeneficiaryDetails",
  components: {
    FormErrorList
  },
  props: {
    status: {
      type: String,
      default: '',
      required: true,
    },
    form_options: {
      type: Object,
      default: function () {
        return {
          invitation_channels: []
        };
      },
      required: true,
    },
    formData: {
      type: Object,
      default: function () {
        return {}
      },
      required: true,
    },
    errorBag: {
      default: function () {
        return {}
      },
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  methods: {
    checkInErrorBag(field) {
      if (!this.checkNotEmptyObj(this.errorBag)) {
        return false;
      }

      let objKeys = Object.keys(this.errorBag);
      return objKeys.includes(field);
    }
  }
}
</script>
