<template>
  <div class=" mt-4">
    <div class="container-bg-white">
      <div class="row p-3">
        <div class="col-12 text-28 text-bold">
          {{ $t('flexben.import_history.import_summary') }}
        </div>
        <div v-for="(item, index) in summary_list" :key="index" class="col-12 col-md-6 col-lg-4 mt-3">
          <span class="text-bold float-left w-65">{{ item.text }}</span>
          <div class="float-right pr-5 w-35 ">
            <span :class="item.value_class" class="mr-2">{{ item.value }}</span>
            <span class="cursor-pointer"
                  @click="setQuickFilter(item.quick_filter); filterValues();">
               <filter-icon v-if="item.quick_filter!==null"
                            :filter-class="item.quick_filter_class"></filter-icon>
            </span>

          </div>
        </div>
        <div v-if="importType==='beneficiary'" class="col-12 mt-4 text-dark">
          {{ $t('flexben.import.summary.notice_new_beneficiary') }}
        </div>
      </div>
    </div>
    <div class="container-bg-white mt-3">
      <ImportReviewFilters :filters="importData.filters"
                           :importFilterValues="importFilterValues"
                           @filterValues="filterValues"/>
    </div>
    <div class="container-bg-white mt-3">
      <div class="row p-3">
        <div class="col-12 text-28 text-bold">
          {{$t("flexben.import.entries")}}
        </div>
        <div :key="tableKey" class="col-12">
          <component :is="tableComponentName"
                     :importDeleteItemIds="importData.importDeleteItemIds"
                     :importFormOptions="importData.form_options"
                     :importItems="importData.items.data"
                     :ts_month="importData.import.ts_month"
                     :ts_year="importData.import.ts_year"
                     @checkForDelete="checkForDelete"
                     @editItem="editItem"></component>
        </div>
        <div class="col-12 p-0">
          <button :disabled="(importData.importDeleteItemIds.length === 0)"
                  class="btn text-danger"
                  @click="dropImportItems()">
            <font-awesome-icon class="mr-2" icon="trash-alt"/>
            {{ $t('flexben.import.buttons.delete_button',{ count:importData.importDeleteItemIds.length }) }}
          </button>
        </div>

        <div class="col-12 p-0">
          <listPagination
            :currentPage="importData.items.current_page"
            :endItem="importData.items.to"
            :itemsPerPage="importData.items.perPage"
            :startItem="importData.items.from"
            :totalItems="importData.items.total"
            @changePerPage="changePerPage"
            @pageChanged="pageChanged"></listPagination>
        </div>
      </div>
    </div>
    <component :is="editModalComponentName"
               v-if="openModalEditItem"
               :filters="importData.filters"
               :importFormOptions="importData.form_options"
               :importType="importType"
               :item_data="editItemData"
               @closeModal="closeModal"
               @dropImportItem="dropImportItem"
               @updateImportItem="updateImportItem"></component>
  </div>
</template>

<script>
import ImportTableBeneficiary from "./tables/ImportTableBeneficiary";
import ImportTableBudget from "./tables/ImportTableBudget";
import ImportTableTimesheet from "./tables/ImportTableTimesheet";
import ImportReviewFilters from "./ImportReviewFilters";
import ImportTableOfferBeneficiary from "./tables/ImportTableOfferBeneficiary";
import ImportTablePPP3 from "@/components/flexben/import/partials/tables/ImportTablePPP3";
import EditBeneficiaryModal from "../modals/EditBeneficiaryModal";
import EditBudgetModal from "../modals/EditBudgetModal";
import EditTimeSheetModal from "../modals/EditTimeSheetModal";
import EditPpp3Modal from "../modals/Ppp3Modal.vue";
import FilterIcon from "../../../fragments/FilterIcon";
import EditOfferBeneficiaryModal from "../modals/EditOfferBeneficiaryModal";
import ImportTableUpdateBeneficiary from "@/components/flexben/import/partials/tables/ImportTableUpdateBeneficiary.vue";
import EditUpdateBeneficiariesModal from "@/components/flexben/import/modals/EditUpdateBeneficiariesModal.vue";

export default {
  name: "ReviewImport",
  components: {
    FilterIcon,
    EditTimeSheetModal,
    EditBudgetModal,
    EditBeneficiaryModal,
    EditUpdateBeneficiariesModal,
    EditPpp3Modal,
    ImportReviewFilters,
    ImportTableTimesheet,
    ImportTableBudget,
    ImportTableBeneficiary,
    ImportTableUpdateBeneficiary,
    EditOfferBeneficiaryModal,
    ImportTableOfferBeneficiary,
    ImportTablePPP3
  },
  props: {
    importType: {
      type: String,
      default: function () {
        return '';
      },
      required: true
    },
    openModalEditItem: {
      type: Boolean,
      default: false,
      required: true
    },
    importData: {
      type: Object,
      default: function () {
        return {
          import: {},
          totals: {},
          filters: {},
          items: {},
          form_options: {max_working_days_month: 0},
          importDeleteItemIds: [],
        };
      },
      required: true,
    },
    unfinishedImport: {
      type: Boolean,
      default: function () {
        return false;
      },
      required: true
    },
    importFilterValues: {
      type: Object,
      default: function () {
        return {
          department: null,
          job_title: null,
          cost_center: null,
          working_point: null,
          benefit_category_code: null,
          status: 'all',
          invitation_channel: null,
        }
      },
      required: true,
    },
    updateItemResponse: {
      type: Object,
      required: true,
      default: function () {
        return {};
      }
    },
    tableKey: {
      type: Number,
      required: true,
      default: 0
    },
  },
  data() {
    return {
      editItemData: {},
      editItemIndex: null,
      tableComponentNames: {
        beneficiaries: 'ImportTableBeneficiary',
        update_beneficiaries: 'ImportTableUpdateBeneficiary',
        offer_beneficiaries: 'ImportTableOfferBeneficiary',
        budgets: 'ImportTableBudget',
        telework_budgets: 'ImportTableBudget',
        time_sheets: 'ImportTableTimesheet',
        ppp3: 'ImportTablePPP3'
      },
      editModalComponentNames: {
        beneficiaries: 'EditBeneficiaryModal',
        update_beneficiaries: 'EditUpdateBeneficiariesModal',
        offer_beneficiaries: 'EditOfferBeneficiaryModal',
        budgets: 'EditBudgetModal',
        telework_budgets: 'EditBudgetModal',
        time_sheets: 'EditTimeSheetModal',
        ppp3: 'EditPpp3Modal'
      }
    };
  },
  computed: {
    summary_list: function () {
      let returnData = [];
      switch (this.importType) {
        case 'beneficiaries':
        case 'update_beneficiaries':
        case 'offer_beneficiaries':
          returnData = [
            {
              text: this.$t('flexben.import.summary.total_items'),
              value_class: '',
              value: this.importData.totals.total_items,
              quick_filter: null,
              quick_filter_class: ''
            },
            {
              text: this.$t('flexben.import.summary.total_valid'),
              value_class: 'text-green',
              value: this.importData.totals.total_valid,
              quick_filter: null,
              quick_filter_class: ''
            },
            {
              text: this.$t('flexben.import.summary.total_invalid'),
              value_class: 'text-danger',
              value: this.importData.totals.total_invalid,
              quick_filter: 'invalid',
              quick_filter_class: 'filter-icon-danger'
            },
            {
              text: this.$t('flexben.import.summary.total_new'),
              value_class: 'text-green',
              value: this.importData.totals.total_new,
              quick_filter: 'new',
              quick_filter_class: 'filter-icon-success'
            },
            {
              text: this.$t('flexben.import.summary.total_duplicate'),
              value_class: 'text-danger',
              value: this.importData.totals.total_duplicate,
              quick_filter: 'duplicate',
              quick_filter_class: 'filter-icon-danger'
            },
            {
              text: this.$t('flexben.import.summary.total_update'),
              value_class: 'text-themed',
              value: this.importData.totals.total_update,
              quick_filter: 'update',
              quick_filter_class: 'filter-icon-warning'
            },
          ];
          break;
        case 'budgets':
        case 'ppp3_budgets':
        case 'telework_budgets':
          returnData = [
            {
              text: this.$t('flexben.import.summary.total_items'),
              value_class: '',
              value: this.importData.totals.total_items,
              quick_filter: null,
              quick_filter_class: ''
            },
            {
              text: this.$t('flexben.import.summary.total_amount'),
              value_class: '',
              value: this.importData.totals.total_budget + ' Lei',
              quick_filter: null,
              quick_filter_class: ''
            },
            {
              text: this.$t('flexben.import.summary.total_valid'),
              value_class: 'text-green',
              value: this.importData.totals.total_valid,
              quick_filter: 'new',
              quick_filter_class: 'filter-icon-success'
            },
            {
              text: this.$t('flexben.import.summary.total_invalid'),
              value_class: 'text-danger',
              value: this.importData.totals.total_invalid,
              quick_filter: 'invalid',
              quick_filter_class: 'filter-icon-danger'
            },
          ];
          break;
        case 'time_sheets':
          returnData = [
            {
              text: this.$t('flexben.import.summary.total_items'),
              value_class: '',
              value: this.importData.totals.total_items,
              quick_filter: null,
              quick_filter_class: ''
            },
            {
              text: this.$t('flexben.import.summary.time_sheet_date'),
              value_class: '',
              value: this.$t('orderMonth_' + this.importData.import.ts_month) + ' ' + this.importData.import.ts_year,
              quick_filter: null,
              quick_filter_class: ''
            },
            {
              text: this.$t('flexben.import.summary.total_valid'),
              value_class: 'text-green',
              value: this.importData.totals.total_valid,
              quick_filter: null,
              quick_filter_class: 'filter-icon-success'
            },
            {
              text: this.$t('flexben.import.summary.total_invalid'),
              value_class: 'text-danger',
              value: this.importData.totals.total_invalid,
              quick_filter: 'invalid',
              quick_filter_class: 'filter-icon-danger'
            },
            {
              text: this.$t('flexben.import.summary.total_new'),
              value_class: 'text-green',
              value: this.importData.totals.total_new,
              quick_filter: 'new',
              quick_filter_class: 'filter-icon-success'
            },
            {
              text: this.$t('flexben.import.summary.total_update'),
              value_class: 'text-themed',
              value: this.importData.totals.total_update,
              quick_filter: 'update',
              quick_filter_class: 'filter-icon-warning'
            },
          ];
          break;
        case 'ppp3':
          returnData = [
            {
              text: this.$t('flexben.import.summary.total_items'),
              value_class: '',
              value: this.importData.totals.total_items,
              quick_filter: null,
              quick_filter_class: ''
            },
            {
              text: this.$t('flexben.import.summary.total_valid'),
              value_class: 'text-green',
              value: this.importData.totals.total_valid,
              quick_filter: null,
              quick_filter_class: 'filter-icon-success'
            },
            {
              text: this.$t('flexben.import.summary.total_invalid'),
              value_class: 'text-danger',
              value: this.importData.totals.total_invalid,
              quick_filter: 'invalid',
              quick_filter_class: 'filter-icon-danger'
            },
            {
              text: this.$t('flexben.import.summary.total_new'),
              value_class: 'text-green',
              value: this.importData.totals.total_new,
              quick_filter: 'new',
              quick_filter_class: 'filter-icon-success'
            },
            {
              text: this.$t('flexben.import.summary.total_update'),
              value_class: 'text-themed',
              value: this.importData.totals.total_update,
              quick_filter: 'update',
              quick_filter_class: 'filter-icon-warning'
            },
          ];
          break;
        default:
          break;
      }

      return returnData;
    },
    tableComponentName: function () {
      return this.tableComponentNames[this.importType];
    },
    editModalComponentName: function () {
      return this.editModalComponentNames[this.importType];
    },
  },
  watch: {
    updateItemResponse: function (val) {
      this.editItemData = val;
    }
  },
  methods: {
    checkForDelete(itemId) {
      this.$parent.checkForDelete(itemId);
    },
    pageChanged(page) {
      this.$emit('pageChanged', page)
    },
    filterValues() {
      this.$emit('filterValues');
    },
    dropImportItems() {
      if (this.importData.importDeleteItemIds.length > 0) {
        this.$emit('dropImportItems');
      }
    },
    editItem(itemIndex) {
      this.editItemIndex = itemIndex;
      this.editItemData = {...this.importData.items.data[itemIndex]};
      this.$parent.openModalEditItem = true;
    },
    dropImportItem() {
      this.$parent.openModalEditItem = false;
      this.editItemIndex = null;
      this.$emit('dropSpecificItem', this.editItemData.id);
      this.editItemData = {};
    },
    closeModal() {
      this.$parent.openModalEditItem = false;
      this.editItemData = {};
      this.editItemIndex = null;
    },
    updateImportItem() {
      this.$emit('updateImportItem', this.editItemData);
    },
    changePerPage(perPage) {
      this.$emit('changePerPage', perPage);
    },
    setQuickFilter(status) {
      this.importFilterValues.department = '';
      this.importFilterValues.job_title = '';
      this.importFilterValues.cost_center = '';
      this.importFilterValues.working_point = '';
      this.importFilterValues.benefit_category_code = '';
      this.importFilterValues.status = status;
    },
    getInvalidReasons(invalidReasons) {
      let errorList = [];
      let indexKey, errorField;
      for (errorField in invalidReasons) {
        if (typeof invalidReasons[errorField] === 'string') {
          errorList.push(this.$t('flexben.formFieldError', {
            field: this.$t('flexben.import.table_headings.' + errorField),
            reason: this.$t('flexben.formErrorReasons.' + invalidReasons[errorField])
          }))
        } else {
          for (indexKey in invalidReasons[errorField]) {
            if (invalidReasons[errorField][indexKey] === 'exceeding_max_working_days_month') {
              errorList.push(this.$t('flexben.formFieldError', {
                field: this.$t('flexben.import.table_headings.' + errorField),
                reason: this.$t('flexben.formErrorReasons.' + invalidReasons[errorField][indexKey], {
                  days_nr: this.importData.form_options.max_working_days_month
                })
              }))
            } else {
              errorList.push(this.$t('flexben.formFieldError', {
                field: this.$t('flexben.import.table_headings.' + errorField),
                reason: this.$t('flexben.formErrorReasons.' + invalidReasons[errorField][indexKey])
              }))
            }
          }
        }
      }
      return errorList.join('<br>');
    },
  }
}
</script>
