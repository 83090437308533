<template>
  <b-modal
    id="dynamic-fields-modal"
    ref="dynamic-fields-modal"
    :hide-header-close="true"
    size="xl"
    centered>
    <template v-slot:modal-header>
      <h2 class="mb-0">{{ $t("flexben.requests.fields_modal.title") }}</h2>
    </template>
    <template v-slot:default>
      <div v-if="checkNotEmptyObj(metasList) || contractNumber!=null" class="row px-3 pb-3">
        <div class="col-12"><span class="text-bold">{{ $t("flexben.requests.table.header.contract_number") }}:</span>
          {{contractNumber}}
        </div>
        <div
          v-for="(field, index) in metasList"
          :key="index"
          class="col-12 col-md-6 col-xl-4 form-group mb-0">
          <label class="col-form-label" :for="index + field.key">
            <template v-if="typeof field.extra.label == 'object'">
              {{ field.extra.label[$store.state.language] }}
            </template>
            <template v-else>
              {{ field.extra.label }}
            </template>
            ({{ field.extra.type }}{{ field.extra.type === "input" ? " " + field.extra.inputType : "" }})
          </label>
          <div
            v-if="field.extra.type !== 'checklist' &&
              field.extra.type !== 'BsSwitch' &&
              field.extra.type !== 'vueMultiSelect' &&
              field.extra.type !== 'textArea' &&
              field.extra.type === 'input' &&
              field.extra.inputType !== 'date' &&
              field.extra.type === 'input' &&
              field.extra.inputType !== 'datetime' &&
              field.extra.type !== 'radios'"
            class="form-control disabled">
            {{ field.value }}
          </div>

          <div v-if="field.extra.type === 'radios'">
            <label
              v-for="(item, index) in field.extra.values"
              :key="index"
              class="mr-2">
              <input
                type="radio"
                :checked="field.value === item.value"
                disabled
                class="mr-1"/>{{ item.name }}</label>
          </div>
          <div v-if="field.extra.type === 'checklist'"
               class="form-control disabled">
            {{ getChecklistValues(field) }}
          </div>
          <div v-if="field.extra.type === 'BsSwitch'"
               class="form-control disabled">
            {{ getBsSwitchValue(field) }}
          </div>

          <div v-if="field.extra.type === 'vueMultiSelect'"
               class="form-control disabled">
            {{ getVueMultiSelectValues(field.value) }}
          </div>

          <div v-if="field.extra.type === 'input' && field.extra.inputType === 'date'"
               class="form-control disabled">
            {{ moment(field.value, "DD/MM/YYYY") }}
          </div>

          <div v-if="
              field.extra.type === 'input' &&
              field.extra.inputType === 'datetime'"
               class="form-control disabled">
            {{ moment(field.value, "DD/MM/YYYY HH:mm", "YYYY-MM-DDThh:mm") }}
          </div>
          <div v-if="field.extra.type === 'select'"
               class="form-control disabled">
            {{ field.value ? field.value : "-" }}
          </div>

          <textarea v-if="field.extra.type === 'textArea'"
                    :id="index + field.key"
                    rows="5"
                    class="form-control-textarea disabled m-0"
                    v-model="field.value"
                    disabled>
          </textarea>
        </div>
      </div>

      <div v-if="benefitVoucher!=null" class="row px-3">
        <div class="col-12">
          <span class="text-bold">{{ $t("flexben.voucher_stocks.fields.code") }}:</span>
          {{ benefitVoucher.code }}
        </div>
        <div class="col-12">
          <span class="text-bold">{{ $t("flexben.voucher_stocks.fields.start_date") }}:</span>
          {{ benefitVoucher.start_date ? moment(benefitVoucher.start_date, 'DD/MM/YYYY') : '-' }}
        </div>
        <div class="col-12">
          <span class="text-bold">{{ $t("flexben.voucher_stocks.fields.end_date") }}:</span>
          {{ moment(benefitVoucher.end_date, 'DD/MM/YYYY') }}
        </div>
        <div class="col-12">
          <span class="text-bold">{{ $t("flexben.voucher_stocks.fields.subscription_period") }}:</span>
          {{ benefitVoucher.subscription_period ? benefitVoucher.subscription_period : '-' }}
        </div>
        <div class="col-12">
          <span class="text-bold">{{ $t("flexben.voucher_stocks.fields.pin_code") }}:</span>
          {{ benefitVoucher.pin_code ? benefitVoucher.pin_code : '-' }}
        </div>
      </div>
      <div v-if="benefitSubType==='subscription_24_pay'" class="row px-3">
        <div v-for="(field, index) in metasList"
             :key="index">
          <div class="col-12">
            <span class="text-bold">{{ $t("flexben.benefit.transport_24_pay.order_id_24_pay") }}:</span>
            {{  field.extra.api_24_pay_response.id }}
          </div>
          <div class="col-12">
            <span class="text-bold">{{ $t("flexben.benefit.transport_24_pay.start_date") }}:</span>
            {{  field.extra.api_24_pay_response.startDate }}
          </div>
          <div class="col-12">
            <span class="text-bold">{{ $t("flexben.benefit.transport_24_pay.expiration_date") }}:</span>
            {{  field.extra.api_24_pay_response.expirationDate }}
          </div>
          <div class="col-12">
            <span class="text-bold">{{ $t("flexben.benefit.transport_24_pay.phone_number") }}:</span>
            {{ field.extra.phone_number}}
          </div>
          <div class="col-12">
            <span class="text-bold">{{ $t("flexben.benefit.transport_24_pay.provider_name") }}:</span>
            {{ field.extra.provider_name}}
          </div>
          <div class="col-12">
            <span class="text-bold">{{ $t("flexben.benefit.transport_24_pay.subscription_name") }}:</span>
            {{ field.extra.product.name}}
          </div>
          <div class="col-12">
            <span class="text-bold">{{ $t("flexben.benefit.transport_24_pay.period_name") }}:</span>
            {{ field.extra.product.periodName}}
          </div>
        </div>
      </div>
      <div v-if="worldClassOrderRequest!=null" class="row px-3">
        <div class="col-12">
          <span class="text-bold">{{ $t('flexben.benefit.world_class_fields.subscription_periods') }}:</span>
          {{ $t('flexben.benefit.subscription_periods.'+worldClassOrderRequest.subscription_period_code) }}
        </div>
        <div class="col-12">
          <span class="text-bold">{{ $t('flexben.benefit.world_class_fields.location') }}:</span>
          {{worldClassOrderRequest.location_name}}
        </div>
        <div class="col-12">
          <span class="text-bold">{{ $t('flexben.benefit.world_class_fields.level') }}:</span>
          {{worldClassOrderRequest.level}}
        </div>
        <div class="col-12">
          <span class="text-bold">{{ $t('flexben.benefit.world_class_fields.is_local_price') }}:</span>
          {{ $t('flexben.general.'+(worldClassOrderRequest.is_local_price? 'yes' : 'no')) }}
        </div>
        <div class="col-12">
          <span class="text-bold">{{ $t('flexben.benefit.world_class_fields.subscription_start_day') }}:</span>
          {{ moment(worldClassOrderRequest.subscription_start_day,'DD/MM/YYYY') }}
        </div>
        <div class="col-12">
          <span class="text-bold">{{ $t('flexben.reports.world_class.fields.phone') }}:</span>
          {{ worldClassOrderRequest.phone_number }}
        </div>
        <div class="col-12">
          <span class="text-bold">{{ $t('flexben.reports.world_class.fields.email') }}:</span>
          {{ worldClassOrderRequest.email }}
        </div>
        <div class="col-12">
          <span class="text-bold">{{ $t('flexben.reports.world_class.fields.first_name') }}:</span>
          {{ worldClassOrderRequest.first_name }}
        </div>
        <div class="col-12">
          <span class="text-bold">{{ $t('flexben.reports.world_class.fields.last_name') }}:</span>
          {{ worldClassOrderRequest.last_name }}
        </div>
        <div class="col-12">
          <span class="text-bold">{{ $t('flexben.flexbenFormFields.birth_date') }}:</span>
          {{ worldClassOrderRequest.birth_date ?
          moment(worldClassOrderRequest.birth_date, 'DD/MM/YYYY') : '-' }}
        </div>
      </div>
      <div
        v-if="!checkNotEmptyObj(metasList) && contractNumber==null && benefitVoucher==null&& worldClassOrderRequest==null"
        class="row px-3">
        {{ $t("flexben.requests.fields_modal.no_results")}}
      </div>
    </template>
    <template v-slot:modal-footer>
      <button class="btn text-themed mx-auto" @click="closeModal()">
        {{ $t("flexben.general.btn.close") }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";

export default {
  name: "RequestDynamicFieldsModal",
  components: {
    "b-modal": BModal,
  },
  data: function () {
    return {
      metasList: [],
      contractNumber: null,
      benefitVoucher: null,
      worldClassOrderRequest: null,
      benefitSubType: null
    };
  },
  methods: {
    openModal(metasList, contractNumber = null, benefitVoucher = null, worldClassOrderRequest = null, benefitSubType = null) {
      this.contractNumber = contractNumber;
      this.benefitVoucher = benefitVoucher;
      this.worldClassOrderRequest = worldClassOrderRequest;
      this.benefitSubType = benefitSubType;

      let benefitDynamicFormFields = {};
      let ignoredMetaKeys = [
        "subscription_period_code",
        "delivery_type",
        "request_card",
        "voucher_billing_value",
        "card_series",
        "voucher_file_path",
        "voucher_code",
      ];
      for (let item of metasList) {
        if (!ignoredMetaKeys.includes(item.key)) {
          benefitDynamicFormFields[item.key] = item;
        }
      }
      this.metasList = benefitDynamicFormFields;
      this.$refs["dynamic-fields-modal"].show();
    },
    closeModal() {
      this.$refs["dynamic-fields-modal"].hide();
    },
    getVueMultiSelectValues(list) {
      if (list.length === 0) {
        return "-";
      }
      let returnVal = "";
      for (let index in list) {
        returnVal += list[index].value;
        if (index != list.length - 1) {
          returnVal += ", ";
        }
      }
      return returnVal;
    },
    getChecklistValues(field) {
      let returnVal = "";
      for (let index in field.value) {
        for (let valuesIndex in field.extra.values) {
          if (field.extra.values[valuesIndex].value === field.value[index]) {
            returnVal += field.extra.values[valuesIndex].name;
            if (index != field.value.length - 1) {
              returnVal += ", ";
            }
          }
        }
      }
      return returnVal;
    },
    getBsSwitchValue(field) {
      if (!field.value && typeof field.extra.leftText !== "undefined") {
        return field.extra.leftText;
      } else if (field.value && typeof field.extra.rightText !== "undefined") {
        return field.extra.rightText;
      } else {
        return this.$t("flexben.general." + (field.value ? "yes" : "no"));
      }
    },
  },
};
</script>
