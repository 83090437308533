<template>
  <div class="flexben-import w-100 mb-4">
    <div :class="initialLoading ? 'hide':''">
      <a class="go-back cursor-pointer" v-if="progressStep === 1" @click="goBack()">{{ $t("flexben.general.go_back")
        }}</a>
      <div class=" flexben-page-title">
        <h1>{{ isAdmin ? clientData.name : $t('flexben.import.title')}}</h1>
        <p v-if="!isAdmin">{{ $t('flexben.import.descriptive_text') }}</p>
      </div>
      <div class="w-100">
        <ClientHeader :clientData="clientData" :isAdmin="isAdmin" v-if="isAdmin"></ClientHeader>
      </div>
      <div class="container-bg-white mt-3" v-if="invalidImportReason !== null">
        <div class="p-3 bg-warning">
          <strong>{{ $t('flexben.general.warning') }}!</strong> {{ this.$t('flexben.import.invalid_reasons.' +
          invalidImportReason, invalidImportReasonExtra) }}
        </div>
      </div>

      <div class="d-inline-flex w-100">
        <div class="row mt-4 w-100">
          <div class="col-12 mb-4 text-28 text-bold" :class="(progressStep!==1)&&'hide'" v-if="isAdmin">
            {{ $t('flexben.import.descriptive_text') }}
          </div>
          <div class="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3">
            <ImportProgressBar :progress-bar-items="progressBarItems" :progressStep="progressStep"/>
          </div>
        </div>
      </div>

      <ChoseImportType v-if="progressStep === 1"
                       :importTypesAccess="import_types_access"
                       :isAdmin="this.isAdmin"
                       @choseImport="choseImport"/>

      <UploadImportFile v-if="progressStep === 2"
                        :clientData="clientData"
                        :importType="importType"
                        :importFileName="importFileName"
                        :errorBag="errorBag"
                        @uploadFile="uploadFile"
                        ref="upload_file_comp"/>

      <ReviewImport v-if="progressStep === 3"
                    :importType="importType"
                    :importData="importData"
                    :unfinishedImport="unfinishedImport"
                    :importFilterValues="importFilterValues"
                    :updateItemResponse="updateItemResponse"
                    :tableKey="tableKey"
                    :openModalEditItem="openModalEditItem"
                    @checkForDelete="checkForDelete"
                    @filterValues="filterValues"
                    @pageChanged="pageChanged"
                    @changePerPage="changePerPage"
                    @dropImportItems="dropImportItems"
                    @updateImportItem="updateImportItem"
                    @dropSpecificItem="dropSpecificItem"/>

      <CompleteImport v-if="progressStep === 4"
                      :importFinish="importFinish"
                      @newImport="newImport"/>

      <div v-if="progressStep === 2 || progressStep === 3"
           class="mt-5 mb-2">
        <div class="w-100 text-center">
          <button class="btn mr-5 text-themed" @click="goBack()">
            {{ (progressStep === 2) ? $t('flexben.import.buttons.go_back_action') :
            $t('flexben.import.buttons.go_back_file_upload') }}
          </button>
          <button class="btn btn-themed py-0"
                  v-if="progressStep === 3"
                  :disabled="(importData.totals.total_items !== importData.totals.total_valid || importData.totals.total_items === 0)"
                  @click="nextStep()">
            {{ $t('flexben.import.buttons.confirm_complete') }}
          </button>
          <button class="btn btn-themed py-0"
                  v-if="progressStep === 2"
                  :disabled="(importFile === null)"
                  @click="nextStep()">
            {{ $t('flexben.import.buttons.next_step') }}
          </button>
        </div>
      </div>
    </div>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import ClientHeader from "@/components/fragments/ClientHeader";
import httpFlexbenService from "@/http/http-flexben.js";
import ImportProgressBar from "./partials/ImportProgressBar";
import ChoseImportType from "./partials/ChoseImportType";
import UploadImportFile from "./partials/UploadImportFile";
import ReviewImport from "./partials/ReviewImport";
import CompleteImport from "./partials/CompleteImport";

export default {
  name: 'ImportIndex',
  components: {
    CompleteImport,
    ReviewImport,
    UploadImportFile,
    ChoseImportType,
    ImportProgressBar,
    ClientHeader,
  },
  data() {
    return {
      httpServiceClient: httpFlexbenService.clients,
      httpServiceImport: httpFlexbenService.import,
      isAdmin: false, //change to dynamic
      initialLoading: true,
      loading: true,
      loaderError: false,
      errorBag: {},

      companyId: null,
      clientData: {
        id: '-',
        cui: '-',
        address: '-',
        iban: '-',
        name: '-',
        type_code: null,
      },
      import_types_access: {
        beneficiaries: true,
        budgets: true,
        time_sheets: true,
        telework_budgets: true,
        ppp3: true,
      },
      progressStep: 1,

      importId: null,
      importItemId: null,
      unfinishedImport: false,
      importType: '',
      importFile: null,
      importFileName: '',
      finishInitialImportLoad: false,
      importParams: {},
      importData: {
        import: {},
        totals: {},
        filters: {},
        items: {},
        form_options: {// value can differ based on import type
          max_working_days_month: 0,
          top_up_fixed_values: {
            enable: false,
            values: []
          },
          top_up_set_ticket_nr: {
            enable: false,
            ticket_value: null,
          },
          up_dejun_enabled: false,
          invitation_channel: []
        },
        importDeleteItemIds: [],
      },
      importFinish: {},
      tableKey: 0,

      invalidImportReason: null,
      invalidImportReasonExtra: {},
      importFilterValues: {
        department: null,
        job_title: null,
        cost_center: null,
        working_point: null,
        benefit_category_code: null,
        status: 'all',
        invitation_channel: null,
      },
      searchString: '',
      sortField: '',
      sortDirection: 'asc',
      reverseSort: false,
      updateItemResponse: {},
      progressBarItems: [
        {
          step: 1,
          type: 'item',
          text: 'chose_import',
          status_class: 'current'
        },
        {
          step: 2,
          type: 'separator',
          index: 1,
          status_class: '',
          width: 25,
          left: 13,
        },
        {
          step: 2,
          type: 'item',
          text: 'chose_file',
          status_class: ''
        },
        {
          step: 3,
          type: 'separator',
          index: 2,
          status_class: '',
          width: 25,
          left: 37,
        },
        {
          step: 3,
          type: 'item',
          text: 'review',
          status_class: ''
        },
        {
          step: 4,
          type: 'separator',
          index: 3,
          status_class: '',
          width: 23.3,
          left: 63,
        },
        {
          step: 4,
          type: 'item',
          text: 'confirmation',
          status_class: ''
        },
      ],

      openModalEditItem: false,
    };
  },
  created() {
    let permission = this.flxUserLevel();
    const employment = this.$store.getters.flx_activeEmployment;
    if (permission.userLevel === 'client' && employment != null) {
      this.companyId = employment.company_id;
      this.isAdmin = false;
      this._getCompanyData();
    } else if (permission.userLevel === 'admin') {
      if (typeof localStorage.getItem('flexbenCompanyId') !== "undefined" && localStorage.getItem('flexbenCompanyId') !== null) {
        this.companyId = localStorage.getItem('flexbenCompanyId');
        this._getCompanyData();
      } else {
        window.location.href = '/multibeneficii/import-history';
      }
      this.isAdmin = true;
    } else {
      window.location.href = '/multibeneficii';
    }
  },
  methods: {
    _getCompanyData() {
      this.httpServiceClient.loadClientSlim(this.companyId).then((response) => {
        this.clientData = response.data.company;
        this.import_types_access = response.data.import_types_access;
        this.initialLoading = false;
        this.loading = false;
        this._quickImportNav();
      })
        .catch((error) => {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else if (error.response.status >= 404) {
            this.goBack();
          }
        });
    },
    _quickImportNav(type) {
      let importType = sessionStorage.getItem('flxQuickImport');
      if (importType !== null) {
        sessionStorage.removeItem('flxQuickImport');
        this.choseImport(importType);
      }
    },
    goBack() {
      if (this.progressStep === 1) {
        if (this.isAdmin) {
          window.location.href = '/multibeneficii/clients';
        } else {
          if (window.history.length) {
            window.history.back();
          } else {
            window.location = '/multibeneficii/dashboard';
          }
        }
      } else {
        this.progressStep--;
        this.errorBag = {};
        if (this.progressStep === 1) {
          this.importType = '';
          this.importFile = null;
          this.importFileName = '';
          this.invalidImportReason = null;
        } else if (this.progressStep === 2) {
          /*
           * when coming from step 3 - review, we drop the current import
           */
          this.invalidImportReason = null;
          this._dropImport();
        }
      }
    },
    choseImport(type) {
      this.importType = type;
      this._checkExistingImport();
    },
    uploadFile(event) {
      this.invalidImportReason = null;
      this.importFile = event.target.files[0];
      this.importFileName = this.importFile.name;
    },
    nextStep() {
      if (this.progressStep === 2) {
        this._createImport();
      } else if (this.progressStep === 3) {
        this._completeImport();
      }
    },
    newImport() {
      this.progressStep = 1;
    },
    checkForDelete(itemId) {
      if (itemId === 'all') {
        let idsBag = this.importData.importDeleteItemIds;
        this.importData.items.data.forEach((obj) => {
          if (!idsBag.includes(obj.id)) {
            idsBag.push(obj.id);
          }
        });
        this.importData.importDeleteItemIds = idsBag;
      } else if (itemId === 'none') {
        let idsBag = this.importData.importDeleteItemIds;
        this.importData.items.data.forEach((obj) => {
          if (idsBag.includes(obj.id)) {
            let index = idsBag.indexOf(obj.id);
            this.importData.importDeleteItemIds.splice(index, 1);
          }
        });
        this.importData.importDeleteItemIds = idsBag;
      } else if (this.importData.importDeleteItemIds.includes(itemId)) {
        let index = this.importData.importDeleteItemIds.indexOf(itemId);
        this.importData.importDeleteItemIds.splice(index, 1);
      } else {
        this.importData.importDeleteItemIds.push(itemId);
      }
    },
    pageChanged(page) {
      this.importParams.page = page;
      this._reviewImport();
    },
    _checkExistingImport() {
      this.loading = true;

      this.httpServiceImport.checkExisting(this._getImportType(), this.companyId)
        .then(function (response) {
          if (response.data.action === 'new' || response.data.action === 'retry') {
            this.progressStep = 2;
            if (response.data.import_status === 'fail') {
              this.invalidImportReason = null;
            }
            this.loading = false;
          } else if (response.data.action === 'require_completion') {
            this.importId = response.data.import_id;
            this._reviewImport();
          }
        }.bind(this))
        .catch(function (error) {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false;
          }
        }.bind(this))
    },
    _createImport() {
      if (this.importFile !== null) {
        this.loading = true;
        let payload = new FormData();
        payload.append('import_file', this.importFile);
        this.httpServiceImport.create(this._getImportType(), this.companyId, payload)
          .then(function (response) {
            if (response.data.status === 'fail') {
              if (response.data.data !== null && typeof response.data.data.invalid_reason !== 'undefined') {
                if (response.data.data.invalid_reason === 'miss_match_last_top_up_day') {
                  this.invalidImportReason = response.data.data.invalid_reason;
                  this.invalidImportReasonExtra = {date: response.data.data.last_ts_date};
                } else {
                  this.invalidImportReason = response.data.data.invalid_reason;
                }

              } else {
                this.invalidImportReason = this.$t('flexben.import.invalid_reasons.import_completion_failed');
              }
              this.loading = false;
            } else {
              this.invalidImportReason = null;
              this.importId = response.data.id;
              this._reviewImport();
            }
            this._cleanInputFile();
          }.bind(this))
          .catch(function (error) {
            if (error.response.status >= 500 && error.response.status < 600) {
              this.loaderError = true;
            } else if (error.response.status >= 400 && error.response.status < 500) {
              this.errorBag = error.response.data.error_fields;
              this.loading = false;
            }
          }.bind(this))
      }
    },
    _cleanInputFile() {
      this.importFile = null;
      this.importFileName = '';
      this.$refs['upload_file_comp'].clearInputFileValue();
    },
    _reviewImport() {
      this.loading = true;
      this.httpServiceImport.review(this._getImportType(), this.companyId, this.importId, this.importParams)
        .then(function (response) {
          if (response.data.action === 'retry' && response.data.import_status === "pending") {
            setTimeout(function () {
              this._reviewImport();
            }.bind(this), 3000);
          } else if (response.data.action === 'retry' && response.data.import_status === "fail") {
            this.invalidImportReason = response.data.invalid_reason;
            this.progressStep = 2;
            this.loading = false;
          } else {
            if (!this.finishInitialImportLoad) {
              this.importData = {
                import: response.data.import,
                totals: response.data.totals,
                filters: response.data.filters instanceof Array ? {} : response.data.filters,
                items: response.data.items,
                form_options: response.data.form_options,
                importDeleteItemIds: [],
              };
              this.finishInitialImportLoad = true;
            } else {
              this.importData.items = response.data.items;
              this.tableKey++;
            }
            this.progressStep = 3;
            this.loading = false;
          }
        }.bind(this))
        .catch(function (error) {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else if (error.response.status === 404) {
            this.loading = false;
          }
        }.bind(this))
    },
    _completeImport() {
      if (this.importData.totals.total_items === 0) {
        return;
      }

      this.loading = true;
      this.httpServiceImport.complete(this._getImportType(), this.companyId, this.importId)
        .then(function (response) {
          this.importFinish = response.data;
          this.progressStep = 4;
        }.bind(this))
        .catch(function (error) {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
            this.importFinish = {status: 'fail'};
          } else {
            this.importFinish = {status: 'fail'};
          }
        }.bind(this))
        .then(function () {
          this.finishInitialImportLoad = false;
          this.importParams = {};
          this.importData = {
            import: {},
            totals: {},
            filters: {},
            items: {},
          };
          this.progressStep = 4;
          this.loading = false;
        }.bind(this));
    },
    _dropImport() {
      this.loading = true;
      this.httpServiceImport.drop(this.companyId, this.importId)
        .then(function (response) {
          this.finishInitialImportLoad = false;
          this.importParams = {};
          this.importData = {
            import: {},
            totals: {},
            filters: {},
            items: {},
          };
          this.importFile = null;
          this.importFileName = '';
          this.loading = false;
        }.bind(this))
        .catch(function (error) {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          }
        }.bind(this))
    },
    _getImportType() {
      switch (this.importType) {
        case "beneficiaries":
          return 'beneficiaries';
        case "offer_beneficiaries":
          return 'offer-beneficiaries';
        case "update_beneficiaries":
          return 'update_beneficiaries';
        case "budgets":
          return 'budgets';
        case "time_sheets":
          return 'time-sheets';
        case "telework_budgets":
          return 'telework-budgets';
        case "ppp3":
          return 'ppp3';
        default:
          return null;
      }
    },
    updateImportItem(payload) {
      this.loading = true;
      this.httpServiceImport.updateItem(this._getImportType(), this.companyId, this.importId, payload.id, payload)
        .then((response) => {
          this.finishInitialImportLoad = false;
          this.updateItemResponse = response.data;
          if (this.updateItemResponse.invalid_reasons === null) {
            this.openModalEditItem = false;
          }
          this._reviewImport();
        })
        .catch((error) => {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          }
        })
    },
    dropImportItems() {
      this.loading = true;
      let payload = {import_item_ids: this.importData.importDeleteItemIds};
      this.httpServiceImport.dropItems(this._getImportType(), this.companyId, this.importId, payload)
        .then((response) => {
          this.tableKey++;
          this.loading = false;
          this.finishInitialImportLoad = false;
          this._reviewImport();
        })
        .catch((error) => {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false;
          }
        })
    },
    dropSpecificItem(itemId) {
      this.importData.importDeleteItemIds = [itemId];
      this.dropImportItems();
    },
    filterValues() {
      if (!this.initialLoading) {
        let paramsBag = {...this.importFilterValues};
        this.searchString = '';
        this.sortField = '';
        this.sortDirection = 'asc';
        this.reverseSort = false;
        this.importParams = paramsBag;
        this._reviewImport();
      }
    },
    changePerPage(perPage) {
      this.importParams.page = 1;
      this.importParams.perPage = perPage;
      this._reviewImport();
    },
  },
};
</script>

<style scoped src="@/assets/styles/flexben.css"></style>
<style scoped src="@/assets/styles/beneficiary.css"></style>
