<template>
  <div>
    <hr>
    <div class="row">
      <div class="col-6 col-md-6 form-group">
        <span id="threshold_description"
              class="d-inline-block flexben-tooltip-icon ml-2 mr-2 text-bold">i</span>
        <b-tooltip :target="'threshold_description'" variant="flexben" placement="topleft">
          <div class="text-left">{{$t('flexben.benefit.thresholds.tooltip', {value:'gym_membership'})}}</div>
        </b-tooltip>

        <label for="threshold" class="form-fields-required text-bold mb-0 mr-4 col-form-label p-0">
          {{ $t('flexben.benefit.thresholds.' + 'gym_membership') }}</label>

        <b-form-checkbox id="threshold" class="float-right"
                         @change="$emit('addThreshold', 'gym_membership', gymMembership)"
                         v-model="gymMembership"
                         switch></b-form-checkbox>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12 col-md-6 form-group">
        <label for="delivery_type" class="form-fields-required col-form-label">
          {{ $t('flexben.benefit.fields.delivery_type') }}</label>
        <select class="form-control" id="delivery_type" v-model="fields.delivery_type"
                :disabled="benefitStatus!=='draft'"
                @change="clearVoucherValue()"
                :class="{'border-danger':(Object.keys(errorsBag).includes('delivery_type'))}">
          <option :value="null">-</option>
          <option v-for="(type, index) in deliveryTypes"
                  :value="type"
                  :key="index">{{ $t('flexben.benefit.delivery_types.'+type) }}
          </option>
        </select>
      </div>

      <div class="col-12 col-md-6 form-group"
           v-show="fields.delivery_type!==null && fields.delivery_type!=='card'">
        <label for="voucher_value" class="form-fields-required col-form-label">
          {{ $t('flexben.benefit.fields.voucher_billing_value') }}</label>
        <input type="number" id="voucher_value" v-model="fields.billing_value" class="form-control"
               :class="{'border-danger':(Object.keys(errorsBag).includes('voucher_billing_value'))}"/>
      </div>
    </div>
  </div>

</template>

<script>
import {BFormCheckbox, BTooltip} from "bootstrap-vue";

export default {
  name: "BenefitVoucherFormFields",
  components: {
    'b-tooltip': BTooltip,
    "b-form-checkbox": BFormCheckbox,

  },
  props: {
    fields: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
    deliveryTypes: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
    errorsBag: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
    voucherValueUrl: {
      default: null,
    },
    formAction: {
      required: true,
      type: String
    },
    benefitStatus: {
      required: true,
      type: String
    },
  },
  data: function () {
    return {
      gymMembership: false
    };
  },
  created() {
    this.gymMembership = this.getGymMembership();
  },
  methods: {
    clearVoucherValue() {
      this.fields.billing_value = 0;
    },
    getGymMembership() {
      if (!this.fields.thresholds.length) {
        return false;
      }

      return this.fields.thresholds.find(function (item) {return item.name === 'gym_membership'}) ?
        this.fields.thresholds.find(function (item) {return item.name === 'gym_membership'})['value'] : false
    }
  }
}
</script>
<style>
.flexben-tooltip-icon {
  font-family: "Times New Roman", sans-serif;
  color: gray;
  border: 1px solid gray;
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  cursor: pointer;
  line-height: initial;
}
</style>
