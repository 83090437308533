<template>
  <div class="show modal fade">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header w-100 no-border pb-0">
          <h3 class="float-left">{{ $t('flexben.general.edit') }} {{ $t('flexben.import.types.budget') }}</h3>
          <span class="float-right my-auto" :class="'import-status-item-'+itemData.status">{{ $t('flexben.import.item_statuses.'+itemData.status) }}</span>
        </div>
        <div class="modal-body py-0 bg-white">
          <div class="row no-gutters text-danger" v-if="itemData.invalid_reasons !== null">
            <div class="col-12 mb-1"
                 v-html="getInvalidReasons(itemData.invalid_reasons)">
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="first_name">
                {{ $t('flexben.import.table_headings.first_name') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white"
                     :class="{'border-danger':checkInErrorBag('first_name')}"
                     id="first_name"
                     v-model="itemData.first_name">
            </div>
            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="last_name">
                {{ $t('flexben.import.table_headings.last_name') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white"
                     :class="{'border-danger':checkInErrorBag('last_name')}"
                     id="last_name"
                     v-model="itemData.last_name">
            </div>
            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="cnp">
                {{ $t('flexben.import.table_headings.cnp') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white"
                     :class="{'border-danger':checkInErrorBag('cnp')}"
                     id="cnp" v-model="itemData.cnp">
            </div>
            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="budget">
                {{ $t('flexben.import.table_headings.total_budget') }}</label>
              <input type="number" class="form-control m-0 w-100 background-white"
                     :class="{'border-danger':checkInErrorBag('budget')}"
                     id="budget" v-model="itemData.budget">
            </div>
            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="expiration_date">
                {{ $t('flexben.import.table_headings.expiration_date') }}</label>
              <input v-if="importType==='telework_budgets'" type="text" disabled class="form-control"
                     :value="expirationDate">
              <datepicker v-if="importType==='budgets'"
                          :format="formatDate"
                          v-model="expirationDate"
                          :class="{'w-100':true}"
                          :inputClass="'form-control m-0 w-100 background-white '+(checkInErrorBag('expiration_date')?'border-danger':'')"
                          id="expiration_date"
                          monday-first
                          @change="changeDate"
              ></datepicker>
            </div>
            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="ppp3_budget">
                {{ $t('flexben.import.table_headings.ppp3_budget') }}</label>
              <input type="number" class="form-control m-0 w-100 background-white"
                     :class="{'border-danger':checkInErrorBag('ppp3_budget')}"
                     id="ppp3_budget" v-model="itemData.ppp3_budget">
            </div>
            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="ppp3_expiration_date">
                {{ $t('flexben.import.table_headings.ppp3_expiration_date') }}</label>
              <datepicker v-if="importType==='budgets'"
                          :format="formatDate"
                          v-model="ppp3ExpirationDate"
                          :class="{'w-100':true}"
                          :inputClass="'form-control m-0 w-100 background-white '+(checkInErrorBag('ppp3_expiration_date')?'border-danger':'')"
                          id="ppp3_expiration_date"
                          monday-first
                          @change="changeDate"
              ></datepicker>
            </div>
          </div>
        </div>

        <div class="modal-footer mb-2 no-border w-100 d-inline">
          <button class="btn text-danger float-left" @click="dropImportItem">
            <font-awesome-icon icon="trash-alt"/>
            {{ $t('flexben.general.delete') }}
          </button>
          <button class="btn save-btn float-right" @click="updateImportItem">{{ $t('flexben.general.btn.save') }}
          </button>
          <button class="btn float-right text-themed" @click="closeModal">{{ $t('flexben.general.btn.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from 'vuejs-datepicker';

export default {
  name: "EditBudgetModal",
  components: {
    'datepicker': Datepicker,
  },
  props: {
    importType: {
      type: String,
      required: true
    },
    item_data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    },
  },
  data() {
    return {
      initialMount: true,
      formatDate: 'dd/MM/yyyy',
      expirationDate: null,
      ppp3ExpirationDate: null
    };
  },
  watch: {
    expirationDate: function (val) {
      if (val !== null) {
        this.itemData.expiration_date = moment(val, 'DD/MM/yyyy').format('DD/MM/yyyy');
      }
    },
    ppp3ExpirationDate: function (val) {
      if (val !== null) {
        this.itemData.ppp3_expiration_date = moment(val, 'DD/MM/yyyy').format('DD/MM/yyyy');
      }
    }
  },
  computed: {
    itemData: function () {
      return this.item_data;
    }
  },
  mounted() {
    if (this.initialMount && this.itemData.expiration_date !== null) {
      this.expirationDate = this.importType === 'telework_budgets' ? moment(this.itemData.expiration_date).format('DD/MM/YYYY') : moment(this.itemData.expiration_date, 'DD/MM/yyyy').toDate();
    }
    if (this.initialMount && this.itemData.ppp3_expiration_date !== null) {
      this.ppp3ExpirationDate = moment(this.itemData.ppp3_expiration_date, 'DD/MM/yyyy').toDate() ;
    }
  },
  methods: {
    updateImportItem() {
      this.itemData.budget = parseFloat(this.itemData.budget);
      this.itemData.ppp3_budget = parseFloat(this.itemData.ppp3_budget);
      this.$emit('updateImportItem');
    },
    closeModal() {
      this.$emit('closeModal');
    },
    dropImportItem() {
      this.$emit('dropImportItem');
    },
    changeDate() {
    },
    getInvalidReasons(invalidReasons) {
      return this.$parent.getInvalidReasons(invalidReasons)
    },
    checkInErrorBag(field) {
      if (this.itemData.invalid_reasons == null) {
        return false;
      }
      let objKeys = Object.keys(this.itemData.invalid_reasons);

      return objKeys.includes(field);
    }
  }
}
</script>
