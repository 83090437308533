<template>
  <div>
    <template v-if="this.listDataLoaded">
      <div class="row">
        <div class="col-12 flexben-page-title">
          <h1>{{ $t("flexben.configuration.title") }}</h1>
          <p>{{ $t("flexben.configuration.description") }}</p>
          <!--          <div class="add_config">-->
          <!--            <button data-backdrop="static" data-keyboard="false"-->
          <!--                    class="btn save-btn" v-b-modal.configModal-->
          <!--                    @click="openCreate()">-->
          <!--              {{ $t("flexben.configuration.table.new_config") }}-->
          <!--            </button>-->
          <!--          </div>-->
        </div>
      </div>
      <div class="container-bg-white mt-2">
        <div class="row p-3">
          <div class="col-md-6 text-18 pt-3 text-bold">
            {{ $t("flexben.configuration.title") }}
          </div>
          <div class="col-md-6">
            <div class="float-right flexben-search-input">
              <input type="text" @keyup.enter="search()" v-model="searchString"
                     v-bind:placeholder="$t('flexben.general.search')"
                     class="formedituser search-input"/>
            </div>
          </div>

          <div class="col-12">
            <div class="table-responsive hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td @click="sortBy('name')" class="cursor-pointer">
                    <strong>{{ $t("flexben.general.parameter") }}</strong>&nbsp;
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :style="sortField === 'name' && sortDirection === 'asc' ? 'transform: rotate(180deg)' : ''"/>
                  </td>
                  <td @click="sortBy('type')" class="cursor-pointer">
                    <strong>{{ $t("flexben.general.type") }}</strong>&nbsp;
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :style="sortField === 'type' && sortDirection === 'asc' ? 'transform: rotate(180deg)':''"/>
                  </td>
                  <td @click="sortBy('value')" class="cursor-pointer">
                    <strong>{{ $t("flexben.configuration.table.current_value") }}</strong>&nbsp;
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :style="sortField === 'value' && sortDirection === 'asc' ? 'transform: rotate(180deg)' : ''"/>
                  </td>
                  <td @click="sortBy('start_use_date')" class="cursor-pointer">
                    <strong>{{ $t("flexben.general.valid_from") }}</strong>&nbsp;
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :style="sortField === 'start_use_date' && sortDirection === 'asc' ? 'transform: rotate(180deg)' : ''"/>
                  </td>
                  <td>
                    <strong>{{ $t("flexben.configuration.table.future_value") }}</strong>
                  </td>
                  <td>
                    <strong>{{ $t("flexben.general.valid_from") }}</strong>
                  </td>
                  <td>&nbsp;</td>
                </tr>
                </thead>
                <tbody>
                <template v-if="this.listDataLoaded">
                  <tr class="trhover" v-for="(item,index) in configurationsList" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>
                      <span v-if="item.type==='percent'" :key="'type_'+item.id">%</span>
                      <span v-else-if="item.type==='numeric'" :key="'type_'+item.id">{{$t('flexben.configuration.fixed_value')}}</span>
                      <span v-else-if="item.type==='text'" :key="'type_'+item.id">text</span>
                    </td>
                    <td>{{ item.value }}</td>
                    <td>{{ moment(item.start_use_date, 'DD/MM/YYYY') }}</td>
                    <td>
                      {{ (item.future_values != null) ? item.future_values.value : '-'}}
                    </td>
                    <td>
                      {{ (item.future_values != null) ? moment(item.future_values.start_use_date, 'DD/MM/YYYY') : '-' }}
                    </td>
                    <td>
                      <button class="btn action-button" @click="openDetails(index)">
                        {{$t('flexben.general.btn.see_more')}}
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="10" class="text-center">{{$t('flexben.general.loading')}}</td>
                  </tr>
                </template>
                </tbody>
                <!-- TODO: implement pagination -->
              </table>
            </div>
          </div>
          <div class="col-12 p-0">
            <listPagination
              :currentPage="this.currentPage"
              :itemsPerPage="this.itemsPerPage"
              :totalItems="this.entriesTotal"
              :startItem="this.startItem"
              :endItem="this.endItem"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage"></listPagination>
          </div>

        </div>
      </div>


      <ConfigurationEditModal v-if="hasItem"
                              :activeItem="activeItem"
                              :itemHasError="itemHasError"
                              :itemErrors="itemErrors"
                              :modalAction="modalAction"
                              :modalShow="hasItem"
                              @saveItem="saveItem"
                              @closeModal="closeModal"
                              @clearFuture="clearFuture"
                              :key="modalKey"></ConfigurationEditModal>
    </template>
    <content-loader :loaderVisible="this.loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
  import {VBModal} from "bootstrap-vue";
  import httpFlexbenService from "@/http/http-flexben.js";
  import moment from "moment";
  import ConfigurationEditModal from "./modals/ConfigurationEditModal";

  export default {
    components: {
      ConfigurationEditModal
    },
    directives: {
      "b-modal": VBModal
    },
    data() {
      return {
        httpService: httpFlexbenService.globalVariablesService,
        // Item data
        hasItem: false,
        modalAction: 'edit',
        itemStructure: {
          id: null,
          start_use_date: "",
          value: '',
          name: '',
          type: '',
          future_values: {
            id: null,
            start_use_date: null,
            value: null
          },
        },
        activeItem: {},
        itemHasError: false,
        itemErrors: {},
        // list related data
        loading: true,
        loaderError: false,
        params: {},
        configurationsList: {},
        activeItemIndex: null,
        listDataLoaded: false,
        searchString: '',
        sortField: '',
        sortDirection: 'asc',
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 1,
        endItem: 1,
        entriesTotal: 0,
        modalKey: 0,
      };
    },
    created() {
      let permissions = this.flxUserLevel();
      if (permissions.hasAccess && permissions.userLevel === 'admin') {
        this.loadList();
      } else {
        window.location.href = '/company';
      }
    },
    methods: {
      sortBy(fieldName) {
        this.sortField = fieldName;
        this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
        this.params.sort = this.sortField;
        this.params.sort_dir = this.sortDirection;
        this.loadList();
      },
      pageChanged(pageNb) {
        this.params.page = pageNb;
        this.loadList();
      },
      loadList() {
        this.loading = true;
        this._sendListRequest();
      },
      openDetails(index) {
        this.modalAction = 'edit'
        this.activeItemIndex = index;
        this._loadItem(this.configurationsList[index].id);
      },
      openCreate() {
        this.activeItem = {...this.itemStructure};
        this.modalAction = 'create'
      },
      saveItem() {
        if (this.activeItem.id) {
          this._updateItem();
        } else {
          this._createItem();
        }
      },
      closeModal() {
        this.hasItem = false;
        this.modalAction = 'edit'
        this.activeItem = {};
        this.activeItemIndex = null;
        this.itemErrors = {};
        this.itemHasError = false;
      },

      _sendListRequest() {
        this.loading = true;
        this.httpService.loadList(this.params)
          .then(function (result) {
            this._processListResult(result.data);
          }.bind(this))
          .catch(function (err) {
            if (axios.isCancel(err)) {
            } else {
              this.loading = false;
              // handle error
            }
          })
      },
      _processListResult(resultData) {
        this.configurationsList = resultData.data;
        this.entriesTotal = resultData.total;
        this.itemsPerPage = resultData.per_page;
        this.currentPage = resultData.current_page;
        this.startItem = resultData.from;
        this.endItem = resultData.to;
        this.listDataLoaded = true;
        this.loading = false;
        this.$forceUpdate();
      },
      _createItem() {
        this.loading = true;
        this.itemErrors = {};
        this.httpService
          .createItem(this.activeItem)
          .then(function (result) {
            this._saveItemSuccessHandler();
          }.bind(this))
          .catch(function (error) {
            if (error.response.status >= 500 && error.response.status < 600) {
              this.loaderError = true;
            } else if (error.response.status === 400) {
              this.loading = false;
              this._saveItemErrorHandler(error.response.data.error_fields);
            }
          }.bind(this));
      },
      _updateItem() {
        this.loading = true;
        this.itemErrors = {};
        let payload = {...this.activeItem};
        if (payload.future_values.start_use_date !== null) {
          payload.future_values.start_use_date = this.moment(payload.future_values.start_use_date, 'YYYY-MM-DD');
        }
        this.httpService
          .updateItem(this.activeItem)
          .then(function (result) {
            this._saveItemSuccessHandler();
          }.bind(this))
          .catch(function (error) {
            if (error.response.status >= 500 && error.response.status < 600) {
              this.loaderError = true;
            } else if (error.response.status === 400) {
              this.loading = false;
              this._saveItemErrorHandler(error.response.data.error_fields);
            }
          }.bind(this));
      },
      _loadItem(id) {
        this.hasItem = false;
        this.loading = true;
        this.activeItem = {};
        this.httpService.loadItem(id)
          .then(function (result) {
            let returnData = result.data;
            if (returnData.future_values === null) {
              returnData.future_values = {...this.itemStructure.future_values};
            }
            this.activeItem = returnData;
            this.modalKey++;
            this.hasItem = true;
            this.loading = false;
          }.bind(this));
        return true;
      },
      _saveItemSuccessHandler() {
        this.closeModal();
        this.loading = false;
        this.loadList();
      },
      _saveItemErrorHandler(error) {
        this.itemHasError = true;
        this.loading = false;
        this.itemErrors = error;
      },
      changePerPage(perPage) {
        this.params.page = 1;
        this.params.per_page = perPage;
        this._sendListRequest();
      },
      moment(date, format) {
        if (date === null) {
          return '-';
        }
        return moment(date).format(format);
      },
      clearFuture() {
        if (this.hasItem) {
          this.activeItem.future_values.id = null;
          this.activeItem.future_values.start_use_date = null;
          this.activeItem.future_values.value = null;
        }
      },
      search() {
        if (this.searchString.length >= 3) {
          this.params.search = this.searchString;
          this.loadList(this.params);
        } else if (this.searchString.length === 0) {
          delete this.params.search;
          this.loadList(this.params);
        }
      }
    },
  };
</script>

<style scoped src="@/assets/styles/modal.css"></style>
<style scoped lang="scss">

  .modal-header {
    border-bottom: none;
  }

  .container-fluid {
    padding: 0;

    span {
      display: block;
      font-size: 12px;

      &.mobile-icon {
        display: inline-block;
      }
    }
  }


  .modal-footer {
    border-top: none;

    .btn-danger {
      color: #F59100;
      background: transparent;
      border: none;
      font-size: 14px;

      &:not(:disabled):not(.disabled):active {
        color: #F59100;
        background: transparent;
        border-color: transparent;
      }

      &:not(:disabled):not(.disabled):active:focus,
      &:focus,
      &.focus {
        box-shadow: none;
      }
    }

    .btn-success {
      color: #fff;
      background: #F59100;
      border: 1px solid #F59100;
      font-size: 14px;
      padding: 10px 30px;
      text-transform: uppercase;
      opacity: 0.9;

      &:hover {
        opacity: 1;
      }

      &:not(:disabled):not(.disabled):active {
        background-color: #F59100;
        border-color: #F59100;
      }


      &:not(:disabled):not(.disabled):active:focus,
      &:focus,
      &.focus {
        box-shadow: none;
      }
    }
  }

  .top {
    border-bottom: 1px solid #D7D7D7;
    padding: 1rem;
  }

  .bottom {
    padding: 1rem;
  }

  .text- {
    &left {
      text-align: left;
    }
  }

</style>
