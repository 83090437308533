<template>
  <div class="mb-4">
    <div class="brand" v-show="!initialLoading && !showForm">
      <div class="row">
        <div class="col-4 col-md-4 flexben-page-title">
          <h1>{{ $t("flexben.brand.title") }}</h1>
          <p>{{ $t("flexben.brand.description") }}</p>
        </div>
        <div class="col-4 col-md-4" />
        <div class="col-4 col-md-4">
          <button class="btn btn-themed px-4 float-right my-2" @click="openForm('create')">
            {{$t("flexben.brand.new")}}
          </button>
        </div>
      </div>
      <div class="container-bg-white">
        <div class="row p-3">
          <div class="col-6 mt-3 text-bold">
            <h3 class="mt-0 pt-1 pl-0">{{ $t("flexben.brand.title") }}</h3>
          </div>
          <div class="col-6 mt-3">
            <input
              type="text"
              v-model="searchString"
              v-on:keyup.enter="search()"
              :placeholder="$t('flexben.general.search')"
              class="formedituser search-input float-right"
            />
          </div>
          <div class="col-12">
            <div class="table-responsive hover-table">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <td>{{ $t("flexben.general.image") }}</td>
                    <td>{{ $t("flexben.brand.fields.name") }}</td>
                    <td />
                  </tr>
                </thead>
                <tbody v-if="list.data.length > 0">
                  <tr v-for="(item, index) in list.data" :key="index" class="trhover">
                    <td><img :src="item.image_url" alt="" width="50"></td>
                    <td>{{ item.name }}</td>
                    <td class="action-group text-right">
                      <button type="button" class="btn action-button"
                        @click="openForm('update', index)">
                          {{$t("flexben.general.btn.see_more")}}
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="trhover gri-cell">
                    <td :colspan="3" class="text-center">
                      {{ $t("flexben.general.no_results_found") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 p-0">
            <listPagination
              :activePage="list.current_page"
              :currentPage="list.current_page"
              :totalItems="list.total"
              :startItem="list.from"
              :endItem="list.to"
              :itemsPerPage="list.perPage"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage"
            />
          </div>
        </div>
      </div>
    </div>
    <Form
      v-if="showForm"
      :formAction="formAction"
      :options="options"
      :itemId="itemId"
      @clearForm="clearForm"
    />
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import Form from "@/components/flexben/brands/Form";

export default {
  name: "BrandList",
  components: {
    Form,
  },
  data: () => {
    return {
      loading: false,
      initialLoading: true,
      httpService: httpFlexbenService.brandService,
      searchString: '',
      list: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      options: {
        available_languages: [],
        scope_availability: [],
      },
      params: {
        page: 1,
        perPage: 20,
      },
      formAction: '',
      itemId: null,
      showForm: false,
    };
  },
  created() {
    let permissions = this.flxUserLevel();
    if (permissions.hasAccess && permissions.userLevel === 'admin') {
      this._loadList();
    } else {
      window.location.href = '/company';
    }
  },
  methods: {
    _loadList() {
      this.loading = true;
      this.httpService.index(this.params)
        .then((response) => {
          this.list = response.data.items;
          this.options = response.data.options;
          this.loading = false;
          this.initialLoading = false;
        })
        .catch(() => {
          this.loading = false;
          this.loaderError = true;
          this.initialLoading = false;
        });
    },
    openForm(action, index = null) {
      this.formAction = action;
      if (action === 'update') {
        this.itemId = this.list.data[index].id;
        this.showForm = true;
      }
      if (action === 'create') {
        this.itemId = null;
        this.showForm = true;
      }
    },
    clearForm() {
      this.formAction = '';
      this.showForm = false;
      this.itemId = null;
      this._loadList();
    },
    pageChanged(pageNb) {
      this.params.page = pageNb;
      this._loadList();
    },
    changePerPage(perPage) {
      this.params.page = 1;
      this.params.perPage = perPage;
      this._loadList();
    },
    search() {
      if (this.searchString.length >= 3) {
        this.params.search = this.searchString;
        this._loadList();
      } else if (this.searchString.length === 0) {
        this.params.search = null;
        this._loadList();
      }
    },
  },
};
</script>
