<template>
  <div class="">
    <a class="btn text-themed go-back" @click="goBack()">{{ $t("flexben.general.go_back") }}</a>
    <div v-show="!initialLoading">
      <div class="row">
        <div class="col-12 col-md-6 flexben-page-title">
          <h1>{{ (formAction==='create') ? $t('flexben.partner.new') : $t('flexben.partner.edit') }}</h1>
        </div>
        <div class="col-12 col-md-6 text-right">
          <button class="btn text-danger px-4" v-if="(formAction==='update' && canDelete)" @click="modalAct('delete')">
            <font-awesome-icon icon="trash-alt"/>
            {{$t("flexben.general.btn.delete")}}
          </button>
          <button class="btn btn-themed px-4"
                  @click="modalAct('form_submit')">
            {{ $t('flexben.general.btn.save') }}
          </button>
        </div>
      </div>
      <div class="row my-1" v-if="checkNotEmptyObj(errorsBag)">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible mb-0">
            <button type="button" class="close" data-dismiss="alert" @click="closeErrorButton = false">&times;</button>
            <form-error-list :errorBag="errorsBag"></form-error-list>
          </div>
        </div>
      </div>
      <div class="container-bg-white mt-3">
        <div class="row px-3">
          <div class="col-12">
            <h3 class="m-0 pl-0">{{ $t('flexben.general.details') }}</h3>
          </div>
          <div class="col-12 col-xl-4 d-flex align-items-start justify-content-center mb-1">
            <div class="image-container cursor-pointer d-flex align-items-center" @click="$refs.image_input.click()">
              <div class="image-changed" v-if="imageChanged">{{$t("flexben.benefit.image_changed")}}</div>
              <img class="img-fluid upload-image"
                   :src="(formData.image!==null) ? imagePreview : formData.image_url"
                   :class="{'img-border-danger':(Object.keys(errorsBag).includes('image'))}"/>
              <div class="upload-image-middle-container">
                <div class="upload-image-text">{{$t("flexben.benefit.upload_image")}}</div>
              </div>
              <input type="file" class="hide" ref="image_input" accept=".png,.jpeg,.jpg,.svg,.jfif" @change="setImageFile($event)">
            </div>
          </div>
          <div class="col-12 col-xl-8">
            <div class="row">
              <div class="col-12 col-md-6 form-group">
                <label for="name" class="form-fields-required col-form-label">{{ $t('flexben.partner.fields.name')
                  }}</label>
                <input type="text" class="form-control" id="name" v-model="formData.name"
                       :class="{'border-danger':(Object.keys(errorsBag).includes('name'))}">
              </div>
              <div class="col-12 col-md-6 form-group">
                <label for="iban" class="form-fields-required col-form-label">{{ $t('flexben.partner.fields.iban')
                  }}</label>
                <input type="text" class="form-control " id="iban" v-model="formData.iban"
                       :class="{'border-danger':(Object.keys(errorsBag).includes('iban'))}">
              </div>
              <div v-if="formData.type === 'affiliated'" class="col-12 col-md-6 form-group">
                <label for="partner_url" class="col-form-label">{{ $t('flexben.partner.fields.affiliate.partner_url')
                  }}</label>
                <input readonly type="text" class="form-control" id="partner_url" v-model="formData.affiliated_partner_url">
              </div>
              <div v-if="formData.type === 'affiliated'" class="col-12 col-md-6 form-group">
                <label for="partner_url" class="col-form-label">{{ $t('flexben.partner.fields.affiliate.channel_url')
                  }}</label>
                <input readonly type="text" class="form-control" id="partner_url" v-model="formData.affiliation_channel_url">
              </div>
              <div v-if="formData.type === 'affiliated' && formData.affiliate_channel.type === 'commercial'" class="col-12 col-md-6 form-group">
                <label for="url" class="col-form-label">{{ $t('flexben.partner.fields.affiliate.commercial_identifier') }}</label>
                <input disabled readonly type="text" class="form-control" id="url" v-model="formData.affiliate_channel.commercial_identifier">
              </div>

              <div v-if="formData.type === 'affiliated' && formData.affiliate_channel.type === 'platform'" class="col-12 col-md-6 form-group">
                <label for="url" class="col-form-label">{{ $t('flexben.partner.fields.affiliate.social_platform')
                  }}</label>
                <input disabled readonly type="text" class="form-control" id="url" v-model="formData.affiliate_channel.social_platform">
              </div>
              <div v-if="formData.type === 'affiliated'" class="col-12 col-md-6 form-group">
                <label for="url" class="col-form-label">{{ $t('flexben.partner.fields.affiliate.registered_date')
                  }}</label>
                <input disabled readonly type="text" class="form-control" id="url" v-model="registered_date">
              </div>

              <div v-if="formData.type === 'affiliated'" class="col-12 col-md-6 form-group">
                <label for="url" class="col-form-label">{{ $t('flexben.partner.fields.affiliate.name')
                  }}</label>
                <input disabled readonly type="text" class="form-control" id="url" v-model="formData.affiliate_channel.name">
              </div>




              <div class="col-12 col-md-6 form-group">
                <label for="site" class="col-form-label">{{ $t('flexben.partner.fields.site') }}</label>
                <input type="text" class="form-control" id="site" v-model="formData.contact.site"
                       :class="{'border-danger':(Object.keys(errorsBag).includes('contact_site'))}">
              </div>
              <div class="col-12 col-md-6 form-group">
                <label for="phone" class="col-form-label">{{ $t('flexben.partner.fields.phone') }}</label>
                <input type="text" class="form-control" id="phone" v-model="formData.contact.phone"
                       :class="{'border-danger':(Object.keys(errorsBag).includes('contact_phone'))}">
              </div>
              <div class="col-12 col-md-6 form-group">
              <label for="cif" class="form-fields-required col-form-label">{{ $t('flexben.partner.fields.cif') }}</label>
              <input type="text" class="form-control" id="cif" v-model="formData.cif"
                     :class="{'border-danger':(Object.keys(errorsBag).includes('cif'))}">
            </div>
              <div class="col-12 form-group">
                <label for="address" class="col-form-label">{{ $t('flexben.partner.fields.address') }}</label>
                <textarea class="form-control-textarea m-0" id="address" rows="3" v-model="formData.contact.address">
              </textarea>
              </div>

              <div class="col-12 form-group">
                <label for="address" class="col-form-label">{{ $t('flexben.partner.fields.scope_availability')
                  }}</label>
                <div v-for="(item, key) of options.scope_availability" :key="key" class="d-flex">
                  <input
                    type="checkbox"
                    :value="item"
                    :id="'scope_availability_'+item"
                    :checked="formData.scope_availability.includes(item)"
                    @change="toggleScopeAvailability(item)"
                    class="mr-2 cursor-pointer"/>
                  <label :for="'scope_availability_'+item" class="text-14 mb-0 cursor-pointer">{{
                    $t('flexben.partner.scope_availability.'+item) }}</label>
                </div>
              </div>

              <div class="col-12 pb-2" v-for="(langCode, index) in options.available_languages"
                   :key="index">

                <label :for="'description_'+index" class="col-form-label">{{
                  $t("flexben.general.description")
                  }} - {{langCode}}</label>
                <span :id="'description_tooltip_'+index"
                      class="d-inline-block flexben-tooltip-icon ml-2 text-bold">i</span>
                <b-tooltip :target="'description_tooltip_'+index" variant="flexben" placement="topleft">
                  <div class="text-left">{{$t("flexben.partner.tooltips.description")}}</div>
                </b-tooltip>
                <p v-if="Object.keys(errorsBag).includes('description.'+langCode)" class="text-danger">
                  {{errorsBag['description.'+langCode][0]}} </p>
                <v-editor :id="'description_'+index" rows="5"
                          class="form-control-textarea my-0"
                          :placeholder="$t('flexben.partner.fields.description_placeholder')"
                          v-model="formData.description[langCode]"
                          :editorToolbar="editorToolbar"
                          :class="{'border-danger':(Object.keys(errorsBag).includes('description|'+langCode))}">
                </v-editor>
                <p> {{formData.description[langCode].length}} / 3000</p>
              </div>


            </div>
          </div>
        </div>
      </div>
      <div class="container-bg-white mt-3" v-if="formAction==='update'">
        <div v-if="formData.type === 'intern'" class="row p-3">
          <div class="col-12">
            <h3 class="m-0 pl-0">{{ $t('flexben.partner.assigned_benefits') }}</h3>
          </div>
          <div class="col-12">
            <div class="table-responsive scaled-table hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td>{{ $t("flexben.general.name") }}</td>
                  <td>{{ $t("flexben.benefit.valid_from") }}</td>
                  <td v-if="canDelete">{{ $t("flexben.benefit.fields.sub_type") }}</td>
                  <td>{{ $t("flexben.benefit.fields.status") }}</td>
                </tr>
                </thead>
                <tbody v-if="assignedBenefits.length>0">
                <tr class="trhover parent" v-for="(item, index) in assignedBenefits" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ moment(item.start_use_date, 'DD/MM/yyyy') }}</td>
                  <td v-if="canDelete">{{ item.sub_type ? $t("flexben.benefit.sub_types." + item.sub_type) : '-'}}</td>
                  <td>
                    <span :class="'benefit-status-'+item.status.toLocaleLowerCase()" class="benefit-status m-0">{{ $t("flexben.general.status_type." + item.status.toLocaleLowerCase()) }}</span>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr class="trhover gri-cell">
                  <td :colspan="canDelete?4:3" class="text-center">{{ $t('flexben.partner.no_assigned_benefits') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row p-3">
          <div class="col-12">
            <h3 class="m-0 pl-0">{{ $t('flexben.partner.assigned_offers') }}</h3>
          </div>
          <div class="col-12">
            <div class="table-responsive scaled-table hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td>{{ $t("flexben.general.name") }}</td>
                  <td>{{ $t("flexben.benefit.fields.status") }}</td>
                </tr>
                </thead>
                <tbody v-if="assignedOffers.length>0">
                <tr class="trhover parent" v-for="(item, index) in assignedOffers" :key="item.id">
                  <td>{{ item.name[locale] }}</td>
                  <td>
                    <span :class="'offer-status-'+item.status" class="offer-status m-0">{{ $t("flexben.offers.statuses." + item.status) }}</span>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr class="trhover gri-cell">
                  <td :colspan="canDelete?4:3" class="text-center">{{ $t('flexben.general.no_results_found') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-right">
          <button class="btn text-danger px-4" v-if="(formAction==='update' && canDelete)" @click="modalAct('delete')">
            <font-awesome-icon icon="trash-alt"/>
            {{$t("flexben.general.btn.delete")}}
          </button>
          <button class="btn btn-themed px-4"
                  @click="modalAct('form_submit')">
            {{ $t('flexben.general.btn.save') }}
          </button>
        </div>
      </div>
    </div>

    <confirm-modal :action="modal.action"
                   :formSuccess="modal.formSuccess"
                   :message="modal.message"
                   :canDelete="modal.canDelete"
                   ref="confirm-modal"
                   @confirmAction="confirmAction">
    </confirm-modal>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import ConfirmModal from "@/components/flexben/partners/modals/ConfirmModal";
import FormErrorList from "@/components/fragments/FormErrorList";
import moment from "moment";
import {BTooltip} from "bootstrap-vue";
import vueEditorSettings from "@/constants/vue_editor_settings";
import {VueEditor} from "vue2-editor";

export default {
  name: "PartnerForm",
  components: {
    "confirm-modal": ConfirmModal,
    "form-error-list": FormErrorList,
    'b-tooltip': BTooltip,
    'v-editor': VueEditor,
  },
  props: {
    itemId: {
      required: true
    },
    formAction: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      initialLoading: true,
      loading: false,
      loaderError: false,
      httpService: httpFlexbenService.partnersService,
      editorToolbar: vueEditorSettings.editorToolbar,
      locale: i18n.locale,
      registered_date: '',
      options: {
        available_languages: [],
        scope_availability: [],
      },
      formData: {
        id: null,
        image: null,
        image_url: httpFlexbenService.globalVariablesService.baseImagePreview,
        name: '',
        iban: '',
        cif: '',
        affiliate_channel: {},
        contact: {
          address: '',
          site: '',
          phone: '',
        },
        description: {},
        scope_availability: [],
        type: '',
      },
      canDelete: true,
      errorsBag: {},
      isFormModified: false,
      imagePreview: null,
      imageChanged: false,
      assignedBenefits: [],
      assignedOffers: [],
      modal: {
        action: '',
        formSuccess: true,
        message: '',
        canDelete: true,
      }
    };
  },
  watch: {
    formData: {
      deep: true,
      handler: function () {
        this.isFormModified = true;
      }
    }
  },
  created() {
    if (this.formAction === 'create') {
      this._getPartnerSettings();
    } else if (this.formAction === 'update' && this.itemId) {
      this._getPartner();
    } else {
      this.goBack();
    }
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  methods: {
    goBack() {
      if (this.isFormModified) {
        this.modalAct('go_back')
      } else {
        this.$emit('clearForm');
      }
    },
    modalAct(action) {
      this.modal.message = '';
      if (action === 'delete' && this.assignedBenefits.length) {
        this.modal.message = this.$t('flexben.partner.modal.extra_delete', {count_benefits: this.assignedBenefits.length});
      }
      this.modal.action = action;

      this.openModal();
    },
    confirmAction(action) {
      if (action === 'go_back') {
        this.isFormModified = false;
        this.goBack();
      }
      if (action === 'form_submit') {
        this.closeModal();
        if (this.formAction === 'update') {
          this._updateItem();
        } else {
          this._createItem();
        }
      } else if (action === 'delete') {
        this.closeModal();
        this._deleteItem();
      }
      if (action === 'end') {
        this.closeModal();
        if (this.modal.formSuccess) {
          this.isFormModified = false;
          this.$emit('clearForm', true);
        }
      }
    },
    setImageFile() {
      this.errorsBag = {};
      if (this.$refs.image_input.accept.indexOf(this.$refs.image_input.files[0].type.split('/')[1]) > 0) {

        let reader = new FileReader();
        reader.readAsDataURL(this.$refs.image_input.files[0]);
        reader.onload = evt => {
          let img = new Image();
          img.onload = () => {
            if (img.width !== img.height) {
              this.errorsBag = {image: 'image_square'};
            } else if (img.width !== 500 || img.height !== 500) {
              this.errorsBag = {image: 'image_500_500'};
            } else {
              this.formData.image = this.$refs.image_input.files[0];
              this.imageChanged = true;
              if (typeof this.formData.image !== "undefined") {
                this.imagePreview = URL.createObjectURL(this.formData.image);
              }
            }
          }
          img.src = evt.target.result;
        }
      } else {
        this.errorsBag = {image: 'image_type_supported'}
      }
    },
    moment(date, format) {
      return moment(date).format(format);
    },
    toggleScopeAvailability(item) {
      if (this.formData.scope_availability.includes(item)) {
        this.formData.scope_availability.splice(this.formData.scope_availability.indexOf(item), 1);
      } else {
        this.formData.scope_availability.push(item);
      }
    },
    _getPartner() {
      this.loading = true;
      this.httpService.details(this.itemId)
        .then(function (response) {
          let partner = response.data.partner;
          this.formData.name = partner.name;
          this.formData.iban = (partner.iban) ? partner.iban : '';
          this.formData.contact = partner.contact;
          this.formData.image_url = partner.image_url;
          this.registered_date = this.moment(partner.created_at,'DD/MM/YYYY')
          this.formData.scope_availability = partner.scope_availability;
          this.formData.affiliation_channel_url = partner.affiliation_channel_url;
          this.formData.affiliated_partner_url = partner.affiliated_partner_url;
          this.formData.affiliate_channel = partner.affiliate_channel;
          this.formData.type = partner.type;
          this.formData.cif = (partner.cif) ? partner.cif : '';
          let langCode;
          this.options.available_languages = response.data.options.available_languages;
          this.options.scope_availability = response.data.options.scope_availability;
          for (let index in this.options.available_languages) {
            langCode = this.options.available_languages[index];
            this.formData.description[langCode] = partner.description[langCode];
          }

          this.assignedBenefits = partner.benefits;
          this.assignedOffers = partner.offers;
          this.loading = false;
          this.initialLoading = false;
        }.bind(this))
        .then(function () {
          this.isFormModified = false;
        }.bind(this))
        .catch(function (error) {
          console.log(error)
          if (500 <= error.response.status && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false;
          }
        }.bind(this))
    },
    _getPartnerSettings() {
      this.loading = true;
      this.httpService.options()
        .then(function (response) {
          this.options.available_languages = response.data.available_languages;
          this.options.scope_availability = response.data.scope_availability;
          for (let index in response.data.available_languages) {
            this.formData.description[response.data.available_languages[index]] = '';
          }
          this.loading = false;
          this.initialLoading = false;
        }.bind(this))
        .then(function () {
          this.isFormModified = false;
        }.bind(this))
        .catch(function (error) {
          if (500 <= error.response.status && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false;
          }
        }.bind(this))
    },
    _createItem() {
      this.loading = true;
      this.httpService.create(this._prepareFormData())
        .then(function (response) {
          this.modal.formSuccess = true;
          this.modal.action = 'end';
          this.loading = false;
          this.openModal();
        }.bind(this))
        .catch(function (error) {
          if (error.response.status === 400) {
            this.errorsBag = error.response.data.error_fields;
            this.modal.formSuccess = false;
            this.modal.message = this.$t('flexben.general.validation_errors');
            this.modal.action = 'end';
            this.loading = false;
            this.openModal();
          } else {
            this.loading = false;
            this.loaderError = true;
          }
        }.bind(this));
    },
    _updateItem() {
      this.loading = true;
      this.httpService.update(this.itemId, this._prepareFormData())
        .then(function (response) {
          this.modal.formSuccess = true;
          this.modal.action = 'end';
          this.loading = false;
          this.openModal();
        }.bind(this))
        .catch(function (error) {
          if (error.response.status === 400) {
            this.errorsBag = error.response.data.error_fields;
            this.modal.formSuccess = false;
            this.modal.message = this.$t('flexben.general.validation_errors');
            this.modal.action = 'end';
            this.loading = false;
            this.openModal();
          } else {
            this.loading = false;
            this.loaderError = true;
          }
        }.bind(this));
    },
    _deleteItem() {
      if (this.modal.canDelete) {
        this.loading = true;
        this.httpService.delete(this.itemId)
          .then(function (response) {
            this.modal.formSuccess = true;
            this.modal.action = 'end';
            this.modal.message = '';
            this.loading = false;
            this.openModal();
          }.bind(this))
          .catch(function (error) {
            if (error.response.status === 400) {
              this.loading = false;
              this.loaderError = true;
            }
          }.bind(this));
      }
    },
    _prepareFormData() {
      let formData = new FormData();
      formData.append('image', this.formData.image);
      formData.append('name', this.formData.name);
      formData.append('iban', this.formData.iban);
      formData.append('cif', this.formData.cif);
      formData.append('contact', JSON.stringify(this.formData.contact));
      formData.append('description', JSON.stringify(this.formData.description));
      formData.append('scope_availability', JSON.stringify(this.formData.scope_availability));
      return formData;
    },
    openModal() {
      this.$refs['confirm-modal'].openModal();
    },
    closeModal() {
      this.$refs['confirm-modal'].closeModal();
    }
  }

}
</script>
