<template>
  <div class="quick-navigation row mb-3">
    <div class="col-10">
      <div class="row">
        <div class="col-md-6 client-edit-info d-inline-flex mt-2">
          <div class="client-id mb-auto d-inline-flex">
            <span class="text-12 text-gray">{{ $t('flexben.admin_client_header.id') }}</span>
            <span class="ml-1  mb-auto">{{ clientData.id }}</span>
          </div>
          <div class="client-cui mb-auto">
            <span class="pr-1 text-12 text-gray">{{ $t('flexben.admin_client_header.cui') }}</span>
            {{ clientData.cui }}
          </div>
          <div class="client-iban mb-auto">
            <span class="pr-1 text-12 text-gray">{{ $t('flexben.admin_client_header.iban') }}</span>
            {{ clientData.iban }}
          </div>
        </div>
        <div class="col-md-6 client-edit-info mt-2">
          <div class="client-address">
            <span class="pr-1 text-12 text-gray">{{ $t('flexben.admin_client_header.address') }}</span>
            {{ clientData.address }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="isAdmin"
         class="col-2 client-quick-actions ">
      <div @onClick="onOver" class="float-right">
        <b-dropdown id="quick-actions-menu" ref="dropdown" right class="rounded-0"
                    toggle-class="rounded-0 client-dropdown-nav-btn"
                    menu-class="rounded-0 client-dropdown-nav-list">
          <b-dropdown-item @click="navigateTo('flexben_edit_client')">
            {{ $t('flexben.menu.benefits') }}
          </b-dropdown-item>
          <b-dropdown-item @click="navigateTo('flexben_benefit_group_list')">
            {{ $t('flexben.menu.benefit_groups') }}
          </b-dropdown-item>
          <b-dropdown-item @click="navigateTo('flexben_beneficiaries')">
            {{ $t('flexben.menu.beneficiaries') }}
          </b-dropdown-item>
          <b-dropdown-item @click="navigateTo('flexben_requests')">
            {{ $t('flexben.menu.requests') }}
          </b-dropdown-item>
          <b-dropdown-item @click="navigateTo('flexben_orders')">
            {{ $t('flexben.menu.orders') }}
          </b-dropdown-item>
          <b-dropdown-item @click="navigateTo('flexben_import_new')">
            {{ $t('flexben.menu.import') }}
          </b-dropdown-item>
          <b-dropdown-item @click="navigateTo('flexben_import_history')">
            {{ $t('flexben.menu.import_history') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>


  </div>
</template>

<script>
import {BDropdown, BDropdownItem} from "bootstrap-vue";

export default {
  name: "ClientHeader",
  components: {
    "b-dropdown": BDropdown,
    "b-dropdown-item": BDropdownItem,
  },
  props: {
    clientData: {
      type: Object,
      default: function () {
        return {
          id: null,
          cui: null,
          address: null,
          iban: null,
        };
      },
      required: true
    },
    isAdmin: {
      type: Boolean,
      default: function () {
        return false;
      },
      required: true
    }
  },
  data: function () {
    return {
      currentMenuItemSelected: null
    };
  },
  created() {
    this.currentMenuItemSelected = this.$route.name;
  },
  methods: {
    onOver() {
      this.$refs.dropdown.visible = true;
    },
    navigateTo(location) {

      let redirLoc = null;
      if (location === 'flexben_edit_client' && this.currentMenuItemSelected !== location) {
        redirLoc = '/multibeneficii/client/' + this.clientData.id;
      } else if (location === 'flexben_benefit_group_list' && this.currentMenuItemSelected !== location) {
        redirLoc = '/multibeneficii/benefit-groups/';
      } else if (location === 'flexben_requests' && this.currentMenuItemSelected !== location) {
        redirLoc = '/multibeneficii/requests';
      } else if (location === 'flexben_beneficiaries' && this.currentMenuItemSelected !== location) {
        redirLoc = '/multibeneficii/beneficiaries';
      } else if (location === 'flexben_orders' && this.currentMenuItemSelected !== location) {
        redirLoc = '/multibeneficii/orders';
      } else if (location === 'flexben_import_new' && this.currentMenuItemSelected !== location) {
        redirLoc = '/multibeneficii/import-new';
      } else if (location === 'flexben_import_history' && this.currentMenuItemSelected !== location) {
        redirLoc = '/multibeneficii/import-history';
      } else {
        return;
      }
      localStorage.setItem('flexbenCompanyId', this.clientData.id);
      window.location.href = redirLoc;
    }
  }
}
</script>

<style lang="scss">
$mainBlackColor: #585858;
$mainOrangeColor: #f79000;
.quick-navigation {
  .dropdown-item {
    width: auto !important;
  }

  .client-dropdown-nav-btn {
    border-color: $mainBlackColor !important;
    background-color: $mainBlackColor !important;
    opacity: 1;


    &:hover, &[aria-expanded="true"] {
      opacity: 1;
      border-color: $mainBlackColor;
      background-color: $mainBlackColor;
      color: $mainOrangeColor;
    }
  }

  .client-dropdown-nav-list {
    margin-top: 0;
    border-color: $mainBlackColor;
    background-color: $mainBlackColor;
    color: white;

    .dropdown-item {
      color: white;

      &:hover {
        border-color: $mainBlackColor;
        background-color: $mainBlackColor;
        color: $mainOrangeColor;
      }
    }
  }

  .text-gray {
    color: #B7B3B5;
  }
}
</style>
