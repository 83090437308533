<template>
  <b-tab :title="configuration.benefit.name" :active="activeTab" :disabled="(configuration.benefit.status !=='ACTIVE')"
         :key="tabIndex" :class="'container-bg-white pt-2'">
    <div v-if="(configuration.benefit.status ==='ACTIVE')">
      <div class="row mx-2 pt-2">
        <div class="col-6 d-inline-flex align-items-center">
          <img src="@/assets/images/logo-up.png" height="20"/>
          <span class="text-20 text-bold ml-2">{{ $t('flexben.clients.benefits.up_dejun') }}</span>
        </div>
        <div class="col-6" v-if="isAdmin">
          <div class="float-right mt-1 pr-1 d-inline-flex align-items-center">
            <label for="benefit_dejun_toggle" class="d-inline-block ml-auto mb-0 mr-4 col-form-label p-0">
              {{$t("flexben.clients.partial.enable_benefit_for_client")}}
            </label>
            <b-form-checkbox id="benefit_dejun_toggle" class="d-inline-block"
                             switch
                             v-model="configuration.is_active">
            </b-form-checkbox>
          </div>
        </div>
      </div>
      <div class="row mt-4 mx-3">
        <div class="col-12">
          <div class="row benefits-order-info pt-3">
            <div class="col-md-2">
              <img src="@/assets/images/flexben/utils/benefit-info.svg" alt=""/>
            </div>
            <div class="col-md-10">
              <div>
                <p><strong>{{$t("flexben.clients.partial.up_dejun.text_1")}}</strong></p>
                <p><strong>{{$t("flexben.clients.partial.up_dejun.text_2")}}</strong></p>
              </div>
            </div>
          </div>
          <div class="row benefits-order-info mt-3 pt-3">
            <div class="col-md-3">
              <img src="@/assets/images/flexben/utils/benefit-info.svg" alt=""/>
            </div>
            <div class="col-md-3">
              <p>{{$t("flexben.general.fiscal_treatment")}}</p>
              <p class="text-16 text-bold"
                 v-if=" Object.keys(configuration.benefit.global_variable_codes).length === 3">
                {{$t("flexben.benefit.deductibility.none")}}
              </p>
              <p class="text-16 text-bold"
                 v-else-if="Object.keys(configuration.benefit.global_variable_codes).length < 3 && Object.keys(configuration.benefit.global_variable_codes).length > 0">
                {{$t("flexben.benefit.deductibility.partial")}}
              </p>
              <p class="text-16 text-bold"
                 v-else-if=" Object.keys(configuration.benefit.global_variable_codes).length === 0">
                {{$t("flexben.benefit.deductibility.complete")}}
              </p>
            </div>
            <div class="col-md-2">
              <p>{{$t("flexben.general.income_tax")}}</p>
              <p class="text-16 text-bold">{{
                configuration.benefit.global_variable_codes.includes('impozit-venit')
                ? globalVariables['impozit-venit'].value : 0 }}%</p>
            </div>
            <div class="col-md-2">
              <p>CAS</p>
              <p class="text-16 text-bold">{{ configuration.benefit.global_variable_codes.includes('cas') ?
                globalVariables['cas'].value : 0 }}%</p>
            </div>
            <div class="col-md-2">
              <p>CASS</p>
              <p class="text-16 text-bold">{{ configuration.benefit.global_variable_codes.includes('cass') ?
                globalVariables['cass'].value : 0 }}%</p>
            </div>
          </div>
          <div class="row benefits-order-info pt-md-3">
            <div class="col-md-3 offset-md-3">
              <div>
                <p>{{$t("flexben.general.last_annual_date_for_orders")}}</p>
                <p class="text-16 text-bold">{{ configuration.benefit.end_use_day_month.day }}/{{
                  configuration.benefit.end_use_day_month.month
                  }}/{{ configuration.benefit.end_use_day_month.year }}</p>
              </div>
            </div>
            <div class="col-md-3">
              <div>
                <p>{{$t("flexben.benefit.daily_minimum_order_value")}}</p>
                <p class="text-16 text-bold">{{ configuration.benefit.data.values.daily_ticket_min_value.value
                  }} Lei</p>
              </div>
            </div>
            <div class="col-md-3">
              <div>
                <p>{{$t("flexben.benefit.fields.daily_ticket_max_value")}}</p>
                <p class="text-16 text-bold">{{ configuration.benefit.data.values.daily_ticket_max_value.value
                  }} Lei</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AutoPlaceOrderSettings :isUpDejun="true" :configuration="configuration.configuration"
                              :clientSettings="clientSettings"
                              :isAdmin="isAdmin"
                              :benefitId="configuration.benefit_id"/>

      <up-dejun-fixed-values :isAdmin="isAdmin"
                             :fixedValues="configuration.configuration.top_up_fixed_values"
                             :topUpSetTicketNr="configuration.configuration.top_up_set_ticket_nr"
                             :divideBudgetForTicketValue="configuration.configuration.divide_budget_for_ticket_value"
                             :minValue="configuration.benefit.data.values.daily_ticket_min_value.value"
                             :maxValue="configuration.benefit.data.values.daily_ticket_max_value.value"
      ></up-dejun-fixed-values>
      <benefit-tax v-if="isAdmin" :tax-settings="configuration.configuration.tax_settings" :benefit-id="configuration.benefit_id"></benefit-tax>
      <benefit-translations v-if="isAdmin" :translations="benefitTranslations"></benefit-translations>
    </div>
  </b-tab>
</template>

<script>
import {BFormCheckbox, BTab} from "bootstrap-vue";
import AutoPlaceOrderSettings from "@/components/flexben/clients/partials/AutoPlaceOrderSettings";
import BenefitTranslations from "./BenefitTranslations";
import UpDejunFixedValues from "./UpDejunFixedValues";
import BenefitTaxSettings from "@/components/flexben/clients/partials/BenefitTaxSettings";

export default {
  components: {
    "b-tab": BTab,
    "b-form-checkbox": BFormCheckbox,
    AutoPlaceOrderSettings,
    'benefit-translations': BenefitTranslations,
    'up-dejun-fixed-values': UpDejunFixedValues,
    'benefit-tax': BenefitTaxSettings
  },
  name: "TabUpDejun",
  props: {
    configuration: {
      type: Object,
      default: function () {
        return {
          benefit: {},
          global_variable_codes: [],
          configuration: {
            top_up_fixed_values: {enable: false, values: []},
            top_up_set_ticket_nr: {enable: false, ticket_value: null},
            divide_budget_for_ticket_value: {enable: false},
          },
        };
      },
      required: true
    },
    benefitTranslations: {
      type: Object,
      default: function () {
        return {
          beneficiary_benefit_taxation: {}
        };

      },
      required: true
    },
    globalVariables: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
    clientSettings: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
    activeTab: {
      type: Boolean,
      default: false,
      required: true
    },
    tabIndex: {
      required: true
    },
    isAdmin: {
      type: Boolean,
      default: false,
      required: true
    },
  }
}
</script>
