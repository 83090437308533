var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"row"},[(_vm.importFinish.status==='fail')?_c('div',{key:'import_status',staticClass:"col-12 text-center"},[_c('p',{staticClass:"text-36 text-bold"},[_vm._v(_vm._s(_vm.$t('flexben.import.complete.fail')))]),_c('p',[_vm._v(_vm._s(_vm.$t('flexben.import.complete.fail_text')))])]):_c('div',{key:'import_status',staticClass:"col-12 text-center"},[_c('p',{staticClass:"text-36 text-bold"},[_vm._v(_vm._s(_vm.$t('flexben.import.complete.thank_you')))]),(_vm.importFinish.type ==='beneficiaries' && typeof _vm.importFinish.data.summary !=='undefined')?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t(('flexben.import.complete.beneficiary.'+(_vm.importFinish.data.summary.total_update_items > 0 ? 'text_1' : 'text_1_no_update')), {
           total: _vm.importFinish.data.summary.total_items,
           updated: _vm.importFinish.data.summary.total_update_items,
         }))}}):_vm._e(),(_vm.importFinish.type ==='update_beneficiary' && typeof _vm.importFinish.data.summary !=='undefined')?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t(('flexben.import.complete.update_beneficiary.'+(_vm.importFinish.data.summary.total_update_items > 0 ? 'text_1' : 'text_1_no_update')), {
           total: _vm.importFinish.data.summary.total_items,
           updated: _vm.importFinish.data.summary.total_update_items,
         }))}}):_vm._e(),(_vm.importFinish.type ==='budgets' && typeof _vm.importFinish.data.summary !=='undefined')?_c('p',{domProps:{"innerHTML":_vm._s( _vm.$t(('flexben.import.complete.budget.'+(_vm.importFinish.data.summary.total_update_items > 0 ? 'text_1' : 'text_1_no_update')), {
         total: _vm.importFinish.data.summary.total_items,
         updated: _vm.importFinish.data.summary.total_update_items,
         }))}}):_vm._e(),(_vm.importFinish.type ==='budgets' && typeof _vm.importFinish.data.summary !=='undefined')?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('flexben.import.complete.budget.text_2',{sum:_vm.importFinish.total_budget}))}}):_vm._e(),(_vm.importFinish.type ==='time_sheets')?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t(('flexben.import.complete.time_sheet.'+(_vm.importFinish.data.summary.total_update_items > 0 ? 'text_1' : 'text_1_no_update')), {
           total: _vm.importFinish.data.summary.total_items,
           updated: _vm.importFinish.data.summary.total_update_items,
         }))}}):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.$t('flexben.import.complete.review_text_2',{type:_vm.$t('flexben.import.types.'+_vm.importFinish.type)})))]),_c('p',[_vm._v(_vm._s(_vm.$t('flexben.import.complete.review_text_3')))])])]),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12 col-lg-8 offset-lg-2"},[_c('div',{staticClass:"d-flex flex-column flex-lg-row align-content-center align-items-center"},[_c('div',{staticClass:"mx-auto ml-md-auto mt-2"},[_c('a',{staticClass:"btn btn-themed",attrs:{"href":"/multibeneficii/import-history"}},[_vm._v(" "+_vm._s(_vm.$t('flexben.import.buttons.go_to_import_history')))])]),_c('div',{staticClass:"mx-auto mx-md-2 mt-2"},[_c('a',{staticClass:"btn btn-themed",attrs:{"href":"/multibeneficii/beneficiaries"}},[_vm._v(" "+_vm._s(_vm.$t('flexben.import.buttons.go_to_beneficiary')))])]),_c('div',{staticClass:"mx-auto mr-md-auto mt-2"},[_c('button',{staticClass:"btn btn-themed mx-auto",on:{"click":function($event){return _vm.$emit('newImport')}}},[_vm._v(" "+_vm._s(_vm.$t('flexben.import.buttons.new_import'))+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }