<template>
  <div>
    <div class="container-alb">
      Banner image
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12 text-left border-box pl-0">
          <div class="h2 my-5">Quick access</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-left border-box pl-0">
          <div class="btn bg-white border-themed mb-4 w-75 p-5 btn-block text-uppercase">Configure benefits</div>
          <div class="btn bg-white border-themed mb-4 w-75 p-5 btn-block text-uppercase">Configure benefits</div>
          <div class="btn bg-white border-themed mb-4 w-75 p-5 btn-block text-uppercase">Configure benefits</div>
          <div class="btn bg-white border-themed mb-4 w-75 p-5 btn-block text-uppercase">Configure benefits</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    data() {return {};},
    methods: {},
    created() {},
    mounted() {},
    watch  : {}
  }
</script>

<style scoped>

</style>
