<template>
  <div class="row mt-1 align-items-center justify-content-center">
    <div class="col-12 col-md-4 mt-1 mt-md-0">
      <div class="btn-request-type text-themed-dark d-flex align-items-center justify-content-center"
           :class="{'btn-request-type-active':(requestsType==='top_up')}"
           @click="changeType('top_up')">
        <img src="@/assets/images/flexben/order/topUp.svg" class="mr-2 mr-xl-3"/>
        <div>
          <div class="text-20 text-uppercase">{{ $t('flexben.requests.btn.request_type_top_up')}}</div>
          <div class="text-12 mt-1">{{ requestsTotals.top_up }} {{ $t('flexben.requests.new_requests')}}</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4 mt-1 mt-md-0">
      <div class="btn-request-type text-themed-dark d-flex align-items-center justify-content-center ml-auto"
           :class="{'btn-request-type-active':(requestsType==='not_legislated')}"
           @click="changeType('not_legislated')">
        <img src="@/assets/images/flexben/order/notLegislated.svg" class="mr-2 mr-xl-3"/>
        <div>
          <div class="text-20 text-uppercase">{{ $t('flexben.requests.btn.request_type_partners')}}</div>
          <div class="text-12 mt-1">{{ requestsTotals.not_legislated }} {{ $t('flexben.requests.new_requests')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestTypesButtons",
  props: {
    requestsType: {
      default: null
    },
    requestsTotals: {
      type: Object,
      default: function () {
        return {
          card: 0,
          not_legislated: 0,
          top_up: 0,
        };
      },
      required: true
    },
  },
  methods: {
    changeType(type) {
      this.$emit('changeType', type);
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-request-type {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 0.25rem;
  color: #585858;
  font-size: 14px;
  opacity: 0.9;
  height: 100%;
  text-align: center;
  line-height: 1.1;
  cursor: pointer;
  width: 100%;
  padding: 1.1rem 0.5rem;
  max-width: 350px;
  transition: background-color 300ms ease;

  &.btn-request-type-active, &:hover {
    opacity: 1;
    background: var(--color-f49400);
    border-color: var(--color-f49400);
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding: 0.6rem 0.3rem;
  }
}
</style>
