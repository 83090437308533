<template>
  <div>
    <div v-if="!operating && summary_data.failed.length!==0">
      <div class="mt-3 container-bg-white">
        <LockedFilters :isAdmin="isAdmin"
                       :params="params"
                       :selectedFilters="selectedFilters"
                       @filterValues="filterValues"/>
      </div>
      <div class="mt-3 container-bg-white">
        <div class="row p-3">
          <div class="col-12">
            <h3 class="pl-0 mt-0 mb-1 text-capitalize">{{ $t("flexben.general.requests") }}</h3>
          </div>
          <div class="col-12">
            <div class="table-responsive hover-table">
              <table class="table table-scroll-y table-scroll-y-500 table-striped">
                <thead>
                <tr>
                  <td class="w-50-p"><input type="checkbox" id="check_all" v-model="checkedAllModel"
                                            @change="checkAll()">
                  </td>
                  <td> {{ $t("flexben.requests.table.header.number") }}</td>
                  <td>{{ $t("flexben.requests.table.header.placed_on") }}</td>
                  <td>{{ $t("flexben.requests.table.header.beneficiary") }}</td>
                  <td>{{ $t("flexben.requests.table.header.beneficiary_cnp") }}</td>
                  <td>{{ $t("flexben.requests.table.header.benefit") }}</td>
                  <td>{{ $t("flexben.requests.table.header.amount") }}</td>
                  <td>{{ $t("flexben.requests.table.header.status") }}</td>
                  <td class="w-50-p"></td>
                </tr>
                </thead>
                <tbody>
                <template v-if="checkNotEmptyObj(remaining_requests)">
                  <tr class="trhover"
                      v-for="(request, index) in remaining_requests" :key="index"
                      v-show="params.status===null || request.status===params.status">
                    <td class="w-50-p">
                      <input :checked="checkedRequests.ids.includes(request.id)" type="checkbox"
                             @change="toggleCheck(index)"/>
                    </td>
                    <td>{{ request.id }}</td>
                    <td>{{ moment(request.placed_at, 'DD/MM/YYYY HH:mm') }}</td>
                    <td>{{ request.company_employee.first_name+' '+ request.company_employee.last_name}}</td>
                    <td>{{ request.company_employee.cnp }}</td>
                    <td>
                      <span :style="{backgroundColor: request.benefit.color}" class="color-bullet mr-1"></span>
                      {{ request.benefit.name }}
                      <span v-if="request.event !== null"> - {{ (request.event.type ==='LEGISLATED') ? $t('flexben.general.legal_events.'+request.event.name) : request.event.name }}</span>
                    </td>
                    <td>{{ request.total_value }} Lei</td>
                    <td>
                      <span :class="'order-status-'+request.status">{{ $t('flexben.requests.summary.status.'+request.status) }}</span>
                    </td>
                    <td class="w-50-p">
                      <template v-if="request.validation_errors && request.validation_errors.length > 0">
                        <span class="ml-1 item-error-reason tooltip-inner" :id="'invalid_tooltip_'+index">!</span>
                        <b-tooltip :target="'invalid_tooltip_'+index" variant="flexben"
                                   placement="topleft">
                          <div v-html="getInvalidReasonMessage(request.validation_errors)" class="p-2 text-12"></div>
                        </b-tooltip>
                      </template>
                    </td>
                  </tr>
                </template>
                <template
                  v-if="remaining_requests.length ===0 || (params.status==='valid' && summary_data.success.length ===0) || (params.status==='invalid' && summary_data.failed.length ===0)">
                  <tr class="gri-cell text-center trhover">
                    <td colspan="9" class="text-center">{{ $t("flexben.general.no_results_found") }}
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 container-bg-white">
        <div class="row p-3 mt-3">
          <div class="col-12">
            <h3 class="pl-0 mt-0 mb-1">{{ $t("flexben.general.summary") }}</h3>
          </div>
          <div class="col-12">
            <div class="container-fluid w-100">
              <div class="row py-3" v-for="(benefit, index) in summary_data.mixed"
                   :key="index"
                   :class="{'gri-cell':!(index%2)}">
                <div class="col-12 col-md-4 text-bold">
                  <span :style="{backgroundColor: benefit.color}" class="color-bullet mr-1"></span> {{ benefit.name }}
                  <span class="ml-2"
                        :class="{'text-success':benefit.order_success,'text-danger':!benefit.order_success}">
                    {{ $t('flexben.requests.summary.'+ (benefit.order_success ? 'order_placed':'order_invalid')) }}
                  </span>
                </div>
                <div class="col-12 col-md-4">
                  <span class="text-bold">{{ $t("flexben.requests.summary.count_total_request") }}:</span>
                  <span class="float-right">{{ benefit.total_count }}</span>
                </div>
                <div class="col-12 col-md-4" v-if="!benefit.order_success">
                  <span class="text-bold">{{ $t("flexben.requests.summary.total_values") }}:</span>
                  <span class="float-right">{{ benefit.total_value }} Lei</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row p-3">
          <div class="col-12 col-md-4">
            <button class="btn text-danger px-0 px-2" :disabled="checkedRequests.ids.length === 0"
                    @click="openRejectModal()">
              <font-awesome-icon icon="trash" class="mr-1"/>
              {{ $t("flexben.order.steps.buttons.reject_selected_order_request", {countOrderRequest:
              checkedRequests.ids.length}) }}
            </button>
          </div>
          <div class="col-12 col-md-4 text-md-center mt-1 mt-md-0">
            <button class="btn text-themed px-0 px-2" @click="reStartFlow()">
              <font-awesome-icon icon="list" class="mr-1"/>
              {{ $t("flexben.requests.btn.back_to_requests") }}
            </button>
          </div>
          <div class="col-12 col-md-4 mt-1 mt-md-0">
            <button class="btn btn-themed px-4 float-md-right" @click="confirmPlaceOrder()"
                    :disabled="this.requestIds.length===0">
              {{ $t("flexben.requests.btn.place_order") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <ConfirmMassPlaceOrderModal @placeOrder="placeOrder" ref="confirm_po_modal"/>
    <RejectModal :rejectData="checkedRequests"
                 @confirmAction="rejectRequests()"
                 ref="reject-modal"/>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import RejectModal from "@/components/flexben/requests/modals/RejectModal";
import {BTooltip} from "bootstrap-vue";
import LockedFilters from "@/components/flexben/requests/partials/LockedFilters";
import ConfirmMassPlaceOrderModal from "@/components/flexben/requests/modals/ConfirmMassPlaceOrderModal";

export default {
  name: "ListPlaceOrderResult",
  components: {
    ConfirmMassPlaceOrderModal,
    LockedFilters,
    RejectModal,
    'b-tooltip': BTooltip
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
      required: true,
    },
    companyId: {
      type: Number,
    },
    selectedRequestToOrder: {
      type: Array,
      default: function () {
        return []
      },
      required: true,
    },
    selectedFilters: {
      type: Object,
      default: function () {
        return {}
      },
      required: true,
    }
  },
  data: function () {
    return {
      operating: false,
      requestIds: [],
      httpService: httpFlexbenService.requests,
      dateFormat: 'dd/MM/yyyy',
      params: {
        status: null
      },
      requests_errors: {},
      remaining_requests: [],

      summary_data: {
        success: [],
        failed: [],
        mixed: [],
      },
      checkedRequests: {
        ids: [],
        indexes: [],
        reject_reason: '',
        summaryList: {}
      },
      checkedAllModel: false,
      successfulOrderTotal: 0,
    };
  },
  created() {
    this.requestIds = this.selectedRequestToOrder;
    this.placeOrder();
  },
  methods: {
    reStartFlow() {
      this.$emit('reStartFlow');
    },
    placeOrder() {
      if (typeof this.$refs['confirm_po_modal'] !== "undefined") {
        this.$refs['confirm_po_modal'].closeModal();
      }
      if (this.requestIds.length === 0) {
        this.reStartFlow();
      }
      this._toggleLoading(true);
      this.operating = true;
      this.httpService.orderTopUpsRequests({
        request_ids: this.requestIds,
        company_id: this.companyId
      })
        .then(function (response) {
          this.remaining_requests = response.data.remaining_requests;
          this.summary_data.success = response.data.summary.success;
          this.summary_data.failed = response.data.summary.failed;

          let mixed = [];
          mixed = mixed.concat(this.summary_data.success).concat(this.summary_data.failed);
          this.summary_data.mixed = mixed;
          this.params.status = null;
          this.checkedAllModel = false;
          this.requestIds = [];
          this.checkedRequests.ids = [];
          this.checkedRequests.indexes = [];
          //count total success for each attempt to place order
          this.successfulOrderTotal += this.summary_data.success.length
          this._toggleLoading(false);

          /*
          if all orders have been placed move to the success screen else
          stay in the current screen and operate failed requests
           */
          if (this.summary_data.failed.length === 0) {
            this._displaySuccessScreen();
          } else {
            this.operating = false;
          }
        }.bind(this))
        .catch(function (error) {
        }.bind(this))
    },
    openRejectModal() {
      if (this.checkedRequests.ids.length !== 0) {
        this.$refs['reject-modal'].openModal();
      }
    },
    removeRequests() {
      if (this.checkedRequests.ids.length === 0) {
        return;
      }
      this.remaining_requests = this.remaining_requests.filter(((request) => {
        return !this.checkedRequests.ids.includes(request.id);
      }).bind(this));

      this.requestIds = [];
      this.checkedRequests.ids = [];
      this.checkedRequests.indexes = [];
      this.checkedAllModel = false;
    },
    rejectRequests() {
      if (this.checkedRequests.ids.length === 0) {
        return;
      }

      this._toggleLoading(true);
      this.$refs['reject-modal'].closeModal();
      let payload = {
        ids: this.checkedRequests.ids,
        reject_reason: this.checkedRequests.reject_reason,
      };

      this.httpService.rejectRequests(payload)
        .then(function (response) {
          this.removeRequests();
          this._toggleLoading(false);
          this._setGeneralData();
          this.filters.status = null;
        }.bind(this))
        .catch(function (error) {
          if (500 <= error.response.status && error.response.status < 600) {
            this.loaderError = true;
            this._toggleLoading(true, true);
          } else {
            this._toggleLoading(false);
          }
        }.bind(this));
    },
    checkAll() {
      if (!this.checkedAllModel) {
        this.checkedRequests.ids = [];
        this.checkedRequests.indexes = [];
        this.requestIds = [];
      } else {
        let request;
        for (let index in this.remaining_requests) {
          request = this.remaining_requests[index];
          if (this.checkedRequests.ids.includes(request.id) && this.checkedAllModel) {
            continue;
          }
          if (this.params.status === null || request.status === this.params.status) {
            this.toggleCheck(index, false);
          }
        }
      }
      this.requestSummaryKey++;
    },
    toggleCheck(index, triggerRerender = true) {
      const request = this.remaining_requests[index];
      if (this.checkedRequests.ids.includes(request.id)) {
        this.checkedRequests.ids.splice(this.checkedRequests.ids.indexOf(request.id), 1);
        this.requestIds.splice(this.requestIds.indexOf(request.id), 1);

        delete this.checkedRequests.summaryList[request.company_id].benefits[request.benefit_id].values[request.id];

        if (!this.checkNotEmptyObj(this.checkedRequests.summaryList[request.company_id].benefits[request.benefit_id].values)) {
          delete this.checkedRequests.summaryList[request.company_id].benefits[request.benefit_id];
        }

        if (!this.checkNotEmptyObj(this.checkedRequests.summaryList[request.company_id].benefits)) {
          delete this.checkedRequests.summaryList[request.company_id];
        }

        this.checkedRequests.indexes.splice(this.checkedRequests.indexes.indexOf(index), 1);
        this.checkedAllModel = false;
      } else {
        this.checkedRequests.ids.push(request.id);
        this.requestIds.push(request.id);
        let companyObj;
        if (request.company_id in this.checkedRequests.summaryList) {
          companyObj = {...this.checkedRequests.summaryList[request.company_id]};
        } else {
          companyObj = {
            name: request.company_name,
            benefits: {}
          };
        }
        if (request.benefit_id in companyObj.benefits) {
          companyObj.benefits[request.benefit_id]['values'][request.id] = request.total_value;
        } else {
          companyObj.benefits[request.benefit_id] = {
            color: request.benefit_color,
            name: request.benefit_name,
            values: {}
          };
          companyObj.benefits[request.benefit_id]['values'][request.id] = request.total_value;
        }
        this.checkedRequests.summaryList[request.company_id] = companyObj;
        this.checkedRequests.indexes.push(index);
      }
      if (triggerRerender) {
        this.requestSummaryKey++;
      }
    },
    getInvalidReasonMessage(invalidReasons) {
      let errorList = [];
      let index;
      for (index in invalidReasons) {
        errorList.push(this.$t('flexben.order.error.' + invalidReasons[index].message_code, invalidReasons[index].to_replace))
      }
      return errorList.join('<br>');
    },
    filterValues() {
      this.checkedRequests.ids = [];
      this.checkedRequests.indexes = [];
      this.requestIds = [];
      this.checkedAllModel = false;
    },
    confirmPlaceOrder() {
      this.$refs['confirm_po_modal'].openModal('order');
    },
    _displaySuccessScreen() {
      this.$emit('displaySuccessScreen', this.successfulOrderTotal);
    },
    _toggleLoading(value, loaderError = false) {
      this.$emit('toggleLoading', value, loaderError);
    },
    _setGeneralData() {
      this.$emit('setGeneralData');
    }
  }
}
</script>
