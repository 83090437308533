import router from "../../../router";
import httpFlexbenService from "@/http/http-flexben.js";

export default {
  name: "ClientsIndex",
  components: {},
  data() {
    return {
      httpService: httpFlexbenService.clients,
      initItem: false,
      activeItemId: null,

      // Item data
      hasItem: false,
      itemStructure: {
        id: false,
        start_use_date: "",
        value: '',
        name: "",
        type: "",
        future_start_date: '',
        future_value: '',
      },
      activeItem: {},
      modalTitle: '',
      itemHasError: false,
      itemErrors: [],
      // list related data
      initialLoading: true,
      loading: true,
      loaderError: false,
      params: {},
      listDataLoaded: false,
      searchString: '',
      sortField: '',
      sortDirection: 'asc',
      reverseSort: false,

      clients: {
        type: Object,
        default: function () {
          return {
            data: [],
            current_page: 1,
            to: 1,
            perPage: 1,
            lastPage: 1,
            total: 1,
          };
        },
        required: true
      },

      itemsPerPage: 10,
      currentPage: 1,
      startItem: 1,
      endItem: 1,
      entriesTotal: 0,
    };
  },
  created() {
    this._loadList();
  },
  methods: {
    _loadList() {
      this.loading = true;
      this.httpService
        .loadList(this.params)
        .then(function (result) {
          this.clients = {
            data: result.data.data,
            current_page: result.data.current_page,
            to: result.data.to,
            perPage: result.data.perPage,
            lastPage: result.data.lastPage,
            total: result.data.total,
          };

          this.listDataLoaded = true;
          this.loading = false;
          this.initialLoading = false;
        }.bind(this))
        .catch(function (error) {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.listDataLoaded = true;
            this.loading = false;
            this.initialLoading = false;
          }
        }.bind(this));
    },
    sortBy(fieldName) {
      if (this.sortField !== fieldName) {
        this.reverseSort = false;
      }
      if (typeof this.params.page !== 'undefined') {
        delete this.params.page;
      }
      this.params.sort_by = fieldName;
      this.params.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this._loadList(this.params)
      this.sortField = fieldName;
      this.reverseSort = !this.reverseSort;
    },
    setClientId(clientId) {
      this.activeItemId = clientId;
      router.push({name: 'flexben_edit_client', params: {id: clientId}})
    },
    pageChanged(pageNb) {
      this.clients.current_page = pageNb;
      this.params.page = pageNb;
      this._loadList();
    },
    search() {
      if (this.searchString.length >= 3) {
        this.params.search = this.searchString;
        this._loadList(this.params);
      } else if (this.searchString.length === 0) {
        delete this.params.search;
        this._loadList(this.params);
      }
    },
    goToNewImport(companyId) {
      localStorage.setItem('flexbenCompanyId', companyId);
      window.location.href = '/multibeneficii/import-new'
    },
    goToBeneficiaries(companyId) {
      localStorage.setItem('flexbenCompanyId', companyId);
      window.location.href = '/multibeneficii/beneficiaries'
    },
    changePerPage(perPage) {
      this.params.page = 1;
      this.params.perPage = perPage;
      this._loadList();
    },
  }
}
