<template>
  <div class="table-responsive hover-table">
    <table class="table table-striped">
      <thead>
      <tr>
        <td class="text-capitalize">{{$t("flexben.import.types.beneficiary")}}</td>
        <td>{{$t("flexben.general.status")}}</td>
        <td>{{$t("flexben.beneficiaries.fields.invitation_email")}}</td>
        <td>{{$t("flexben.import.table_headings.phone_number")}}</td>
        <td>{{$t("flexben.import.table_headings.cnp")}}</td>
        <td>{{$t("flexben.beneficiaries.fields.employee_id")}}</td>
        <td>{{$t("flexben.import.table_headings.benefit_group")}}</td>
        <td>{{ $t('flexben.import.table_headings.up_dejun_enable_auto_topup') }}</td>
        <td>{{ $t('flexben.import.table_headings.up_dejun_ticket_value') }}</td>
        <td>{{ $t('flexben.import.table_headings.income_tax_percent') }}</td>
        <td>{{ $t('flexben.import.table_headings.cass_percent') }}</td>
        <td>{{ $t('flexben.import.table_headings.cas_percent') }}</td>
      </tr>
      </thead>
      <tbody>
      <template v-if="import_items.length">
        <tr v-for="(item, index) in import_items" :key="index"
            class="trhover">
          <td>{{ item.last_name || item.first_name ? (item.last_name ? item.last_name : '') + ' ' + (item.first_name ? item.first_name : '') : '-' }}</td>
          <td><span
            :class="'import-status-item-' + item.status">{{ $t('flexben.general.status_type.' + item.status) }}</span>
          </td>
          <td>{{ item.invitation_email ? item.invitation_email : '-' }}</td>
          <td>{{ item.phone_number ? item.phone_number : '-' }}</td>
          <td>{{item.cnp}}</td>
          <td>{{ item.employee_number ? item.employee_number : '-' }}</td>
          <td>{{ item.benefit_category_code ? item.benefit_category_code : '-' }}</td>
          <td>{{ item.up_dejun_enable_auto_topup ? (item.up_dejun_enable_auto_topup ? $t('flexben.general.yes') : $t('flexben.general.no')) : '-' }}</td>
          <td>{{ (item.up_dejun_enable_auto_topup && item.up_dejun_ticket_value !=null) ? item.up_dejun_ticket_value :
            '-' }}</td>
          <td>{{ item.income_tax_percent!=null ? item.income_tax_percent : '-' }}</td>
          <td>{{ item.cas_percent!=null ? item.cas_percent : '-' }}</td>
          <td>{{ item.cass_percent!=null ? item.cass_percent : '-' }}</td>
        </tr>
      </template>
      <template v-else>
        <tr class="gri-cell text-center trhover">
          <td colspan="7">{{ $t("flexben.general.no_results_found") }}</td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: "TableTypeUpdateBeneficiary",
    props: {
      importItems: {
        type: Array,
        default: function () {
          return [];
        },
        required: true,
      }
    },
    computed: {
      import_items: function () {
        return this.importItems;
      }
    }
  }
</script>
