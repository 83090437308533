<template>
  <div :class="{show: openModal }"
       class="modal fade">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header pb-0 no-border">
          <h3 class="mt-0 pt-1">{{ (modalAction==='add') ? $t("flexben.clients.modal.add") :
            $t("flexben.clients.modal.edit") }}
            {{ (type==='company_event') ? $t("flexben.clients.modal.event") :
            $t("flexben.clients.modal.occasion")}} </h3>
          <span :class="formData.is_active ? 'occasion-active' :'occasion-inactive'"
                class="occasion-active float-right">{{ formData.is_active ? $t("flexben.general.status_type.active") : $t("flexben.general.status_type.inactive") }}</span>
        </div>

        <div class="modal-body py-0 bg-white">
          <div class="row mt-2" v-if="Object.keys(errorBag).length > 0 && errorBag.constructor === Object">
            <div class="col-12">
              <FormErrorList :errorBag="errorBag"></FormErrorList>
            </div>
          </div>
          <div class="row pr-3">
            <div class="col-12 col-md-6 mb-2">
              <label for="event_name" class="w-100 cursor-pointer form-fields-required col-form-label">{{$t("flexben.general.name")}}</label>
              <input type="text" class="form-control w-100" id="event_name"
                     :class="{
                      'background-white':!isLegislated
                     }"
                     :disabled="isLegislated"
                     v-model="formData.name">
            </div>
            <div class="col-12 col-md-6">
              <label
                class="w-100 cursor-pointer form-fields-required col-form-label">{{$t("flexben.general.date")}}</label>
              <div class="row">
                <div class="col-md-7 mb-2">
                  <select name="month" id="month" v-model="date.month" class="form-control"
                          :disabled="formData.is_birthday || isLegislated"
                          :class="{' cursor-pointer':!formData.is_birthday}"
                          @change="function() {
                    tickDay++;
                  }">
                    <option value="" disabled>{{ $t('flexben.general.month') }}</option>
                    <option value="1">{{$t("flexben.general.months.1")}}</option>
                    <option value="2">{{$t("flexben.general.months.2")}}</option>
                    <option value="3">{{$t("flexben.general.months.3")}}</option>
                    <option value="4">{{$t("flexben.general.months.4")}}</option>
                    <option value="5">{{$t("flexben.general.months.5")}}</option>
                    <option value="6">{{$t("flexben.general.months.6")}}</option>
                    <option value="7">{{$t("flexben.general.months.7")}}</option>
                    <option value="8">{{$t("flexben.general.months.8")}}</option>
                    <option value="9">{{$t("flexben.general.months.9")}}</option>
                    <option value="10">{{$t("flexben.general.months.10")}}</option>
                    <option value="11">{{$t("flexben.general.months.11")}}</option>
                    <option value="12">{{$t("flexben.general.months.12")}}</option>
                  </select>
                </div>

                <div class="col-md-5 mb-2">
                  <select name="day" id="day" v-model="date.day"
                          :disabled="(date.month ==='' || formData.is_birthday || isLegislated)"
                          class="w-100 form-control"
                          :class="{' cursor-pointer':!(date.month ==='' || formData.is_birthday)}"
                          :key="tickDay">
                    <option value="" disabled>{{$t("flexben.general.day")}}</option>
                    <option :value="day" v-for="day in getMonthMaxDays()" :key="day">{{day}}</option>
                  </select>
                </div>

              </div>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <label for="max_top_up" class="w-100 form-fields-required col-form-label">{{$t("flexben.general.top_up_limit")}}
                (Lei)</label>
              <input type="number" min="1" class="form-control w-100" id="max_top_up"
                     v-model.number="formData.maximum_topup_limit">
            </div>
            <div class="col-12 col-md-6 mb-2" v-if="type==='company_event'">
              <div class="w-100 mb-3 form-fields-required col-form-label">{{$t("flexben.clients.modal.birthday")}}</div>
              <div class="row">
                <div class="col-6 col-md-4">
                  <label for="birthday_yes" class="form-fields-required col-form-label">
                    <input type="radio"
                           name="birthday"
                           class="radio-custom cursor-pointer"
                           id="birthday_yes"
                           :value="true"
                           :disabled="isLegislated"
                           v-model="formData.is_birthday"/>
                    {{$t("flexben.general.yes")}}</label>
                </div>
                <div class="col-6 col-md-4">
                  <label for="birthday_no" class="col-form-label">
                    <input type="radio"
                           name="birthday"
                           class="radio-custom cursor-pointer"
                           id="birthday_no"
                           :value="false"
                           :disabled="isLegislated"
                           v-model="formData.is_birthday"/>
                    {{$t("flexben.general.no")}}</label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2" v-if="type==='company_event'">
              <div class="w-100 mb-3 col-form-label pb-0">{{ $t("flexben.clients.modal.gender_availability") }}
              </div>
              <div class="row">
                <div class="col-6 d-inline-flex">
                  <input type="checkbox" id="gender_men" class="cursor-pointer my-auto"
                         v-model="formData.gender_availability.male"
                         :disabled="isLegislated"/>
                  <label for="gender_men"
                         class="ml-2 mb-0 cursor-pointer col-form-label p-0">{{$t("flexben.clients.modal.gender_male")}}</label>
                </div>
                <div class="col-6 d-inline-flex">
                  <input type="checkbox" id="gender_women" class="cursor-pointer my-auto"
                         v-model="formData.gender_availability.female"
                         :disabled="isLegislated"/>
                  <label for="gender_women" class="ml-2 mb-0 cursor-pointer col-form-label p-0">{{$t("flexben.clients.modal.gender_female")}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 mb-2" v-if="formData.has_limit_per_child">
              <label for="max_limit_child" class="w-100 form-fields-required col-form-label">
                {{$t("flexben.clients.modal.max_limit_for_topup_per_child")}} (Lei)
              </label>
              <input type="number" min="1" class="form-control w-100" id="max_limit_child"
                     v-model.number="maximum_topup_limit_per_minor_value"
                     :max="formData.maximum_topup_limit">
            </div>
          </div>
        </div>

        <div class="modal-footer no-border">
          <button type="button"
                  @click="toggleStatus()"
                  class="btn mr-auto text-themed">
            <font-awesome-icon icon="ban" v-if="formData.is_active" :key="'status_changer'"/>
            <font-awesome-icon icon="check" v-else :key="'status_changer'"/>
            {{ formData.is_active ? $t("flexben.clients.modal.set_inactive") : $t("flexben.clients.modal.set_active") }}
          </button>
          <button type="button"
                  @click="closeModal()"
                  class="btn text-themed">{{$t("flexben.general.btn.cancel")}}
          </button>
          <button type="button"
                  @click="submitForm()"
                  class="btn save-btn">{{$t("flexben.general.btn.save")}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormErrorList from "../../../fragments/FormErrorList";

export default {
  name: "CompanyEventModal",
  components: {FormErrorList},
  props: {
    openModal: {
      type: Boolean,
      default: false,
      required: true
    },
    type: {
      type: String,
      default: '',
      required: true
    },
    formData: {
      type: Object,
      default: function () {
        return {
          occasionId: null,
          occasionIndex: null,
          name: '',
          is_active: true,
          maximum_topup_limit: 0,
          maximum_topup_limit_per_minor: 0,
          date: {
            day: null,
            month: null,
            year: null,
          },
          is_birthday: false,
          gender_availability: {
            male: true,
            female: true,
          },
        };
      },
      required: true
    },
    modalAction: null,
    errorBag: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isLegislated: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      date: {
        day: '',
        month: '',
      },
      tickDay: 0,
      maximum_topup_limit_per_minor_value: null
    }
  },
  watch: {
    date: {
      handler(val) {
        this.formData.date = {
          day: this.date.day,
          month: this.date.month,
          year: null,
        };
      },
      deep: true
    },
    maximum_topup_limit_per_minor_value: {
      handler(val) {
        if (val) {
          this.formData.maximum_topup_limit_per_minor = parseFloat(val);
        } else {
          this.formData.maximum_topup_limit_per_minor = null;
        }
      }
    }
  },
  created() {
    if (this.formData.date.month !== null && this.formData.date.day !== null) {
      this.date = {
        day: this.formData.date.day,
        month: this.formData.date.month,
      };
    }
    this.maximum_topup_limit_per_minor_value = this.formData.maximum_topup_limit_per_minor;
  },


  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    submitForm() {
      this.$emit('submitForm');
    },
    toggleStatus() {
      this.$emit('toggleStatus');
    },
    getMonthMaxDays() {
      if (this.date.month === '') {
        return 0;
      } else {
        let days = [];
        let month = this.date.month;
        let maxDays = (new Date((new Date()).getFullYear(), month, 0)).getDate();
        for (let nr = 1; nr <= maxDays; nr++) {
          days.push(nr);
        }
        return days;
      }
    }
  }
}
</script>
<style scoped>
.form-control {
  margin: 0 !important;
  border: 1px solid #ced4da;
  padding: 0 5px;
  height: 36px !important;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}
</style>
