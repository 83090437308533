<template>
  <div>
    <div v-show="!showForm && !initialLoading">
      <div class="row mb-md-4">
        <div class="col-12 col-md-6 flexben-page-title">
          <h1>{{$t("flexben.fixed_benefits.title")}}</h1>
          <p>{{$t("flexben.fixed_benefits.subtitle")}}</p>
        </div>
        <div class="col-12 col-md-6">
          <button @click="openForm('create')" class="btn btn-themed px-4 float-left float-md-right my-2">{{
            $t("flexben.fixed_benefits.new") }}
          </button>
        </div>
      </div>
      <div class="container-bg-white" v-if="isAdmin">
        <div class="row p-3">
          <div class="col-12 col-xl-3 mb-2 mb-xl-0">
            <img src="@/assets/images/filter.svg"/>
            <span class="ml-3">{{$t("flexben.general.filter")}}</span>
          </div>
          <div class="col-12 col-xl-9">
            <div class="row">
              <div class="col-12 col-md-4 offset-md-8">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-0" for="client">{{ $t("flexben.general.client") }}</label>
                  <select id="client" class="form-control w-100"
                          @change="filtersValues()"
                          v-model="filters_values.company">
                    <option :value="null">-</option>
                    <option v-for="(item, value) in filters.companies.values"
                            :key="value"
                            :value="value">{{ item }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-6 mt-3 text-bold">
            <h3 class="mt-0 pt-1 pl-0">{{ $t("flexben.fixed_benefits.list") }}</h3>
          </div>
          <div class="col-6 mt-3">
            <input type="text"
                   v-model="searchString"
                   v-on:keyup.enter="search()"
                   :placeholder="$t('flexben.general.search')"
                   class="formedituser search-input float-right"/>
          </div>

          <div class="col-12">
            <div class="table-responsive hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td>Img</td>
                  <td v-if="isAdmin">{{ $t("flexben.fixed_benefits.table.company") }}</td>
                  <td>{{ $t("flexben.fixed_benefits.table.name") }}</td>
                  <td></td>
                </tr>
                </thead>
                <tbody v-if="fixedBenefitList.data.length >0">
                <tr v-for="(item, index) in fixedBenefitList.data" :key="index"
                    class="trhover">
                  <td><img :src="item.image_url" alt="" width="50"></td>
                  <td v-if="isAdmin">{{ item.company_name }}</td>
                  <td> {{ item.name[locale] }}</td>
                  <td class="action-group text-right">
                    <button type="button" class="btn action-button"
                            @click="openForm('update', index)">{{$t("flexben.general.btn.see_more")}}
                    </button>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td :colspan="isAdmin ? 4 : 3" class="text-center trhover gri-cell">
                    {{ $t("flexben.general.no_results_found") }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 p-0">
            <listPagination
              :activePage="fixedBenefitList.current_page"
              :currentPage="fixedBenefitList.current_page"
              :totalItems="fixedBenefitList.total"
              :startItem="fixedBenefitList.from"
              :endItem="fixedBenefitList.to"
              :itemsPerPage="fixedBenefitList.perPage"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage"></listPagination>
          </div>
        </div>
      </div>
    </div>
    <fixed-benefit-detail v-if="showForm"
                          :groupId="fixedBenefitDetailId"
                          :companyId="selectedCompanyId"
                          :formAction="formAction"
                          @triggerLoading="triggerLoading"
                          @updateListByIndex="updateListByIndex"
                          @clearForm="clearForm"/>

    <select-client-modal v-if="openModal"
                         :companies="filters.companies.values"
                         :openModal="openModal"
                         :noClientSelected="noClientSelected"
                         @closeModal="closeModal"
                         @goToCreate="goToCreate"/>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import FixedBenefitDetail from "@/components/flexben/fixed_benefits/FixedBenefitDetail";
import SelectClientModal from "@/components/fragments/SelectClientModal";
import i18n from "@/plugins/language/i18n";

export default {
  name: "FixedBenefitsList",
  components: {
    'fixed-benefit-detail': FixedBenefitDetail,
    'select-client-modal': SelectClientModal,
  },
  data() {
    return {
      initialLoading: true,
      loading: false,
      loaderError: false,
      isAdmin: false,
      locale: i18n.locale,

      httpService: httpFlexbenService.fixedBenefitsService,

      fixedBenefitList: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      filters: {
        'companies': {
          'key': 'company',
          'values': []
        },
      },

      filters_values: {
        company: null,
        page: 1,
        perPage: 20,
        search: null,
      },
      searchString: '',

      openModal: false,
      noClientSelected: false,
      selectedCompanyId: null,
      benefitGroupDetailIndex: null,
      fixedBenefitDetailId: null,
      formAction: null,
      showForm: false,
    }
  },
  created() {
    let userRole = this.flxUserLevel().userLevel;
    const employment = this.$store.getters.flx_activeEmployment;
    if (userRole === 'client' && employment != null) {
      this.filters_values.company = employment.company_id;
      this.selectedCompanyId = employment.company_id;
      this.isAdmin = false;
    } else if (userRole === 'admin') {
      if (typeof localStorage.getItem('flexbenCompanyId') !== "undefined" && localStorage.getItem('flexbenCompanyId') !== null) {
        this.selectedCompanyId = localStorage.getItem('flexbenCompanyId');
        this.filters_values.company = localStorage.getItem('flexbenCompanyId')
        localStorage.removeItem('flexbenCompanyId');
      }
      this.isAdmin = true;
    } else {
      window.location.href = '/';
      return;
    }

    this._loadList();
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  methods: {
    _loadList() {
      this.loading = true;
      this.httpService.list(this.filters_values)
        .then(function (response) {
          this.fixedBenefitList.data = response.data.list.data;
          this.fixedBenefitList.current_page = response.data.list.current_page;
          this.fixedBenefitList.to = response.data.list.to;
          this.fixedBenefitList.perPage = response.data.list.perPage;
          this.fixedBenefitList.lastPage = response.data.list.lastPage;
          this.fixedBenefitList.total = response.data.list.total;

          if (this.initialLoading) {
            this.filters = response.data.filters;
            this.initialLoading = false;
          }
          this.loading = false;
        }.bind(this))
        .catch(function (error) {

        }.bind(this))
    },
    openForm(action, groupIndex = null) {
      this.formAction = action;
      if (action === 'update') {
        this.benefitGroupDetailIndex = groupIndex;
        this.fixedBenefitDetailId = this.fixedBenefitList.data[groupIndex].id;
        this.goToCreate();
      }

      if (action === 'create') {
        if (this.isAdmin) {
          this.openModal = true;
        } else {
          this.goToCreate();
        }
      }
    },
    goToCreate() {
      this.openModal = false;
      this.showForm = true;
    },
    clearForm(triggerRerenderList = false) {
      if (this.isAdmin) {
        this.selectedCompanyId = null;
        this.noClientSelected = false;
      }
      this.benefitGroupDetailIndex = null;
      this.fixedBenefitDetailId = null;
      this.formAction = null;
      this.showForm = false;
      this.loading = false;
      if (triggerRerenderList) {
        this._loadList();
      }
    },
    search() {
      this.filters_values.search = this.searchString;
      this._loadList();
    },
    pageChanged(page) {
      this.filters_values.page = page;
      this._loadList();
    },
    changePerPage(perPage) {
      this.filters_values.perPage = perPage;
      this._loadList();
    },
    filtersValues() {
      this._loadList();
    },
    triggerLoading(toggle, loaderError = false) {
      this.loading = toggle;
      this.loaderError = loaderError;
    },
    updateListByIndex(data) {
      this.fixedBenefitList.data[this.benefitGroupDetailIndex] = data;
    },
    closeModal() {
      this.openModal = false;
      this.selectedCompanyId = false;
      this.noClientSelected = false;
    },
    sortBy(fieldName) {
      if (this.sortField !== fieldName) {
        this.reverseSort = false;
      }
      this.filters_values.page = 1;
      this.filters_values.sort_by = fieldName;
      this.filters_values.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this._loadList()
      this.sortField = fieldName;
      this.reverseSort = !this.reverseSort;
    },
  }
}
</script>
