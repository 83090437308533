<template>
  <b-modal id="modal-1" title="BootstrapVue"
           ref="confirm-modal"
           :hide-header-close="true"
           header-class="border-0 text-center"
           :body-class="{'modal-body no-border background-white text-center':true,'p-0':(message.length===0)}"
           footer-class="modal-footer border-top-0"
           :no-close-on-esc="true"
           :no-close-on-backdrop="true"
           centered>
    <template v-slot:modal-header>
      <h3 v-if="action === 'form_submit'" :key="'confirm_modal_title'" class="mx-auto my-0">
        {{$t("flexben.partner.modal.confirm_save")}}
      </h3>
      <h3 v-else-if="action === 'delete'" :key="'confirm_modal_title'" class="mx-auto my-0">
        {{$t("flexben.partner.modal.confirm_delete")}}
      </h3>
      <h3 v-else-if="action === 'go_back'" :key="'confirm_modal_title'" class="mx-auto my-0">
        {{$t("flexben.partner.modal.confirm_leave")}}
      </h3>
      <h3 v-else-if="action === 'end'" :key="'confirm_modal_title'" class="mx-auto my-0">
        {{ $t('flexben.general.status_type.'+(formSuccess ? 'success' : 'failed')) }}
      </h3>
    </template>
    <template v-slot:default>
      <div class="text-center" :class="{'text-danger':!formSuccess}" v-html="message"></div>
    </template>
    <template v-slot:modal-footer>
      <button v-if="action === 'end'" type="button"
              @click="confirmAction()"
              class="btn save-btn float-right m-auto">
        {{$t("flexben.general.btn.continue")}}
      </button>
      <button v-if="action !== 'end'" type="button" @click="closeModal()"
              class="btn mr-auto text-themed">
        {{$t("flexben.general.btn.cancel")}}
      </button>
      <button v-if="action === 'go_back'" type="button"
              @click="$emit('confirmAction', 'go_back')"
              class="btn save-btn">
        {{$t("flexben.general.btn.confirm")}}
      </button>
      <button v-if="action === 'form_submit' || action === 'delete'" type="button"
              :disabled="(action === 'delete' && !canDelete)"
              @click="confirmAction()"
              class="btn save-btn">
        {{$t("flexben.general.btn.continue")}}
      </button>
    </template>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";

export default {
  name: "ConfirmModal",
  components: {
    'b-modal': BModal
  },
  props: {
    action: {
      type: String,
      required: true,
      default: 'ro'
    },
    formSuccess: {
      type: Boolean,
      required: true,
      default: true
    },
    message: {
      type: String,
      default: ''
    },
    canDelete: {
      type: Boolean,
      default: true,
      required: true
    },
  },
  methods: {
    confirmAction() {
      if (this.action === 'end' && !this.formSuccess) {
        this.closeModal();
      } else {
        this.$emit('confirmAction', this.action);
      }
    },
    openModal() {
      this.$refs['confirm-modal'].show();
    },
    closeModal() {
      this.$refs['confirm-modal'].hide();
    }
  },
}
</script>
