<template>
  <div class="mb-4">
    <div v-show="!showForm && !initialLoading">
      <div class="row">
        <div class="col-12 col-md-6 flexben-page-title">
          <h1>{{$t("flexben.benefit_groups.title")}}</h1>
        </div>
        <div class="col-12 col-md-6">
          <button @click="openForm('create')" class="btn btn-themed px-4 float-left float-md-right my-2">{{
            $t("flexben.benefit_groups.new") }}
          </button>
        </div>
      </div>
      <div class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-12 col-xl-3 mb-2 mb-xl-0">
            <img src="@/assets/images/filter.svg"/>
            <span class="ml-3">{{$t("flexben.general.filter")}}</span>
          </div>
          <div class="col-12 col-xl-9">
            <div class="row">
              <div class="col-12 col-md-4" v-if="isAdmin">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-xl-0" for="client">{{ $t("flexben.general.client") }}</label>
                  <select id="client" class="form-control w-100"
                          @change="filtersValues()"
                          v-model="filters_values.company">
                    <option :value="null">-</option>
                    <option v-for="(item, value) in filters.companies.values"
                            :key="value"
                            :value="value">{{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4"
                   :class="{'offset-md-4':!isAdmin}">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-xl-0"
                         for="status">{{$t("flexben.benefit_groups.fields.type")}}</label>
                  <select @change="filtersValues()" id="status" class="form-control w-100"
                          v-model="filters_values.type">
                    <option v-for="(type, index) in filters.types.values"
                            :key="index"
                            :value="type">{{ $t("flexben.benefit_groups.types." + type) }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-xl-0"
                         for="benefit_type">{{$t("flexben.benefit_groups.fields.benefit_type")}}</label>
                  <select @change="filtersValues()" id="benefit_type" class="form-control w-100"
                          v-model.number="filters_values.benefit_type">
                    <option v-for="(item) in filters.benefit_types.values"
                            :key="item"
                            :value="item">{{ $t('flexben.benefit_groups.benefit_types.'+item) }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-xl-0"
                         for="benefit">{{$t("flexben.benefit_groups.benefit_types.1")}}</label>
                  <select @change="filtersValues()" id="benefit" class="form-control w-100"
                          v-model="filters_values.benefit"
                          :disabled="filters_values.benefit_type!==1">
                    <option :value="null">-</option>
                    <option v-for="(item, value, index) in filters.benefits.values"
                            :key="index"
                            :value="value">{{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-xl-0"
                         for="fixed_benefit">{{$t("flexben.benefit_groups.benefit_types.2")}}</label>
                  <select @change="filtersValues()" id="fixed_benefit" class="form-control w-100"
                          v-model="filters_values.fixed_benefit"
                          :disabled="filters_values.benefit_type!==2">
                    <option :value="null">-</option>
                    <option v-for="(item, value, index) in filters.fixed_benefits.values"
                            :key="index"
                            :value="value">{{ item[currentLang] }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-6 text-bold">
            <h3 class="mt-0 pt-1 pl-0">{{ $t("flexben.benefit_groups.list") }}</h3>
          </div>
          <div class="col-6">
            <input type="text"
                   v-model="searchString"
                   v-on:keyup.enter="search()"
                   :placeholder="$t('flexben.general.search')"
                   class="formedituser search-input float-right"/>
          </div>

          <div class="col-12">
            <div class="table-responsive hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td v-if="isAdmin">
                    {{ $t("flexben.benefit_groups.fields.company") }}&nbsp;
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class=" (sortField === 'company' && reverseSort) ?'rotate-180':''"/>
                  </td>
                  <td @click="sortBy('name')" class="cursor-pointer">
                    {{ $t("flexben.benefit_groups.fields.group") }}&nbsp;
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class=" (sortField === 'name' && reverseSort) ?'rotate-180':''"/>
                  </td>
                  <td @click="sortBy('code')" class="cursor-pointer">
                    {{ $t("flexben.benefit_groups.fields.code") }}&nbsp;
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class=" (sortField === 'code' && reverseSort) ?'rotate-180':''"/>
                  </td>
                  <td>{{ $t("flexben.benefit_groups.fields.type") }}</td>
                  <td>{{ $t("flexben.benefit_groups.fields.benefit_type") }}</td>
                  <td>{{ $t("flexben.benefit_groups.fields.benefits") }}</td>
                  <td></td>
                </tr>
                </thead>


                <tbody v-if="benefitGroups.data.length >0">
                <tr v-for="(companyGroup, index) in benefitGroups.data" :key="index"
                    class="trhover">
                  <td v-if="isAdmin">{{ companyGroup.company_name }}</td>
                  <td> {{ companyGroup.name }}</td>
                  <td> {{ companyGroup.code }}</td>
                  <td> {{ companyGroup.is_default ? $t("flexben.benefit_groups.types.default") :
                    $t("flexben.benefit_groups.types.not_default") }}
                  </td>
                  <td> {{ $t("flexben.benefit_groups.benefit_types."+companyGroup.benefit_type) }}</td>
                  <td class="client-benefits">
                    <ul v-if="companyGroup.benefit_type===1" :key="'benefits_'+companyGroup.id">
                      <li v-for="(benefit, index) in companyGroup.benefits_slim" v-if="shouldShowBenefits(index, companyGroup.id)" :key="benefit.id">
                        <span :style="{backgroundColor: benefit.color}" class="color-bullet"></span>
                        {{ benefit.name }}
                      </li>
                    </ul>
                    <ul v-else :key="'benefits_'+companyGroup.id">
                      <li v-for="(benefit, index) in companyGroup.fixed_benefits" v-if="shouldShowBenefits(index, companyGroup.id)" :key="benefit.id">
                        <span class="color-bullet flx-bg-themed"></span>
                        {{ benefit.name[currentLang] }}
                      </li>
                    </ul>
                    <a v-if="shouldShowExpandButton(companyGroup)"
                      class="text-themed btn-link cursor-pointer" @click="toggleExpand(companyGroup.id)">
                      {{ expandedStates[companyGroup.id] ? $t("flexben.general.read_less") : $t("flexben.general.read_more") }}
                    </a>
                  </td>
                  <td class="action-group text-right">
                    <button type="button" class="btn action-button"
                            @click="openForm('update', index)">{{$t("flexben.general.btn.see_more")}}
                    </button>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td :colspan="isAdmin ? 6 : 5" class="text-center trhover gri-cell">
                    {{ $t("flexben.general.no_results_found") }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 p-0">
            <listPagination
              :activePage="benefitGroups.current_page"
              :currentPage="benefitGroups.current_page"
              :totalItems="benefitGroups.total"
              :startItem="benefitGroups.from"
              :endItem="benefitGroups.to"
              :itemsPerPage="benefitGroups.perPage"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage"></listPagination>
          </div>
        </div>
      </div>
    </div>
    <benefit-group-detail v-if="showForm"
                          :groupId="benefitGroupDetailId"
                          :companyId="selectedCompanyId"
                          :formAction="formAction"
                          @triggerLoading="triggerLoading"
                          @updateListByIndex="updateListByIndex"
                          @clearForm="clearForm"/>

    <select-client-modal v-if="openModal"
                         :companies="filters.companies.values"
                         :openModal="openModal"
                         :noClientSelected="noClientSelected"
                         @closeModal="closeModal"
                         @goToCreate="goToCreate"/>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import BenefitGroupDetail from "@/components/flexben/benefit_groups/BenefitGroupDetail";
import SelectClientModal from "@/components/fragments/SelectClientModal";
import i18n from "../../../plugins/language/i18n";

export default {
  name: "BenefitGroupList",
  components: {
    'benefit-group-detail': BenefitGroupDetail,
    'select-client-modal': SelectClientModal,
  },
  data() {
    return {
      numberOfBenefitsToShow: 5 ,
      expandedStates: {},
      initialLoading: true,
      loading: false,
      loaderError: false,
      isAdmin: false,

      httpService: httpFlexbenService.benefitGroup,

      benefitGroups: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      filters: {
        companies: {
          key: 'company',
          values: []
        },
        types: {
          key: 'type',
          values: []
        },
        benefits: {
          key: 'benefit',
          values: []
        },
        benefit_types: {
          key: 'benefit_types',
          values: []
        },
        fixed_benefits: {
          key: 'fixed_benefits',
          values: []
        },
      },
      params: {
        company: null
      },
      filters_values: {
        company: null,
        type: 'all',
        benefit: null,
        fixed_benefit: null,
        benefit_type: 'all',
        page: 1,
        perPage: 20,
        search: null,
        sort_by: 'name',
        sort_direction: 'asc',
      },
      searchString: '',
      sortField: 'name',
      sortDirection: 'asc',
      reverseSort: true,

      openModal: false,
      noClientSelected: false,
      selectedCompanyId: null,
      benefitGroupDetailIndex: null,
      benefitGroupDetailId: null,
      formAction: null,
      showForm: false,
      currentLang: i18n.locale,
    }
  },
  created() {
    let userRole = this.flxUserLevel().userLevel;
    const employment = this.$store.getters.flx_activeEmployment;
    if (userRole === 'client' && employment != null) {
      this.params.company = employment.company_id;
      this.filters_values.company = employment.company_id;
      this.selectedCompanyId = employment.company_id;
      this.isAdmin = false;
    } else if (userRole === 'admin') {
      if (typeof localStorage.getItem('flexbenCompanyId') !== "undefined" && localStorage.getItem('flexbenCompanyId') !== null) {
        this.selectedCompanyId = localStorage.getItem('flexbenCompanyId');
        this.filters_values.company = localStorage.getItem('flexbenCompanyId')
        localStorage.removeItem('flexbenCompanyId');
      }
      this.isAdmin = true;
    } else {
      window.location.href = '/';
      return;
    }

    this._loadList();
  },
  methods: {
    shouldShowBenefits(index, id) {
      return index < this.numberOfBenefitsToShow || this.expandedStates[id];
    },
    shouldShowExpandButton(companyGroup) {
      return (companyGroup.benefits_slim && companyGroup.benefits_slim.length > this.numberOfBenefitsToShow) ||
        (companyGroup.fixed_benefits && companyGroup.fixed_benefits.length > this.numberOfBenefitsToShow);
    },
    toggleExpand(groupId) {
      if (this.expandedStates[groupId] === undefined) {
        this.$set(this.expandedStates, groupId, false);
      }
      this.expandedStates[groupId] = !this.expandedStates[groupId];
    },
    _loadList() {
      this.loading = true;
      this.httpService.list(this.filters_values)
        .then(function (response) {
          this.benefitGroups.data = response.data.list.data;
          this.benefitGroups.current_page = response.data.list.current_page;
          this.benefitGroups.to = response.data.list.to;
          this.benefitGroups.perPage = response.data.list.perPage;
          this.benefitGroups.lastPage = response.data.list.lastPage;
          this.benefitGroups.total = response.data.list.total;

          if (this.initialLoading) {
            this.filters = response.data.filters;
            this.initialLoading = false;
          }
          if (!this.filters_values.company) {
            this.filters.fixed_benefits.values = [];
          } else {
            this.filters.fixed_benefits.values = response.data.filters.fixed_benefits.values;
          }
          this.loading = false;
        }.bind(this))
        .catch(function (error) {

        }.bind(this))
    },
    openForm(action, groupIndex = null) {
      this.formAction = action;
      if (action === 'update') {
        this.benefitGroupDetailIndex = groupIndex;
        this.benefitGroupDetailId = this.benefitGroups.data[groupIndex].id;
        this.goToCreate();
      }

      if (action === 'create') {
        if (this.isAdmin) {
          this.openModal = true;
        } else {
          this.goToCreate();
        }
      }
    },
    goToCreate() {
      this.openModal = false;
      this.showForm = true;
    },
    clearForm(triggerRerenderList = false) {
      if (this.isAdmin) {
        this.selectedCompanyId = null;
        this.noClientSelected = false;
      }
      this.benefitGroupDetailIndex = null;
      this.benefitGroupDetailId = null;
      this.formAction = null;
      this.showForm = false;
      this.loading = false;
      if (triggerRerenderList) {
        this._loadList();
      }
    },
    search() {
      this.filters_values.search = this.searchString;
      this._loadList();
    },
    pageChanged(page) {
      this.filters_values.page = page;
      this._loadList();
    },
    changePerPage(perPage) {
      this.filters_values.perPage = perPage;
      this._loadList();
    },
    filtersValues() {
      this._loadList();
    },
    triggerLoading(toggle, loaderError = false) {
      this.loading = toggle;
      this.loaderError = loaderError;
    },
    updateListByIndex(data) {
      this.benefitGroups.data[this.benefitGroupDetailIndex] = data;
    },
    closeModal() {
      this.openModal = false;
      this.selectedCompanyId = false;
      this.noClientSelected = false;
    },
    sortBy(fieldName) {
      if (this.sortField !== fieldName) {
        this.reverseSort = false;
      }
      this.filters_values.page = 1;
      this.filters_values.sort_by = fieldName;
      this.filters_values.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this._loadList()
      this.sortField = fieldName;
      this.reverseSort = !this.reverseSort;
    },
  },
  computed:{
    shouldShowBenefit() {
      return this.index < this.numberOfBenefitsToShow || this.expandedStates[this.companyGroup.id];
    }
  }
}
</script>
