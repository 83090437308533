<template>
  <section class="container-bg-white mt-3">
    <div class="row p-3">
      <div class="col-12">
        <h3 class="m-0 p-0 text-capitalize">{{ $t('flexben.general.beneficiary') }}</h3>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label" for="first_name">{{ $t('flexben.import.table_headings.first_name') }}</label>
        <div class="form-control w-100 m-0 bg-light">
          {{ employee.first_name }}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label" for="last_name">{{ $t('flexben.import.table_headings.last_name') }}</label>
        <div class="form-control w-100 m-0 bg-light">
          {{ employee.last_name }}
        </div>
      </div>
      <div  class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label" for="phone_number">{{ $t('flexben.import.table_headings.phone_number') }}</label>
        <input
          v-model="formData.phone_number"
          :class="{'border-danger':error_code_phone_nr}"
          id="phone_number" type="number" class="form-control mr-1 bg-white">

      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label" for="cnp">{{ $t('flexben.import.table_headings.cnp') }}</label>
        <input
          v-model="formData.cnp"
          :class="{'border-danger':error_cnp_code}"
          id="cnp" type="number" min="13" max="13" class="form-control mr-1 bg-white">
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label" for="invitation_email">{{ $t('flexben.import.table_headings.invitation_email') }}</label>
        <input
          v-model="formData.email"
          id="invitation_email" min="5" max="30" class="form-control mr-1 bg-white">
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0 ">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.total_budget') }}</label>
        <div class="form-control w-100 m-0 bg-light">
          {{ employee.total_budget }}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.expiration_date') }}</label>
        <div class="form-control w-100 m-0 bg-light">
          {{ employee.budget_expiration_date }}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.underage_children_nr') }}</label>
        <div class="form-control w-100 m-0 bg-light">
          {{ employee.underage_children_nr }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "OrderRequestDetails",
  props: {
    employee: {
      required: true,
      type: Object
    },
    error_code: {
      required: true
    },
    formData: {
      required: true
    },
  },
  data: function () {
    return {
      error_code_phone_nr: false,
      error_address_code: false,
      error_cnp_code: false,

    };
  },
  created() {
    if (this.error_code === 'card_error_not_ivr_mobile') {
      this.error_code_phone_nr = true;
      this.$emit('getError', 'phone_error')
    }
    if (this.error_code === 'card_error_mandatory' || (this.error_code === 'card_error_length') || (this.error_code === 'card_error_duplicate')
      || (this.error_code === 'card_error_invalid') || (this.error_code === 'card_error_load_value')) {
      this.error_cnp_code = true;
      this.$emit('getError', 'cnp_error')
    }
  },
}
</script>
