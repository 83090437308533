<template>
  <div>
    <div v-show="!initialLoading && !showForm">
      <div class="row">
        <div class="col-12 col-md-4 flexben-page-title">
          <h1>{{ $t("flexben.categories.title") }}</h1>
          <p>{{ $t("flexben.categories.description") }}</p>
        </div>
        <div class="col-md-4 col-md-4">
          <label for="select_type" class="col-form-label pt-0">{{ $t("flexben.partner.filters.title_type") }}</label>
          <select class="form-control" v-model="filters_values.type" id="select_type" @change=_loadList>
            <option :value="null">-</option>
            <option v-for="(type, key) in categoryTypes" :key="key" :value="type">

              {{$t("flexben.categories.filters." + type)}}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-4">
          <button @click="openForm('create')" class="btn btn-themed px-4 float-left float-md-right my-2">
            {{ $t("flexben.categories.new") }}
          </button>
        </div>
      </div>
      <div class="container-bg-white">
        <div class="row p-3">
          <div class="col-md-6">
            <h3 class="mt-0">{{ $t("flexben.general.categories") }}</h3>
          </div>
          <div class="col-md-6">
            <input type="text"
                   v-model="searchString"
                   v-on:keyup.enter="search()"
                   :placeholder="$t('flexben.general.search')"
                   class="formedituser search-input float-right"/>
          </div>
          <div class="col-12">
            <div class="table-responsive scaled-table hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td>{{ $t("flexben.general.category") }}</td>
                  <td>{{ $t("flexben.categories.table.benefits") }}</td>
                  <td>{{ $t("flexben.categories.table.description") }}</td>
                  <td></td>
                </tr>
                </thead>
                <tbody>
                <template v-if="categories.data.length > 0">
                  <tr class="trhover parent" v-for="(item,index) in categories.data" :key="item.id">
                    <td class="d-flex">
                        <img class="mr-2" :src="item.image_url" width="50"/>
                        <span class="my-auto">{{ item.name[locale] }}</span>
                    </td>
                    <td class="align-middle">
                      <template v-if="item.benefits.length > 0">
                        <span class="mr-2" v-for="(benefit, key) in item.benefits" :key="benefit.id">
                          <span class="text-ellipsis-15">
                            <span class="color-bullet mr-1" :id="'bullet-' + key"
                                  :style="'background-color: ' + benefit.color"></span>
                            {{ benefit.name }}
                          </span>
                        </span>
                      </template>
                      <template v-else>-</template>
                    </td>
                    <td class="align-middle">
                      <span>{{ item.description[locale] }}</span>
                    </td>
                    <td class="align-middle text-right">
                      <button class="btn action-button" @click="openForm('update',index)">
                        {{$t('flexben.general.btn.see_more')}}
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr class="trhover">
                    <td colspan="4" class="text-center"> {{ $t("flexben.general.no_results_found") }}</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 p-0">
            <listPagination
              :activePage="categories.current_page"
              :currentPage="categories.current_page"
              :totalItems="categories.total"
              :startItem="categories.from"
              :endItem="categories.to"
              :itemsPerPage="categories.perPage"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage"></listPagination>
          </div>
        </div>
      </div>
    </div>
    <BenefitCategoryForm v-if="showForm" :formAction="formAction" :itemId="itemId"
                         @clearForm="clearForm"></BenefitCategoryForm>
    <content-loader :loaderVisible="loading" :loader-error="loaderError"></content-loader>
  </div>


</template>

<script>
import httpFlexbenService from "@/http/http-flexben.js";
import i18n from "@/plugins/language/i18n";
import BenefitCategoryForm from "@/components/flexben/categories/BenefitCategoryForm";

export default {
  components: {
    BenefitCategoryForm
  },
  data() {
    return {
      initialLoading: false,
      loading: false,
      loaderError: false,
      httpService: httpFlexbenService.categoriesService,
      locale: i18n.locale,

      itemIndex: null,
      itemId: null,
      formAction: '',
      showForm: false,
      searchString: '',
      categoryTypes: [],
      filters_values: {
        page: 1,
        perPage: 20,
        search: null,
        type: null
      },
      categories: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
    }
  },
  created() {
    let permissions = this.flxUserLevel();
    if (permissions.hasAccess && permissions.userLevel === 'admin') {
      this._loadList();

    } else {
      window.location.href = '/company';
    }
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  methods: {
    _loadList() {
      this.loading = true;
      this.httpService.list(this.filters_values)
        .then(function (response) {
          this.categories = response.data.categories;
          this.categoryTypes = response.data.options.type;

          if (this.initialLoading) {
            this.initialLoading = false;
          }
          this.loading = false;
        }.bind(this))
        .catch(function (error) {
          this.loaderError = true;
        }.bind(this))
    },
    openForm(action, index = null) {
      this.formAction = action;
      if (action === 'update') {
        this.itemIndex = index;
        this.itemId = this.categories.data[index].id;
        this.showForm = true;
      }

      if (action === 'create') {
        this.itemId = null;
        this.showForm = true;
      }
    },
    filtersValues() {
      this._loadList();
    },
    search() {
      this.filters_values.search = this.searchString;
      this._loadList();
    },
    pageChanged(page) {
      this.filters_values.page = page;
      this._loadList();
    },
    changePerPage(perPage) {
      this.filters_values.perPage = perPage;
      this._loadList();
    },
    resetFilters() {
      let perPage = this.filters_values.perPage;
      this.filters_values = {
        county: null,
        type: null,
        status: null,
        category: null,
        code_type: null,
        page: 1,
        perPage: perPage,
        search: null,
      };
    },
    clearForm(reRender = false) {
      this.showForm = false;
      this.detailIndex = null;
      this.itemId = null;
      this.resetFilters();
      this._loadList();
    }
  },
};
</script>
