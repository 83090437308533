<template>
  <div class="row p-3">
    <div class="col-12 col-md-6">
      <h3 class="pl-0 mt-0 mb-0 pb-1">{{ $t("flexben.requests.summary.title") }}</h3>
    </div>
    <div class="col-12 mt-3">
      <div class="container-fluid" v-for="(companyData, companyId, cIndex) in summaryList"
           :key="cIndex">
        <div v-for="(benefitData, benefitId, bIndex) in companyData.benefits" :key="bIndex"
             class="row py-2 py-md-3" :class="{'gri-cell':!(bIndex%2)}">
          <div class="col-12 col-md-4 text-bold">
            <span :style="{backgroundColor: benefitData.color}" class="color-bullet mr-1"></span> {{ benefitData.name }}
            <span v-if="benefitIdsWithProblems.includes(parseInt(benefitId))" class="text-danger ml-2">{{ $t("flexben.requests.summary.error_placing") }}</span>
          </div>
          <div class="col-12 col-md-4" :class="{'offset-md-4':!showTotalValue}">
            <span class="text-bold">{{ $t("flexben.requests.summary.count_total_request") }}:</span>
            <span class="float-right">{{ Object.keys(benefitData.values).length }}</span>
          </div>
          <div class="col-12 col-md-4" v-if="showTotalValue">
            <span class="text-bold">{{ $t("flexben.requests.summary.total_values") }}:</span>
            <span class="float-right">{{ objSum(benefitData.values) }} Lei</span>
          </div>
        </div>
      </div>
      <div v-if="!checkNotEmptyObj(summaryList)" class="text-center">
        {{ $t("flexben.general.no_results_found") }}
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="row">
        <div v-if="showCompanyAddresses" class="col-12 col-md-4 form-group mb-0">
          <button class="btn text-green pl-0" @click="setDeliveryAddresses()"
                  :disabled="checkedIds.length===0 || deliveryAddress===null">
            <font-awesome-icon icon="home" class="mr-1"/>
            {{ $t("flexben.requests.btn.set_delivery_address_to") }}
          </button>
          <select name="delivery_address" id="delivery_address" class="form-control"
                  v-model="deliveryAddress" :disabled="(checkedIds.length===0)">
            <option :value="null" disabled>-</option>
            <option v-for="(companyAddress, id) in companyAddresses" :value="id"
                    :key="id">{{id}} - {{ companyAddress }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-4" :class="{'text-md-center':showCompanyAddresses}">
          <button class="btn text-danger px-0 px-md-2" @click="openRejectModal()"
                  :disabled="checkedIds.length===0">
            <font-awesome-icon icon="trash" class="mr-1"/>
            {{ $t("flexben.requests.action.refuse_requests") }}
          </button>
        </div>
        <div class="col-12 col-md-4 ml-auto mt-1 mt-md-0">
          <button class="btn btn-themed px-4 float-md-right" @click="confirmPlaceOrder()"
                  :disabled="checkedIds.length===0">
            {{ $t("flexben.requests.btn.place_order") }}
          </button>
        </div>
      </div>
    </div>

    <RejectModal :rejectData="rejectData"
                 :showDisableTopUps="showDisableTopUps"
                 @confirmAction="rejectRequests"
                 ref="reject-modal"/>
    <ConfirmMassPlaceOrderModal ref="confirm_po_modal"
                                :isAdmin="isAdmin"
                                :showNonLegislatedMessage="showNonLegislatedMessage"
                                @placeOrder="placeOrder"/>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import RejectModal from "@/components/flexben/requests/modals/RejectModal";
import ConfirmMassPlaceOrderModal from "@/components/flexben/requests/modals/ConfirmMassPlaceOrderModal";

export default {
  name: "RequestsSummary",
  components: {
    ConfirmMassPlaceOrderModal,
    RejectModal
  },
  props: {
    summaryList: {
      type: Object,
      required: true,
    },
    checkedIds: {
      type: Array,
      required: true,
      default: function () {
        return [];
      }
    },
    benefitIdsWithProblems: {
      type: Array,
      required: false,
      default: function () {
        return [];
      }
    },
    isAdmin: {
      type: Boolean,
      required: true,
      default: true
    },
    showPlaceOrderBtn: {
      type: Boolean,
      default: false
    },
    showCompanyAddresses: {
      type: Boolean,
      default: false
    },
    showTotalValue: {
      type: Boolean,
      default: false
    },
    companyAddresses: {
      type: [Object, Array],
      default: function () {
        return [];
      },
      required: false
    },
    showDisableTopUps: {
      type: Boolean,
      default: false
    },
    showNonLegislatedMessage: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    rejectData() {
      return {
        ids: this.checkedIds,
        reject_reason: ''
      }
    }
  },
  data: function () {
    return {
      loading: false,
      loaderError: false,
      httpService: httpFlexbenService.requests,
      reject_reason: '',
      deliveryAddress: null
    };
  },
  methods: {
    objSum(values) {
      let total = 0;
      for (let index in values) {
        total += values[index];
      }
      return total;
    },
    openRejectModal() {
      if (this.checkedIds.length !== 0) {
        this.$refs['reject-modal'].openModal();
      }
    },
    rejectRequests(toggleDisableAutoTopUp) {
      if (this.checkedIds.length === 0) {
        return;
      }

      this.loading = true;
      this.$refs['reject-modal'].closeModal();
      let payload = {...this.rejectData};
      payload.toggle_disable_auto_top_up = toggleDisableAutoTopUp;
      this.httpService.rejectRequests(payload)
        .then(function (response) {
          this.loading = false;
          this.reRenderParentList();
        }.bind(this))
        .catch(function (error) {
          if (500 <= error.response.status && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false;
          }
        }.bind(this));
    },
    reRenderParentList() {
      this.$emit('reRenderList')
    },
    confirmPlaceOrder() {
      this.$refs['confirm_po_modal'].openModal('order');
    },
    placeOrder() {
      this.$refs['confirm_po_modal'].closeModal();
      this.$emit('placeOrder');
    },
    setDeliveryAddresses() {
      if (this.deliveryAddress !== null) {
        this.$emit('setDeliveryAddresses', this.deliveryAddress)
      }
      this.deliveryAddress = null;
    },
  }
}
</script>
