<template>
  <div class="">
    <div v-if="!this.initialLoading" v-show="componentAction === 'listing'">
      <div class="row mb-1 px-3">
        <div class="col-12 col-md-6 pl-sm-0 flexben-page-title">
          <h1>{{ $tc("flexben.voucher_stocks.titles.list") }}</h1>
        </div>
        <div class="col-12 col-md-6 pr-0">
          <div class="d-flex flex-column flex-md-row">
            <button class="btn btn-themed px-4 ml-md-auto mt-2"
                    @click="startImport()">
              {{$t("flexben.voucher_stocks.btns.import_start")}}
            </button>

          </div>
        </div>
      </div>
      <filters :filter-options="filterOptions" :filters="filters">
        <template v-slot:summary_bottom>
          <hr class="mt-0">
          <div class="row px-3 pb-3">
            <div class="col-12 col-md-6 col-lg-2"><b>{{ $t('flexben.voucher_stocks.summary.in_stock') }}:</b> {{
              summary.in_stock }}
            </div>
            <div class="col-12 col-md-6 col-lg-2"><b>{{ $t('flexben.voucher_stocks.summary.will_expiring') }}:</b> {{
              summary.will_expiring }}
            </div>
            <div class="col-12 col-md-6 col-lg-2"><b>{{ $t('flexben.voucher_stocks.summary.reserved') }}:</b> {{
              summary.reserved }}
            </div>
            <div class="col-12 col-md-6 col-lg-2"><b>{{ $t('flexben.voucher_stocks.summary.used') }}:</b> {{
              summary.used
              }}
            </div>
            <div class="col-12 col-md-6 col-lg-2"><b>{{ $t('flexben.voucher_stocks.summary.expired') }}:</b> {{
              summary.expired }}
            </div>
            <div class="col-12 col-md-6 col-lg-2">
              <a class="btn btn-themed px-4 ml-md-auto mt-2 mt-md-0" :class="{'disabled':vouchers.data.length===0}"
                 :href="exportUrl"
                 target="_blank"
                 download
                 @click="(event)=>{if(vouchers.data.length===0) {event.preventDefault();}}">
                {{$t("flexben.general.btn.export")}}
              </a>
            </div>
          </div>
        </template>
      </filters>
      <div class="container-bg-white mt-3 p-3">
        <div class="row">
          <div class="col-12 col-md-6">
            <h2 class="pl-0 mt-0">{{ $t("flexben.voucher_stocks.titles.list") }}</h2>
          </div>
          <div class="col-12 col-md-6">
            <input type="text"
                   v-model.trim="searchString"
                   v-on:keyup.enter="search()"
                   :placeholder="$t('flexben.general.search')"
                   class="formedituser search-input float-right"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-responsive hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td>{{ $t("flexben.voucher_stocks.fields.benefit") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.fields.partner") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.fields.status") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.fields.code") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.fields.start_date") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.fields.end_date") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.fields.created_at") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.fields.placed_at") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.fields.pin_code") }}</td>
                  <td></td>
                </tr>
                </thead>
                <tbody>
                <template v-if="vouchers.data.length>0">
                  <tr v-for="(item,index) of vouchers.data" :key="item.id"
                      class="trhover parent">
                    <td>{{ item.benefit.name }}</td>
                    <td>{{ item.partner.name }}</td>
                    <td>
                      <span class="flx-type-badge"
                            :class="{
                        'bg-success' : (item.status==1),
                        'bg-primary' : (item.status==2),
                        'bg-danger' : (item.status==4 || item.status==5)
                      }">{{ $t('flexben.voucher_stocks.statuses.'+item.status) }}</span>
                    </td>
                    <td>{{ item.code }}</td>
                    <td>{{ (item.start_date != null) ? moment(item.start_date, 'DD.MM.YYYY') :'-' }}</td>
                    <td class="text-bold">{{ moment(item.end_date, 'DD.MM.YYYY') }}</td>
                    <td class="text-bold">{{ moment(item.created_at, 'DD.MM.YYYY') }}</td>
                    <td class="text-bold">{{ (item.order_request != null) ? moment(item.order_request.placed_at, 'DD.MM.YYYY') : '-' }}</td>
                    <td>{{ item.pin_code }}</td>
                    <td>
                      <button class="btn action-button" @click="showDetails(item.id)">
                        {{$t("flexben.general.btn.see_more")}}
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr class="trhover gri-cell">
                    <td colspan="100" class="text-center">{{ $t("flexben.general.no_results_found") }}
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-12 p-0">
            <listPagination
              :activePage="vouchers.current_page"
              :currentPage="vouchers.current_page"
              :totalItems="vouchers.total"
              :startItem="vouchers.from"
              :endItem="vouchers.to"
              :itemsPerPage="vouchers.perPage"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage"></listPagination>
          </div>
        </div>
      </div>
    </div>
    <import-benefit-vouchers-steps v-if=" componentAction==='import'"
                                   @backToListing="backToListing"
                                   @toggleLoading="toggleLoading"
    ></import-benefit-vouchers-steps>
    <benefit-voucher-details-modal ref="benefit_voucher_details_modal"
                                   :voucherDetails="voucherDetails"
                                   :action="'details'"
                                   :availableOptions="filterOptions"
                                   @confirmAction="updateVoucher"
                                   @deleteAction="deleteVoucher"
    ></benefit-voucher-details-modal>
    <content-loader :loader-visible="loading" :loader-error="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben.js";
import Filters from "./partials/Filters";
import ImportBenefitVouchersSteps from "./ImportBenefitVouchersSteps";
import BenefitVoucherDetailsModal from "./modals/BenefitVoucherDetailsModal";

export default {
  name: "BenefitVoucherList",
  components: {
    'filters': Filters,
    'import-benefit-vouchers-steps': ImportBenefitVouchersSteps,
    'benefit-voucher-details-modal': BenefitVoucherDetailsModal,
  },
  data: () => {
    return {
      initialLoading: true,
      loading: true,
      loaderError: false,
      httpService: httpFlexbenService.benefitVoucherStocks,
      filterOptions: {
        benefits: [],
        partners: [],
        statuses: [],
        startCreatedAt: null,
        endCreatedAt: null,
        startPlacedAt: null,
        endPlacedAt: null,
      },
      filters: {
        benefit: null,
        partner: null,
        status: 0,
        search: null,
        startCreatedAt: null,
        endCreatedAt: null,
        startPlacedAt: null,
        endPlacedAt: null,
        perPage: 20,
        page: 1
      },
      summary: {
        in_stock: 0,
        will_expiring: 0,
        reserved: 0,
        used: 0,
        expired: 0
      },
      searchString: '',
      filterWithExtra: true,
      vouchers: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      componentAction: 'listing',
      voucherDetails: {
        id: null
      },
      exportUrl: ''
    };
  },
  watch: {
    filters: {
      handler: function (val) {
        this._loadList();
      },
      deep: true
    }
  },
  created() {
    const permissions = this.flxUserLevel();
    if (!permissions.hasAccess || permissions.userLevel !== 'admin') {
      window.location.href = '/company';
      return;
    }
    this._loadList();
  },
  methods: {
    search() {
      this.filters.search = this.searchString;
      this._loadList(true);
    },
    changePerPage(perPage) {
      this.filters.page = 1;
      this.filters.perPage = perPage;
      this._loadList();
    },
    pageChanged(pageNb) {
      this.filters.page = pageNb;
      this._loadList();
    },
    _loadList(isSearch = false) {
      let filters;
      if (isSearch) {
        filters = {
          search: this.searchString
        };
      } else {
        filters = {...this.filters};
      }
      this.loading = true;
      filters.filterWithExtra = this.filterWithExtra ? 1 : 0;
      this.httpService.list(this.filters)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          this.vouchers = data.list;
          if (this.filterWithExtra) {
            this.filterOptions = data.filters;
            this.summary = data.summary;
            this.filterWithExtra = false;
          }
          if (this.initialLoading) {
            this.initialLoading = false;
          }
          this.loading = false;

          this._buildExportUrl();
        })
        .catch((error) => {
          this.loaderError = true;
          this.loading = true;
        });
    },
    showDetails(id) {
      this.loading = true;
      this.httpService.details(id)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          this.voucherDetails = data;
          this.voucherDetails.invalid_reasons = null;
          this.loading = false;
          this.$refs['benefit_voucher_details_modal'].openModal(this.voucherDetails, 'update');
        })
        .catch((error) => {
          this.loaderError = true;
          this.loading = true;
        });
    },
    updateVoucher(data) {
      this.loading = true;
      let payload = {...data};
      payload.start_date = (payload.start_date != null) ? this.moment(payload.start_date, 'YYYY-MM-DD') : null;
      payload.end_date = (payload.end_date != null) ? this.moment(payload.end_date, 'YYYY-MM-DD') : null;
      this.httpService.update(data.id, payload)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          if (data.status === 'success') {
            this._loadList();
          } else {
            this.voucherDetails.invalid_reasons = data.invalid_reasons;
            this.$refs['benefit_voucher_details_modal'].openModal(this.voucherDetails, 'update');
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loaderError = true;
          this.loading = true;
        });
    },
    deleteVoucher(id) {
      this.loading = true;
      this.httpService.delete(id)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          if (data.status === 'success') {
            this._loadList();
          } else {
            this.voucherDetails.invalid_reasons = data.invalid_reasons;
            this.$refs['benefit_voucher_details_modal'].openModal(this.voucherDetails, 'update');
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loaderError = true;
          this.loading = true;
        });
    },
    startImport() {
      this.componentAction = 'import';
    },

    backToListing(withRefresh = false) {
      if (this.componentAction === 'details') {
        this.voucherDetails = {
          id: null
        };
      }

      if (this.componentAction === 'import') {
        this.voucherDetails = {
          id: null
        };
      }

      this.componentAction = 'listing';
      if (withRefresh) {
        this.filterWithExtra = false;
        this._loadList();
      }
    },
    toggleLoading(loading, loaderError = false) {
      this.loading = loading;
      this.loaderError = loaderError;
    },
    _buildExportUrl() {
      let url = process.env.VUE_APP_FLEXBEN_BASE + 'benefit-vouchers/export?';
      let params = {
        benefit: this.filters.benefit,
        partner: this.filters.partner,
        status: this.filters.status
      };

      let esc = encodeURIComponent;
      let query = Object.keys(params)
        .filter(k => (params[k] != null))
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');
      this.exportUrl = url + query;
    },
    getInvalidReasons(invalidReasons) {
      let errorList = [];
      let indexKey, errorField;
      for (errorField in invalidReasons) {
        if (typeof invalidReasons[errorField] === 'string') {
          errorList.push(this.$t('flexben.formFieldError', {
            field: this.$t('flexben.voucher_stocks.fields.' + errorField),
            reason: this.$t('flexben.formErrorReasons.' + invalidReasons[errorField])
          }))
        } else {
          for (indexKey in invalidReasons[errorField]) {
            errorList.push(this.$t('flexben.formFieldError', {
              field: this.$t('flexben.voucher_stocks.fields.' + errorField),
              reason: this.$t('flexben.formErrorReasons.' + invalidReasons[errorField][indexKey])
            }))
          }
        }
      }
      return errorList.join('<br>');
    },
  }
}
</script>
