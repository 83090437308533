<template>
  <div class="">
    <div>
      <a class="btn text-themed go-back" @click="backToListing()">{{ $t("flexben.general.go_back") }}</a>
    </div>

    <ImportStep1 v-if="step===1"
                 :importFileName="importFileName"
                 :importInvalidReason="importInvalidReason"
                 @uploadFile="uploadFile"/>
    <ImportStep2 v-if="step===2"
                 :import-id="importId"
                 ref="step_2"
                 @toggleLoading="toggleLoading"
                 @previousStep="previousStep()"
                 @setInvalidCount="setInvalidCount()"/>
    <ImportStep3 v-if="step===3"
                 :summary="summary"
                 @newImport="startImport"
                 @backToListing="backToListing"/>
    <div v-if="step===1 || step===2" class="row mt-3">
      <div class="col-12 text-center">
        <button v-if="step===2" class="btn btn-themed mr-5" @click="previousStep()">
          {{ $t('flexben.general.btn.previous') }}
        </button>
        <button class="btn btn-themed"
                :disabled="(step===1 && importFile==null) || (step===2 && invalidCount!==0)"
                @click="nextStep()">{{ $t('flexben.general.btn.next') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben.js";
import ImportStep1 from "./partials/ImportStep1";
import ImportStep3 from "./partials/ImportStep3";
import ImportStep2 from "./partials/ImportStep2";

export default {
  name: "ImportBenefitVouchersSteps",
  components: {
    ImportStep1,
    ImportStep2,
    ImportStep3,
  },
  data() {
    return {
      httpService: httpFlexbenService.benefitVoucherStocks,
      step: 1,
      summary: {
        total: 0,
        ok_count: 0,
        error_count: 0
      },
      importFile: null,
      importFileName: '',
      importId: null,
      importInvalidReason: null,
      invalidCount: 0
    }
  },
  created() {
    this._checkExistingImport()
  },
  methods: {
    startImport() {
      this.step = 1;
    },
    backToListing(withRefresh = false) {
      this.$emit('backToListing', withRefresh);
    },
    toggleLoading(loading, loaderError = false) {
      this.$emit('toggleLoading', loading, loaderError);
    },
    nextStep() {
      switch (this.step) {
        case 1:
          this._createImport();
          break;
        case 2:
          this._finishImport();
          break;
        case 3:
          this.backToListing(true);
          break;
        default:
          this.backToListing(true);
      }
    },
    previousStep() {
      if (this.step === 2) {
        this._deleteImport();
      }
      if (this.step === 3) {
        this.step = 1;
      }
    },
    uploadFile(event) {
      this.invalidImportReason = null;
      this.importFile = event.target.files[0];
      this.importFileName = this.importFile.name;
    },
    _checkExistingImport() {
      this.toggleLoading(true);
      this.httpService.checkExistingImport()
        .then((response) => {
          if (response.data.status === 'exising') {
            this.importId = response.data.import.id;
            this.step = 2;
          } else {
            this.importId = null;
            this.step = 1;
          }
          this.toggleLoading(false);
        })
        .catch((exception) => {
          this.toggleLoading(true, true);
        });
    },
    _createImport() {
      this.toggleLoading(true);
      let payload = new FormData();
      this.importInvalidReason = null;
      payload.append('import_file', this.importFile);
      this.httpService.createImport(payload)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          if (data.import.status_code === 'failed') {
            this._setImportFailMessage(data.import.data.invalid_reason);
          } else {
            this.importId = data.import.id;
            this._clearImportFile();
            this.step = 2;
          }
          this.toggleLoading(false);
        })
        .catch((exception) => {
          this.toggleLoading(true, true);
        });
    },
    _finishImport() {
      this.toggleLoading(true);
      this.$refs['step_2'].setGeneralErrorMessage(null);
      this.httpService.finishImport(this.importId)
        .then((response) => {
          if (response.data.status === 'success') {
            this.step = 3;
            this.summary = response.data.summary;
          } else if (response.data.status === 'has_invalid_items') {
            this.$refs['step_2'].getImportDetails();
            this.$refs['step_2'].setGeneralErrorMessage(this.$t('flexben.voucher_stocks.import.messages.has_invalid_items'));
          }

          this.toggleLoading(false);
        })
        .catch((exception) => {
          this.toggleLoading(true, true);
        })
    },
    _deleteImport() {
      this.toggleLoading(true);
      this.httpService.deleteImport(this.importId)
        .then((response) => {
          this.toggleLoading(false);
          this.step = 1;
          this.importId = null;
        })
        .catch((exception) => {
          this.toggleLoading(true, true);
        })
    },
    _clearImportFile() {
      this.importFile = null;
      this.importFileName = '';
      this.importInvalidReason = null;
    },
    _setImportFailMessage(invalidReason) {
      this.importInvalidReason = this.$t('flexben.voucher_stocks.import.invalid_reasons.' + invalidReason);
    },
    setInvalidCount(invalidCount = 0) {
      this.invalidCount = invalidCount;
    }
  }
}
</script>
