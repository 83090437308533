<template>
  <div v-show="!initialLoading" class="col-12">
    <div class="container-bg-white">
      <div class="row p-3" id="filterGroup">
        <div class="col-12 col-xl-3 mb-2 mb-xl-0">
          <img src="@/assets/images/filter.svg"/>
          <span class="ml-3">{{$t("flexben.general.filter")}}</span>
        </div>
        <div class="col-12 col-xl-9">
          <div class="row">
            <div class="col-12 col-md-4 form-group mb-0">
              <label class="col-form-label" for="start_date">{{$t("flexben.reports.inputLabels.startDate")}}</label>
              <div class="w-100 d-inline-flex">
                <label for="start_date" class="my-auto mr-2 col-form-label">
                  <font-awesome-icon icon="calendar-alt" class="text-24"/>
                </label>
                <vue-datepicker :format="dateFormat"
                                :inputClass="'form-control-d m-0 w-100 background-white'"
                                v-model="startDate"
                                valueType="format"
                                :class="'w-100'"
                                key="start_date"
                                id="start_date">
                </vue-datepicker>
                <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                     @click="function (){startDate=null;}"/>
              </div>
            </div>
            <div class="col-12 col-md-4 form-group mb-0">
              <label class="col-form-label" for="end_date">{{$t("flexben.reports.inputLabels.endDate")}}</label>
              <div class="w-100 d-inline-flex">
                <label for="end_date" class="my-auto mr-2 col-form-label">
                  <font-awesome-icon icon="calendar-alt" class="text-24"/>
                </label>
                <vue-datepicker :format="dateFormat"
                                :inputClass="'form-control-d m-0 w-100 background-white'"
                                v-model="endDate"
                                valueType="format"
                                :class="'w-100'"
                                key="end_date"
                                id="end_date">
                </vue-datepicker>
                <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                     @click="function (){endDate=null;}"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-bg-white mt-3">
      <div class="row p-3">
        <div class="col-12 mt-2">
          <div class="table-responsive scaled-table hover-table">
            <table class="table table-striped">
              <thead>
              <tr>
                <td>{{ $t('flexben.reports.points_pago.fields.index') }}</td>
                <td>{{ $t('flexben.reports.points_pago.fields.first_name') }}</td>
                <td>{{ $t('flexben.reports.points_pago.fields.last_name') }}</td>
                <td>{{ $t('flexben.reports.points_pago.fields.email') }}</td>
                <td>{{ $t('flexben.reports.points_pago.fields.cnp') }}</td>
                <td>{{ $t('flexben.reports.points_pago.fields.order_request_id') }}</td>
                <td>{{ $t('flexben.reports.points_pago.fields.request_value_total') }}</td>
                <td>{{ $t('flexben.reports.points_pago.fields.request_value_pago') }}</td>
                <td>{{ $t('flexben.reports.points_pago.fields.processing_tax') }}</td>
                <td>{{ $t('flexben.reports.points_pago.fields.conversion_value') }}</td>
                <td>{{ $t('flexben.reports.points_pago.fields.points') }}</td>
                <td>{{ $t('flexben.reports.points_pago.fields.placed_at') }}</td>
              </tr>
              </thead>
              <tbody>
              <tr class="trhover" v-for="(item, key) in reportItems" :key="key">
                <td>{{ item.index }}</td>
                <td>{{ item.first_name }}</td>
                <td>{{ item.last_name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.cnp }}</td>
                <td>{{ item.order_request_id }}</td>
                <td>{{ item.request_value_total }}</td>
                <td>{{ item.request_value_pago }}</td>
                <td>{{ item.platform_tax }}</td>
                <td>{{ item.budget_to_point_ratio }}</td>
                <td>{{ item.points }}</td>
                <td>{{ moment(item.placed_at, 'DD.MM.YYYY') }}</td>
              </tr>
              <tr v-if="reportItems.length===0" class="trhover gri-cell">
                <td colspan="10000" class="text-center ">
                  {{ $t("flexben.general.no_results_found") }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import utils from "@/plugins/utils";

export default {
  name: "PointsPagoReport",
  components: {
    'vue-datepicker': Datepicker,
  },
  data: function () {
    return {
      initialLoading: false,
      httpService: httpFlexbenService.reports.points_pago,
      currentYear: new Date().getFullYear(),
      filter: {
        month: null,
        year: null,
        is_local_price: false,
      },
      reportItems: [],
      errors: {
        show: false,
        message: null,
      },
      dateFormat: 'dd/MM/yyyy',
      momentFormat: 'YYYY-MM-DD',
      startDate: null,
      endDate: null,
    }
  },
  created() {
    this.initialLoading = false;
  },
  methods: {
    filterValues() {
      this._validateFilters();
      if (this.errors.show) {
        return;
      }

      this._toggleLoading(true);
      this.httpService.list(this._buildPayload())
        .then((response) => {
          this.reportItems = response.data;
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(true, true);
        })
    },
    download() {
      this._validateFilters();
      if (this.errors.show) {
        return;
      }

      this._toggleLoading(true);
      this.httpService.download(this._buildPayload())
        .then((response) => {
          utils.generateDownloadFromReponse(response);
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(true, true);
        })
    },
    _toggleLoading(loading, loaderError = null) {
      this.$emit('toggleLoading', loading, loaderError);
    },
    _validateFilters() {
      this.errors.show = false;
      this.errors.message = null;
      if (this.startDate === null || this.endDate === null) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.no_date_selected');
      }
      this.$emit('setErrors', this.errors.show, this.errors.message);
    },
    _buildPayload() {
      return {
        startDate: moment(this.startDate, this.dateFormat).format(this.momentFormat),
        endDate: moment(this.endDate, this.dateFormat).format(this.momentFormat)
      };
    }
  }
}
</script>
