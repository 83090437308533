<template>
  <div v-show="!initialLoading" class="col-12">
    <div class="container-bg-white">
      <div class="row p-3" id="filterGroup">
        <div class="col-12 col-xl-3 mb-2 mb-xl-0">
          <img src="@/assets/images/filter.svg"/>
          <span class="ml-3">{{$t("flexben.general.filter")}}</span>
        </div>
        <div class="col-12 col-xl-9">
          <div class="row">
            <div class="col-12 col-md-4 form-group mb-0">
              <label class="col-form-label" for="start_date">{{$t("flexben.reports.inputLabels.startDate")}}</label>
              <div class="w-100 d-inline-flex">
                <label for="start_date" class="my-auto mr-2 col-form-label">
                  <font-awesome-icon icon="calendar-alt" class="text-24"/>
                </label>
                <vue-datepicker :format="dateFormat"
                                :inputClass="'form-control-d m-0 w-100 background-white'"
                                v-model="startDate"
                                valueType="format"
                                :class="'w-100'"
                                key="start_date"
                                id="start_date">
                </vue-datepicker>
                <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                     @click="function (){startDate=null;}"/>
              </div>
            </div>
            <div class="col-12 col-md-4 form-group mb-0">
              <label class="col-form-label" for="end_date">{{$t("flexben.reports.inputLabels.endDate")}}</label>
              <div class="w-100 d-inline-flex">
                <label for="end_date" class="my-auto mr-2 col-form-label">
                  <font-awesome-icon icon="calendar-alt" class="text-24"/>
                </label>
                <vue-datepicker :format="dateFormat"
                                :inputClass="'form-control-d m-0 w-100 background-white'"
                                v-model="endDate"
                                valueType="format"
                                :class="'w-100'"
                                key="end_date"
                                id="end_date">
                </vue-datepicker>
                <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                     @click="function (){endDate=null;}"/>
              </div>
            </div>
            <div class="col-12 col-md-4 form-group-multiselect mb-0" v-if="isAdmin">
              <label for="select_company" class="col-form-label">{{ $t("flexben.reports.filters.client")
                }}</label>

              <multiselect :multiple="false"
                           :class="{'from-control':true}"
                           label="name"
                           trackBy="value"
                           id="select_company"
                           openDirection="bottom"
                           :closeOnSelect="true"
                           :searchable="true"
                           :placeholder="$t('flexben.general.search')"
                           selectLabel=""
                           deselectLabel=""
                           selectedLabel=""
                           v-model="companySelect"
                           :options="filterOptions.companies"
                           ref="multiselect_companies">
              </multiselect>
            </div>
            <div class="col-12 col-md-4 mb-0 form-group-multiselect">
              <label for="select_benefit" class="col-form-label">{{ $t("flexben.reports.filters.benefit")
                }}</label>
              <multiselect :multiple="false"
                           :class="{'from-control':true}"
                           label="name"
                           trackBy="value"
                           id="select_benefit"
                           openDirection="bottom"
                           :closeOnSelect="true"
                           :searchable="true"
                           :placeholder="$t('flexben.general.all')"
                           selectLabel=""
                           deselectLabel=""
                           selectedLabel=""
                           :noResult="'offfd'"
                           v-model="benefitSelect"
                           :options="filterOptions.benefits"
                           ref="multiselect_benefits">
              </multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-bg-white mt-3">
      <div class="row p-3">
        <div class="col-12 col-md-4 col-xl-3">
          <span class="text-bold">{{ $t('flexben.reports.inputLabels.startDate') }}</span>
          <br>{{ (lockedModelData.startDate !== null) ? moment(lockedModelData.startDate, 'DD/MM/YYYY'):'-' }}
        </div>
        <div class="col-12 col-md-4 col-xl-3">
          <span class="text-bold">{{ $t('flexben.reports.inputLabels.endDate') }}</span>
          <br>
          {{ (lockedModelData.endDate !== null) ? moment(lockedModelData.endDate, 'DD/MM/YYYY'):'-' }}
        </div>
        <div class="col-12 col-md-4 col-xl-3" v-if="isAdmin">
          <span class="text-bold">{{ $t('flexben.reports.filters.company') }}</span>
          <br>
          {{ lockedModelData.company ? lockedModelData.company : '-' }}
        </div>
        <div class="col-12 col-md-4 col-xl-3">
          <span class="text-bold">{{ $t('flexben.reports.filters.benefit') }}</span>
          <br>
          {{ lockedModelData.benefit ? lockedModelData.benefit : '-' }}
        </div>

        <div class="col-12 mt-2">
          <div class="table-responsive scaled-table hover-table">
            <table class="table table-striped">
              <thead>
              <tr>
                <td v-if="modelData.company===null">{{ $t('flexben.reports.standard.fields.company') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.employee_code') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.cnp') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.name') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.fist_name') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.allocated_budget') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.available_budget') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.budget_expiration_date') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.budget_type') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.benefit') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.quantity') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.unit_value') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.total_value') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.tax_value') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.cass_value') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.cas_value') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.cam_value') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.net_value') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.placed_at') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.status') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.contract_number') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.partner_name') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.partner_iban') }}</td>
                <td>{{ $t('flexben.reports.standard.fields.personal_use') }}</td>
              </tr>
              </thead>
              <tbody>
              <tr class="trhover" v-for="(item, key) in reportItems" :key="key">
                <td v-if="modelData.company===null">{{ item.company }}</td>
                <td>{{ item.employee_number }}</td>
                <td>{{ item.cnp }}</td>
                <td>{{ item.last_name }}</td>
                <td>{{ item.first_name }}</td>
                <td>{{ item.allocated_budget }}</td>
                <td>{{ item.available_budget }}</td>
                <td>{{ item.available_budget_expiration_date }}</td>
                <td>{{ item.budget_type }}</td>
                <td>{{ item.benefit_name }}</td>
                <td>{{ item.ticket_count }}</td>
                <td>{{ item.ticket_value }}</td>
                <td>{{ item.total_consumed }}</td>
                <td>{{ item.value_tax }}</td>
                <td>{{ item.value_cass }}</td>
                <td>{{ item.value_cas }}</td>
                <td>{{ item.value_cam }}</td>
                <td>{{ item.value }}</td>
                <td>{{ item.placed_at }}</td>
                <td>
                   <span class="beneficiary-request-status"
                         :class="'flx-'+item.status">{{ $t('flexben.requests.summary.status.' + item.status) }}
                    </span>
                </td>
                <td>{{ item.contract_number ? item.contract_number : '-' }}</td>
                <td>{{ item.partner_name ? item.partner_name : '-' }}</td>
                <td>{{ item.partner_iban ? item.partner_iban : '-' }}</td>
                <td >{{ item.personal_use ? $t("flexben.general.yes") : $t("flexben.general.no") }}</td>
              </tr>
              <tr v-if="reportItems.length===0" class="trhover gri-cell">
                <td colspan="10000" class="text-center ">
                  {{ $t("flexben.general.no_results_found") }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import i18n from "../../../../plugins/language/i18n";
import utils from "@/plugins/utils";

export default {
  name: "StandardReport",
  components: {
    'vue-datepicker': Datepicker,
  },
  props: {
    isAdmin: {
      required: true,
      default: false
    },
    company: {
      required: true,
      default: null
    },
  },
  data: function () {
    return {
      initialLoading: true,
      isShowReport: false,
      idAdmin: false,
      httpService: httpFlexbenService.reports.standard,
      dateFormat: 'dd/MM/yyyy',
      momentFormat: 'YYYY-MM-DD',
      filterOptions: {
        companies: [],
        benefits: [],
      },
      startDate: null,
      endDate: null,
      companySelect: null,
      benefitSelect: null,
      modelData: {
        startDate: null,
        endDate: null,
        company: null,
        benefit: null,
      },

      withBenefits: false,
      lockedModelData: {
        startDate: null,
        endDate: null,
        company: null,
        benefit: 'Toate',
      },
      disabledFields: {
        benefit: true
      },
      reportItems: [],
      errors: {
        show: false,
        message: null,
      }
    }
  },
  watch: {
    modelData: {
      handler: function (val) {
        this._getOptions();
        // if (this.modelData.startDate !== null && this.modelData.endDate !== null) {
        //   this.withBenefits = true;
        // } else {
        //   this.withBenefits = false;
        //   this.filterOptions.benefits = [];
        //   this.disabledFields.benefit = true;
        // }
      },
      deep: true
    },
    startDate: {
      handler: function (val) {
        this.modelData.startDate = (val === null) ? null : moment(val).format('yyyy-MM-DD');
      }
    },
    endDate: {
      handler: function (val) {
        this.modelData.endDate = (val === null) ? null : moment(val).format('yyyy-MM-DD');
      }
    },
    companySelect: {
      handler: function (val) {
        this.modelData.company = (val === null) ? null : val.value;
        this.$emit('setCompany', this.modelData.company)
      }
    },
    benefitSelect: {
      handler: function (val) {
        this.modelData.benefit = (val === null) ? null : val.value;
      }
    },
  },
  created() {
    if (!this.isAdmin) {
      this.modelData.company = this.company;
    }
    this.lockedModelData.benefit = this.$t('flexben.general.all');

    this._getOptions();
  },
  methods: {
    filterValues() {
      this._validateFilters();
      if (this.errors.show) {
        return;
      }

      this.lockedModelData.startDate = moment(this.startDate, this.dateFormat);
      this.lockedModelData.endDate = moment(this.endDate, this.dateFormat);
      this.lockedModelData.company = this.companySelect ? this.companySelect.name : null;
      this.lockedModelData.benefit = this.benefitSelect ? this.benefitSelect.name : this.$t('flexben.general.all');
      this._toggleLoading(true);
      let params = {...this.modelData};
      params.lang = i18n.locale.toLowerCase();
      this.httpService.list(params)
        .then(function (response) {
          this.reportItems = response.data;
          this._toggleLoading(false);
        }.bind(this))
        .catch(function (error) {
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        }.bind(this));
    },
    download() {
      this._validateFilters();
      if (this.errors.show) {
        return;
      }

      let payload = {...this.modelData}

      payload.table_headers = [
        this.$t('flexben.reports.standard.fields.employee_code'),
        this.$t('flexben.reports.standard.fields.cnp'),
        this.$t('flexben.reports.standard.fields.name'),
        this.$t('flexben.reports.standard.fields.fist_name'),
        this.$t('flexben.reports.standard.fields.allocated_budget'),
        this.$t('flexben.reports.standard.fields.available_budget'),
        this.$t('flexben.reports.standard.fields.budget_expiration_date'),
        this.$t('flexben.reports.standard.fields.budget_type'),
        this.$t('flexben.reports.standard.fields.benefit'),
        this.$t('flexben.reports.standard.fields.quantity'),
        this.$t('flexben.reports.standard.fields.unit_value'),
        this.$t('flexben.reports.standard.fields.total_value'),
        this.$t('flexben.reports.standard.fields.tax_value'),
        this.$t('flexben.reports.standard.fields.cas_value'),
        this.$t('flexben.reports.standard.fields.cass_value'),
        this.$t('flexben.reports.standard.fields.cam_value'),
        this.$t('flexben.reports.standard.fields.net_value'),
        this.$t('flexben.reports.standard.fields.placed_at'),
        this.$t('flexben.reports.standard.fields.status'),
        this.$t('flexben.reports.standard.fields.contract_number'),
        this.$t('flexben.reports.standard.fields.partner_name'),
        this.$t('flexben.reports.standard.fields.partner_iban'),
        this.$t('flexben.reports.standard.fields.personal_use'),
      ];
      if (this.modelData.company === null) {
        payload.table_headers.unshift(this.$t('flexben.reports.standard.fields.company'));
      }
      payload.filters_info = {
        startDate: {
          label: this.$t('flexben.reports.inputLabels.startDate'),
          value: (this.modelData.startDate === null) ? null : moment(this.modelData.startDate).format('DD/MM/YYYY')
        },
        endDate: {
          label: this.$t('flexben.reports.inputLabels.endDate'),
          value: (this.modelData.endDate === null) ? null : moment(this.modelData.endDate).format('DD/MM/YYYY')
        },
        company: {
          label: this.$t('flexben.reports.filters.company'),
          value: this.companySelect ? this.companySelect.name : null
        },
        benefit: {
          label: this.$t('flexben.reports.filters.benefit'),
          value: this.benefitSelect ? this.benefitSelect.name : null
        }
      };
      payload.statuses = {
        ordered: this.$t('flexben.requests.summary.status.ordered'),
        await_top_up: this.$t('flexben.requests.summary.status.await_top_up'),
        await_voucher_upload: this.$t('flexben.requests.summary.status.await_voucher_upload'),
      };
      payload.lang = i18n.locale.toLowerCase();
      this._toggleLoading(true);
      this.httpService.download(payload)
        .then(function (response) {
          utils.generateDownloadFromReponse(response);
          this._toggleLoading(false);
        }.bind(this))
        .catch(function (error) {
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        }.bind(this));
    },
    _validateFilters() {
      this.errors.show = false;
      this.errors.message = null;
      if (this.modelData.startDate === null || this.modelData.endDate === null) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.no_date_selected');
      } else if (moment(this.modelData.startDate).isAfter(moment(this.modelData.endDate))) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.endDateBeforeStartDate');
      }

      this.$emit('setErrors', this.errors.show, this.errors.message);
    },
    _isEndDateBeforeStartDate() {
      if (this.modelData.period[this.params.period.startDate.index].value === null ||
        this.modelData.period[this.params.period.endDate.index].value === null) {
        this.errorMessage = this.$t('flexben.reports.errors.no_date_selected');
        this.showError = true;
        return this.showError;
      }
      this.showError = moment(this.modelData.period[this.params.period.endDate.index].value)
        .isBefore(moment(this.modelData.period[this.params.period.startDate.index].value));
      if (this.showError) {
        this.errorMessage = this.$t('flexben.reports.errors.endDateBeforeStartDate');
      }

      return this.showError;
    },
    _getOptions() {
      this._toggleLoading(true);
      let params = {
        startDate: this.modelData.startDate,
        endDate: this.modelData.endDate,
        company: this.modelData.company,
        withBenefits: this.withBenefits ? 1 : 0,
      };
      this.httpService.getOptions(params)
        .then(function (response) {

          if (this.withBenefits) {
            this.filterOptions.benefits = response.data.benefits;
            this.disabledFields.benefit = false;
          } else {
            if (this.filterOptions.companies.length === 0) {
              this.filterOptions.benefits = response.data.benefits;
              this.filterOptions.companies = response.data.companies;
              this.initialLoading = false;
            }
          }

          this._toggleLoading(false);
        }.bind(this))
        .catch(function (error) {
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        }.bind(this));
    },
    _toggleLoading(loading, loaderError = null) {
      this.$emit('toggleLoading', loading, loaderError);
    }
  }
}
</script>
