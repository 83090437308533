<template>
  <div class="client-info" id="clientConfigurationForm" @submit.prevent="getFormValues">
    <div class="text-28 text-bold">{{$t("flexben.benefit.title")}}</div>
    <b-tabs nav-class="flexben-tabs mt-3">
      <template v-for="(configuration, index) in formData.legislated">
        <TabUpDejun v-if="configuration.type ==='up_dejun'"
                    :isAdmin="isAdmin"
                    :configuration="configuration"
                    :benefitTranslations="formData.benefit_translations[configuration.benefit_id]"
                    :globalVariables="globalVariables"
                    :clientSettings="formData.client_settings"
                    :tabIndex="index"
                    :activeTab="((quickActive!==null && quickActive==='up_dejun') ||(quickActive ==null && index===0))"
                    :key="index"
                    @setBenefitTranslation="setBenefitTranslation()"></TabUpDejun>

        <TabUpVacation v-if="configuration.type ==='up_vacation'"
                       :isAdmin="isAdmin"
                       :configuration="configuration"
                       :benefitTranslations="formData.benefit_translations[configuration.benefit_id]"
                       :globalVariables="globalVariables"
                       :tabIndex="index"
                       :clientSettings="formData.client_settings"
                       :activeTab="((quickActive!==null && quickActive==='up_vacation') ||(quickActive ==null && index===0))"
                       :key="index"
                       @setBenefitTranslation="setBenefitTranslation()"></TabUpVacation>

        <TabUpCulture v-if="configuration.type ==='up_culture'"
                      :isAdmin="isAdmin"
                      :configuration="configuration"
                      :benefitTranslations="formData.benefit_translations[configuration.benefit_id]"
                      :globalVariables="globalVariables"
                      :tabIndex="index"
                      :clientSettings="formData.client_settings"
                      :clientId="clientId"
                      :activeTab="((quickActive!==null && quickActive==='up_culture') ||(quickActive ==null && index===0))"
                      :key="index"
                      @setBenefitTranslation="setBenefitTranslation()"></TabUpCulture>

        <TabUpGift v-if="configuration.type ==='up_gift'"
                   :isAdmin="isAdmin"
                   :configuration="configuration"
                   :benefitTranslations="formData.benefit_translations[configuration.benefit_id]"
                   :globalVariables="globalVariables"
                   :tabIndex="index"
                   :clientSettings="formData.client_settings"
                   :clientId="clientId"
                   :activeTab="((quickActive!==null && quickActive==='up_gift') ||(quickActive ==null && index===0))"
                   :key="index"
                   @setBenefitTranslation="setBenefitTranslation()"></TabUpGift>
      </template>
      <TabPP3 :benefits="formData.pp3_benefits"
              :isAdmin="isAdmin"
              :clientId="clientId"
              :tabIndex="9"
              :formOptions="formOptions"
              :key="9"
              @toggleLoading="toggleLoading"
              @refreshData="getNewPPP3Data"></TabPP3>
      <TabPartnerBenefits :benefits="formData.not_legislated"
                          :isAdmin="isAdmin"
                          :clientId="clientId"
                          @toggleLoading="toggleLoading"
                          :tabIndex="10"
                          :key="10"/>
    </b-tabs>
  </div>
</template>

<script>
import {BTabs} from "bootstrap-vue";
import TabUpDejun from "./TabUpDejun";
import TabUpVacation from "./TabUpVacation";
import TabUpCulture from "./TabUpCulture";
import TabUpGift from "./TabUpGift";
import TabPP3 from "./TabPP3";
import TabPartnerBenefits from "@/components/flexben/clients/partials/TabPartnerBenefits";
import httpFlexbenService from "@/http/http-flexben";

export default {
  props: {
    globalVariables: {
      type: Object,
      default: function () {
        return {'impozit-venit': null, 'cass': null, 'cas': null};
      },
      required: true
    },
    formData: {
      type: Object,
      default: function () {
        return {legislated: [], not_legislated: [],pp3_benefits:[], benefit_translations: {}};
      },
      required: true
    },
    clientId: {
      default: null,
      required: true
    },
    isAdmin: {
      type: Boolean,
      default: true,
      required: true
    },
    formOptions: {
      ppp3_contributions: []
    },
  },
  components: {
    TabPartnerBenefits,
    TabUpGift,
    TabUpCulture,
    TabUpVacation,
    TabUpDejun,
    TabPP3,
    "b-tabs": BTabs,
  },
  name: "ClientBenefits",
  data: function () {
    return {
      quickActive: null
    }
  },
  created() {
    if (this.$route.query.tab) {
      this.quickActive = this.$route.query.tab;
    }
  },
  methods: {
    getNewPPP3Data(){
      this.toggleLoading(true);
      if (this.clientId !== null) {

        httpFlexbenService.clients
          .loadClient(this.clientId)
          .then((result) => {
            this.formData.pp3_benefits = result.data.pp3_benefits;
            this.toggleLoading(false);
          })
          .catch((error) => {
            console.log(error);
            this.toggleLoading(false);
          });
      }
    },
    getReArrangeChildren(children) {
      return {
        law_events: children.law_events,
        company_events: children.company_events,
        stim: children.stim
      }
    },
    getFormValues() {
    },
    setBenefitTranslation(benefitId, translations) {
      // this.formData.benefit_translations[benefitId] = translations;
    },
    toggleLoading(loading, loaderError = false) {
      this.$emit('toggleLoading', loading, loaderError)
    },
  },
}
</script>
<style scoped lang="scss">
.dropdown-item {
  width: auto;
}
</style>
