<template>
  <div>
    <div class="row align-items-center">
      <template v-for="(obj,key,index) of reportTypes">
        <div
          v-if="isAdmin || (!isAdmin && !obj.for_admin)"
          :key="index"
          class="col-12 col-md-4 col-xl-3 mt-2">
          <div class="btn-request-type text-themed-dark d-flex align-items-center justify-content-center"
               :class="{'btn-type-active':(type===key)}"
               @click="changeType(key)">
            <div>
              <div class="text-20 text-uppercase">{{ $t('flexben.reports.buttons.types.'+key)}}</div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="row mt-4" :class="{'d-none': (type===null)}">
      <div class="col-12 col-md-2 col-xl-4 flexben-page-title">
        <h1>{{ $t("flexben.reports.titles."+(type ? type : 'types'))}}</h1>
        <span v-if="type==='world_class'" id="world_class_info"
              class="d-inline-block flexben-tooltip-icon ml-2 mr-2 text-bold">i</span>
        <b-tooltip v-if="type==='world_class'"  :target="'world_class_info'" variant="flexben" placement="topleft">
          <div class="text-left">{{$t('flexben.reports.info.world_class')}}</div>
        </b-tooltip>
      </div>

      <div class="col-12 col-md-10 col-xl-8 d-flex align-items-center">
        <button  class="btn btn-themed ml-0 ml-md-auto"
                @click="triggerFilter()"  v-if="type !== 'unconsumed_budget' && type !== 'upBenefits' && type!= 'offers'">
          {{ $t("flexben.reports.buttons.show_report") }}
        </button>
        <button :class="[(type === 'unconsumed_budget' || type === 'upBenefits' || type === 'offers') ? 'btn btn-themed ml-0 ml-md-auto' : 'btn btn-themed ml-3']"
                @click="triggerDownload()">
          {{ $t("flexben.reports.buttons.download_report") }}
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import {BTooltip} from "bootstrap-vue";

export default {
  name: "ActionButtons",
  components: {
    'b-tooltip': BTooltip,
  },
  props: {
    reportTypes: {
      type: Object,
      required: true
    },
    isAdmin: {
      default: false,
      required: true
    },
    type: {
      default: null
    },
    company: {
      default: null
    },
  },
  methods: {
    changeType(type) {
      this.$emit('changeType', type);
    },
    triggerFilter() {
      this.$emit('triggerFilter');
    },
    triggerDownload() {
      this.$emit('triggerDownload');
    },
  }
}
</script>

<style lang="scss" scoped>
.btn-request-type {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 0.25rem;
  color: #585858;
  font-size: 14px;
  opacity: 0.9;
  height: 100%;
  text-align: center;
  line-height: 1.1;
  cursor: pointer;
  width: 100%;
  padding: 1.1rem 0.5rem;
  max-width: 350px;
  transition: background-color 300ms ease;

  &.btn-type-active, &:hover {
    opacity: 1;
    background: var(--color-f49400);
    border-color: var(--color-f49400);
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding: 0.6rem 0.3rem;
  }
}
.flexben-tooltip-icon {
  font-family: "Times New Roman", sans-serif;
  color: gray;
  border: 1px solid gray;
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  cursor: pointer;
  line-height: initial;
}
</style>
