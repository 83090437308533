<template>
  <div class="border-box row">
    <div class="col-12 col-xl-10 offset-xl-1">
      <div class="import-path">
        <div class="row text-center">
          <div class="col-12 my-4 my-lg-5 text-bold text-24"> {{ $t('flexben.import.greetings_question') }}</div>
        </div>
        <div class="row">
          <template v-for="(obj, key) of importTypesAccess">
            <div v-if="shouldRender(obj, key)" class="col-md-6 mt-2 mb-md-4" :key="key">
              <button class="btn import-options d-inline-flex" @click="choseImport(key)"
                      :disabled="!obj.enable">
                <img v-if="key==='beneficiaries' || key==='offer_beneficiaries'|| key==='update_beneficiaries' "
                     class="mr-4 ml-2 float-left my-auto"
                     src="@/assets/images/flexben/import/import-beneficiaries-icon.png">
                <img v-else-if="(key==='budgets' || key==='ppp3')"
                     class="mr-4 ml-2 float-left my-auto"
                     src="@/assets/images/flexben/import/import-budgets-icon.png">
                <img v-else-if="key==='time_sheets'"
                     class="mr-4 ml-2 float-left my-auto"
                     src="@/assets/images/flexben/import/import-timesheets-icon.png">
                <img v-else-if="key==='telework_budgets'"
                     class="mr-4 ml-2 float-left my-auto"
                     src="@/assets/images/flexben/import/import-budgets-icon.png">

                <span class="my-auto text-uppercase text-line-h-1">{{ $t('flexben.import.buttons.chose_'+key) }}</span>
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChoseImportType",
  props: {
    isAdmin: {
      type: Boolean,
      required: true
    },
    importTypesAccess: {
      type: Object,
      required: true,
      default: function () {
        return {
          beneficiaries: false,
          budgets: false,
          time_sheets: false,
          telework_budgets: false,
          ppp3: false,
        };
      }
    }
  },
  methods: {
    shouldRender(obj, key) {
      if (this.isAdmin === false && key === 'ppp3') {
        return false;
      }
      return obj.show;
    },
    choseImport(type) {
      this.$emit('choseImport', type)
    }
  }
}
</script>
