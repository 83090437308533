<template>
  <div class="container-bg-white p-3 mt-3" v-show="showCard">
    <div class="row">
      <div class="col-12 col-md-6 col-xl-4">
        {{ $t('flexben.beneficiaries.mass_actions.selected_count')}} <span
        class="text-bold">{{ massActionIds.length }}</span>
      </div>
      <div v-if="notFoundItemsCount!==0" class="col-12 col-md-6 col-xl-4">{{
        $t('flexben.beneficiaries.mass_actions.not_found_count')}} <span
          class="text-bold">{{ notFoundItemsCount }}</span>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-12 mb-2 text-bold"
           v-show="upload_import.show"
           :class="{'text-danger':upload_import.failed, 'text-success':!upload_import.failed}"
      >{{ upload_import.message }}
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <input type="file" class="d-none" ref="upload_import" @change="handleFileUpload()">
        <button :disabled="!company"
                class="btn btn-themed"
                @click="function (){$refs['upload_import'].click()}">{{
          $t('flexben.beneficiaries.mass_actions.btn_upload_import') }}
        </button>
      </div>
      <div class="col-12 col-md-6 col-xl-4 text-xl-center mt-2 mt-xl-0">
        <a :href="exampleLink" target="_blank" download class="btn btn-themed-green text-white">
          {{ $t('flexben.beneficiaries.mass_actions.btn_download_example') }}</a>
      </div>
      <div class="col-12 col-md-6 col-xl-4 text-xl-right mt-2 mt-xl-0">
        <button class="btn btn-themed text-white"
                @click="downloadExport()">
          {{ $t('flexben.beneficiaries.mass_actions.btn_export') }}
        </button>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-12 col-md-6 col-xl-4">
        <button :disabled="massActionIds.length===0"
                class="btn text-success" @click="triggerAction('activate')">{{$t("flexben.benefit.reactivate")}}
        </button>
      </div>
      <div class="col-12 col-md-6 col-xl-4 text-xl-center">
        <button :disabled="massActionIds.length===0"
                class="btn text-warning" @click="triggerAction('suspend')">{{$t("flexben.benefit.suspended")}}
        </button>
      </div>
      <div class="col-12 col-md-6 col-xl-4 text-xl-right">
        <button :disabled="massActionIds.length===0"
                class="btn text-danger" @click="triggerAction('delete')">{{$t("flexben.benefit.delete")}}
        </button>
      </div>
    </div>
    <StatusChangeModal :plural-texts="true"
                       @confirmAction="confirmAction"
                       ref="status_change_modal"/>
    <div :class="{show: this.popUpModal }" class="modal fade">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-white">
          <div class="modal-body bg-white d-flex flex-column">
            <h4 class="text-danger" style="font-size: 20px" v-html="$t('flexben.general.block_pop_up')"></h4>
            <button @click="popUpModal = false" class="btn btn-themed mx-auto">{{ $t('flexben.general.btn.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment";
import StatusChangeModal from "../modals/StatusChangeModal";
import i18n from "../../../../plugins/language/i18n";
import httpFlexbenService from "@/http/http-flexben.js";
import utils from "@/plugins/utils.js";

export default {
  name: "MassActions",
  components: {
    StatusChangeModal
  },
  props: {
    massActionIds: {
      type: Array,
      default: function () {
        return [];
      },
      required: true
    },
    showCard: {
      type: Boolean,
      default: false,
      required: true
    },
    company: {
      type: Number
    },
    companyName: {},
    filters: {
      type: Object,
      default: () => {
        return {
          company: null,
          department: null,
          job_title: null,
          cost_center: null,
          working_point: null,
          benefit_category_code: null,
          delivery_address: null,
          status: 'all',
          fixed_benefit_group: null
        };
      }
    }
  },
  data: function () {
    return {
      httpServiceMassActions: httpFlexbenService.beneficiaries.massActions,
      httpExportServices: httpFlexbenService.exports,
      notFoundItemsCount: 0,
      modal: {
        open: false,
        statusChange: '',
        formSuccess: false,
        action: null,
        endMessage: '',
        disableUntil: {to: moment().toDate()},
        companyName: null,
      },
      last_employment_date: null,
      upload_import: {
        show: false,
        failed: false,
        message: null
      },
      popUpModal: false,
    };
  },
  computed: {
    exampleLink() {
      return process.env.VUE_APP_FLEXBEN_BASE + 'beneficiary-administration/mass-actions/download-import-example?lang=' + i18n.locale;
    },
  },
  watch: {
    showCard: {
      handler: function (val) {
        if (!val) {
          this._setImportErrors(false);
        }
      }
    }
  },
  methods: {
    triggerAction(action) {
      this.modal.statusChange = action;
      this.$refs['status_change_modal'].openModal(this.companyName, this.modal.statusChange, this.massActionIds.length);
    },
    confirmAction(lastEmploymentDate) {
      let payload = {
        new_status: this.modal.statusChange,
        employee_ids: this.massActionIds,
        company: this.company,
        last_employment_date: (this.modal.statusChange === 'delete') ? moment(lastEmploymentDate).format('YYYY-MM-DD') : null,
      };

      this._toggleLoading(true);

      this.httpServiceMassActions.postAction(payload)
        .then(function (response) {
          if (response.data.status === 'success') {
            this.$refs['status_change_modal'].setMessage(this.$t("flexben.beneficiaries.modal.statuses_changed." + ((response.data.data.changed_status_count > 1 || response.data.data.changed_status_count === 0) ? 1 : 0), {count: response.data.data.changed_status_count}),
              false, true);
            this._reloadList();
          } else {
            this.$refs['status_change_modal'].setMessage(this.$t("flexben.beneficiaries.modal.could_not_change"), true, true);
          }
          this._toggleLoading(false);
        }.bind(this))
        .catch(function (error) {
          this._toggleLoading(true, true);
        }.bind(this))
    },
    handleFileUpload() {
      let payload = new FormData();
      payload.append('file', this.$refs['upload_import'].files[0]);
      payload.append('company', this.company);
      this._setImportErrors(false);
      this._toggleLoading(true);
      this.httpServiceMassActions.uploadImport(payload)
        .then(function (response) {
          let ids = [], notFoundCount = 0;
          let loading = false, errorLoader = false;
          if (response.data.status === 'success') {
            ids = response.data.data.ids;
            notFoundCount = response.data.data.not_found_count;
            this._setImportErrors(true);
          } else {
            this._setImportErrors(true, true, response.data.data.error_reason);
            if (response.data.data.error_reason === null) {
              loading = true;
              errorLoader = true;
            }
          }
          this._setMassActionIds(ids);
          this._setNotFoundCount(notFoundCount);
          payload = null;
          this.$refs['upload_import'].value = null;
          this._toggleLoading(loading, errorLoader);
        }.bind(this))
        .catch(function (error) {
          this._toggleLoading(true, true);
        }.bind(this));
    },
    downloadExport() {
      this._toggleLoading(true);


      let instance = this;
      let filters = {...this.filters};
      filters.company = this.company;
      filters.lang = i18n.locale;
      this.httpExportServices.beneficiaries(filters)
        .then(function (response) {
          utils.generateDownloadFromReponse(response);
        }).finally(() => {
        instance._toggleLoading(false);
      });
    },
    _toggleLoading(loading, loaderError = false) {
      this.$emit('toggleLoading', loading, loaderError);
    },
    _reloadList() {
      this.$emit('massActionReloadList')
    },
    _setMassActionIds(ids = []) {
      this.$emit('setMassActionIds', ids)
    },
    _setNotFoundCount(count) {
      this.notFoundItemsCount = count;
    },
    _setImportErrors(show, failed = false, errorCode = null) {
      this.upload_import.show = show;
      if (failed) {
        this.upload_import.failed = failed;
        this.upload_import.message = errorCode !== null ? this.$t('flexben.beneficiaries.mass_actions.messages.' + errorCode) : null;
      } else {
        this.upload_import.message = this.$t('flexben.beneficiaries.mass_actions.messages.import_success');
      }
    }
  }
}
</script>
