<template>
  <div v-show="!initialLoading" class="col-12">
    <div class="container-bg-white">
      <div class="row p-3" id="filterGroup">
        <div class="col-12 col-xl-3 mb-2 mb-xl-0">
          <img src="@/assets/images/filter.svg"/>
          <span class="ml-3">{{$t("flexben.general.filter")}}</span>
        </div>
        <div class="col-12 col-xl-9">
          <div class="row">
            <div class="col-12 col-md-4 form-group mb-0">
              <label class="col-form-label" for="start_date">{{$t("flexben.reports.inputLabels.startDate")}}</label>
              <div class="w-100 d-inline-flex">
                <label for="start_date" class="my-auto mr-2 col-form-label">
                  <font-awesome-icon icon="calendar-alt" class="text-24"/>
                </label>
                <vue-datepicker :format="dateFormat"
                                :inputClass="'form-control-d m-0 w-100 background-white'"
                                v-model="startDate"
                                valueType="format"
                                :class="'w-100'"
                                key="start_date"
                                id="start_date">
                </vue-datepicker>
                <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                     @click="function (){startDate=null;}"/>
              </div>
            </div>
            <div class="col-12 col-md-4 form-group mb-0">
              <label class="col-form-label" for="end_date">{{$t("flexben.reports.inputLabels.endDate")}}</label>
              <div class="w-100 d-inline-flex">
                <label for="end_date" class="my-auto mr-2 col-form-label">
                  <font-awesome-icon icon="calendar-alt" class="text-24"/>
                </label>
                <vue-datepicker :format="dateFormat"
                                :inputClass="'form-control-d m-0 w-100 background-white'"
                                v-model="endDate"
                                valueType="format"
                                :class="'w-100'"
                                key="end_date"
                                id="end_date">
                </vue-datepicker>
                <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                     @click="function (){endDate=null;}"/>
              </div>
            </div>
            <div class="col-12 col-md-4 form-group-multiselect mb-0" v-if="isAdmin">
              <label for="select_company" class="col-form-label">{{ $t("flexben.reports.filters.client")
                }}</label>

              <multiselect :multiple="false"
                           :class="{'from-control':true}"
                           label="name"
                           trackBy="value"
                           id="select_company"
                           openDirection="bottom"
                           :closeOnSelect="true"
                           :searchable="true"
                           :placeholder="$t('flexben.general.search')"
                           selectLabel=""
                           deselectLabel=""
                           selectedLabel=""
                           v-model="companySelect"
                           :options="filterOptions.companies"
                           ref="multiselect_companies">
              </multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-bg-white mt-3">
      <div class="row p-3">
        <div class="col-12 col-md-4 col-xl-3">
          <span class="text-bold">{{ $t('flexben.reports.inputLabels.startDate') }}</span>
          <br>{{ (lockedModelData.startDate !== null) ? moment(lockedModelData.startDate, 'DD/MM/YYYY'):'-' }}
        </div>
        <div class="col-12 col-md-4 col-xl-3">
          <span class="text-bold">{{ $t('flexben.reports.inputLabels.endDate') }}</span>
          <br>
          {{ (lockedModelData.endDate !== null) ? moment(lockedModelData.endDate, 'DD/MM/YYYY'):'-' }}
        </div>
        <div class="col-12 col-md-4 col-xl-3" v-if="isAdmin">
          <span class="text-bold">{{ $t('flexben.reports.filters.company') }}</span>
          <br>
          {{ lockedModelData.company ? lockedModelData.company : '-' }}
        </div>

        <div class="col-12 mt-2">
          <div class="table-responsive scaled-table hover-table">
            <table class="table table-striped">
              <thead>
              <tr>
                <td v-if="modelData.company===null">{{ $t('flexben.reports.standard.fields.company') }}</td>
                <td>{{ $t('flexben.reports.allocated_budget.fields.employee_code') }}</td>
                <td>{{ $t('flexben.reports.allocated_budget.fields.cnp') }}</td>
                <td>{{ $t('flexben.reports.allocated_budget.fields.name') }}</td>
                <td>{{ $t('flexben.reports.allocated_budget.fields.allocated_budget') }}</td>
                <td>{{ $t('flexben.reports.allocated_budget.fields.available_budget') }}</td>
                <td>{{ $t('flexben.reports.allocated_budget.fields.budget_expiration_date') }}</td>
                <td>{{ $t('flexben.reports.allocated_budget.fields.allocated_telework_budget') }}</td>
                <td>{{ $t('flexben.reports.allocated_budget.fields.available_telework_budget') }}</td>
                <td>{{ $t('flexben.reports.allocated_budget.fields.budget_telework_expiration_date') }}</td>
                <td>{{ $t('flexben.reports.allocated_budget.fields.allocated_ppp3_budget') }}</td>
                <td>{{ $t('flexben.reports.allocated_budget.fields.available_ppp3_budget') }}</td>
                <td>{{ $t('flexben.reports.allocated_budget.fields.budget_ppp3_expiration_date') }}</td>
              </tr>
              </thead>
              <tbody>
              <tr class="trhover" v-for="(item, key) in reportItems" :key="key">
                <td v-if="modelData.company===null">{{ item.company }}</td>
                <td>{{ item.employee_number }}</td>
                <td>{{ item.cnp }}</td>
                <td>{{ item.full_name }}</td>
                <td>{{ item.allocated_budget }}</td>
                <td>{{ item.available_budget }}</td>
                <td>{{ item.available_budget_expiration_date }}</td>
                <td>{{ item.allocated_telework_budget }}</td>
                <td>{{ item.available_telework_budget }}</td>
                <td>{{ item.available_telework_budget_expiration_date }}</td>
                <td>{{ item.allocated_ppp3_budget }}</td>
                <td>{{ item.available_ppp3_budget }}</td>
                <td>{{ item.available_ppp3_budget_expiration_date }}</td>

              </tr>
              <tr v-if="reportItems.length===0" class="trhover gri-cell">
                <td colspan="10000" class="text-center ">
                  {{ $t("flexben.general.no_results_found") }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import utils from "@/plugins/utils";

export default {
  name: "AllocatedBudgetReport",
  components: {
    'vue-datepicker': Datepicker,
  },
  props: {
    isAdmin: {
      required: true,
      default: false
    },
    company: {
      required: true,
      default: null
    },
  },
  data: function () {
    return {
      initialLoading: true,
      isShowReport: false,
      idAdmin: false,
      httpService: httpFlexbenService.reports.allocated_budget,
      dateFormat: 'dd/MM/yyyy',
      momentFormat: 'YYYY-MM-DD',
      filterOptions: {
        companies: [],
      },
      startDate: null,
      endDate: null,
      companySelect: null,
      modelData: {
        startDate: null,
        endDate: null,
        company: null,
        lang: this.$store.state.language
      },

      lockedModelData: {
        startDate: null,
        endDate: null,
        company: null,
      },
      reportItems: [],
      errors: {
        show: false,
        message: null,
      }
    }
  },
  watch: {
    modelData: {
      handler: function (val) {
        this._getOptions();
      },
      deep: true
    },
    startDate: {
      handler: function (val) {
        this.modelData.startDate = (val === null) ? null : moment(val).format('yyyy-MM-DD');
      }
    },
    endDate: {
      handler: function (val) {
        this.modelData.endDate = (val === null) ? null : moment(val).format('yyyy-MM-DD');
      }
    },
    companySelect: {
      handler: function (val) {
        this.modelData.company = (val === null) ? null : val.value;
        this.$emit('setCompany', this.modelData.company)
      }
    }
  },
  created() {
    if (!this.isAdmin) {
      this.modelData.company = this.company;
    }

    this._getOptions();
  },
  methods: {
    filterValues() {
      this._validateFilters();
      if (this.errors.show) {
        return;
      }

      this.lockedModelData.startDate = moment(this.startDate, this.dateFormat);
      this.lockedModelData.endDate = moment(this.endDate, this.dateFormat);
      this.lockedModelData.company = this.companySelect ? this.companySelect.name : null;
      this._toggleLoading(true);
      let params = {...this.modelData};
      params.lang = this.$store.state.language;

      this.httpService.list(params)
        .then((response) => {
          this.reportItems = response.data;
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        });
    },
    download() {
      this._validateFilters();
      if (this.errors.show) {
        return;
      }

      let payload = {...this.modelData}

      payload.filtersInfo = {
        startDate: {
          label: this.$t('flexben.reports.inputLabels.startDate'),
          value: (this.modelData.startDate === null) ? null : moment(this.modelData.startDate).format('DD/MM/YYYY')
        },
        endDate: {
          label: this.$t('flexben.reports.inputLabels.endDate'),
          value: (this.modelData.endDate === null) ? null : moment(this.modelData.endDate).format('DD/MM/YYYY')
        },
        company: {
          label: this.$t('flexben.reports.filters.company'),
          value: this.companySelect ? this.companySelect.name : null
        }
      };

      payload.statuses = {
        ordered: this.$t('flexben.requests.summary.status.ordered'),
        await_top_up: this.$t('flexben.requests.summary.status.await_top_up'),
      };
      payload.lang = this.$store.state.language;

      this._toggleLoading(true);

      this.httpService.download(payload)
        .then((response) => {
          if (response.data.success === false) {
              console.log('Could not download report.' + response.data.message);
              this._toggleLoading(false);
              return;
          }

          utils.generateDownloadFromReponse(response);
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        });
    },
    _validateFilters() {
      this.errors.show = false;
      this.errors.message = null;
      if (this.modelData.startDate === null || this.modelData.endDate === null) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.no_date_selected');
      } else if (moment(this.modelData.startDate).isAfter(moment(this.modelData.endDate))) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.endDateBeforeStartDate');
      }

      this.$emit('setErrors', this.errors.show, this.errors.message);
    },
    _isEndDateBeforeStartDate() {
      if (this.modelData.period[this.params.period.startDate.index].value === null ||
        this.modelData.period[this.params.period.endDate.index].value === null) {
        this.errorMessage = this.$t('flexben.reports.errors.no_date_selected');
        this.showError = true;
        return this.showError;
      }
      this.showError = moment(this.modelData.period[this.params.period.endDate.index].value)
        .isBefore(moment(this.modelData.period[this.params.period.startDate.index].value));
      if (this.showError) {
        this.errorMessage = this.$t('flexben.reports.errors.endDateBeforeStartDate');
      }

      return this.showError;
    },
    _getOptions() {
      this._toggleLoading(true);
      let params = {
        startDate: this.modelData.startDate,
        endDate: this.modelData.endDate,
        company: this.modelData.company,
      };
      this.httpService.getOptions(params)
        .then((response) => {
          this.initialLoading = false;
          if (this.filterOptions.companies.length === 0) {
            this.filterOptions.companies = response.data.companies;
          }

          this._toggleLoading(false);
        })
        .catch((error) => {
          console.log(error);
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        });
    },
    _toggleLoading(loading, loaderError = null) {
      this.$emit('toggleLoading', loading, loaderError);
    }
  }
}
</script>

<style scoped>

</style>
