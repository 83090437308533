<template>
  <div>
    <div v-show="!showForm && !initialLoading">
      <div class="row mb-md-4">
        <div class="col-12 col-md-4 flexben-page-title">
          <h1>{{$t("flexben.offer_category.title")}}</h1>
          <p>{{$t("flexben.offer_category.subtitle")}}</p>
        </div>
        <div class="col-md-4 col-md-4">
          <label for="select_type" class="col-form-label pt-0">{{ $t("flexben.partner.filters.title_type") }}</label>
          <select class="form-control" v-model="filters_values.type" id="select_type" @change=_loadList>
            <option :value="null">-</option>
            <option v-for="(type, key) in categoryTypes" :key="key" :value="type">

              {{$t("flexben.categories.filters." + type)}}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-4">
          <button @click="openForm('create')" class="btn btn-themed px-4 float-left float-md-right my-2">{{
            $t("flexben.offer_category.new") }}
          </button>
        </div>
      </div>
      <div class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-6 mt-3">
            <h3 class="mt-0 pt-1 pl-0">{{ $t("flexben.offer_category.list") }}</h3>
          </div>
          <div class="col-6 mt-3">
            <input type="text"
                   v-model="searchString"
                   v-on:keyup.enter="search()"
                   :placeholder="$t('flexben.general.search')"
                   class="formedituser search-input float-right"/>
          </div>

          <div class="col-12">
            <div class="table-responsive hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td>{{ $t("flexben.general.image") }}</td>
                  <td>{{ $t("flexben.offer_category.table.name") }}</td>
                  <td></td>
                </tr>
                </thead>


                <tbody v-if="list.data.length >0">
                <tr v-for="(item, index) in list.data" :key="index"
                    class="trhover">
                  <td><img :src="item.image_url" alt="" width="50"></td>
                  <td> {{ item.name[locale] }}</td>
                  <td class="action-group text-right">
                    <button type="button" class="btn action-button"
                            @click="openForm('update', index)">{{$t("flexben.general.btn.see_more")}}
                    </button>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td colspan="3" class="text-center trhover gri-cell">
                    {{ $t("flexben.general.no_results_found") }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 p-0">
            <listPagination
              :activePage="list.current_page"
              :currentPage="list.current_page"
              :totalItems="list.total"
              :startItem="list.from"
              :endItem="list.to"
              :itemsPerPage="list.perPage"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage"></listPagination>
          </div>
        </div>
      </div>
    </div>
    <fixed-offer-category-detail v-if="showForm"
                                 :detailId="detailId"
                                 :formAction="formAction"
                                 @triggerLoading="triggerLoading"
                                 @updateListByIndex="updateListByIndex"
                                 @clearForm="clearForm"></fixed-offer-category-detail>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import OfferCategoryDetail from "@/components/flexben/offer_category/OfferCategoryDetail";
import i18n from "@/plugins/language/i18n";

export default {
  name: "OfferCategoryList",
  components: {
    'fixed-offer-category-detail': OfferCategoryDetail,
  },
  data() {
    return {
      initialLoading: true,
      loading: false,
      loaderError: false,
      isAdmin: false,
      locale: i18n.locale,

      httpService: httpFlexbenService.offerCategoriesService,
      categoryTypes: [],
      list: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      filters_values: {
        page: 1,
        perPage: 20,
        search: null,
        type: null
      },
      searchString: '',

      detailIndex: null,
      detailId: null,
      formAction: null,
      showForm: false,
    }
  },
  created() {
    let userRole = this.flxUserLevel().userLevel;
    if (userRole !== 'admin') {
      window.location.href = '/';
    } else {
      this._loadList();
    }
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  methods: {
    _loadList() {
      this.loading = true;
      this.httpService.list(this.filters_values)
        .then(function (response) {
          this.list.data = response.data.list.data;
          this.list.current_page = response.data.list.current_page;
          this.list.to = response.data.list.to;
          this.list.perPage = response.data.list.perPage;
          this.list.lastPage = response.data.list.lastPage;
          this.list.total = response.data.list.total;
          this.categoryTypes = response.data.options.type;

          if (this.initialLoading) {
            this.initialLoading = false;
          }
          this.loading = false;
        }.bind(this))
        .catch(function (error) {

        }.bind(this))
    },
    openForm(action, index = null) {
      this.formAction = action;
      if (action === 'update') {
        this.detailIndex = index;
        this.detailId = this.list.data[index].id;
        this.goToCreate();
      }

      if (action === 'create') {
        this.goToCreate();
      }
    },
    goToCreate() {
      this.openModal = false;
      this.showForm = true;
    },
    clearForm(triggerRerenderList = false) {
      this.detailIndex = null;
      this.detailId = null;
      this.formAction = null;
      this.showForm = false;
      this.loading = false;
      if (triggerRerenderList) {
        this._loadList();
      }
    },
    search() {
      this.filters_values.search = this.searchString;
      this._loadList();
    },
    pageChanged(page) {
      this.filters_values.page = page;
      this._loadList();
    },
    changePerPage(perPage) {
      this.filters_values.perPage = perPage;
      this._loadList();
    },
    triggerLoading(toggle, loaderError = false) {
      this.loading = toggle;
      this.loaderError = loaderError;
    },
    updateListByIndex(data) {
      this.list.data[this.detailIndex] = data;
    },
    closeModal() {
      this.openModal = false;
      this.selectedCompanyId = false;
      this.noClientSelected = false;
    },
    sortBy(fieldName) {
      if (this.sortField !== fieldName) {
        this.reverseSort = false;
      }
      this.filters_values.page = 1;
      this.filters_values.sort_by = fieldName;
      this.filters_values.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this._loadList()
      this.sortField = fieldName;
      this.reverseSort = !this.reverseSort;
    },
  }
}
</script>
