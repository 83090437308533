<template>
  <div class="col-12 text-right" :class="{'col-lg-6':half}">
    <button class="btn text-danger" v-if="(formAction==='update'&& !isLegislated)" @click="modalAct('delete')">
      <font-awesome-icon icon="trash-alt"/>
      {{$t("flexben.general.btn.delete")}}
    </button>
    <button class="btn text-themed px-4" v-if="(formAction==='update'&& status==='active')"
            @click="modalAct('status_change','inactive')">
      <font-awesome-icon icon="ban"/>
      {{$t("flexben.general.btn.set_inactive")}}
    </button>
    <button class="btn text-success px-4" v-if="(formAction==='update'&& status==='inactive')"
            @click="modalAct('status_change','active')">
      <font-awesome-icon icon="check"/>
      {{$t("flexben.general.btn.set_active")}}
    </button>
    <button class="btn text-themed"
            v-if="(formAction==='create' || (formAction==='update' && status==='draft'))"
            @click="modalAct('draft')">{{ $t('flexben.general.btn.save_draft') }}
    </button>
    <button class="btn btn-themed px-4"
            @click="modalAct((status==='draft' || formAction==='create') ? 'publish': 'save')">
      {{ (status==='active') ? $t('flexben.general.btn.save') : $t('flexben.general.btn.publish') }}
    </button>
  </div>
</template>

<script>
export default {
  name: "FormButtons",
  props: {
    formAction: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    half: {
      type: Boolean,
      default: false,
      required: true
    },
    isLegislated: {
      type: Boolean,
      default: false,
      required: true
    },

  },
  methods: {
    modalAct(action, statusToChange) {
      this.$emit('modalAct', action, statusToChange)
    }
  }
}
</script>
