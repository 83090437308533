<template>
  <div>
    <div v-if="this.company" class="menu-items">
      <a
        v-if="
          (this.company.role === 'FAC' || this.company.role === 'FLX' || this.company.role === 'HLP') &&
          user.id !== 57647
        "
        @click="pushRoute('company_search')"
        class="search-icon cursor-pointer"
        :class="this.menuSelected.companySearch && 'search-active'"
        >{{ $t("searchName") }}</a
      >
      <a
        v-if="this.company.company_id !== 0 && this.company.role !== 'FAC' && this.company.role !== 'FLX' && this.company.role !== 'FLS'"
        @click="pushRoute('customer_dashboard')"
        class="dashboard cursor-pointer"
        :class="this.menuSelected.dashboard && 'dashboard-active'"
        >{{ $t("dashboardName") }}</a
      >
      <a
        v-if="this.company.company_id !== 0"
        @click="pushRoute('customer_orders')"
        class="orders cursor-pointer"
        :class="this.menuSelected.orders && 'orders-active'"
        >{{ $t("ordersName") }}</a
      >
      <FlexbenMenu v-if="showFlxMenu" :menu-selected="menuSelected" />
      <a
        v-if="this.company.company_id !== 0"
        @click="pushRoute('customer_beneficiaries')"
        class="beneficiaries cursor-pointer"
        :class="this.menuSelected.beneficiaries && 'beneficiaries-active'"
        >{{ $t("beneficiariesName") }}</a
      >
      <a
        v-if="this.company.company_id !== 0"
        @click="pushRoute('delivery_points')"
        class="points cursor-pointer"
        :class="this.menuSelected.deliveryPoints && 'points-active'"
        >{{ $t("deliveryPointsName") }}</a
      >
      <a
        v-if="this.company.company_id !== 0"
        @click="pushRoute('customer_invoices')"
        class="invoices cursor-pointer"
        :class="this.menuSelected.invoices && 'invoices-active'"
        >{{ $t("invoicesName") }}</a
      >
      <a
        @click="pushRoute('customer_account')"
        class="amanage cursor-pointer"
        :class="this.menuSelected.account && 'amanage-active'"
        >{{ $t("settingsName") }}</a
      >
      <a
        v-if="
          this.company.company_id !== 0 &&
          !user.username.includes('@') &&
          this.company &&
          this.company.role !== 'FAC' &&
          !this.company.state_owned &&
          this.company.user_config &&
          this.company.user_config.roles &&
          this.company.user_config.roles.includes('EDITOR')
        "
        @click="pushRoute('manage_company')"
        class="cmanage cursor-pointer"
        :class="this.menuSelected.manageCompany && 'cmanage-active'"
        >{{ $t("companyManagementTitle") }}</a
      >
      <a
        v-if="this.company.role !== 'FAC' || this.company.role !== 'FLX'"
        @click="pushRoute('customer_contact')"
        class="contact cursor-pointer"
        :class="this.menuSelected.contact && 'contact-active'"
        >{{ $t("contactName") }}</a
      >
      <a
        v-if="this.company.role !== 'FAC' || this.company.role !== 'FLX'"
        @click="pushRoute('customer_faq')"
        class="faq cursor-pointer"
        :class="this.menuSelected.faq && 'faq-active'"
        >{{ $t("faqName") }}</a
      >
      <a
        v-if="this.company.role !== 'FAC' || this.company.role !== 'FLX'"
        @click="pushRoute('customer_terms')"
        class="term cursor-pointer"
        :class="this.menuSelected.terms && 'term-active'"
        >{{ $t("termsAndConditionsName") }}</a
      >

      <a href="#" class="logout" @click="logout()">{{ $t("logoutName") }}</a>
    </div>

    <a
      v-if="
        (!flxUserLevel().hasAccess ||
          (flxUserLevel().hasAccess && this.company.can_order_up) ||
          this.company.role === 'FAC') &&
        this.company.company_id !== 0
      "
      @click="pushRoute('customer_new_order')"
      class="notification-action step0"
    >
      {{ $t("newOrderName") }}
    </a>
    <div class="socials">
      <a href="https://www.facebook.com/UpRomania" class="facebook"></a>
      <a
        href="https://www.linkedin.com/company/up-romania"
        class="linkedin"
      ></a>
      <a
        href="https://www.youtube.com/c/UpRomaniaBucuresti"
        class="youtube"
      ></a>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import FlexbenMenu from "@/components/fragments/FlexbenMenu";
export default {
  name: "MenuItemsCustomer",
  components: {
    FlexbenMenu
  },
  props: {
    company: Object,
    user: Object,
    menuSelected: {
      dashboard: false,
      beneficiaries: false,
      orders: false,
      deliveryPoints: false,
      invoices: false,
      newOrder: false,
      faq: false,
      account: false,
      contact: false,
      terms: false,
      gdpr: false,
      companySearch: false,
      manageCompany: false,
      flexben: true
    },
  },
  data: function () {
    return { showFlxMenu: false };
  },
  mounted() {
    if (this.menuSelected.companySearch) {
      this.company.companyId = "0";
    }
    if (sessionStorage.getItem('flx_active_employment') || this.flxUserLevel().userLevel==='admin') {
      this.showFlxMenu = true;
    }
  },
  methods: {
    logout() {
      Cookies.remove("userLink");
      Cookies.remove("user_role");
      Cookies.remove("token");
      let hasFxlAccess = this.flxUserLevel().hasAccess;
      Cookies.remove("flexbenClient");
      Cookies.remove("flxCookie");
      sessionStorage.removeItem('flx_active_employments');
      sessionStorage.removeItem('flx_active_employment');
      this.$store.state.flx_beneficiaryActiveEmploymentId = null;
      this.$store.state.flx_beneficiaryActiveEmployment = null;
      this.$store.state.flx_activeEmployment = null;
      this.$store.state.flx_beneficiaryActiveEmployments = [];
      if (hasFxlAccess) {
        this.$router.push({
          name: "loginMultibenefits",
          params: {loginUserSelected: false}
        });
      } else {
        this.$router.push({
          name: "login",
          params: {loginUserSelected: false}
        });
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    isFlexBenRoute() {
      return (
        this.menuSelected.flexben ||
        this.menuSelected.flexbenConfiguration ||
        this.menuSelected.flexbenCategories ||
        this.menuSelected.flexbenClients ||
        this.menuSelected.flexbenEditClient ||
        this.menuSelected.flexbenBenefits ||
        this.menuSelected.flexbenBeneficiaries ||
        this.menuSelected.flexbenNewBeneficiary ||
        this.menuSelected.flexbenEditBeneficiary ||
        this.menuSelected.flexbenImportHistory ||
        this.menuSelected.flexbenImportNew ||
        this.menuSelected.flexbenRequests ||
        this.menuSelected.flexbenRequestsHistoryList ||
        this.menuSelected.flexbenBenefitGroupList ||
        this.menuSelected.flexbenFixedBenefitsList ||
        this.menuSelected.flexbenOfferCategoryList ||
        this.menuSelected.flexbenPartnerList ||
        this.menuSelected.flexbenOfferList ||
        this.menuSelected.flexbenReportsList ||
        this.menuSelected.flexbenChannelsList
      );
    },
    renderFlxMenu(show) {
      this.showFlxMenu = show;
    }
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
