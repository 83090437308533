<template>
  <div :class="{show: openModal }"
       class="modal fade">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header no-border text-center">
          <h3 class="w-100">{{$t("flexben.general.modals.select_client")}}</h3>
        </div>

        <div class="modal-body bg-white ">
          <div class="row">
            <div class="col-xl-8 mx-auto">
              <div class="text-danger" v-if="noClientSelected">{{$t("flexben.general.modals.select_company")}}</div>
              <div class="form-group form-group-multiselect flx-multiselect-single">
                <label for="client_new">{{$t("flexben.general.modals.client_company")}}</label>
                <select id="client" class="form-control w-100"
                        v-model="selectedCompany">
                  <option :value="null">-</option>
                  <option v-for="item in companies"
                          :key="item.id"
                          :value="item">{{ item.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer mb-2 border-0">
          <button type="button"
                  @click="$emit('closeModal')"
                  class="btn text-themed mr-auto">
            {{$t("flexben.general.btn.cancel")}}
          </button>
          <button type="button"
                  @click="$emit('goToCreate')"
                  :disabled="selectedCompany === null"
                  class="btn btn-themed">
            {{$t("flexben.general.btn.continue")}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectClientTypeModal",
  props: {
    openModal: {
      type: Boolean,
      default: false,
      required: true
    },
    noClientSelected: {
      type: Boolean,
      default: false,
      required: true
    },
    companies: {
      type: [Object,Array],
      default: function () {
        return {};
      },
      required: true
    }
  },
  data() {
    return {
      selectedCompany: null,
    }
  },
  watch: {
    selectedCompany: {
      handler: function (val) {
        this.$parent.selectedCompany = this.selectedCompany;
        this.$parent.noClientSelected = false;
      }
    }
  },
}
</script>
