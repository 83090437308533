<template>
  <div class="row px-3">
    <div class="col-xl-4">
      <h3 class="mt-0 pl-0">
        {{$t("flexben.general.details")}}
      </h3>
    </div>
    <div class="col-xl-4">
      <div class="form-group">
        <label class="form-fields-required col-form-label pt-0" for="valid_from_1">{{ $t("flexben.benefit.fields.valid_form") }}</label>
        <div class="d-inline-flex w-100 align-items-center" v-if="formAction==='create' || formData.status ==='DRAFT'">
          <label for="valid_from_1" class="my-auto mr-2 col-form-label">
            <font-awesome-icon icon="calendar-alt" class="text-24 flx-calendar-icon"/>
          </label>

          <datepicker :format="dateFormat"
                      class="w-100"
                      :inputClass="{
                          'form-control m-0 w-100': true,
                          'background-white': true,
                          'border-danger': Object.keys(errorsBag).includes('start_use_date')
                        }"
                      v-model="formData.start_use_date"
                      :key="1"
                      :value-type="dateFormat"
                      id="valid_from_1"></datepicker>
          <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
               @click="formData.start_use_date=null"/>
        </div>
        <div v-else class="form-control d-inline-block disabled">
          {{ moment(formData.start_use_date,'DD/MM/yyyy') }}
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <div class="form-group">
        <label class="form-fields-required col-form-label pt-0" for="valid_from_2">{{
          $t("flexben.benefit.fields.latest_annual_for_placing_orders") }}</label>
        <div class="d-inline-flex w-100 align-items-center">
          <label for="valid_from_2" class="my-auto mr-2 col-form-label">
            <font-awesome-icon icon="calendar-alt" class=" text-24 flx-calendar-icon"/>
          </label>
          <div class="row w-100">
            <div class="col-12 col-md-6 pr-xl-0 mb-1 mb-xl-0">
              <select name="month" id="valid_from_2" v-model="formData.end_use_day_month.month" class="w-100 form-control"
                      @change="() => {tickDay++;formData.end_use_day_month.day=null;}"
                      :class="{'border-danger':(Object.keys(errorsBag).includes('end_use_day_month_month'))}">
                <option :value="null" disabled>{{ $t('flexben.general.month') }}</option>
                <option :value="1">{{$t("flexben.general.months.1")}}</option>
                <option :value="2">{{$t("flexben.general.months.2")}}</option>
                <option :value="3">{{$t("flexben.general.months.3")}}</option>
                <option :value="4">{{$t("flexben.general.months.4")}}</option>
                <option :value="5">{{$t("flexben.general.months.5")}}</option>
                <option :value="6">{{$t("flexben.general.months.6")}}</option>
                <option :value="7">{{$t("flexben.general.months.7")}}</option>
                <option :value="8">{{$t("flexben.general.months.8")}}</option>
                <option :value="9">{{$t("flexben.general.months.9")}}</option>
                <option :value="10">{{$t("flexben.general.months.10")}}</option>
                <option :value="11">{{$t("flexben.general.months.11")}}</option>
                <option :value="12">{{$t("flexben.general.months.12")}}</option>
              </select>
            </div>

            <div class="col-12 col-md-6">
              <select name="day" id="day" v-model="formData.end_use_day_month.day"
                      class="w-100 form-control"
                      :class="{
                'cursor-pointer':!(formData.end_use_day_month.month === null ),
                'border-danger':(Object.keys(errorsBag).includes('end_use_day_month_day'))
              }"
                      :key="tickDay">
                <option :value="null" disabled>{{$t("flexben.general.day")}}</option>
                <option :value="day" v-for="day in getMonthMaxDays()" :key="day">{{day}}</option>
              </select>
            </div>

          </div>
          <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
               @click="function() {
                   formData.end_use_day_month.month=null;
                   formData.end_use_day_month.day=null;
                 }"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  name: "BenefitPeriodAvailability",
  components: {
    'datepicker': Datepicker
  },
  props: {
    formAction: {
      required: true,
      type: String,
      default: ''
    },
    formData: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    errorsBag: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    dateFormat: {
      required: true,
      type: String,
      default: 'dd/MM/yyyy'
    }
  },
  data: function () {
    return {
      tickDay: 0
    };
  },
  methods: {
    getMonthMaxDays() {
      if (this.formData.end_use_day_month.month === '') {
        return 0;
      } else {
        let days = [];
        let month = this.formData.end_use_day_month.month;
        let maxDays = (new Date((new Date()).getFullYear(), month, 0)).getDate();
        for (let nr = 1; nr <= maxDays; nr++) {
          days.push(nr);
        }
        return days;
      }
    },
    moment(date, format) {
      return moment(date).format(format);
    },
  }
}
</script>
