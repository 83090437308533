<template>
  <div class="container-bg-white p-3 mt-3">
    <div class="row">
      <h1 class="col-12 m-0 text-center">{{ $t('flexben.voucher_stocks.titles.import_complete') }}</h1>
    </div>
    <div class="row">
      <div class="col-12 mt-3 text-center text-success">
        {{ $t('flexben.voucher_stocks.import.summary.new_count') }} {{ summary.ok_count }}
      </div>
      <div class="col-12 mt-3 text-center text-warning">
        {{ $t('flexben.voucher_stocks.import.summary.total_update') }} {{ summary.update_count }}
      </div>

      <div class="col-12 mt-3 text-center">
        <button class="btn btn-themed" @click="newImport()"> {{$t("flexben.voucher_stocks.btns.import_restart")}}
        </button>
      </div>
      <div class="col-12 mt-3 text-center">
        <button class="btn btn-themed" @click="backToListing()"> {{$t("flexben.voucher_stocks.btns.back_to_list")}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImportStep3",
  props: {
    summary: {
      required: true,
      default: () => {
        return {
          total: 0,
          ok_count: 0,
          error_count: 0,
          update_count: 0,
        }
      },
      type: Object
    }
  },
  methods: {
    newImport() {
      this.$emit('newImport');
    },
    backToListing() {
      this.$emit('backToListing');
    }
  }
}
</script>
