<template>
  <div class="row px-3 pb-2 mt-3">
    <div class="col-12 mt-2 sub-tab-table">
      <div class="table-responsive hover-table">
        <table class="table no-min-width">
          <thead>
          <tr>
            <td>{{$t("flexben.beneficiaries.updated_at")}}</td>
            <td>{{$t("flexben.beneficiaries.fields.benefit")}}</td>
            <td>{{$t("flexben.beneficiaries.fields.contract_number")}}</td>
            <td>{{$t("flexben.beneficiaries.fields.contribution_type")}}</td>
            <td>{{$t("flexben.beneficiaries.fields.company_contribution_value")}}</td>
            <td>{{$t("flexben.beneficiaries.fields.personal_contribution_value")}}</td>
            <td>{{$t("flexben.beneficiaries.fields.action")}}</td>
          </tr>
          </thead>
          <tbody>
          <template v-if="autoTopupHistoryPPP3.length >0">
            <tr class="trhover" v-for="(row, index) in autoTopupHistoryPPP3" :key="index">
              <td>{{ row.created_at }}</td>
              <td>{{ row.benefit_name }}</td>
              <td>{{ row.contract_number }}</td>
              <td>{{ row.contribution_type }}</td>
              <td>{{ row.recurring_company_value || '-' }}</td>
              <td>{{ row.recurring_personal_value || '-' }}</td>
              <td><span class="benefit-status m-0"
                        :class="'history-status-'+row.action_type">{{ $t('flexben.general.status_type.history.' + row.action_type) }}</span></td>
            </tr>
          </template>

          <template v-else>
            <tr class="gri-cell text-center trhover">
              <td colspan="1000" class="text-center ">{{ $t("flexben.general.no_results_found") }}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabPPP3History',
  props:{
    autoTopupHistoryPPP3:{}
  }
};
</script>

