<template>
  <div class="">
    <div class="row">
      <span id="threshold_description"
            class="d-inline-block flexben-tooltip-icon ml-2 text-bold">i</span>
      <b-tooltip :target="'threshold_description'" variant="flexben" placement="topleft">
        <div class="text-left">{{$t('flexben.benefit.thresholds.tooltip', {value:'gym_membership'})}}</div>
      </b-tooltip>
      <label for="threshold" class="form-fields-required text-bold mb-0 ml-2 mr-2 col-form-label p-0">
        {{ $t('flexben.benefit.thresholds.' + 'gym_membership') }}</label>

      <b-form-checkbox id="threshold" class="float-right"
                       v-model="gymMembership"
                        disabled=""
                       switch></b-form-checkbox>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 form-group">
        <label for="delivery_type" class="col-form-label text-bold">
          {{ $t('flexben.benefit.world_class_fields.location') }}</label>
        <select class="form-control" id="delivery_type"
                v-model="locationIndex">
          <option :value="null">-</option>
          <option v-for="(location, index) in fields.locations"
                  :value="index"
                  :key="index">{{ location.level.toUpperCase() }} - {{ location.name }}
          </option>
        </select>
      </div>

      <div class="col-12 col-md-6 form-group">
        <label for="delivery_type" class="col-form-label text-bold">
          {{ $t('flexben.benefit.world_class_fields.last_day_to_order_for_next_month') }}</label>
        <input type="text" disabled :value="fields.last_day_to_order_for_next_month" class="form-control">
      </div>
    </div>
    <div v-if="location!==null" class="row">
      <div class="col-12">
        <div class="scroll-table">
          <label for="delivery_type" class="col-form-label text-bold">
            {{ $t('flexben.benefit.world_class_fields.prices') }}</label>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>{{ $t('flexben.benefit.world_class_fields.subscription_periods') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.is_local_price') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.price') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(priceData, index) of location.normal_prices" :key="index">
              <td class="text-center">{{ $t('flexben.benefit.subscription_periods.' + priceData.period_code) }}</td>
              <td class="text-center text-bold">{{ $t('flexben.general.' + (priceData.is_local_price ? 'yes' : 'no'))
                }}
              </td>
              <td class="text-center">{{ priceData.price }} Lei</td>
            </tr>
            <tr v-for="(priceData, index) of location.local_prices" :key="index">
              <td class="text-center">{{ $t('flexben.benefit.subscription_periods.' + priceData.period_code) }}</td>
              <td class="text-center text-bold">{{ $t('flexben.general.' + (priceData.is_local_price ? 'yes' : 'no'))
                }}
              </td>
              <td class="text-center">{{ priceData.price }} Lei</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="scroll-table">
          <label for="delivery_type" class="col-form-label text-bold">
            {{ $t('flexben.benefit.world_class_fields.facilities') }}</label>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>{{ $t('flexben.benefit.world_class_fields.has_gym') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.has_aerobic_studio') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.has_cycling_studio') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.has_pt') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.has_indoor_pool') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.has_outdoor_terrace') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.has_outdoor_pool') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.has_indoor_football') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.has_dry_sauna') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.has_wet_sauna') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.has_jacuzzi') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.has_pilates_studio') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.has_yoga_studio') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.has_kids_studio') }}</th>
              <th>{{ $t('flexben.benefit.world_class_fields.has_squash') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_gym')"></td>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_aerobic_studio')"></td>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_cycling_studio')"></td>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_pt')"></td>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_indoor_pool')"></td>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_outdoor_terrace')">
              </td>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_outdoor_pool')"></td>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_indoor_football')">
              </td>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_dry_sauna')"></td>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_wet_sauna')"></td>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_jacuzzi')"></td>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_pilates_studio')"></td>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_yoga_studio')"></td>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_kids_studio')"></td>
              <td class="text-center"><input type="checkbox" disabled
                                             :checked="location.available_services.includes('has_squash')"></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="scroll-table mt-3">
          <label for="delivery_type" class="col-form-label text-bold">
            {{ $t('flexben.benefit.world_class_fields.time_schedule') }}</label>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>{{ $t('flexben.general.week_days.monday') }}</th>
              <th>{{ $t('flexben.general.week_days.tuesday') }}</th>
              <th>{{ $t('flexben.general.week_days.wednesday') }}</th>
              <th>{{ $t('flexben.general.week_days.thursday') }}</th>
              <th>{{ $t('flexben.general.week_days.friday') }}</th>
              <th>{{ $t('flexben.general.week_days.saturday') }}</th>
              <th>{{ $t('flexben.general.week_days.sunday') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="text-center">{{ location.time_schedule.monday }}</td>
              <td class="text-center">{{ location.time_schedule.tuesday }}</td>
              <td class="text-center">{{ location.time_schedule.wednesday }}</td>
              <td class="text-center">{{ location.time_schedule.thursday }}</td>
              <td class="text-center">{{ location.time_schedule.friday }}</td>
              <td class="text-center">{{ location.time_schedule.saturday }}</td>
              <td class="text-center">{{ location.time_schedule.sunday }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="row mt-3">
          <div class="col-12 col-md-6">
            <label for="delivery_type" class="col-form-label text-bold">
              {{ $t('flexben.benefit.world_class_fields.phone_number') }}</label>
            <div class="form-control disabled">
              {{ location.phone_number }}
            </div>
          </div>
          <div class="col-12 col-md-6">
            <label for="delivery_type" class="col-form-label text-bold">
              {{ $t('flexben.benefit.world_class_fields.address') }}</label>
            <div class="form-control disabled">
              {{ location.address }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {BFormCheckbox, BTooltip} from "bootstrap-vue";

export default {
  name: "BenefitWorldClass",
  components: {
    'b-tooltip': BTooltip,
    "b-form-checkbox": BFormCheckbox,
  },
  props: {
    fields: {
      type: Object,
      default: function () {
        return {
          locations: {},
          last_day_to_order_for_next_month: 27,
        };
      },
      required: true
    },
    errorsBag: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
  },
  data: function () {
    return {
      locationIndex: null,
      location: null,
      gymMembership: false
    };
  },
  created() {
    this.gymMembership = this.getGymMembership();
  },
  watch: {
    locationIndex: {
      handler: function (index) {
        this.location = (index === null) ? null : this.fields.locations[index];
      }
    }
  },
  methods: {
    getGymMembership() {
      if (!Object.values(this.fields.thresholds).length) {
        return false;
      }
      return this.fields.thresholds.find(function (item) {return item.name === 'gym_membership'}) ?
        this.fields.thresholds.find(function (item) {return item.name === 'gym_membership'})['value'] : false
    }
  }
}
</script>
