<template>
  <div class="mt-5">
    <div class="row">
      <div class="col-12 text-center" v-if="importFinish.status==='fail'" :key="'import_status'">
        <p class="text-36 text-bold">{{ $t('flexben.import.complete.fail') }}</p>
        <p>{{ $t('flexben.import.complete.fail_text') }}</p>
      </div>
      <div class="col-12 text-center" v-else :key="'import_status'">
        <p class="text-36 text-bold">{{ $t('flexben.import.complete.thank_you') }}</p>


        <p v-if="importFinish.type ==='beneficiaries' && typeof importFinish.data.summary !=='undefined'"
           v-html="$t(('flexben.import.complete.beneficiary.'+(importFinish.data.summary.total_update_items > 0 ? 'text_1' : 'text_1_no_update')), {
             total: importFinish.data.summary.total_items,
             updated: importFinish.data.summary.total_update_items,
           })"></p>
        <p v-if="importFinish.type ==='update_beneficiary' && typeof importFinish.data.summary !=='undefined'"
           v-html="$t(('flexben.import.complete.update_beneficiary.'+(importFinish.data.summary.total_update_items > 0 ? 'text_1' : 'text_1_no_update')), {
             total: importFinish.data.summary.total_items,
             updated: importFinish.data.summary.total_update_items,
           })"></p>
        <p v-if="importFinish.type ==='budgets' && typeof importFinish.data.summary !=='undefined'"
           v-html=" $t(('flexben.import.complete.budget.'+(importFinish.data.summary.total_update_items > 0 ? 'text_1' : 'text_1_no_update')), {
           total: importFinish.data.summary.total_items,
           updated: importFinish.data.summary.total_update_items,
           })"></p>
        <p v-if="importFinish.type ==='budgets' && typeof importFinish.data.summary !=='undefined'"
           v-html="$t('flexben.import.complete.budget.text_2',{sum:importFinish.total_budget})"></p>
        <p v-if="importFinish.type ==='time_sheets'"
           v-html="$t(('flexben.import.complete.time_sheet.'+(importFinish.data.summary.total_update_items > 0 ? 'text_1' : 'text_1_no_update')), {
             total: importFinish.data.summary.total_items,
             updated: importFinish.data.summary.total_update_items,
           })"></p>

        <p>{{ $t('flexben.import.complete.review_text_2',{type:$t('flexben.import.types.'+importFinish.type)}) }}</p>
        <p>{{ $t('flexben.import.complete.review_text_3') }}</p>
      </div>

    </div>
    <div class="row mt-5">
      <div class="col-12 col-lg-8 offset-lg-2">
        <div class="d-flex flex-column flex-lg-row align-content-center align-items-center">
          <div class="mx-auto ml-md-auto mt-2">
             <a href="/multibeneficii/import-history" class="btn btn-themed">
            {{ $t('flexben.import.buttons.go_to_import_history') }}</a>
          </div>
          <div class="mx-auto mx-md-2 mt-2">
             <a href="/multibeneficii/beneficiaries" class="btn btn-themed ">

            {{ $t('flexben.import.buttons.go_to_beneficiary') }}</a>
          </div>
          <div class="mx-auto mr-md-auto mt-2">
            <button class="btn btn-themed mx-auto"

                  @click="$emit('newImport')">
            {{ $t('flexben.import.buttons.new_import') }}
          </button>
          </div>



        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompleteImport",
  props: {
    importFinish: {
      required: true,
      type: Object,
      default: function () {
        return {status: 'success'}
      }
    }
  }
}
</script>
<style lang="scss">
.btn-finish {
  width: 45%;
  margin: auto;
  text-align: center;
  @media (min-width: 1001px) and (max-width: 1350px) {

  }
}
</style>
