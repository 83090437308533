<template>
  <div class="px-3 mt-3">
    <div class="row">
      <div class="col-12 text-24 mb-3">
        {{$t("flexben.clients.partial.up_dejun.title_fixed_values")}}
      </div>
      <div class="col-12 col-lg-6 col-xl-4">
        <div class="mt-1 pr-1 d-inline-flex align-items-center">
          <label for="fixed_value_toggle" class="d-inline-block ml-auto mb-0 mr-4 col-form-label p-0">
            {{$t("flexben.clients.partial.up_dejun.fixed_values")}}
          </label>
          <b-form-checkbox id="fixed_value_toggle" class="d-inline-block"
                           switch
                           v-model="fixedValues.enable">
          </b-form-checkbox>
        </div>

        <div class="mt-3 pr-1 d-inline-flex align-items-center">
          <label for="top_up_set_ticket_nr" class="d-inline-block ml-auto mb-0 mr-4 col-form-label p-0">
            {{$t("flexben.clients.partial.up_dejun.top_up_set_ticket_nr")}}
          </label>
          <b-form-checkbox id="top_up_set_ticket_nr" class="d-inline-block"
                           switch
                           v-model="topUpSetTicketNr.enable">
          </b-form-checkbox>
        </div>

        <div class="mt-3 pr-1 d-inline-flex align-items-center">
          <label for="divide_budget_for_ticket_value" class="d-inline-block ml-auto mb-0 mr-4 col-form-label p-0">
            {{$t("flexben.clients.partial.up_dejun.divide_budget_for_ticket_value")}}
          </label>
          <b-form-checkbox id="divide_budget_for_ticket_value" class="d-inline-block"
                           switch
                           v-model="divideBudgetForTicketValue.enable">
          </b-form-checkbox>

          <div class="ml-2">
            <span id="divide_budget_for_ticket_value_tooltip"
                  class="d-inline-block flexben-tooltip-icon ml-2 text-bold">i</span>
            <b-tooltip :target="'divide_budget_for_ticket_value_tooltip'" variant="flexben" placement="topleft">
              <div v-html="$t('flexben.clients.partial.up_dejun.tooltips.divide_budget_for_ticket_value')"
                   class="text-left"></div>
            </b-tooltip>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl-4" v-if="fixedValues.enable">
        <button class="btn py-0  btn-themed mr-2"
                @click="addValue()">
          {{ $t("flexben.general.btn.add") }}
          <font-awesome-icon icon="plus"/>
        </button>
        <span class="text-bold">{{$t("flexben.clients.partial.up_dejun.fixed_values_info",{min:minValue, max:maxValue})}}</span>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group" v-if="fixedValues.enable">
        <div v-for="(obj, index) in fixedValues.values" :key="index" class="mb-1">
          <div class="w-100 d-inline-flex align-items-center">
            <input type="number"
                   v-model.number="fixedValues.values[index]"
                   :min="minValue"
                   :max="maxValue"
                   @input="validateValue(index)"
                   :class="{'border-danger':invalidValuesIndexes.includes(index)}"
                   class="form-control mr-1 bg-white">
            <img class="cursor-pointer" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                 @click="removeValue(index)"/>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group" v-if="topUpSetTicketNr.enable">
        <label for="ticket_value">{{$t("flexben.clients.partial.up_dejun.fixed_values_info_singular",{min:minValue,
          max:maxValue})}}</label>
        <input type="number"
               id="ticket_value"
               v-model.number="topUpSetTicketNr.ticket_value"
               :min="minValue"
               :max="maxValue"
               :class="{'border-danger':(topUpSetTicketNr.ticket_value < minValue || topUpSetTicketNr.ticket_value >maxValue)}"
               class="form-control mr-1 bg-white">
      </div>
    </div>
  </div>
</template>

<script>
import {BFormCheckbox, BTooltip} from "bootstrap-vue";

export default {
  components: {
    "b-form-checkbox": BFormCheckbox,
    "b-tooltip": BTooltip,
  },
  name: "UpDejunFixedValues",
  props: {
    fixedValues: {
      type: Object,
      default: function () {
        return {
          enable: false,
          values: [],
        };
      },
      required: true
    },
    topUpSetTicketNr: {
      type: Object,
      default: function () {
        return {
          enable: false,
          ticket_value: null,
        };
      },
      required: true
    },
    divideBudgetForTicketValue: {
      type: Object,
      default: function () {
        return {
          enable: false,
        };
      },
      required: true
    },
    minValue: {
      type: Number,
      default: 0,
      required: true
    },
    maxValue: {
      type: Number,
      default: 0,
      required: true
    },
    isAdmin: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  data: function () {
    return {
      invalidValuesIndexes: [],
      fixedValuesSettings: {}
    };
  },
  created() {
    this.fixedValuesSettings = this.fixedValues;
  },
  watch: {
    fixedValues: {
      handler: function (val) {
        if (val.enable) {
          this.topUpSetTicketNr.enable = false;
        }
      },
      deep: true
    },
    topUpSetTicketNr: {
      handler: function (val) {
        if (val.enable) {
          this.fixedValues.enable = false;
          this.divideBudgetForTicketValue.enable = false;
        }
      },
      deep: true
    },
  },
  methods: {
    addValue() {
      let index = this.fixedValues.values.length;
      this.invalidValuesIndexes[index] = index;
      this.fixedValues.values.push('');
    },
    removeValue(index) {
      this.fixedValues.values.splice(index, 1);
    },
    validateValue(index) {
      let value = this.fixedValues.values[index];
      if (value && (
        value < this.minValue || value > this.maxValue
      )) {
        this.invalidValuesIndexes[index] = index;
      } else {
        this.invalidValuesIndexes.splice(index, 1);
      }
    }
  }
}
</script>
