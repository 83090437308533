<template>
  <div class=" mt-3 container-bg-white">
    <div class="row p-3">
      <div class="col-12 text-center">
        <h1 class="py-5">Multumim</h1>
        <div class="mt-3"
        v-html="$t('flexben.requests.success.line_1',{count:countPlacedOrders, scope:$t('flexben.requests.success.scope_'+scope)})">

        </div>
        <div class="mt-3">
          {{ isAdmin ? $t("flexben.requests.success.line_2") : $t("flexben.requests.success.line_2_billing") }}
        </div>
      </div>
      <div class="col-12 col-md-8 col-xl-6 mt-5 mx-auto d-flex ">
        <button class="btn btn-themed-blue px-4" @click="reStartFlow()">{{ $t("flexben.requests.btn.back_to_requests") }}</button>
        <a class="btn btn-themed px-4 ml-sm-auto" href="/multibeneficii/orders" :key="'btn'" v-if="isAdmin"> {{ $t("flexben.order.orders") }}</a>
        <a class="btn btn-themed px-4 ml-sm-auto" href="/customer/orders" :key="'btn'" v-else> {{ $t("flexben.order.orders") }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessPlaceOrders",
  props: {
    countPlacedOrders: {
      type: Number,
      default: 0,
      required: true,
    },
    scope: {
      type: String,
      default: '',
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    reStartFlow() {
      this.$emit('reStartFlow');
    }
  }
}
</script>

