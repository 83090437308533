<template>
  <b-modal id="modal-1"
           ref="confirm-order-modal"
           :hide-header-close="true"
           headerClass="border-0 text-center"
           :bodyClass="(isAdmin && confirmAction==='order') ? 'py-1' : 'd-none'"
           footerClass="modal-footer border-top-0"
           centered>
    <template v-slot:modal-header>
      <h3 class="mx-auto mb-0" v-if="confirmAction==='order'" key="1">{{
        $t('flexben.requests.modal.confirm_mass_place_order')}}</h3>
      <h3 class="mx-auto mb-0" v-if="confirmAction==='new'" key="1">{{
        $t('flexben.requests.modal.confirm_mass_place_order_status_new')}}</h3>
      <h3 class="mx-auto mb-0" v-else-if="confirmAction==='mark_pending'" key="1">{{
        $t('flexben.requests.modal.confirm_mark_pending')}}</h3>
    </template>

    <template v-if="isAdmin && confirmAction==='order'" v-slot:default>
      <div v-if="showNonLegislatedMessage">
        {{$t('flexben.requests.modal.info_non_legislated_recurring')}}
      </div>
    </template>
      <template v-slot:modal-footer>
      <button class="btn text-themed mr-auto" @click="closeModal()">{{ $t('flexben.general.btn.close') }}</button>
      <button class="btn btn-themed" @click="placeOrder()">{{ $t('flexben.general.btn.confirm') }}</button>
    </template>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";

export default {
  name: "ConfirmMassPlaceOrderModal",
  components: {
    'b-modal': BModal
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    showNonLegislatedMessage:{
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      confirmAction: null
    };
  },
  methods: {
    openModal(confirmAction) {
      this.confirmAction = confirmAction;
      this.$refs['confirm-order-modal'].show();
    },
    closeModal() {
      this.confirmAction = null;
      this.$refs['confirm-order-modal'].hide();
    },
    placeOrder() {
      this.$emit('placeOrder', this.confirmAction);
      this.confirmAction = null;
    }
  }
}
</script>
