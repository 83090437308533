<template>
  <div>
      <section class="container-bg-white mt-3">
        <div class="row px-3 pt-3">
          <div class="col-12">
            <h3 class="m-0 p-0">{{$t("flexben.requests.title_specific_info")}}</h3>
          </div>
        </div>
        <div class="row px-3 py-2">
          <div class="col-12 d-flex">
            <img class="partner-badge mr-2" src="@/assets/images/flexben/utils/info.png"
                 alt="company_icon">
            <div class="text-info d-flex align-items-center">{{$t("flexben.requests.await_callback.message")}}</div>
          </div>
        </div>
      </section>
      <div class="row mt-3">
        <div class="col-12 text-right">
          <button  class="btn text-danger mb-1"  @click="openRejectModal()">
            <font-awesome-icon icon="trash" class="mr-1"/>
            {{ $t("flexben.requests.btn.reject_request") }}
          </button>
          <button  class="btn btn-themed-o mr-1 mb-1" @click="setOrderNew()">
            {{ $t("flexben.requests.btn.mark_new") }}
          </button>
        </div>
      </div>
    <RejectModal :rejectData="rejectData"
                 :showDisableTopUps="false"
                 @confirmAction="rejectRequests()"
                 ref="reject-modal"/>
    <ConfirmMassPlaceOrderModal @placeOrder="placeOrder" ref="confirm_po_modal"/>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import RejectModal from "@/components/flexben/requests/modals/RejectModal.vue";
import ConfirmMassPlaceOrderModal from "@/components/flexben/requests/modals/ConfirmMassPlaceOrderModal.vue";
import httpFlexbenService from "@/http/http-flexben";


export default {
  name: "AwaitCallbackRequestDetails",
  components: {
    RejectModal,
    ConfirmMassPlaceOrderModal
  },
  props: {
    detailsId: {
      required: true,
      type: Number
    }
  },
  data: function () {
    return {
      initialLoading: true,
      loading: false,
      loaderError: false,
      httpService: httpFlexbenService.requests,
      rejectData: {
        ids: [],
        reject_reason: ''
      },
    };
  },
  created() {
    this.rejectData.ids.push(this.detailsId);
  },
  methods: {
    placeOrder() {
      this.$refs['confirm_po_modal'].closeModal();
      this.loading = true;
      let payload = {
        details_id: this.detailsId
      };
      this.httpService.orderTopUpMarkNew(payload)
        .then((response) => {
          this.loading = false;
          if (response.data.status === 'success') {
            this.goBack(true);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.loading = false;
            this.scrollToTop();
          } else {
            this.loading = false;
            this.loaderError = true;
          }
        });
      this.goBack(true);
    },
    rejectRequests() {
      this.loading = true;
      this.$refs['reject-modal'].closeModal();
      let payload = {...this.rejectData};

      this.httpService.rejectRequests(payload)
        .then(function (response) {
          this.loading = false;
          this.goBack(true);
        }.bind(this))
        .catch(function (error) {
          if (500 <= error.response.status && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false;
          }
        }.bind(this));
    },
    openRejectModal() {
      this.$refs['reject-modal'].openModal();
    },
    setOrderNew () {
      this.$refs['confirm_po_modal'].openModal('new');
    },
    goBack(withReRender = false) {
      this.$emit('hideDetails', withReRender)
    },
  }

}
</script>
