<template>
  <div v-show="!initialLoading" class="col-12">
    <div class="container-bg-white">
      <div class="row p-3" id="filterGroup">
        <div class="col-12 col-xl-3 mb-2 mb-xl-0">
          <img src="@/assets/images/filter.svg"/>
          <span class="ml-3">{{$t("flexben.general.filter")}}</span>
        </div>
        <div class="col-12 col-xl-9">
          <div class="row">
            <div class="col-12 col-md-4 form-group mb-0">
              <label for="budget_at_date" class="my-auto mr-2 col-form-label">{{ $t("flexben.reports.unconsumed_budget.fields.date") }}
                <font-awesome-icon icon="calendar-alt" class="text-24"/>
              </label>
              <div class="w-100 d-inline-flex">
                <vue-datepicker :format="dateFormat"
                                :inputClass="'form-control-d m-0 w-100 background-white'"
                                v-model="modelData.date"
                                valueType="format"
                                :class="'w-100'"
                                key="date"
                                id="date">
                </vue-datepicker>
                <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                     @click="function (){date=null;}"/>
              </div>
            </div>
            <div class="col-12 col-md-4 mb-0" v-if="isAdmin">
              <label for="select_company" class="col-form-label">{{ $t("flexben.reports.filters.client") }}</label>
              <multiselect :multiple="false"
                           :class="{'from-control':true}"
                           label="name"
                           trackBy="value"
                           id="select_company"
                           openDirection="auto"
                           :closeOnSelect="true"
                           :searchable="true"
                           :placeholder="$t('flexben.general.search')"
                           v-model="modelData.company_id"
                           :options="filterOptions.companies"
                           ref="multiselect_companies">
              </multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import utils from "@/plugins/utils";

export default {
  name: "UnconsumedBudgetReport",
  components: {
    'vue-datepicker': Datepicker,
    Multiselect
  },
  props: {
    isAdmin: {
      required: true,
      default: false
    },
    company: {
      required: true,
      default: null
    },
  },
  data: function () {
    return {
      initialLoading: true,
      httpService: httpFlexbenService.reports.unconsumed_budget,
      dateFormat: 'dd/MM/yyyy',
      momentFormat: 'YYYY-MM-DD',
      filterOptions: {
        companies: [],
      },
      modelData: {
        date: null,
        company_id: null,
        lang: this.$store.state.language
      },
      errors: {
        show: false,
        message: null,
      }
    }
  },
  watch: {
    'modelData.date': {
      handler: function (val) {
        this.modelData.date = (val === null) ? null : moment(val).format(this.momentFormat);
      }
    },

  },
  created() {
    if (this.isAdmin) {
      this._getOptions()
    } else {
      this.modelData.company_id = this.company;
      this.initialLoading = false;
      this._toggleLoading(false);
    }
  },
  methods: {
    _getOptions() {
      this._toggleLoading(true);
      this.httpService.getOptions()
        .then((response) => {
          this.filterOptions.companies = response.data.companies;
          this.initialLoading = false;
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        });
    },
    _validateFilters() {
      this.errors.show = false;
      this.errors.message = null;
      if (this.modelData.date === null) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.no_date_selected_unconsumed_budget');
      }

      if (this.modelData.company_id === null) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.no_client_selected');
      }

      this.$emit('setErrors', this.errors.show, this.errors.message);
    },
    download() {
      this._validateFilters();
      if (this.errors.show) {
        return;
      }
      if(!this.company){
        this.modelData.company_id = this.modelData.company_id.id;
      }
      let payload = {...this.modelData}
      payload.lang = this.$store.state.language;

      this._toggleLoading(true);
      this.httpService.download(payload)
        .then((response) => {
          if (response.data.success === false) {
            this._toggleLoading(false);
            return;
          }

          utils.generateDownloadFromReponse(response);
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        });
    },
    _toggleLoading(loading, loaderError = null) {
      this.$emit('toggleLoading', loading, loaderError);
    }
  },
}
</script>
