<template>
  <div class="row">
    <div class="col-12 col-md-6 form-group">
      <label for="min_order_value" class="col-form-label">{{ $t('flexben.benefit.fields.min_order_value')
        }}</label>
      <input id="min_order_value" type="number" class="form-control" min="0" v-model="fields.min_order_value">
    </div>
    <div class="col-12 text-danger">{{ $t('flexben.benefit.fields.info_contract_number') }}</div>
  </div>
</template>

<script>
export default {
  name: "BenefitMedicalInsurance",
  props: {
    fields: {
      type: Object,
      default: function () {
        return {min_order_value: 100};
      },
      required: true
    },
    errorsBag: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    }
  },
}
</script>
