 <template>
  <div class="container-bg-white">
    <slot name="summary_top"></slot>
    <div class="row p-3">
      <div class="col-12 col-xl-3 mb-2 mb-xl-0">
        <img src="@/assets/images/filter.svg"/>
        <span class="ml-3">{{$t("flexben.general.filter")}}</span>
      </div>
      <div class="col-12 col-md-4 col-xl-3 form-group-multiselect ml-xl-auto mb-0">
        <label for="benefit" class="col-form-label pt-md-0">{{ $t("flexben.voucher_stocks.fields.benefit") }}</label>
        <multiselect :multiple="false"
                     :showLabels="false"
                     :class="'from-control'"
                     track-by="value"
                     value-field="value"
                     label="text"
                     id="benefit"
                     :closeOnSelect="true"
                     :allow-empty="true"
                     :placeholder="$t('flexben.general.search')"
                     v-model="filtersSpecial.benefit"
                     :options="filterOptions.benefits"
                     :key="'benefit_select'">
          <template slot="noResult">{{$t('flexben.general.no_results_found')}}</template>
        </multiselect>
      </div>
      <div class="col-12 col-md-4 col-xl-3 form-group-multiselect mb-0">
        <label for="partner" class="col-form-label pt-md-0">{{ $t("flexben.voucher_stocks.fields.partner") }}</label>
        <multiselect :multiple="false"
                     :showLabels="false"
                     :class="'from-control'"
                     track-by="value"
                     label="text"
                     id="partner"
                     :closeOnSelect="true"
                     :allow-empty="true"
                     :placeholder="$t('flexben.general.search')"
                     v-model="filtersSpecial.partner"
                     :options="filterOptions.partners"
                     :key="'partner_select'">
          <template slot="noResult">{{$t('flexben.general.no_results_found')}}</template>
        </multiselect>
      </div>
      <div class="col-12 col-md-4 col-xl-3 form-group mb-0">
        <label for="status" class="col-form-label pt-md-0">{{ $t("flexben.voucher_stocks.fields.status") }}</label>
        <select id="status" v-model="filters.status"
                class="form-control">
          <option v-for="(item,index) of filterOptions.statuses" :value="item" :key="index">{{ $t(statusTransPatch+item)
            }}
          </option>
        </select>
      </div>
      <div class="col-12 col-md-6 form-group mb-0">
        <label class="col-form-label" for="request_end_date">{{$t("flexben.voucher_stocks.inputLabels.startCreatedAt")}}</label>
        <div class="w-100 d-inline-flex">
          <label for="voucher_created_start_date" class="my-2 mr-2">
            <font-awesome-icon icon="calendar-alt" class="text-24"/>
          </label>
          <vue-datepicker :format="dateFormat"
                          :inputClass="'form-control-d m-0 w-100 background-white'"
                          v-model="filters.startCreatedAt"
                          :class="'w-100'"
                          :key="2"
                          @input="filterDate('startCreatedAt')"
                          @cleared="filterDate('startCreatedAt',true)"
                          id="voucher_created_start_date"></vue-datepicker>
          <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
               @click="filterDate('startCreatedAt',true)"/>
        </div>
      </div>
      <div class="col-12 col-md-6 form-group mb-0">
        <label class="col-form-label" for="request_end_date">{{$t("flexben.voucher_stocks.inputLabels.endCreatedAt")}}</label>
        <div class="w-100 d-inline-flex">
          <label for="voucher_created_end_date" class="my-2 mr-2">
            <font-awesome-icon icon="calendar-alt" class="text-24"/>
          </label>
          <vue-datepicker :format="dateFormat"
                          :inputClass="'form-control-d m-0 w-100 background-white'"
                          v-model="filters.endCreatedAt"
                          :class="'w-100'"
                          :key="2"
                          @input="filterDate('endCreatedAt')"
                          @cleared="filterDate('endCreatedAt',true)"
                          id="voucher_created_start_date"></vue-datepicker>
          <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
               @click="filterDate('endCreatedAt',true)"/>
        </div>
      </div>
      <div class="col-12 col-md-6 form-group mb-0">
        <label class="col-form-label" for="request_end_date">{{$t("flexben.voucher_stocks.inputLabels.startPlacedAt")}}</label>
        <div class="w-100 d-inline-flex">
          <label for="voucher_placed_at_start_date" class="my-2 mr-2">
            <font-awesome-icon icon="calendar-alt" class="text-24"/>
          </label>
          <vue-datepicker :format="dateFormat"
                          :inputClass="'form-control-d m-0 w-100 background-white'"
                          v-model="filters.startPlacedAt"
                          :class="'w-100'"
                          :key="2"
                          @input="filterDate('startPlacedAt')"
                          @cleared="filterDate('startPlacedAt',true)"
                          id="voucher_placed_at_start_date"></vue-datepicker>
          <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
               @click="filterDate('startPlacedAt',true)"/>
        </div>
      </div>
      <div class="col-12 col-md-6 form-group mb-0">
        <label class="col-form-label" for="request_end_date">{{$t("flexben.voucher_stocks.inputLabels.endPlacedAt")}}</label>
        <div class="w-100 d-inline-flex">
          <label for="voucher_placed_at_end_date" class="my-2 mr-2">
            <font-awesome-icon icon="calendar-alt" class="text-24"/>
          </label>
          <vue-datepicker :format="dateFormat"
                          :inputClass="'form-control-d m-0 w-100 background-white'"
                          v-model="filters.endPlacedAt"
                          :class="'w-100'"
                          :key="2"
                          @input="filterDate('endPlacedAt')"
                          @cleared="filterDate('endPlacedAt',true)"
                          id="voucher_placed_at_end_date"></vue-datepicker>
          <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
               @click="filterDate('endPlacedAt',true)"/>
        </div>
      </div>
    </div>
    <slot name="summary_bottom"></slot>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment/moment";

export default {
  name: "Filters",
  components: {
    'vue-datepicker': Datepicker,
  },
  props: {
    filterOptions: {
      type: Object,
      default: () => {
        return {
          benefits: [],
          partners: [],
          statuses: [],
          startCreatedAt: null,
          endCreatedAt: null,
          startPlacedAt: null,
          endPlacedAt: null,
        }
      },
      required: true
    },
    filters: {
      type: Object,
      default: () => {
        return {
          benefit: null,
          partner: null,
          status: 0,
          search: null,
          startCreatedAt: null,
          endCreatedAt: null,
          startPlacedAt: null,
          endPlacedAt: null,
          per_page: 20,
          page: 1
        }
      },
      required: true
    },
    statusTransPatch: {
      type: String,
      default: "flexben.voucher_stocks.statuses."
    }
  },
  data() {
    return {
      filtersSpecial: {
        benefit: null,
        partner: null,
      },
      startCreatedAt: null,
      endCreatedAt: null,
      startPlacedAt: null,
      endPlacedAt: null,
      dateFormat: 'dd/MM/yyyy',
    };
  },
  watch: {
    filtersSpecial: {
      handler: function (v) {
        this.filters.benefit = (v.benefit !== null) ? v.benefit.value : null
        this.filters.partner = (v.partner !== null) ? v.partner.value : null
      },
      deep: true
    },
  },
  methods: {
    filterDate(key, clearDate = false) {
      if (clearDate) {
        this.filters[key] = null;
      } else {
        this.filters[key] = this.moment(this.filters[key], 'yyyy-MM-DD');
      }
    },
  }
}
</script>
