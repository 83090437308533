<template>
  <div>
    <div v-if="!initialLoading">
      <section class="container-bg-white mt-3">
        <div class="row px-3 pt-3">
          <div class="col-12">
            <h3 class="m-0 p-0">{{$t("flexben.requests.error_message")}}</h3>
          </div>
        </div>
        <div class="row px-3 py-2">
          <div class="col-12 d-flex">
            <img class="partner-badge mr-2" src="@/assets/images/flexben/utils/error.png"
                 alt="company_icon">
            <div class="text-danger d-flex align-items-center">{{error_message}}</div>
          </div>
        </div>
      </section>
      <OrderRequestDetails
        :employee="requestData.company_employee"
        :error_code="this.error_code"
        :form-data="formData"
        @getError="handleErrorPlacingOrder"
      />
      <div class="row mt-3">
        <div class="col-12 text-right">
          <button  class="btn text-danger mb-1"  @click="openRejectModal()">
            <font-awesome-icon icon="trash" class="mr-1"/>
            {{ $t("flexben.requests.btn.reject_request") }}
          </button>
          <button  class="btn btn-themed-o mr-1 mb-1" @click="setOrderNew()">
            {{ $t("flexben.requests.btn.mark_new") }}
          </button>
        </div>
      </div>
    </div>
    <RejectModal :rejectData="rejectData"
                 :showDisableTopUps="false"
                 @confirmAction="rejectRequests()"
                 ref="reject-modal"/>
    <ConfirmMassPlaceOrderModal @placeOrder="placeOrder" ref="confirm_po_modal"/>
    <confirm-modal :openModal="modal.open"
                   :modalAction="modal.action"
                   :formSuccess="modal.formSuccess"
                   :endMessage="modal.endMessage"
                   :options="modal.options"
                   @closeModal="closeModal"
                   @confirmAction="confirmAction"/>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import RejectModal from "@/components/flexben/requests/modals/RejectModal.vue";
import OrderRequestDetails from "@/components/flexben/requests/top_up/OrderRequestDetails";
import ConfirmMassPlaceOrderModal from "@/components/flexben/requests/modals/ConfirmMassPlaceOrderModal";
import ConfirmModal from "@/components/flexben/offers/modals/ConfirmModal.vue";

export default {
  name: "ValidationFailedRequestDetails",
  components: {
    'confirm-modal': ConfirmModal,
    OrderRequestDetails,
    RejectModal,
    ConfirmMassPlaceOrderModal
  },
  props: {
    detailsId: {
      required: true,
      type: Number
    }
  },
  data: function () {
    return {
      initialLoading: true,
      loading: false,
      params: {},
      loaderError: false,
      httpService: httpFlexbenService.requests,
      requestData: {},
      error_message: {},
      error_code: {},
      rejectData: {
        ids: [],
        reject_reason: ''
      },
      errorPlacingOrder: null,
      invalidOrderReason: null,
      formData: {
        company_employee_id :null,
        phone_number: null,
        cnp: null,
        email: null,
        cod_address: null,
      },
      modal: {
        action: '',
        subOperation: '',
        open: false,
        formSuccess: false,
        endMessage: '',
        options: {
          saveAs: '',
          statusChange: '',
        }
      },
    };
  },
  created() {
    this.rejectData.ids.push(this.detailsId);
    this._getRequestDetails();
  },
  methods: {
    goBack(withReRender = false) {
      this.$emit('hideDetails', withReRender)
    },
    closeModal() {
      this.modal.action = '';
      this.modal.options.statusChange = '';
      this.modal.options.saveAs = '';
      this.modal.open = false;
    },
    confirmAction(action, rejectReason = null) {
      this.modal.endMessage = '';
      if (action === 'go_back') {
        this._clearForm();
        this.goBack();
      }
      if (action === 'rejected') {
        this.formData.reason = rejectReason;
        this.modal.open = false;
        this._updateItem();
        this.closeModal();
      }
      if (action === 'form_submit') {
        this.loading = true;
        this.errorBag = {};
        this.modal.open = false;
        if (this.modal.action === 'form_submit') {
          if (this.formAction === 'update') {
            this._updateItem();
          } else {
            this._createItem();
          }
        }
      }
      if (this.modal.action === 'delete') {
        this._deleteItem();
      }
      if (action === 'end') {
        this.closeModal();
        if (this.modal.formSuccess) {
          this._clearForm();
          this.$emit('clearForm', true);
        }
      }
    },
    _getRequestDetails() {
      this.loading = true;
      this.params = {
        details_id :this.detailsId,
        error_type : 'validation_failed'
      };
      this.httpService.getOrderRequestDetails(this.params)
        .then((response) => {
          this.requestData = response.data.details;
          this.error_message = response.data.error.message;
          this.error_code = response.data.error.internal_key;
          this.formData.company_employee_id = this.requestData.company_employee.id
          this.formData.phone_number = this.requestData.company_employee.phone_number
          this.formData.cnp = this.requestData.company_employee.cnp
          this.formData.email = this.requestData.company_employee.invitation_email
          this.loading = false;
          this.initialLoading = false;
          localStorage.setItem("form_data",  JSON.stringify(this.formData));

        })
        .catch((error) => {
           this.goBack();
        });
    },
    openRejectModal() {
      this.$refs['reject-modal'].openModal();
    },
    setOrderNew () {
      this.$refs['confirm_po_modal'].openModal('new');
    },
    handleErrorPlacingOrder (data) {
      this.errorPlacingOrder = data
    },
    rejectRequests() {
      this.loading = true;
      this.$refs['reject-modal'].closeModal();
      let payload = {...this.rejectData};

      this.httpService.rejectRequests(payload)
        .then(function (response) {
          this.loading = false;
          this.goBack(true);
        }.bind(this))
        .catch(function (error) {
          if (500 <= error.response.status && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false;
          }
        }.bind(this));
    },
    _preparePayload() {
      let formData = new FormData();
      formData.append('phone_number', this.formData.phone_number);
      formData.append('company_employee_id', this.formData.company_employee_id);
      formData.append('cnp', this.formData.cnp);
      formData.append('invitation_email', this.formData.email);
      return formData;
    },
    validatePlaceOrder () {
      let error = false;
      let retrievedObject = localStorage.getItem('form_data');
      if (this.errorPlacingOrder === 'phone_error' && JSON.parse(retrievedObject)['phone_number'] !== this.formData.phone_number) {
        error = true;
      }
      if (this.errorPlacingOrder === 'cnp_error' && JSON.parse(retrievedObject)['cnp'] !== this.formData.cnp) {
        error = true;
      }
      return error;
    },
    placeOrder() {
      this.$refs['confirm_po_modal'].closeModal();
      let payload = this._preparePayload();
      if (this.validatePlaceOrder()) {
        this.loading = true;
          this.invalidOrderReason = null;
          this.httpService.orderTopUpFormMarkNew(this.detailsId, payload)
            .then((response) => {
              this.loading = false;
              if (response.data.status === 'success') {
                this.goBack(true);
              }
            })
            .catch((error) => {
              if (error.response.status === 400) {
                this.modal.formSuccess = false;
                this.modal.endMessage = this.$t('flexben.general.validation_errors');
                this.modal.action = 'end';
                this.loading = false;
                this.modal.open = true;
                this.scrollToTop();
              } else {
                this.loading = false;
                this.loaderError = true;
              }
            });
        this.goBack(true);
        localStorage.removeItem("form_data");
      } else {
        this.scrollToTop();
      }

    }
  }
}
</script>
