<template>
  <b-tab :title="configuration.benefit.name" :active="activeTab" :key="tabIndex"
         :disabled="(configuration.benefit.status !=='ACTIVE')"
         :class="'container-bg-white pt-2 pb-1'">
    <div v-if="(configuration.benefit.status ==='ACTIVE')">
      <div class="row mx-2 pt-2">
        <div class="col-6 d-inline-flex align-items-center">
          <img src="@/assets/images/logo-up.png" height="20"/>
          <span class="text-20 text-bold ml-2">{{ $t('flexben.clients.benefits.up_culture') }}</span>
        </div>
        <div class="col-6" v-if="isAdmin">
          <div class="float-right mt-1 pr-1 d-inline-flex align-items-center">
            <label for="benefit_culture_toggle" class="d-inline-block ml-auto mb-0 mr-4 col-form-label">
              {{$t("flexben.clients.partial.enable_benefit_for_client")}}
            </label>
            <b-form-checkbox id="benefit_culture_toggle" class="d-inline-block"
                             switch
                             v-model="configuration.is_active">
            </b-form-checkbox>
          </div>
        </div>
      </div>
      <div class="row mt-4 mx-3">
        <div class="col-12">
          <div class="row benefits-order-info pt-3">
            <div class="col-2">
              <img src="@/assets/images/flexben/utils/benefit-info.svg" alt=""/>
            </div>
            <div class="bold col-10">
              <p>{{$t("flexben.clients.partial.up_culture.text_1")}}</p>
              <p v-html="$t('flexben.clients.partial.up_culture.text_2')"></p>
              <p>{{$t("flexben.clients.partial.up_culture.text_3")}}</p>
            </div>
          </div>
          <div class="row benefits-order-info mt-3 pt-md-3">
            <div class="col-md-3">
              <img src="@/assets/images/flexben/utils/benefit-info.svg" alt=""/>
            </div>
            <div class="col-md-3">
              <p>{{$t("flexben.general.fiscal_treatment")}}</p>
              <p class="text-16 text-bold"
                 v-if=" Object.keys(configuration.benefit.global_variable_codes).length === 3">
                {{$t("flexben.benefit.deductibility.none")}}
              </p>
              <p class="text-16 text-bold"
                 v-else-if="Object.keys(configuration.benefit.global_variable_codes).length < 3 && Object.keys(configuration.benefit.global_variable_codes).length > 0">
                {{$t("flexben.benefit.deductibility.partial")}}
              </p>
              <p class="text-16 text-bold"
                 v-else-if=" Object.keys(configuration.benefit.global_variable_codes).length === 0">
                {{$t("flexben.benefit.deductibility.complete")}}
              </p>
            </div>
            <div class="col-md-2">
              <p>{{$t("flexben.general.income_tax")}}</p>
              <p class="text-16 text-bold">{{ configuration.benefit.global_variable_codes.includes('impozit-venit') ?
                globalVariables['impozit-venit'].value : 0 }}%</p>
            </div>
            <div class="col-md-2">
              <p>CAS</p>
              <p class="text-16 text-bold">{{ configuration.benefit.global_variable_codes.includes('cas') ?
                globalVariables['cas'].value : 0 }}%</p>
            </div>
            <div class="col-md-2">
              <p>CASS</p>
              <p class="text-16 text-bold">{{ configuration.benefit.global_variable_codes.includes('cass') ?
                globalVariables['cass'].value : 0 }}%</p>
            </div>
            <div class="col-12 my-3"></div>
            <div class="col-md-3">
              <p>{{$t("flexben.general.last_annual_date_for_orders")}}</p>
              <p class="text-16 text-bold">{{ configuration.benefit.end_use_day_month.day }}/{{
                configuration.benefit.end_use_day_month.month }}/{{
                configuration.benefit.end_use_day_month.year }}</p>
            </div>
            <p>
              {{configuration.benefit.data.values.minimum_value_per_order}}
            </p>
            <div class="col-md-3">
              <p>{{$t("flexben.benefit.fields.maximum_legal_value_per_month")}}</p>
              <p class="text-16 text-bold">{{ configuration.benefit.data.values.maximum_legal_value_per_month.value }}
                Lei</p>
            </div>
            <div class="col-md-3">
              <p>{{$t("flexben.benefit.fields.maximum_value_per_order_month")}}</p>
              <p class="text-16 text-bold">{{ configuration.benefit.data.values.maximum_value_per_order_month.value }}
                Lei</p>
            </div>
            <div class="col-md-3">
              <p>{{$t("flexben.benefit.fields.maximum_legal_value_per_event")}}</p>
              <p class="text-16 text-bold">{{ configuration.benefit.data.values.maximum_legal_value_per_event.value }}
                Lei</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 mx-1">
        <div class="col-12">
          <span class="text-28 text-bold">{{$t("flexben.clients.partial.attribute_type")}}</span>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="mt-4 d-flex">
            <label for="montlhyAttribution"
                   class="mb-0 mr-4 col-form-label p-0">{{$t("flexben.clients.partial.monthly_attribution")}}</label>
            <b-form-checkbox id="montlhyAttribution" class="ml-md-auto float-right"
                             v-model="attribution_permission_monthly"
                             switch></b-form-checkbox>
          </div>
          <div class="mt-4 d-flex">
            <label for="occasionalAttribution"
                   class="mb-0 mr-4 col-form-label p-0">{{$t("flexben.clients.partial.occasional_attribution")}}</label>
            <b-form-checkbox id="occasionalAttribution" class="ml-md-auto float-right"
                             v-model="attribution_permission_occasionally"
                             switch></b-form-checkbox>
          </div>
        </div>
      </div>
      <AutoPlaceOrderSettings :configuration="configuration.configuration" :clientSettings="clientSettings" :benefitId="configuration.benefit_id"/>
      <div class="row mt-4 mx-1">
        <div class="col-md-6">
          <span class="text-28 text-bold">
            {{ $t("flexben.clients.partial.company_specific_occasions") }}</span>
        </div>
        <div class="col-md-6">
          <button @click="openModalForm('add')"
                  class="btn btn-themed float-right">
            {{ $t("flexben.clients.partial.add_occasion") }}
          </button>
        </div>
      </div>
      <div class="row mt-4 mx-1">
        <div class="col-12">
          <div class="container-fluid px-0 m-0 sub-tab-table">
            <div class="table-responsive hover-table">
              <table class="table no-min-width" :key="tableKey">
                <thead>
                <tr>
                  <td>{{ $t("flexben.general.occasion") }}</td>
                  <td>{{ $t("flexben.general.event_date") }}</td>
                  <td>{{ $t("flexben.general.top_up_limit") }}</td>
                  <td>{{ $t("flexben.general.status") }}</td>
                  <td></td>
                </tr>
                </thead>
                <tbody>
                <template v-if="configuration.benefit.events.length > 0">
                  <tr v-for="(event, index) in configuration.benefit.events"
                      class="trhover parent" :key="index">
                    <td><span class="mobile-icon pr-2 flx-calendar-icon"><font-awesome-icon icon="calendar-alt"/></span>{{
                      event.name }}
                    </td>
                    <td>{{ (parseInt(event.event_date.day) < 10) ? "0"+event.event_date.day : event.event_date.day }}/
                      {{ (parseInt(event.event_date.month) < 10) ? "0"+event.event_date.month : event.event_date.month
                      }}/
                      {{ current_year }}
                    </td>
                    <td>{{ event.maximum_topup_limit }} Lei</td>
                    <td>
                      <span :class="event.is_active ?  'occasion-active' : 'occasion-inactive'">{{ $t('flexben.general.status_type.' + (event.is_active ? 'active':'inactive'))  }}</span>
                    </td>
                    <td>
                      <button
                        type="button" class="btn action-button"
                        @click="openModalForm('update', index)">
                        {{$t("flexben.general.btn.see_more")}}
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr class="trhover gri-cell">
                    <td colspan="5" class="text-center">{{$t("flexben.general.no_events")}}</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CompanyEventModal v-if="openModal"
                       :type="'occasion'"
                       :openModal="openModal"
                       :formData="formData"
                       :modalAction="modalAction"
                       :errorBag="errorBag"
                       @closeModal="closeModal"
                       @submitForm="submitForm"
                       @toggleStatus="toggleStatus"
    ></CompanyEventModal>
    <benefit-tax v-if="isAdmin" :taxSettings="configuration.configuration.tax_settings" :benefitId="configuration.benefit_id"></benefit-tax>
    <benefit-translations v-if="isAdmin" :translations="benefitTranslations"></benefit-translations>
  </b-tab>
</template>

<script>
import {BFormCheckbox, BTab} from "bootstrap-vue";
import httpFlexbenService from "@/http/http-flexben.js";
import CompanyEventModal from "../modals/CompanyEventModal";
import moment from "moment";
import AutoPlaceOrderSettings from "@/components/flexben/clients/partials/AutoPlaceOrderSettings";
import BenefitTranslations from "./BenefitTranslations";
import BenefitTaxSettings from "@/components/flexben/clients/partials/BenefitTaxSettings";

export default {
  components: {
    CompanyEventModal,
    "b-tab": BTab,
    "b-form-checkbox": BFormCheckbox,
    AutoPlaceOrderSettings,
    'benefit-translations': BenefitTranslations,
    'benefit-tax': BenefitTaxSettings
  },
  name: "TabUpCulture",
  props: {
    configuration: {
      type: Object,
      default: function () {
        return {
          benefit: {},
          global_variable_codes: [],
          configuration: {}
        };

      },
      required: true
    },
    benefitTranslations: {
      type: Object,
      default: function () {
        return {
          beneficiary_benefit_taxation: {}
        };

      },
      required: true
    },
    globalVariables: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
    clientSettings: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
    activeTab: {
      type: Boolean,
      default: false,
      required: true
    },
    tabIndex: {
      required: true
    },
    clientId: {
      default: null,
      required: true
    },
    methods: {
      toggleEventInactive: function (event) {
        event.is_active = !event.is_active;
      }
    },
    isAdmin: {
      type: Boolean,
      default: true,
      required: true
    },

  },
  data() {
    return {
      httpService: httpFlexbenService.clients.events,
      topLevelParent: this.$parent.$parent.$parent,
      openModal: false,
      modalAction: '',
      formData: {
        type: Object,
        default: function () {
          return {
            occasionId: null,
            occasionIndex: null,
            name: '',
            is_active: true,
            maximum_topup_limit: 0,
            date: {
              day: null,
              month: null,
              year: null,
            },
            is_birthday: false,
            gender_availability: {
              male: true,
              female: true,
            },
          };
        },
        required: true,
        allEvents: [],
      },
      errorBag: {},
      current_year: moment().year(),
      tableKey: 0,
      attribution_permission_occasionally: false,
      attribution_permission_monthly: false,
    }
  },
  watch: {
    attribution_permission_occasionally: {
      handler(val) {
        if (val) {
          this.attribution_permission_monthly = false
        }
        this.configuration.configuration.attribution_permission.occasionally = val;
      }
    },
    attribution_permission_monthly: {
      handler(val) {
        if (val) {
          this.attribution_permission_occasionally = false
        }
        this.configuration.configuration.attribution_permission.monthly = val;
      }
    },
  },
  created() {
    this.attribution_permission_occasionally = this.configuration.configuration.attribution_permission.occasionally;
    this.attribution_permission_monthly = this.configuration.configuration.attribution_permission.monthly;
  },
  computed: {
    getAllEvents: function () {
      return this.topLevelParent.formData.legislated[this.tabIndex].benefit.events;
    }
  },
  methods: {
    openModalForm(modalAction, occasionIndex = null) {
      this.errorBag = {};
      if (modalAction === 'add') {
        this.modalAction = 'add';
        this.formData = {
          occasionId: null,
          occasionIndex: null,
          name: '',
          is_active: true,
          maximum_topup_limit: 0,
          date: {
            day: null,
            month: null,
            year: null,
          },
        };

        this.openModal = true;
      }

      if (modalAction === 'update') {
        let event = this.configuration.benefit.events[occasionIndex];
        this.modalAction = 'update';
        this.formData = {
          occasionId: event.id,
          occasionIndex: occasionIndex,
          name: event.name,
          is_active: event.is_active,
          maximum_topup_limit: event.maximum_topup_limit,
          date: event.event_date,
          is_birthday: false,
          gender_availability: {
            male: true,
            female: true,
          },
        };
        this.openModal = true;
      }
    },
    closeModal() {
      this.topLevelParent.loading = false;
      this.openModal = false;
      this.errorBag = {};
      this.formData = {
        occasionId: null,
        occasionIndex: null,
        name: '',
        is_active: '',
        maximum_topup_limit: 0,
        date: {
          day: null,
          month: null,
          year: null,
        },
      };
    },
    toggleStatus() {
      this.formData.is_active = !this.formData.is_active;
    },
    submitForm() {
      this.topLevelParent.loading = true;
      this.openModal = false
      if (this.modalAction === 'add') {
        this._addOccasion();
      }
      if (this.modalAction === 'update') {
        this._updateOccasion();
      }
    },
    _addOccasion() {
      let formData = {
        is_active: this.formData.is_active,
        name: this.formData.name,
        event_date: this.formData.date,
        maximum_topup_limit: this.formData.maximum_topup_limit,
      };
      this.httpService.createEvent(this.clientId, this.configuration.benefit.id, formData)
        .then(function (response) {
          this.configuration.benefit.events.push(response.data);
          this.tableKey++; // forces re-render of table for status change
          this.topLevelParent.loading = false;
          this.closeModal();
        }.bind(this))
        .catch(function (error) {
          this.topLevelParent.loading = false;
          this.openModal = true;
          this.errorBag = error.response.data.error_fields;
        }.bind(this));
    },
    _updateOccasion() {
      let formData = {
        is_active: this.formData.is_active,
        name: this.formData.name,
        event_date: this.formData.date,
        maximum_topup_limit: this.formData.maximum_topup_limit,
      };
      let event = this.configuration.benefit.events[this.formData.occasionIndex];
      this.httpService.updateEvent(this.clientId, this.configuration.benefit.id, event.id, formData)
        .then(function (response) {
          this.configuration.benefit.events[this.formData.occasionIndex] = response.data;
          this.tableKey++; // forces re-render of table for status change
          this.topLevelParent.loading = false;
          this.closeModal();
        }.bind(this))
        .catch(function (error) {
          this.topLevelParent.loading = false;
          this.openModal = true;
          this.errorBag = error.response.data.error_fields;
        }.bind(this));
    },
  }
}
</script>
