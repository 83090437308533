<template>
  <b-tab :title="configuration.benefit.name" :key="tabIndex" :active="activeTab"
         :disabled="(configuration.benefit.status !=='ACTIVE')"
         :class="'container-bg-white py-2 pb-3'">
    <div v-if="(configuration.benefit.status ==='ACTIVE')">
      <div class="row mx-2 pt-2">
        <div class="col-6 d-inline-flex align-items-center">
          <img src="@/assets/images/logo-up.png" height="20"/>
          <span class="text-20 text-bold ml-2">{{ $t('flexben.clients.benefits.up_gift') }}</span>
        </div>
      </div>

      <div class="row mx-3 mt-4">
        <div class="col-12">
          <div class="row benefits-order-info pt-3">
            <div class="col-2">
              <img src="@/assets/images/flexben/utils/benefit-info.svg" alt=""/>
            </div>
            <div class="bold col-10">
              <p>{{$t("flexben.clients.partial.up_culture.text_1")}}</p>
              <p v-html="$t('flexben.clients.partial.up_culture.text_2')"></p>
              <p>{{$t("flexben.clients.partial.up_culture.text_3")}}</p>
            </div>
          </div>
        </div>
      </div>
      <AutoPlaceOrderSettings :configuration="configuration.configuration" :clientSettings="clientSettings" :benefitId="configuration.benefit_id"/>
      <div class="row mx-1 mt-3">
        <div class="col-12">
          <span class="text-28 text-bold">{{ $t("flexben.clients.partial.up_gift.title_config_events") }}</span>
        </div>
      </div>
      <div class="row mx-1 mt-2">
        <div class="col-12">
          <b-tabs nav-class="flexben-tabs flexben-tabs-bordered"
                  content-class="flexben-tabs-bordered">
            <template v-for="(childConfig, childIndex) in configuration.configuration.children">
              <SubTabUpLawEvents v-if="childConfig.type === 'law_events'"
                                 :childConfig="childConfig"
                                 :childIndex="childIndex"
                                 :globalVariables="globalVariables"
                                 :clientId="clientId"
                                 :parentBenefit="configuration.benefit.children.up_gift_legislated"
                                 :key="childIndex"
                                 :isAdmin="isAdmin"/>
              <SubTabUpCompanyEvents v-if="childConfig.type === 'company_events'"
                                     :childConfig="childConfig"
                                     :childIndex="childIndex"
                                     :globalVariables="globalVariables"
                                     :parentBenefit="configuration.benefit.children.up_gift_specific_events"
                                     :clientId="clientId"
                                     :key="childIndex"
                                     :isAdmin="isAdmin"/>
              <SubTabUpStim v-if="childConfig.type === 'stim'"
                            :childConfig="childConfig"
                            :childIndex="childIndex"
                            :globalVariables="globalVariables"
                            :parentBenefit="configuration.benefit.children.up_gift_s_t_i_m"
                            :key="childIndex"
                            :isAdmin="isAdmin"/>
            </template>
          </b-tabs>
        </div>
      </div>
    </div>
    <benefit-tax v-if="isAdmin" :tax-settings="configuration.configuration.tax_settings" :benefit-id="configuration.benefit_id"></benefit-tax>
    <benefit-translations v-if="isAdmin" :translations="benefitTranslations"></benefit-translations>
  </b-tab>
</template>

<script>
import {BTabs, BTab} from "bootstrap-vue";
import SubTabUpLawEvents from "./SubTabUpLawEvents";
import SubTabUpCompanyEvents from "./SubTabUpCompanyEvents";
import SubTabUpStim from "./SubTabUpStim";
import AutoPlaceOrderSettings from "@/components/flexben/clients/partials/AutoPlaceOrderSettings";
import BenefitTranslations from "./BenefitTranslations";
import BenefitTaxSettings from "@/components/flexben/clients/partials/BenefitTaxSettings";

export default {
  components: {
    SubTabUpStim,
    SubTabUpCompanyEvents,
    SubTabUpLawEvents,
    "b-tabs": BTabs,
    "b-tab": BTab,
    AutoPlaceOrderSettings,
    'benefit-translations': BenefitTranslations,
    'benefit-tax': BenefitTaxSettings
  },
  name: "TabUpGift",
  props: {
    configuration: {
      type: Object,
      default: function () {
        return {
          benefit: {},
          global_variable_codes: [],
          configuration: {}
        };

      },
      required: true
    },
    benefitTranslations: {
      type: Object,
      default: function () {
        return {
          beneficiary_benefit_taxation: {}
        };

      },
      required: true
    },
    globalVariables: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
    clientSettings: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
    activeTab: {
      type: Boolean,
      default: false,
      required: true
    },
    tabIndex: {
      required: true
    },
    clientId: {
      default: null,
      required: true
    },
    isAdmin: {
      type: Boolean,
      default: true,
      required: true
    },
  }
}
</script>
