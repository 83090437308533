<template>
  <div class="mb-5">
    <div v-show="!showDetailsForm" class="mt-3 container-bg-white">
      <Filters :isAdmin="isAdmin"
               :companyId="companyId"
               :params="params"
               :filterOptions="filter_options"
               :showExportButton="true"
               :benefitMultiselect="true"
               :showStatuses="true"
               :showPartner="true"
               @exportResults="exportResults"
               @filterValues="filterValues"/>
    </div>
    <div v-show="!showDetailsForm" class="mt-3 container-bg-white">
      <div class="row p-3">
        <div class="col-12 col-md-6">
          <h3 class="pl-0 mt-0 mb-1">{{ $t("flexben.requests.new_requests") }}</h3>
        </div>
        <div class="col-12 col-md-6">
          <input type="text"
                 v-model="searchString"
                 v-on:keyup.enter="search()"
                 :placeholder="$t('flexben.general.search')"
                 class="formedituser search-input float-right"/>
        </div>
        <div class="col-12">
          <div class="table-responsive hover-table">
            <table class="table-flx table table-striped">
              <thead>
              <tr>
                <td class="w-25-p"><input type="checkbox" id="check_all" v-model="checkedAllModel" @change="checkAll()"
                                          class="cursor-pointer"/>
                </td>
                <td class="cursor-pointer" @click="sortBy('id')">
                  {{ $t("flexben.requests.table.header.number") }}
                  <img src="@/assets/images/arrow-down-fill.svg"
                       :class="{'rotate-180':(sortByField === 'id' && reverseSort)}"/>
                </td>
                <td class="cursor-pointer" @click="sortBy('placed_at')">
                  {{ $t("flexben.requests.table.header.placed_on") }}
                  <img src="@/assets/images/arrow-down-fill.svg"
                       :class="{'rotate-180':(sortByField === 'placed_at' && reverseSort)}"/>
                </td>
                <td>{{ $t("flexben.requests.table.header.beneficiary") }}</td>
                <td class="w-120-p">{{ $t("flexben.requests.table.header.beneficiary_cnp") }}</td>
                <td v-if="isAdmin">{{ $t("flexben.requests.table.header.client") }}</td>
                <td>{{ $t("flexben.requests.table.header.benefit") }}</td>
                <td>{{ $t("flexben.requests.table.header.status") }}</td>
                <td>{{ $t("flexben.requests.table.header.partner") }}</td>
                <td>{{ $t("flexben.requests.table.header.platform_source") }}</td>
                <td class="cursor-pointer" @click="sortBy('total_value')">
                  {{ $t("flexben.requests.table.header.amount") }}
                  <img src="@/assets/images/arrow-down-fill.svg"
                       :class="{'rotate-180':(sortByField === 'total_value' && reverseSort)}"/>
                </td>
                <td>{{ $t("flexben.requests.table.header.taxes") }}</td>
                <td v-if="companySettings.cam_budget_taxation">
                  {{ $t("flexben.beneficiary_pages.requests.table.cam_value") }}
                </td>
                <td v-if="companySettings.gross_budget_taxation || companySettings.cam_budget_taxation">
                  {{ $t("flexben.beneficiary_pages.requests.table.gross_amount") }}
                </td>
                <td>{{ $t('flexben.requests.table.header.contract_number') }}</td>
                <td>{{ $t('flexben.requests.table.header.contract_verified') }}</td>
                <td class="w-60-p"></td>
              </tr>
              </thead>
              <tbody class="table-scroll-y-500">
              <template v-if="requests.length >0">
                <tr class="trhover"
                    v-for="(request, index) in requests" :key="index">
                  <td class="w-25-p">
                    <input :checked="checkedRequests.ids.includes(request.id)" type="checkbox"
                           @change="toggleCheck(index)" class="cursor-pointer"/>
                  </td>
                  <td>{{ request.id }}</td>
                  <td>{{ moment(request.placed_at, 'DD/MM/YYYY HH:mm') }}</td>
                  <td>{{ request.employee_name }}</td>
                  <td class="w-120-p">{{ request.employee_cnp }}</td>
                  <td v-if="isAdmin">{{ request.company_name }}</td>
                  <td>
                    <span :style="{backgroundColor: request.benefit_color}" class="color-bullet mr-1"></span>
                    {{ request.benefit_name }}
                  </td>
                  <td>
                    <span class="beneficiary-request-status"
                          :class="'flx-'+request.status">{{ $t('flexben.requests.summary.status.' + request.status) }}
                    </span>
                  </td>
                  <td>{{ (request.benefit.partner_id!==null) ? request.benefit.partner.name : '-' }}</td>
                  <td> {{request.platform_source}}</td>
                  <td>{{ request.total_value }} Lei</td>
                  <td>{{ (request.total_value > 0) ?
                    ((request.total_value_cas+request.total_value_cass+request.total_value_tax).toFixed(2)+' Lei') :
                    '-'}}
                  </td>
                  <td v-if="companySettings.cam_budget_taxation">
                    {{ request.value_cam.toFixed(2) }} Lei
                  </td>
                  <td v-if="companySettings.gross_budget_taxation || companySettings.cam_budget_taxation">
                    {{ calculateTotal(request).toFixed(2) }} Lei
                  </td>
                  <td>{{ request.contract_number ? request.contract_number : '-' }}</td>
                  <td v-if="request.contract_verified === null">
                    -
                  </td>
                  <td v-else>
                    <font-awesome-icon :icon="request.contract_verified ? 'check' : 'minus'" />
                  </td>
                  <td class="w-60-p">
                    <button class="btn action-button" @click="showDetails(index)">
                      {{$t("flexben.general.btn.see_more")}}
                    </button>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr class="gri-cell text-center trhover">
                  <td :colspan="100" class="text-center">{{ $t("flexben.general.no_results_found") }}
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!showDetailsForm" class="mt-3 container-bg-white">
      <RequestsSummary :isAdmin="isAdmin"
                       :summaryList="checkedRequests.summaryList"
                       :checkedIds="checkedRequests.ids"
                       :benefitIdsWithProblems="checkedRequests.benefitIdsWithProblems"
                       :showDisableTopUps="true"
                       :showNonLegislatedMessage="true"
                       :key="requestSummaryKey"
                       @reRenderList="reRenderList()"
                       @placeOrder="placeOrder()"/>
    </div>

    <div>
      <a class="btn text-themed go-back" @click="hideDetails()">{{ $t("flexben.general.go_back") }}</a>
      <component v-if="showDetailsForm"
                 :is="subTypeTemplate"
                 :isAdmin="isAdmin"
                 :detailsId="detailsId"
                 :companyId="companyId"
                 @hideDetails="hideDetails"></component>
    </div>

    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import moment from "moment";
import Filters from "@/components/flexben/requests/partials/Filters";
import RequestsSummary from "@/components/flexben/requests/partials/RequestsSummary";
import SubscriptionRequestDetails from "@/components/flexben/requests/not_legislated/SubscriptionRequestDetails";
import VoucherRequestDetails from "@/components/flexben/requests/not_legislated/VoucherRequestDetails";
import RecurringBenefitRequestDetails from "./RecurringBenefitRequestDetails";
import WorldCLassRequestDetails from "@/components/flexben/requests/not_legislated/WorldCLassRequestDetails";

export default {
  name: "NotLegislatedRequestsList",
  components: {
    VoucherRequestDetails,
    SubscriptionRequestDetails,
    RecurringBenefitRequestDetails,
    WorldCLassRequestDetails,
    RequestsSummary,
    Filters,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
      required: true,
    },
    companyId: {
      type: Number,
    },
  },
  data: function () {
    return {
      loading: false,
      loaderError: false,
      httpService: httpFlexbenService.requests,
      dateFormat: 'dd/MM/yyyy',
      filter_options: {
        benefits: [],
        benefit_groups: [],
        companies: [],
        beneficiaries: [],
        company_addresses: [],
        departments: [],
        cost_centers: [],
        working_points: [],
        job_title_list: [],
        statuses: [],
        partners: [],
        platform_sources: [],
      },
      searchString: '',
      reverseSort: false,
      sortByField: null,
      params: {
        benefit: null,
        company: null,
        beneficiary: null,
        company_address: null,
        department: null,
        cost_center: null,
        working_point: null,
        partner: null,
        job_title: null,
        benefit_group: null,
        start_date: null,
        end_date: null,
        only_list: null,
        sort_by: null,
        sort_direction: null,
        status: null,
        platform_source: null,
      },
      requests: {},
      checkedRequests: {
        ids: [],
        summaryList: {},
        benefitIdsWithProblems: [],
      },
      companySettings: {
        cam_budget_taxation: false,
        gross_budget_taxation: false,
        up_dejun_auto: false,
        up_gift_auto: false
      },
      requestSummaryKey: 0,
      detailsId: null,
      detailsType: null,
      showDetailsForm: false,
      checkedAllModel: false,
      subTypeTemplates: {
        subscription: 'SubscriptionRequestDetails',
        voucher: 'VoucherRequestDetails',
        subscription_world_class: 'WorldCLassRequestDetails',
        ppp3: 'RecurringBenefitRequestDetails',
        medical_insurance: 'RecurringBenefitRequestDetails'
      },
      subTypeTemplate: null
    };
  },
  created() {
    this.params.company = this.companyId;
    this._loadList();
  },
  methods: {
    setGeneralData() {
      this.$emit('setGeneralData');
    },
    sortBy(fieldName) {
      if (this.sortByField !== fieldName) {
        this.reverseSort = false;
      }
      this.params.sort_by = fieldName;
      this.params.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this._loadList(this.params)
      this.sortByField = fieldName;

      this.reverseSort = !this.reverseSort;
    },
    changePerPage(perPage) {
      this.params.page = 1;
      this.params.perPage = perPage;
      this._loadList();
    },
    pageChanged(pageNb) {
      this.params.page = pageNb;
      this._loadList();
    },
    search() {
      if (this.searchString.length >= 3) {
        this.resetFilters();
        this.params.search = this.searchString;
        this._loadList();
      } else if (this.searchString.length === 0) {
        this.resetFilters();
        this.params.search = null;
        this._loadList();
      }
    },
    moment(date, format) {
      return moment(date).format(format);
    },
    filterValues() {
      this.$emit('setQuickFilterCompany', (this.params.company !== null) ? parseInt(this.params.company) : null);
      this._loadList();
    },
    toggleCheck(index, triggerRerender = true) {
      const request = this.requests[index];

      if (this.checkedRequests.ids.includes(request.id)) {
        this.checkedRequests.ids.splice(this.checkedRequests.ids.indexOf(request.id), 1);

        delete this.checkedRequests.summaryList[request.company_id].benefits[request.benefit_id].values[request.id];

        if (!this.checkNotEmptyObj(this.checkedRequests.summaryList[request.company_id].benefits[request.benefit_id].values)) {
          delete this.checkedRequests.summaryList[request.company_id].benefits[request.benefit_id];
        }

        if (!this.checkNotEmptyObj(this.checkedRequests.summaryList[request.company_id].benefits)) {
          delete this.checkedRequests.summaryList[request.company_id];
        }
      } else {
        this.checkedRequests.ids.push(request.id);
        let companyObj;
        if (request.company_id in this.checkedRequests.summaryList) {
          companyObj = {...this.checkedRequests.summaryList[request.company_id]};
        } else {
          companyObj = {
            name: request.company_name,
            benefits: {}
          };
        }
        if (request.benefit_id in companyObj.benefits) {
          companyObj.benefits[request.benefit_id]['values'][request.id] = request.total_value;
        } else {
          companyObj.benefits[request.benefit_id] = {
            color: request.benefit_color,
            name: request.benefit_name,
            values: {}
          };
          companyObj.benefits[request.benefit_id]['values'][request.id] = request.total_value;
        }
        this.checkedRequests.summaryList[request.company_id] = companyObj;
      }
      if (triggerRerender) {
        this.requestSummaryKey++;
      }
    },
    checkAll() {
      if (!this.checkedAllModel) {
        this.checkedRequests.ids = [];
        this.checkedRequests.summaryList = {};
      } else {
        for (let index in this.requests) {
          if (this.checkedRequests.ids.includes(this.requests[index].id) && this.checkedAllModel) {
            continue;
          }
          this.toggleCheck(index, false);
        }
      }
      this.requestSummaryKey++;
    },
    showDetails(index) {
      let request = this.requests[index];
      let detailsType = request.benefit_sub_type;
      if (typeof this.subTypeTemplates[detailsType] == 'undefined') {
        return;
      }

      this.detailsId = request.id;
      this.detailsType = detailsType;
      this.subTypeTemplate = this.subTypeTemplates[detailsType]
      this.showDetailsForm = true;
      this.toggleTopButtons();
    },
    hideDetails(withReRender = false) {
      this.detailsId = null;
      this.detailsType = null;
      this.showDetailsForm = false;
      this.toggleTopButtons();
      if (withReRender) {
        this.reRenderList();
      }
    },
    reRenderList(withResetChecked = true) {
      if (withResetChecked) {
        this.checkedRequests.ids = [];
        this.checkedRequests.summaryList = {};
      }
      this.setGeneralData();
      this._loadList();
    },
    resetFilters() {
      this.params.benefit = null;
      this.params.company = null;
      this.params.beneficiary = null;
      this.params.company_address = null;
      this.params.department = null;
      this.params.cost_center = null;
      this.params.working_point = null;
      this.params.job_title = null;
      this.params.benefit_group = null;
      this.params.start_date = null;
      this.params.end_date = null;
      this.params.sort_by = null;
      this.params.sort_direction = null;
      this.params.platform_source = null;
    },
    placeOrder() {
      this._approveOrderRequests();
    },
    toggleTopButtons() {
      this.$emit('toggleTopButtons')
    },
    exportResults() {
      this.loading = true;
      let params = {...this.params};
      params.headings = [
        this.$t("flexben.requests.table.header.number"),
        this.$t("flexben.requests.table.header.placed_on"),
        this.$t("flexben.requests.table.header.beneficiary"),
        this.$t("flexben.requests.table.header.beneficiary_cnp"),
        this.$t("flexben.requests.table.header.client"),
        this.$t("flexben.requests.table.header.benefit"),
        this.$t("flexben.requests.table.header.partner"),
        this.$t("flexben.requests.table.header.amount"),
        this.$t("flexben.requests.table.header.taxes"),
        this.$t("flexben.requests.table.header.contract_number"),
      ];
      this.httpService.exportVoucherRequests(params)
        .then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link;
          if (document.getElementById('download_link_export')) {
            link = document.getElementById('download_link_export');
            link.href = url;
          } else {
            link = document.createElement('a');

            link.href = url;
            link.id = 'download_link_export';
            link.setAttribute('download', 'solicitari.xls');
            document.body.appendChild(link);
          }

          link.click();
          this.loading = false;
        }.bind(this))
        .catch(function (error) {
          if (error.response.status == 400) {
            this.loading = false;
          } else {
            this.loaderError;
          }
        }.bind(this));
    },
    calculateTotal(item) {
      let returnVal = item.total_value;
      if (item.is_gross_taxed) {
        returnVal += item.total_value_cas + item.total_value_cass + item.total_value_tax;
      }
      if (item.is_cam_in_value) {
        returnVal += item.value_cam;
      }
      return returnVal;
    },
    _loadList() {
      this.loading = true;
      this.httpService.notLegislatedList(this.params)
        .then(function (response) {
          this.requests = response.data.list;
          this.filter_options = response.data.filters;
          this.companySettings = response.data.company_settings;
          this.checkedAllModel = false;
          this.loading = false;
        }.bind(this))
        .catch(function (error) {
        }.bind(this));
    },
    _approveOrderRequests() {
      this.loading = true;
      this.checkedRequests.benefitIdsWithProblems = [];
      this.httpService.orderRequests({
        company_id: this.companyId,
        ids: this.checkedRequests.ids
      })
        .then(function (response) {
          if (response.data.benefit_ids_problems.length !== 0) {
            this.checkedRequests.benefitIdsWithProblems = response.data.benefit_ids_problems;
            this._eliminateSuccessfullyPlacedBenefits();
          }
          this.loading = false;
          this.reRenderList((this.checkedRequests.benefitIdsWithProblems.length === 0));
        }.bind(this))
        .catch(function (error) {
          this.loaderError = false;
        }.bind(this))
    },
    _eliminateSuccessfullyPlacedBenefits() {
      let benefitId;
      for (benefitId in this.checkedRequests.summaryList[this.companyId].benefits) {
        if (!this.checkedRequests.benefitIdsWithProblems.includes(benefitId)) {
          delete Object.keys(this.checkedRequests.summaryList[this.companyId].benefits[benefitId]);
        }
      }
      this.requestSummaryKey++;
    }
  }
}
</script>
