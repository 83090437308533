<template>
  <div class="hover-table table-responsive">
    <table class="table table-striped">
      <thead>
      <tr>
        <td class="w-50-p">
          <input type="checkbox" v-model="check_all_checkbox" :key="'all'"/>
        </td>
        <td>{{ $t('flexben.import.table_headings.name') }}</td>
        <td>{{ $t('flexben.import.table_headings.cnp') }}</td>
        <td>{{ $t('flexben.import.table_headings.working_days') }}</td>
        <td>{{ $t('flexben.import.table_headings.status_entry') }}</td>
        <td></td>
      </tr>
      </thead>
      <tbody>
      <template v-if="import_items.length">
        <tr v-for="(item, index) in import_items" :key="index" class="trhover">
          <td class="w-50-p"><input type="checkbox"
                                    :checked="importDeleteItemIds.includes(item.id)"
                                    @change="checkForDelete(item.id)" :key="item.id"/></td>
          <td>{{item.last_name}} {{item.first_name}}</td>
          <td>{{item.cnp}}</td>
          <td>{{item.working_days}}</td>
          <td>
            <span
              :class="'import-status-item-'+item.status">{{ $t('flexben.import.item_statuses.'+item.status) }}</span>
            <span v-if="item.invalid_reasons !== null"
                  :id="'invalid_tooltip_'+index"
                  class="ml-1 item-error-reason">!</span>
            <b-tooltip v-if="item.invalid_reasons !== null"
                       :target="'invalid_tooltip_'+index" variant="flexben"
                       placement="topleft">
              <div v-html="getInvalidReasons(item.invalid_reasons)"></div>
            </b-tooltip>
          </td>
          <td>
            <button class="btn action-button" @click="editItem(index)">{{$t("flexben.general.btn.see_more")}}
            </button>
          </td>
        </tr>
      </template>
      <template v-else>
        <tr class="gri-cell text-center">
          <td colspan="1000">{{ $t("flexben.general.no_results_found") }}</td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import {BTooltip} from "bootstrap-vue";

export default {
  name: "ImportTableimesheet",
  components: {
    'b-tooltip': BTooltip
  },
  props: {
    importItems: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    },
    importFormOptions: {
      type: Object,
      default: function () {
        return {
          max_working_days_month: 0
        };
      },
      required: true,
    },
    importDeleteItemIds: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    },
    ts_month: {
      type: Number,
      required: true,
    },
    ts_year: {
      type: Number,
      required: true,
    },
  },
  computed: {
    import_items: function () {
      return this.importItems;
    },
  },
  data: function () {
    return {
      check_all_checkbox: false
    }
  },
  watch: {
    check_all_checkbox: {
      handler: function (val) {
        this.$emit('checkForDelete', val ? 'all' : 'none');
      },
    }
  },
  methods: {
    checkForDelete(itemId) {
      this.$emit('checkForDelete', itemId);
    },
    getInvalidReasons(invalidReasons) {
      return this.$parent.getInvalidReasons(invalidReasons);
    },
    editItem(itemIndex) {
      this.$emit('editItem', itemIndex)
    },
  }
}
</script>
