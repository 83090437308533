<template>
  <div v-show="!initialLoading" class="col-12">
    <div class="container-bg-white">
      <div class="row p-3" id="filterGroup">
        <div class="col-12 col-xl-3 mb-2 mb-xl-0">
          <img src="@/assets/images/filter.svg"/>
          <span class="ml-3">{{$t("flexben.general.filter")}}</span>
        </div>
        <div class="col-12 col-xl-9">
          <div class="row">
            <div class="col-12 col-md-4 form-group mb-0">
              <label class="col-form-label pt-0"
                     for="start_date">{{$t("flexben.reports.inputLabels.world_class_year")}}</label>
              <select name="year" id="year" class="form-control"
                      v-model="filter.year">
                <option :value="null">-</option>
                <option v-for="item of generateRange(2022, parseInt(currentYear) + 1)" :value="item" :key="item">{{ item
                  }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-4 form-group mb-0">
              <label class="col-form-label pt-0"
                     for="start_date">{{$t("flexben.reports.inputLabels.world_class_month")}}</label>
              <select name="year" id="year" class="form-control"
                      v-model="filter.month">
                <option :value="null">-</option>
                <option v-for="item of generateRange(1,12)" :value="item" :key="item">{{
                  $t('flexben.general.months.'+item) }}
                </option>
              </select>
            </div>

            <div class="col-12 col-md-4 form-group mb-0">
              <label class="col-form-label pt-0"
                     for="start_date">{{$t("flexben.benefit.world_class_fields.is_local_price")}}</label>
              <div class="d-flex pt-2">
                <span>{{ $t('flexben.general.no') }}</span>
                <b-form-checkbox v-model="filter.is_local_price" name="selectedCards"
                                 switch
                                 class="ml-2 mr-3"></b-form-checkbox>
                <span>{{ $t('flexben.general.yes') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-bg-white mt-3">
      <div class="row p-3">
        <div class="col-12 mt-2">
          <div class="table-responsive scaled-table hover-table">
            <table class="table table-striped">
              <thead>
              <tr>
                <td>{{ $t('flexben.reports.world_class.fields.index') }}</td>
                <td>{{ $t('flexben.reports.world_class.fields.last_name') }}</td>
                <td>{{ $t('flexben.reports.world_class.fields.first_name') }}</td>
                <td>{{ $t('flexben.reports.world_class.fields.email') }}</td>
                <td>{{ $t('flexben.reports.world_class.fields.phone') }}</td>
                <td>{{ $t('flexben.reports.world_class.fields.birth_day') }}</td>
                <td>{{ $t('flexben.reports.world_class.fields.ci_number') }}</td>
                <td>{{ $t('flexben.reports.world_class.fields.city') }}</td>
                <td>{{ $t('flexben.reports.world_class.fields.address') }}</td>
                <td>{{ $t('flexben.reports.world_class.fields.network') }}</td>
                <td>{{ $t('flexben.reports.world_class.fields.club_name') }}</td>
                <td>{{ $t('flexben.reports.world_class.fields.type') }}</td>
                <td>{{ $t('flexben.reports.world_class.fields.subscription_duration') }}</td>
                <td>{{ $t('flexben.reports.world_class.fields.subscription_start_day') }}</td>
                <td>{{ $t('flexben.reports.world_class.fields.order_request_id') }}</td>
              </tr>
              </thead>
              <tbody>
              <tr class="trhover" v-for="(item, key) in reportItems" :key="key">
                <td>{{ key+1 }}</td>
                <td>{{ item.last_name }}</td>
                <td>{{ item.first_name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.birth_day }}</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>{{ item.club_name }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.subscription_duration }}</td>
                <td>{{ moment(item.subscription_start_day, 'DD.MM.YYYY') }}</td>
                <td>{{ item.order_request_id }}</td>
              </tr>
              <tr v-if="reportItems.length===0" class="trhover gri-cell">
                <td colspan="10000" class="text-center ">
                  {{ $t("flexben.general.no_results_found") }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import {BFormCheckbox} from "bootstrap-vue";
import utils from "@/plugins/utils";

export default {
  name: "WorldClassReport",
  components: {
    'b-form-checkbox': BFormCheckbox
  },
  data: function () {
    return {
      initialLoading: true,
      httpService: httpFlexbenService.reports.world_class,
      currentYear: new Date().getFullYear(),
      filter: {
        month: null,
        year: null,
        is_local_price: false,
      },
      reportItems: [],
      errors: {
        show: false,
        message: null,
      }
    }
  },
  created() {
    this.initialLoading = false;
    this._toggleLoading(false);
  },
  methods: {
    filterValues() {
      this._validateFilters();
      if (this.errors.show) {
        return;
      }

      this._toggleLoading(true);
      this.httpService.list({
        month: this.filter.month,
        year: this.filter.year,
        is_local_price: this.filter.is_local_price ? 1 : 0,
      })
        .then((response) => {
          this.reportItems = response.data;
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(true, true);
        })
    },
    download() {
      this._validateFilters();
      if (this.errors.show) {
        return;
      }

      this._toggleLoading(true);
      this.httpService.download({
        month: this.filter.month,
        year: this.filter.year,
        is_local_price: this.filter.is_local_price ? 1 : 0,
        lang: i18n.locale.toLowerCase(),
      })
        .then((response) => {
          utils.generateDownloadFromReponse(response);
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(true, true);
        })
    },
    _toggleLoading(loading, loaderError = null) {
      this.$emit('toggleLoading', loading, loaderError);
    },
    _validateFilters() {
      this.errors.show = false;
      this.errors.message = null;
      if (this.filter.year === null) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.year_required');
      } else if (this.filter.month === null) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.month_required');
      }
      this.$emit('setErrors', this.errors.show, this.errors.message);
    },
  }
}
</script>
