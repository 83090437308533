<template>
  <div v-if="benefitEvents.length > 0">
    <div class="table-responsive hover-table">
      <table class="table no-min-width">
        <thead>
        <tr>
          <td>{{ $t("flexben.general.event") }}</td>
          <td>{{ $t("flexben.general.event_date") }}</td>
          <td>{{ $t("flexben.benefit.up_gift_law_tab_table_gender_restrictions") }}</td>
          <td>{{ $t("flexben.benefit.up_gift_law_tab_table_for_birthdays") }}</td>
          <td>{{ $t("flexben.benefit.up_gift_law_tab_table_for_limit_child") }}</td>
        </tr>
        </thead>
        <tbody>
        <tr class="trhover parent" v-for="(item,index) in benefitEvents" :key="item.id"
            :class="(index%2 === 0) ? 'gri-cell':''">
          <td>{{$t("flexben.general.legal_events." + item.name)}}</td>
          <td>{{ item.event_date.day }}/{{ item.event_date.month }}/{{ (item.event_date.year) ? item.event_date.year :
            currentYear }}
          </td>
          <td class="text-capitalize">{{$t('flexben.general.gender.' + item.gender_availability.toLowerCase())}}</td>
          <td>{{ $t('flexben.general.'+(item.on_birthday ? 'yes' : 'no')) }}</td>
          <td class="w-25">
            <div class="d-flex">
              <b-form-checkbox :id="'toggle_per_child_event_'+item.id" class="d-inline-block"
                               switch
                               v-model="item.has_limit_per_child">
              </b-form-checkbox>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import {BFormCheckbox} from "bootstrap-vue";

export default {
  name: 'BenefitEventsTable',
  components: {
    "b-form-checkbox": BFormCheckbox,
  },
  props: {
    benefitEvents: {
      type: Array,
      required: true,
      default: function () {
        return [];
      }
    },
  },
  data() {
    return {
      sortByField: '',
      reverseSort: null,
      params: {
        sort_by: null,
        sort_direction: null,
      },
      currentYear: (new Date()).getFullYear(),
    };
  }
}
</script>
