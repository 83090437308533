<template>
  <div class="flexben-import-history">
    <div :class="(initialLoading || selectedImport !== null) && 'hide'">
      <div class="row">
        <div class="col-md-6 flexben-page-title">
          <h1>{{$t("flexben.import_history.title")}}</h1>
        </div>
        <div class="col-md-6">
          <button class="btn btn-themed px-4 float-right my-2" @click="newImport()">{{
            $t("flexben.import_history.new_import") }}
          </button>
        </div>
      </div>
      <div class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-12 col-lg-3 mb-2 mb-xl-0">
            <img src="@/assets/images/filter.svg"/>
            <span class="ml-3">{{$t("flexben.general.filter")}}</span>
          </div>
          <div class="col-12 col-xl-9">
            <div class="row">
              <div class="col-12 col-md-4 offset-md-4 form-group mb-0" v-if="isAdmin">
                <label for="client" class="col-form-label pt-0">{{$t("flexben.beneficiaries.fields.client")}}</label>
                <select id="client" class="form-control w-100"
                        @change="filtersValues()"
                        v-model="filters_values.company">
                  <option :value="null">-</option>
                  <option v-for="(item, value) in filters.companies"
                          :key="value"
                          :value="value">{{ item }}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-4 form-group">
                <label class="col-form-label pt-0" for="type">{{$t("flexben.general.type")}}</label>
                <select id="type" class="form-control w-100 text-capitalize"
                        @change="filtersValues()"
                        v-model="filters_values.type">
                  <option :value="null">{{$t("flexben.general.all")}}</option>
                  <option v-for="(value, index) in filters.types"
                          :key="index"
                          class="text-capitalize"
                          :value="value">{{$t("flexben.import.types." + value)}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-4 form-group" :class="{'offset-md-4':isAdmin}">
                <label class="col-form-label pt-0"
                       for="import_start_date">{{$t("flexben.import_history.start_date")}}</label>
                <div class="w-100 d-inline-flex">
                  <label for="import_start_date" class="my-auto mr-2 col-form-label">
                    <font-awesome-icon icon="calendar-alt" class="text-24 "/>
                  </label>
                  <datepicker :format="dateFormat"
                              :inputClass="'form-control-d m-0 w-100 background-white'"
                              v-model="startDate"
                              :class="'w-100'"
                              :key="1"
                              id="import_start_date"></datepicker>
                  <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                       @click="startDate=null"/>
                </div>
              </div>
              <div class="col-12 col-md-4 form-group mb-0">
                <label for="import_end_date"
                       class="col-form-label pt-0">{{$t("flexben.import_history.end_date")}}</label>
                <div class="w-100 d-inline-flex">
                  <label for="import_end_date" class="my-auto mr-2 col-form-label">
                    <font-awesome-icon icon="calendar-alt" class="text-24 "/>
                  </label>
                  <datepicker :format="dateFormat"
                              :inputClass="'form-control-d m-0 w-100 background-white'"
                              v-model="endDate"
                              :class="'w-100'"
                              :key="2"
                              id="import_end_date"></datepicker>
                  <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                       @click="endDate=null"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-bg-white mt-3">
        <div class="row px-3">
          <div class="col-6 ">
            <h3 class="pl-0">{{$t("flexben.import_history.list_of_imported_files")}}</h3>
          </div>
          <div class="col-6 flexben-search-input">
            <input type="text"
                   id="search"
                   name="search"
                   v-model="searchString"
                   v-on:keyup.enter="search()"
                   :placeholder="$t('flexben.general.search')"
                   class="float-right search-input m-0"/>
          </div>
          <div class="col-12">
            <div class="table-responsive hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td v-if="isAdmin" class="cursor-pointer" @click="sortBy('name')">
                    {{ $t("flexben.import_history.client") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class=" (sortField === 'name' && reverseSort) ?'rotate-180':''"/>
                  </td>
                  <td v-if="isAdmin" class="cursor-pointer" @click="sortBy('cui')">
                    CUI
                  </td>
                  <td class="cursor-pointer" @click="sortBy('created_at')">
                    {{$t("flexben.import_history.import_on")}}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class=" (sortField === 'created_at' && reverseSort) ?'rotate-180':''"/>
                  </td>
                  <td class="cursor-pointer" @click="sortBy('type')">
                    {{$t("flexben.import_history.import_type")}}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class=" (sortField === 'type' && reverseSort) ?'rotate-180':''"/>
                  </td>
                  <td class="cursor-pointer">
                    {{$t("flexben.import_history.total_number_of_items")}}
                  </td>
                  <td class="cursor-pointer">
                    {{$t("flexben.import_history.new_items")}}
                  </td>
                  <td class="cursor-pointer">
                    {{$t("flexben.import_history.updated_items")}}
                  </td>
                  <td></td>
                </tr>
                </thead>
                <tbody v-if="!loading && importHistory.data.length !== 0">
                <tr class="trhover" v-for="(itemImport, index) in importHistory.data"
                    :key="index">
                  <td v-if="isAdmin">{{ itemImport.company_name }}</td>
                  <td v-if="isAdmin">{{ itemImport.company_cui }}</td>
                  <td>{{ moment(itemImport.created_at,'DD/MM/YYYY') }}</td>
                  <td>
                    <div v-if="itemImport.type ==='beneficiary'"
                         class="import-type-icon import-type-icon-beneficiary"
                         :id="'import_type_tooltip_'+index"></div>

                    <div v-if="itemImport.type ==='update_beneficiary'"
                         class="import-type-icon import-type-icon-beneficiary"
                         :id="'import_type_tooltip_'+index"></div>

                    <div v-if="itemImport.type ==='budget'"
                         class="import-type-icon import-type-icon-timesheet"
                         :id="'import_type_tooltip_'+index"></div>

                    <div v-if="itemImport.type ==='time_sheet'"
                         class="import-type-icon import-type-icon-budget"
                         :id="'import_type_tooltip_'+index"></div>

                    <b-tooltip :target="'import_type_tooltip_'+index"
                               triggers="hover" variant="flexben" placement="topleft" custom-class="text-uppercase">
                      {{$t('flexben.import.types.'+itemImport.type)}}
                    </b-tooltip>
                  </td>
                  <td>{{ (itemImport.status==='complete' && itemImport.data && typeof itemImport.data.summary !=='undefined') ?
                    itemImport.data.summary.total_items : '-' }}
                  </td>
                  <td>{{ (itemImport.status==='complete' && itemImport.data && typeof itemImport.data.summary !=='undefined') ?
                    itemImport.data.summary.total_new_items : '-' }}
                  </td>
                  <td>{{ (itemImport.status==='complete' && itemImport.data && typeof itemImport.data.summary !=='undefined') ?
                    itemImport.data.summary.total_update_items : '-' }}
                  </td>
                  <td>
                    <button class="btn action-button" @click="getImportDetails(itemImport.id)">
                      {{$t("flexben.general.btn.see_more")}}
                    </button>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr class="gri-cell text-center trhover">
                  <td colspan="8" class="text-center">{{ $t("flexben.general.no_results_found") }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 p-0">
            <listPagination
              :currentPage="importHistory.current_page"
              :totalItems="importHistory.total"
              :startItem="importHistory.from"
              :endItem="importHistory.to"
              :itemsPerPage="importHistory.perPage"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage">
            </listPagination>
          </div>
        </div>
      </div>
    </div>

    <ImportHistoryDetails v-if="(selectedImport !== null)"
                          :importId="selectedImport"
                          @backToList="backToList()"
                          @toggleLoading="toggleLoading"></ImportHistoryDetails>
    <SelectClientModal :openModal="openModal"
                       :companies="filters.companies"
                       :noClientSelected="noClientSelected"
                       @closeModal="closeModal"
                       @goToCreate="goToCreate"></SelectClientModal>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben.js";
import Datepicker from "vuejs-datepicker";
import ImportHistoryDetails from "./partials/ImportHistoryDetails";
import moment from "moment";
import SelectClientModal from "@/components/fragments/SelectClientModal";
import {BTooltip} from "bootstrap-vue";

export default {
  name: "ImportHistory",
  components: {
    SelectClientModal,
    ImportHistoryDetails,
    Datepicker,
    'b-tooltip': BTooltip
  },
  data() {
    return {
      httpService: httpFlexbenService.importHistory,
      initialLoading: false,
      loading: false,
      loaderError: false,
      isAdmin: false,

      openModal: false,
      noClientSelected: false,
      selectedCompanyId: null,

      dateFormat: 'dd/MM/yyyy',
      searchString: '',
      sortField: '',
      reverseSort: false,
      importHistory: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      filters: {
        companies: {},
        types: [],
      },
      startDate: null,
      endDate: null,
      selectedImport: null,
      filters_values: {
        company: null,
        type: null,
        start_date: null,
        end_date: null,
      },
      params: {},
    };
  },
  created() {
    let userRole = this.flxUserLevel().userLevel;
    const employment = this.$store.getters.flx_activeEmployment;
    if (userRole === 'client' && employment != null) {
      this.selectedCompanyId = employment.company_id;
      this.isAdmin = false;
    } else if (userRole === 'admin') {
      if (typeof localStorage.getItem('flexbenCompanyId') !== "undefined" && localStorage.getItem('flexbenCompanyId') !== null) {
        this.params.company = this.filters_values.company = localStorage.getItem('flexbenCompanyId');
        localStorage.removeItem('flexbenCompanyId');
      }
      this.isAdmin = true;
    } else {
      window.location.href = '/company';
      return;
    }

    this._loadList();
  },
  watch: {
    startDate: {
      handler: function (val) {
        this.params.start_date = val;
        this._loadList();
      }
    },
    endDate: {
      handler: function (val) {
        this.params.end_date = val;
        this._loadList();
      }
    },
  },

  methods: {
    search() {
      if (this.searchString.length >= 3) {
        this.params.search = this.searchString;
        this._loadList(this.params);
      } else if (this.searchString.length === 0) {
        delete this.params.search;
        this._loadList(this.params);
      }
    },
    filtersValues() {
      if (!this.initialLoading) {
        let paramsBag = {};
        let filterValues = this.filters_values;

        if (filterValues.company !== null) {
          paramsBag.company = filterValues.company;
        }
        if (filterValues.type !== null) {
          paramsBag.type = filterValues.type;
        }
        if (filterValues.start_date !== null) {
          paramsBag.start_date = filterValues.start_date;
        }
        if (filterValues.end_date !== null) {
          paramsBag.end_date = filterValues.end_date;
        }


        this.searchString = '';
        this.sortField = '';
        this.sortDirection = 'asc';
        this.reverseSort = false;
        this.params = paramsBag;
        this._loadList();
      }
    },
    pageChanged(page) {
      this.params.page = page;
      this._loadList();
    },
    sortBy(fieldName) {
      if (this.sortField !== fieldName) {
        this.reverseSort = false;
      }
      if (typeof this.params.page !== 'undefined') {
        delete this.params.page;
      }
      this.params.sort_by = fieldName;
      this.params.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this._loadList(this.params)
      this.sortField = fieldName;
      this.reverseSort = !this.reverseSort;
    },
    _loadList() {
      this.loading = true;
      let params = this._getFormattedDate({...this.params});

      this.httpService.loadList(params)
        .then(function (response) {
          const sortedData = response.data.items.data.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });
          this.importHistory = {
            ...response.data.items,
            data: sortedData
          };
          this.filters = {
            companies: response.data.filters.companies.values,
            types: response.data.filters.types.values,
          };
          this.initialLoading = false;
          this.loading = false;
        }.bind(this))
        .catch(function (error) {
          this.loaderError = false;
          this.loading = false;
        }.bind(this));
    },
    _getFormattedDate(params) {
      if (typeof params.start_date !== 'undefined' && params.start_date !== null) {
        params.start_date = moment(params.start_date).format('YYYY-MM-DD');
      } else if (typeof params.start_date !== 'undefined' && params.start_date === null) {
        delete params.start_date;
      }
      if (typeof params.end_date !== 'undefined' && params.end_date !== null) {
        params.end_date = moment(params.end_date).format('YYYY-MM-DD');
      } else if (typeof params.end_date !== 'undefined' && params.end_date === null) {
        delete params.end_date;
      }

      return params;
    },
    getImportDetails(importId) {
      this.loading = true;
      this.selectedImport = importId;
    },
    backToList() {
      this.selectedImport = null;
    },
    changePerPage(perPage) {
      this.params.page = 1;
      this.params.perPage = perPage;
      this._loadList();
    },
    newImport() {
      if (this.isAdmin) {
        this.openModal = true;
        this.selectedCompanyId = null;
      } else {
        this.goToCreate();
      }
    },
    goToCreate() {
      if (this.selectedCompanyId !== null) {
        localStorage.setItem('flexbenCompanyId', this.selectedCompanyId);
        window.location.href = '/multibeneficii/import-new';
      } else {
        this.noClientSelected = true;
      }
    },
    closeModal() {
      if (this.isAdmin) {
        this.selectedCompanyId = null;
      }
      this.openModal = false;
    },
    moment(date, format) {
      if (date === null) {
        return '-';
      }
      return moment(date).format(format);
    },
    toggleLoading(loading, loaderError = false) {
      this.loading = loading;
      this.loaderError = loaderError;
    },
  }
}
</script>

<style src="@/assets/styles/flexben.css"></style>
