<template>
  <div class="row p-3">
    <div class="col-12 col-lg-3 mb-2 mb-xl-0">
      <img src="@/assets/images/filter.svg"/>
      <span class="ml-3">{{$t("flexben.general.filter")}}</span>
    </div>
    <div class="col-12 col-xl-9">
      <div class="row">
        <div class="col-12 col-md-4 form-group mb-0"
             :class="{'form-group-multiselect flx-multiselect-single':benefitMultiselect}">
          <label for="benefit" class="col-form-label pt-md-0">{{ $t("flexben.requests.filter.field.benefit") }}</label>
          <multiselect v-if="benefitMultiselect"
                       :multiple="false"
                       :showLabels="false"
                       :class="'from-control'"
                       track-by="value"
                       label="name"
                       id="benefit"
                       :closeOnSelect="true"
                       :allow-empty="false"
                       :placeholder="$t('flexben.general.search')"
                       v-model="selectedBenefit" :options="benefitOptions" :key="'benefit_select'">
            <template slot="noResult">{{$t('flexben.general.no_results_found')}}</template>
          </multiselect>
          <select v-else class="form-control w-100" id="benefit" @change="filterValues()"
                  v-model="params.benefit" :key="'benefit_select'">
            <option :value="null">-</option>
            <option v-for="(name, id) in filterOptions.benefits" :key="id" :value="id">
              {{ name }}
            </option>
          </select>
        </div>
        <div v-if="showPartner" class="col-12 col-md-4 form-group mb-0"
             :class="{'form-group-multiselect flx-multiselect-single':benefitMultiselect}">
          <label for="partner" class="col-form-label pt-md-0">{{ $t("flexben.requests.filter.field.partner") }}</label>
          <multiselect v-if="benefitMultiselect"
                       :multiple="false"
                       :showLabels="false"
                       :class="'from-control'"
                       track-by="value"
                       label="name"
                       id="partner"
                       :closeOnSelect="true"
                       :allow-empty="false"
                       :placeholder="$t('flexben.general.search')"
                       v-model="selectedPartner" :options="partnerOptions" :key="'partner_select'">
            <template slot="noResult">{{$t('flexben.general.no_results_found')}}</template>
          </multiselect>
          <select v-else class="form-control w-100" id="partner" @change="filterValues()"
                  v-model="params.partner" :key="'partner_select'">
            <option :value="null">-</option>
            <option v-for="(name, id) in filterOptions.partners" :key="id" :value="id">
              {{ name }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-4 form-group form-group-multiselect flx-multiselect-single mb-0" v-if="isAdmin">
          <label for="company" class="col-form-label pt-md-0">{{ $t("flexben.requests.filter.field.client")
            }}</label>
          <multiselect :multiple="false"
                       :showLabels="false"
                       :class="'from-control'"
                       track-by="value"
                       label="name"
                       id="company"
                       :closeOnSelect="true"
                       :allow-empty="false"
                       :placeholder="$t('flexben.general.search')"
                       v-model="selectedCompany" :options="companyOptions">
            <template slot="noResult">{{$t('flexben.general.no_results_found')}}</template>
          </multiselect>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label for="beneficiary" class="col-form-label pt-md-0">{{
            $t("flexben.requests.filter.field.beneficiary") }}</label>
          <select class="form-control w-100" id="beneficiary" :disabled="(params.company === null)"
                  v-model="params.beneficiary" @change="filterValues()">
            <option :value="null">-</option>
            <option v-for="(entry, id) in filterOptions.beneficiaries" :key="id" :value="id">
              {{ entry }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label for="working_point" class="col-form-label" :class="{'pt-md-0':!isAdmin}">
            {{ $t("flexben.requests.filter.field.working_point") }}</label>
          <select class="form-control w-100" id="working_point"
                  :disabled="(params.company === null)"
                  v-model="params.working_point" @change="filterValues()">
            <option :value="null">-</option>
            <option v-for="(item, value, index) in filterOptions.working_points" :key="index" :value="value">
              {{ item }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label for="department" class="col-form-label">{{
            $t("flexben.requests.filter.field.department") }}</label>
          <select class="form-control w-100" id="department"
                  :disabled="(params.company === null)"
                  v-model="params.department" @change="filterValues()">
            <option :value="null">-</option>
            <option v-for="(item, value, index) in filterOptions.departments" :key="index" :value="value">
              {{ item }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label for="job_title" class="col-form-label">{{
            $t("flexben.requests.filter.field.job_title") }}</label>
          <select class="form-control w-100" id="job_title"
                  :disabled="(params.company === null)"
                  v-model="params.job_title" @change="filterValues()">
            <option :value="null">-</option>
            <option v-for="(item, value, index) in filterOptions.job_title_list" :key="index" :value="value">
              {{ item }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label for="cost_center" class="col-form-label">{{
            $t("flexben.requests.filter.field.cost_center") }}</label>
          <select class="form-control w-100" id="cost_center"
                  :disabled="(params.company === null)"
                  v-model="params.cost_center" @change="filterValues()">
            <option :value="null">-</option>
            <option v-for="(item, value, index) in filterOptions.cost_centers" :key="index" :value="value">
              {{ item }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-4 form-group mb-0" :class="{'offset-md-4':!isAdmin}">
          <label for="benefit_group" class="col-form-label">{{
            $t("flexben.requests.filter.field.benefit_group") }}</label>
          <select class="form-control w-100" id="benefit_group"
                  :disabled="(params.company === null)"
                  v-model="params.benefit_group" @change="filterValues()">
            <option :value="null">-</option>
            <option v-for="(item, value, index) in filterOptions.benefit_groups" :key="index" :value="value">
              {{ item }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label for="up_ro_addresses" class="col-form-label">{{ $t("flexben.requests.filter.field.delivery_address")
            }}</label>
          <select class="form-control w-100" id="up_ro_addresses" @change="filterValues()"
                  v-model="params.company_address"
                  :disabled="(params.company === null)">
            <option :value="null">-</option>
            <option v-for="(name, id) in filterOptions.company_addresses" :key="id" :value="id">
              {{ name }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-4 form-group mb-0" v-if="showStatuses">
          <label for="status" class="col-form-label">{{ $t("flexben.requests.filter.field.status")
            }}</label>
          <select class="form-control w-100" id="status" @change="filterValues()"
                  v-model="params.status">
            <option :value="null">-</option>
            <option v-for="( value,key) in filterOptions.statuses" :key="key" :value="value">
              {{$t('flexben.requests.summary.status.'+value) }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-4 form-group mb-0" :class="{'offset-md-4':!showStatuses}">
          <label for="new_request_start_date" class="col-form-label">{{ $t("flexben.requests.filter.field.start_date")
            }}</label>
          <div class="w-100 d-inline-flex">
            <label for="new_request_start_date" class="my-auto col-form-label">
              <font-awesome-icon icon="calendar-alt" class="text-24 mr-2"/>
            </label>
            <datepicker :format="dateFormat"
                        :inputClass="'form-control-d m-0 w-100 background-white'"
                        :class="'w-100'"
                        v-model="filters.start_date"
                        @input="filterDate('start_date')"
                        @cleared="filterDate('start_date',true)"
                        :key="2"
                        id="new_request_start_date"></datepicker>
            <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                 @click="filterDate('start_date',true)"/>
          </div>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label for="new_request_start_date" class="col-form-label">{{
            $t("flexben.requests.filter.field.end_date") }}</label>
          <div class="w-100 d-inline-flex">
            <label for="new_request_end_date" class="my-auto col-form-label">
              <font-awesome-icon icon="calendar-alt" class="text-24 mr-2"/>
            </label>
            <datepicker :format="dateFormat"
                        :inputClass="'form-control-d m-0 w-100 background-white'"
                        :class="'w-100'"
                        v-model="filters.end_date"
                        @input="filterDate('end_date')"
                        @cleared="filterDate('end_date',true)"
                        :key="2"
                        id="new_request_end_date"></datepicker>
            <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                 @click="filterDate('end_date',true)"/>
          </div>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label for="platform_source" class="col-form-label">{{
            $t("flexben.requests.filter.field.platform_source") }}</label>
          <select class="form-control w-100" id="platform_source"
                  v-model="params.selected_platform" @change="filterValues()">
            <option :value="null">-</option>
            <option v-for="(item, value, index) in filterOptions.platform_sources" :key="index" :value="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="showExportButton" class="col-12 mt-2">
      <button class="btn btn-themed float-right px-4" @click="exportResults">
        {{ $t("flexben.requests.btn.export_requests") }}
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  name: "Filters",
  components: {
    datepicker: Datepicker
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
      required: true,
    },
    showDeliveryAddress: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: Number,
    },
    params: {
      type: Object,
      required: true
    },
    filterOptions: {
      type: Object,
      required: true
    },
    showExportButton: {
      type: Boolean,
      default: false,
      required: false
    },
    benefitMultiselect: {
      type: Boolean,
      default: false,
      required: false
    },
    showStatuses: {
      type: Boolean,
      default: false,
      required: false
    },
    showPartner: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  computed: {
    companyOptions: function () {
      let returnData = [
        {value: null, name: '-'}
      ];
      let companyId;
      for (companyId in this.filterOptions.companies) {
        returnData.push({value: companyId, name: this.filterOptions.companies[companyId]});
      }
      return returnData;
    },
    benefitOptions: function () {
      let returnData = [
        {value: null, name: '-'}
      ];
      let benefitId;
      for (benefitId in this.filterOptions.benefits) {
        returnData.push({value: benefitId, name: this.filterOptions.benefits[benefitId]});
      }
      return returnData;
    },
    partnerOptions: function () {
      let returnData = [
        {value: null, name: '-'}
      ];
      let id;
      for (id in this.filterOptions.partners) {
        returnData.push({value: id, name: this.filterOptions.partners[id]});
      }
      return returnData;
    },
  },
  data: function () {
    return {
      filters: {
        start_date: null,
        end_date: null,
      },
      selectedCompany: {value: null, name: '-'},
      selectedBenefit: {value: null, name: '-'},
      selectedPartner: {value: null, name: '-'},
      dateFormat: 'dd/MM/yyyy',
    }
  },
  watch: {
    selectedCompany: {
      handler: function (value) {
        this.params.company = value.value;
        this.filterValues();
      }
    },
    selectedBenefit: {
      handler: function (value) {
        this.params.benefit = value.value;
        this.filterValues();
      }
    },
    selectedPartner: {
      handler: function (value) {
        this.params.partner = value.value;
        this.filterValues();
      }
    }
  },
  methods: {
    filterValues() {
      this.$emit('filterValues');
    },
    filterDate(key, clearDate = false) {
      if (clearDate) {
        this.filters[key] = null;
        this.params[key] = null;
      } else {
        this.params[key] = moment(this.filters[key]).format('YYYY-MM-DD');
      }
      if (key === 'start_date') {
        this.params.end_date = null;
      }
      this.filterValues();
    },
    exportResults() {
      this.$emit('exportResults');
    }
  }
}
</script>

<style v-if="benefitMultiselect" src="vue-multiselect/dist/vue-multiselect.min.css"></style>
