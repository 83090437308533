<template>
  <b-modal id="modal-1"
           ref="details_modal"
           :hide-header-close="true"
           headerClass="border-0 text-center"
           bodyClass="modal-body no-border background-white"
           footerClass="modal-footer border-top-0"
           size="xl"
           centered>
    <template v-slot:modal-header>
      <h2 class="mb-0 mx-auto text-capitalize text-center">
        {{$t("flexben.voucher_stocks.titles.import_item_details")}}
        <span v-if="action==='import'" class="flx-type-badge"
              :class="{
                      'bg-success':voucherDetails.status==1,
                      'bg-danger':voucherDetails.status==2
                    }">{{ $t('flexben.voucher_stocks.import.statuses.'+voucherDetails.status) }}</span>

        <span v-if="action==='update'" class="flx-type-badge"
              :class="{
                      'bg-success' : (voucherDetails.status==1),
                        'bg-primary' : (voucherDetails.status==2),
                        'bg-danger' : (voucherDetails.status==4 || voucherDetails.status==5)
                    }">{{ $t('flexben.voucher_stocks.statuses.'+voucherDetails.status) }}</span>
      </h2>
    </template>
    <template v-slot:default>
      <div class="row text-danger" v-if="voucherDetails.invalid_reasons !== null">
        <div class="col-12 mb-1"
             v-html="getInvalidReasons(voucherDetails.invalid_reasons)">
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-xl-3 form-group-multiselect  mb-0">
          <label for="benefit" class="form-fields-required col-form-label">{{
            $t("flexben.voucher_stocks.fields.benefit") }}</label>
          <multiselect :multiple="false"
                       :showLabels="false"
                       :class="{'from-control':true, 'border-danger':checkInErrorBag('benefit')}"
                       track-by="value"
                       value-field="value"
                       label="text"
                       id="benefit"
                       :closeOnSelect="true"
                       :allow-empty="true"
                       :placeholder="$t('flexben.general.search')"
                       v-model="selectedBenefit"
                       :options="availableOptions.benefits"
                       :key="'benefit_select'">
            <template slot="noResult">{{$t('flexben.general.no_results_found')}}</template>
          </multiselect>
        </div>
        <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
          <label for="partner" class="col-form-label">{{ $t("flexben.voucher_stocks.fields.partner") }}</label>
          <input type="text" class="form-control w-100 m-0"
                 :class="{'border-danger':checkInErrorBag('benefit')}"
                 disabled :value="foundPartner">
        </div>
        <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
          <label for="start_date" class="col-form-label pt-0">
            {{ $t("flexben.voucher_stocks.fields.start_date") }}
          </label>
          <div class="d-inline-flex w-100">
            <font-awesome-icon icon="calendar-alt" class="my-auto mr-2 text-24 flx-calendar-icon"/>
            <datepicker :format="dateFormat" :inputClass="'form-control-d m-0 w-100 background-white'"
                        v-model="voucherDetails.start_date"
                        :class="{'w-100':true, 'border-danger':checkInErrorBag('start_date')}"
                        id="start_date"></datepicker>
            <img class="cursor-pointer ml-2" alt="x"
                 src="@/assets/images/flexben/table-remove-button.svg"
                 @click="voucherDetails.start_date=null"/>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
          <label for="end_date" class="form-fields-required col-form-label  pt-0">
            {{ $t("flexben.voucher_stocks.fields.end_date") }}
          </label>
          <div class="d-inline-flex w-100">
            <font-awesome-icon icon="calendar-alt" class="my-auto mr-2 text-24 flx-calendar-icon"/>
            <datepicker :format="dateFormat" :inputClass="'form-control-d m-0 w-100 background-white'"
                        v-model="voucherDetails.end_date"
                        :class="{'w-100':true, 'border-danger':checkInErrorBag('end_date')}"
                        id="end_date"></datepicker>
            <img class="cursor-pointer ml-2" alt="x"
                 src="@/assets/images/flexben/table-remove-button.svg"
                 @click="voucherDetails.end_date=null"/>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
          <label for="code" class="form-fields-required col-form-label">{{ $t("flexben.voucher_stocks.fields.code")
            }}</label>
          <input id="code" type="text" class="form-control w-100 m-0"
                 maxlength="255"
                 :class="{'border-danger':checkInErrorBag('code')}"
                 v-model.trim="voucherDetails.code">
        </div>
        <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
          <label for="subscription_period" class="col-form-label">{{
            $t("flexben.voucher_stocks.fields.subscription_period") }}</label>
          <input id="subscription_period" type="text" class="form-control w-100 m-0"
                 :class="{'border-danger':checkInErrorBag('subscription_period')}"
                 maxlength="500"
                 v-model.trim="voucherDetails.subscription_period">
        </div>

        <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
          <label for="pin_code" class="col-form-label">{{ $t("flexben.voucher_stocks.fields.pin_code") }}</label>
          <input id="pin_code" type="text" class="form-control w-100 m-0"
                 maxlength="255"
                 :class="{'border-danger':checkInErrorBag('pin_code')}"
                 v-model.trim="voucherDetails.pin_code">
        </div>

        <div class="col-12 form-group mb-0">
          <label for="instructions" class="form-fields-required col-form-label">{{
            $t("flexben.voucher_stocks.fields.instructions") }}</label>
          <textarea name="instructions" id="instructions" class="form-control" rows="4"
                    maxlength="2000"
                    :class="{'border-danger':checkInErrorBag('instructions')}"
                    v-model.trim="voucherDetails.instructions"></textarea>
        </div>

        <div class="col-12 form-group mb-0">
          <label for="campaign_terms" class="col-form-label">{{ $t("flexben.voucher_stocks.fields.campaign_terms")
            }}</label>
          <textarea name="campaign_terms" id="campaign_terms" class="form-control" rows="4"
                    maxlength="2000"
                    :class="{'border-danger':checkInErrorBag('campaign_terms')}"
                    v-model.trim="voucherDetails.campaign_terms"></textarea>
        </div>
        <div class="col-12 form-group mb-0">
          <label for="comments" class="col-form-label">{{ $t("flexben.voucher_stocks.fields.comments")
            }}</label>
          <textarea name="comments" id="comments" class="form-control" rows="4"
                    maxlength="500"
                    :class="{'border-danger':checkInErrorBag('comments')}"
                    v-model.trim="voucherDetails.comments"></textarea>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="w-100 d-flex">
        <button class="btn text-themed mt-2" @click="closeModal()">{{ $t('flexben.general.btn.close') }}
        </button>
        <button v-if="action=='import'"
                class="btn btn-themed-red ml-auto px-4" @click="deleteAction()">{{
          $t('flexben.general.btn.delete') }}
        </button>
        <button v-if="(action==='update' && voucherDetails.status==1) || action=='import'"
                class="btn btn-themed ml-2 px-4"
                :class="{'ml-auto':(action==='update')}"
                @click="confirmAction()">{{
          $t('flexben.general.btn.save') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";
import Datepicker from "vuejs-datepicker";

export default {
  name: "BenefitVoucherDetailsModal",
  components: {
    'b-modal': BModal,
    datepicker: Datepicker
  },
  props: {
    availableOptions: {
      type: Object,
      require: true,
      default() {
        return {
          benefits: [],
          partners: []
        };
      }
    }
  },
  data: () => {
    return {
      initialSet: false,
      dateFormat: 'dd.MM.yyyy',
      disabledDates: new Date(),
      voucherDetails: {
        id: null,
        import_benefit_voucher_id: null,
        benefit_id: null,
        partner_id: null,
        status: null,
        benefit_crm_code: null,
        code: null,
        start_date: null,
        end_date: null,
        pin_code: null,
        instructions: null,
        campaign_terms: null,
        subscription_period: null,
        invalid_reasons: null,
        comments: null,
      },
      selectedBenefit: null,
      foundPartner: null,
      action: 'import',
    };
  },
  watch: {
    selectedBenefit: {
      handler: function (v) {
        if (this.initialSet) {
          this.voucherDetails.benefit_id = (v === null || v.value === 'not_found') ? null : v.value;

          if (this.voucherDetails.benefit_id === null) {
            this.voucherDetails.partner_id = null;
          } else {
            let foundPartner = (this.voucherDetails.benefit_id === null) ? [] : this.availableOptions.partners.filter((item) => {
              return parseInt(item.value) == parseInt(v.partner_id);
            });
            this.foundPartner = (foundPartner.length > 0) ? foundPartner[0].text : null;
            this.voucherDetails.partner_id = (foundPartner.length > 0) ? foundPartner[0].value : null;
          }
        } else {
          this.initialSet = true;
        }
      }
    }
  },
  methods: {
    openModal(voucherDetails, action = 'import') {
      this.initialSet = false;
      this.action = action;
      this.voucherDetails = voucherDetails;
      if (action === 'import') {
        if (voucherDetails.benefit_id !== null) {
          this.selectedBenefit = this.availableOptions.benefits.filter((item) => {
            return item.value == voucherDetails.benefit_id;
          })[0];
        } else {
          this.selectedBenefit = this.availableOptions.benefits[0];
        }
        this.initialSet = true;
      } else {
        this.selectedBenefit = {
          value: voucherDetails.benefit_id,
          text: voucherDetails.benefit.name,
        };
        this.foundPartner = voucherDetails.partner.name;
      }
      this.$refs['details_modal'].show();
    },
    closeModal() {
      this.$emit('closeAction');
      this.$refs['details_modal'].hide();
    },
    confirmAction() {
      this.$emit('confirmAction', this.voucherDetails);
      this.$refs['details_modal'].hide();
    },
    deleteAction() {
      this.$emit('deleteAction', this.voucherDetails.id);
      this.$refs['details_modal'].hide();
    },
    checkInErrorBag(key) {
      return (this.voucherDetails.invalid_reasons != null && typeof this.voucherDetails.invalid_reasons[key] != 'undefined')
    },
    getInvalidReasons(invalidReasons) {
      return this.$parent.getInvalidReasons(invalidReasons)
    },
  },
}
</script>
