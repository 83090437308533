var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container-bg-white mt-3"},[_c('div',{staticClass:"row px-3 pt-3"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"m-0 p-0"},[_vm._v(_vm._s(_vm.$t("flexben.requests.title_specific_info")))])])]),_c('div',{staticClass:"row px-3 py-2"},[(_vm.requestData.benefit.partner_id!==null)?_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"partner-badge mr-2",attrs:{"src":require("@/assets/images/flexben/utils/new-beneficiary-icon.png"),"alt":"company_icon"}}),_vm._v(_vm._s(_vm.requestData.benefit.partner.name)+" - "+_vm._s(_vm.requestData.benefit.partner.iban)+" ")]):_c('div',{staticClass:"col-12"},[_vm._v(" "+_vm._s(_vm.$t("flexben.requests.no_benefit_partner"))+" ")])]),_c('div',{staticClass:"row px-3 py-2"},[_c('div',{staticClass:"col-2"},[_c('h3',{staticClass:"m-0 p-0"},[_vm._v(_vm._s(_vm.$t("flexben.requests.fields.personal_use")))])]),_c('div',{staticClass:"col-2"},[_c('b-form-checkbox',{staticClass:"mx-3",attrs:{"switch":"","disabled":true},model:{value:(_vm.requestData.benefit.personal_use),callback:function ($$v) {_vm.$set(_vm.requestData.benefit, "personal_use", $$v)},expression:"requestData.benefit.personal_use"}})],1)]),(_vm.benefitDynamicFormFields.length>0)?_c('div',{staticClass:"row px-3 pb-3"},_vm._l((_vm.benefitDynamicFormFields),function(field,index){return _c('div',{key:index,staticClass:"col-12 col-md-6 col-xl-4 form-group mb-0"},[_c('label',{staticClass:"col-form-label",attrs:{"for":index+field.key}},[(typeof field.extra.label == 'object')?[_vm._v(" "+_vm._s(field.extra.label[_vm.$store.state.language])+" ")]:[_vm._v(" "+_vm._s(field.extra.label)+" ")],_vm._v(" ("+_vm._s(field.extra.type)+_vm._s((field.extra.type==='input')?' '+field.extra.inputType :'')+")")],2),(field.extra.type !== 'checklist' &&
         field.extra.type!=='BsSwitch' &&
          field.extra.type!=='vueMultiSelect'&&
           field.extra.type!=='textArea'&&
           (field.extra.type==='input'&& field.extra.inputType!=='date')&&
           (field.extra.type==='input'&& field.extra.inputType!=='datetime')&&
            field.extra.type!=='radios')?_c('div',{staticClass:"form-control disabled"},[_vm._v(_vm._s(field.value)+" ")]):_vm._e(),(field.extra.type === 'radios')?_c('div',_vm._l((field.extra.values),function(item,index){return _c('label',{key:index,staticClass:"mr-2"},[_c('input',{staticClass:"mr-1",attrs:{"type":"radio","disabled":""},domProps:{"checked":(field.value===item.value)}}),_vm._v(_vm._s(item.name))])}),0):_vm._e(),(field.extra.type === 'checklist')?_c('div',{staticClass:"form-control disabled"},[_vm._v(_vm._s(_vm.getChecklistValues(field))+" ")]):_vm._e(),(field.extra.type === 'BsSwitch')?_c('div',{staticClass:"form-control disabled"},[_vm._v(_vm._s(_vm.getBsSwitchValue(field))+" ")]):_vm._e(),(field.extra.type === 'vueMultiSelect')?_c('div',{staticClass:"form-control disabled"},[_vm._v(_vm._s(_vm.getVueMultiSelectValues(field.value))+" ")]):_vm._e(),(field.extra.type==='input'&& field.extra.inputType==='date')?_c('div',{staticClass:"form-control disabled"},[_vm._v(_vm._s(_vm.moment(field.value, 'DD/MM/YYYY'))+" ")]):_vm._e(),(field.extra.type==='input'&& field.extra.inputType==='datetime')?_c('div',{staticClass:"form-control disabled"},[_vm._v(_vm._s(_vm.moment(field.value, 'DD/MM/YYYY HH:mm', 'YYYY-MM-DDThh:mm'))+" ")]):_vm._e(),(field.extra.type==='select')?_c('div',{staticClass:"form-control disabled"},[_vm._v(_vm._s(field.value ? field.value : '-')+" ")]):_vm._e(),(field.extra.type === 'textArea')?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(field.value),expression:"field.value"}],staticClass:"form-control-textarea disabled m-0",attrs:{"id":index+field.key,"rows":"5"},domProps:{"value":(field.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(field, "value", $event.target.value)}}}):_vm._e()])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }