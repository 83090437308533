<template>
  <div class="container-bg-white mt-3">
    <div class="row px-3 pt-3">
      <div class="col-12 col-md-6 col-xl-9">
        <h3 class="mt-0 pt-0 pl-0 mb-1 text-capitalize">
          {{ $t('flexben.benefit_groups.benefit_types.2') }}
        </h3>
      </div>
      <div class="col-12 col-md-6 col-xl-3 d-flex">
        <button class="btn btn-themed-o mr-1 ml-0 ml-md-auto ml-xl-0" @click="massAllocate(true)">{{
          $t('flexben.benefit_groups.btn.add_all') }}
        </button>
        <button class="btn btn-themed-o" @click="massAllocate(false)">{{ $t('flexben.benefit_groups.btn.remove_all')
          }}
        </button>
      </div>
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <td class="w-25">{{ $t("flexben.benefit_groups.detail.ben_name") }}</td>
              <td class="w-50 d-none d-md-table-cell">{{ $t("flexben.benefit_groups.detail.description") }}</td>
              <td class="w-25">{{ $t("flexben.benefit_groups.detail.allocation_control") }}</td>
            </tr>
            </thead>
            <tbody v-if="benefits.fixed_benefits.length > 0">
            <tr v-for="(benefit, index) in benefits.fixed_benefits" :key="index">
              <td class="w-25" v-html="benefit.name[currentLang]">
              </td>
              <td class="w-50 d-none d-md-table-cell">
                <div class="text-ellipsis-40 links-container" :ref="'benefit_details_'+benefit.id"
                     v-html="benefit.description[currentLang]">
                </div>
                <br>
                <a class="text-themed btn-link cursor-pointer"
                   v-if="benefit.description[currentLang]!==null && benefit.description[currentLang].length > 40"
                   @click="readMore(benefit.id)">
                  {{ $t('flexben.general.'+(benefit.id===readMoreBenId ? 'read_less': 'read_more')) }}
                </a>
              </td>
              <td class="d-inline-flex w-25">
                <b-form-checkbox :id="'benefit_toggle_'+benefit.id"
                                 switch
                                 :checked="formData.benefit_ids.includes(benefit.id)"
                                 @change="toggleBenefitId(benefit.id)">
                </b-form-checkbox>
                <span class="ml-3 my-auto">{{ $t("flexben.benefit_groups.detail."+
                  (formData.benefit_ids.includes(benefit.id) ? 'allocated': 'not_allocated')) }}</span>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="3" class="text-center trhover gri-cell">
                {{
                $t("flexben.benefit_groups.no_benefits_available",{type:$t('flexben.benefit_groups.benefit_type_flexible')})
                }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BFormCheckbox} from "bootstrap-vue";

export default {
  name: "FixedBenefits",
  components: {
    "b-form-checkbox": BFormCheckbox,
  },
  props: {
    currentLang: {
      type: String,
      default: 'ro'
    },
    formAction: {
      type: String,
      required: true,
    },
    benefits: {
      type: Object,
      default: function () {
        return {
          fixed_benefits: [],
        };
      }
    },
    formData: {
      type: Object,
      default: function () {
        return {
          id: null,
          company: null,
          benefit_type: null,
          name: '',
          code: '',
          is_default: false,
          benefit_ids: []
        };
      }
    },
  },
  data() {
    return {
      readMoreBenId: null,
    };
  },
  methods: {
    toggleBenefitId(benefitId) {
      if (this.formData.benefit_ids.includes(benefitId)) {
        let benefitIndex = this.formData.benefit_ids.indexOf(benefitId);
        this.formData.benefit_ids.splice(benefitIndex, 1);
      } else {
        this.formData.benefit_ids.push(benefitId);
      }
    },
    readMore(benefitId) {
      if (this.readMoreBenId !== benefitId &&
        this.readMoreBenId !== null &&
        !this.$refs['benefit_details_' + this.readMoreBenId][0].classList.contains('text-ellipsis-40')) {
        this.$refs['benefit_details_' + this.readMoreBenId][0].classList.add('text-ellipsis-40');
      }

      if (this.$refs['benefit_details_' + benefitId][0].classList.contains('text-ellipsis-40')) {
        this.$refs['benefit_details_' + benefitId][0].classList.remove('text-ellipsis-40');
      } else {
        this.$refs['benefit_details_' + benefitId][0].classList.add('text-ellipsis-40');
      }
      this.readMoreBenId = (this.readMoreBenId === benefitId) ? null : benefitId;
    },
    massAllocate(add) {
      let item, indexOfId;
      for (item of this.benefits.fixed_benefits) {
        if (add) {
          if (!this.formData.benefit_ids.includes(item.id)) {
            this.formData.benefit_ids.push(item.id);
          }
        } else {
          if (this.formData.benefit_ids.includes(item.id)) {
            indexOfId = this.formData.benefit_ids.indexOf(item.id);
            this.formData.benefit_ids.splice(indexOfId, 1);
          }
        }
      }
    },
  }
}
</script>
