<template>
  <div class="row p-3">
    <div class="border-box col-12 col-md-3 ">
      <img src="@/assets/images/filter.svg"/>
      <span class="ml-3">{{$t("flexben.general.filter")}}</span>
    </div>
    <div class="border-box col-12 col-md-9">
      <div class="row">
        <div v-if="filters.departments" class="border-box col-12 col-md-4">
          <label class="col-form-label" for="department">{{$t("flexben.import.table_headings.department")}}</label>
          <select id="department" class="form-control w-100"
                  @change="filterValues()"
                  v-model="importFilterValues.department">
            <option value="" selected>-</option>
            <option v-for="(item, value) in filters.departments.values"
                    :key="value"
                    :value="value">{{ item }}
            </option>
          </select>
        </div>
        <div v-if="filters.job_title_list" class="border-box col-12 col-md-4">
          <label class="col-form-label" for="job_title">{{$t("flexben.import.table_headings.job_title")}}</label>
          <select id="job_title" class="form-control w-100"
                  @change="filterValues()"
                  v-model="importFilterValues.job_title">
            <option value="" selected>-</option>
            <option v-for="(item, value) in filters.job_title_list.values"
                    :key="value"
                    :value="value">{{ item }}
            </option>
          </select>
        </div>
        <div v-if="filters.cost_centers" class="border-box col-12 col-md-4">
          <label class="col-form-label" for="cost_center">{{$t("flexben.import.table_headings.cost_center")}}</label>
          <select id="cost_center" class="form-control w-100"
                  @change="filterValues()"
                  v-model="importFilterValues.cost_center">
            <option value="" selected>-</option>
            <option v-for="(item, value) in filters.cost_centers.values"
                    :key="value"
                    :value="value">{{ item }}
            </option>
          </select>
        </div>
        <div v-if="filters.working_points" class="border-box col-12 col-md-4">
          <label class="col-form-label"
                 for="working_point">{{$t("flexben.import.table_headings.working_point")}}</label>
          <select id="working_point" class="form-control w-100"
                  @change="filterValues()"
                  v-model="importFilterValues.working_point">
            <option value="" selected>-</option>
            <option v-for="(item, value) in filters.working_points.values"
                    :key="value"
                    :value="value">{{ item }}
            </option>
          </select>
        </div>
        <div v-if="filters.benefit_categories" class="border-box col-12 col-md-4">
          <label class="col-form-label" for="group">{{$t("flexben.import.table_headings.group")}}</label>
          <select id="group" class="form-control w-100"
                  @change="filterValues()"
                  v-model="importFilterValues.benefit_category_code">
            <option value="" selected>-</option>
            <option v-for="(item, value) in filters.benefit_categories.values"
                    :key="value"
                    :value="value">{{ item }}
            </option>
          </select>
        </div>
        <div v-if="filters.statuses" class="border-box col-12 col-md-4">
          <label class="col-form-label" for="status">{{$t("flexben.general.status")}}</label>
          <select @change="filterValues()" id="status" class="form-control w-100"
                  v-model="importFilterValues.status">
            <option :value="null">All</option>
            <option v-for="(status, index) in filters.statuses.values"
                    :key="index"
                    :value="status">{{ $t("flexben.import.item_statuses."+status) }}
            </option>
          </select>
        </div>
        <div v-if="filters.invitation_channel.values.length > 0"
             class="border-box col-12 col-md-4">
          <label class="col-form-label" for="channel">{{$t("flexben.import.table_headings.invitation_channel")}}</label>
          <select @change="filterValues()" id="channel" class="form-control w-100"
                  v-model="importFilterValues.invitation_channel">
            <option :value="null">All</option>
            <option v-for="(value, index) in filters.invitation_channel.values"
                    :key="index"
                    :value="value">{{ value }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImportReviewFilters",
  props: {
    importFilterValues: {
      required: true,
      type: Object
    },
    filters: {
      required: true,
      type: Object
    },
  },
  methods: {
    filterValues() {
      this.$emit('filterValues');
    }
  }
}
</script>
