<template>
  <div class="show modal fade">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header w-100 no-border pb-0">
          <h3 class="float-left">{{ $t('flexben.general.edit') }} {{ $t('flexben.import.types.time_sheet') }}</h3>
          <span class="float-right my-auto" :class="'import-status-item-'+itemData.status">{{ $t('flexben.import.item_statuses.'+itemData.status) }}</span>
        </div>
        <div class="modal-body py-0 bg-white">
          <div class="row text-danger" v-if="itemData.invalid_reasons !== null">
            <div class="col-12 text-danger mb-1"
                 v-html="getInvalidReasons(itemData.invalid_reasons)">
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="first_name">
                {{ $t('flexben.import.table_headings.first_name') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white"
                     :class="{'border-danger':checkInErrorBag('first_name')}" id="first_name"
                     v-model="itemData.first_name">
            </div>
            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="last_name">
                {{ $t('flexben.import.table_headings.last_name') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white"
                     :class="{'border-danger':checkInErrorBag('last_name')}" id="last_name"
                     v-model="itemData.last_name">
            </div>
            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="cnp">
                {{ $t('flexben.import.table_headings.cnp') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white"
                     :class="{'border-danger':checkInErrorBag('cnp')}" id="cnp" v-model="itemData.cnp">
            </div>
            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="working_days">
                {{ $t('flexben.import.table_headings.working_days') }}</label>
              <input type="number" class="form-control m-0 w-100 background-white"
                     :class="{'border-danger':checkInErrorBag('working_days')}" id="working_days"
                     v-model="itemData.working_days">
            </div>
          </div>
        </div>


        <div class="modal-footer mb-2 no-border w-100 d-inline">
          <button class="btn text-danger float-left" @click="dropImportItem">
            <font-awesome-icon icon="trash-alt"/>
            {{ $t('flexben.general.delete') }}
          </button>
          <button class="btn save-btn float-right" @click="updateImportItem">{{ $t('flexben.general.btn.save') }}</button>
          <button class="btn float-right text-themed" @click="closeModal">{{ $t('flexben.general.btn.cancel') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "EditTimeSheetModal",
    components: {},
    props: {
      item_data: {
        required: true,
        type: Object,
        default: function () {
          return {};
        }
      },

    },
    computed: {
      itemData: function () {
        return this.item_data;
      }
    },
    methods: {
      updateImportItem() {
        this.itemData.working_days = parseInt(this.itemData.working_days);
        this.$emit('updateImportItem');
      },
      closeModal() {
        this.$emit('closeModal');
      },
      dropImportItem() {
        this.$emit('dropImportItem');
      },
      getInvalidReasons(invalidReasons) {
        return this.$parent.getInvalidReasons(invalidReasons)
      },
      checkInErrorBag(field) {
        if (this.itemData.invalid_reasons == null) {
          return false;
        }

        let objKeys = Object.keys(this.itemData.invalid_reasons);
        return objKeys.includes(field);
      }
    }
  }
</script>
