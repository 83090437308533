<template>
  <div>
    <div class="mt-3 container-bg-white">
      <Filters :isAdmin="isAdmin"
               :companyId="companyId"
               :params="params"
               :filterOptions="filter_options"
               :showDeliveryAddress="true"
               @filterValues="filterValues"/>
    </div>
    <div class="mt-3 container-bg-white">
      <div class="row p-3">
        <div class="col-12 col-md-6">
          <h3 class="pl-0 mt-0 mb-1">{{ $t("flexben.requests.new_requests") }}</h3>
        </div>
        <div class="col-12 col-md-6">
          <input type="text"
                 v-model="searchString"
                 v-on:keyup.enter="search()"
                 :placeholder="$t('flexben.general.search')"
                 class="formedituser search-input float-right"/>
        </div>
        <div class="col-12">
          <div class="table-responsive hover-table">
            <table class="table table-flx table-striped">
              <thead>
              <tr>
                <td class="w-25-p"><input type="checkbox" id="check_all" v-model="checkedAllModel" @change="checkAll()">
                </td>
                <td class="cursor-pointer" @click="sortBy('id')">
                  {{ $t("flexben.requests.table.header.number") }}
                  <img src="@/assets/images/arrow-down-fill.svg"
                       :class="{'rotate-180':(sortByField === 'id' && reverseSort)}"/>
                </td>
                <td class="cursor-pointer" @click="sortBy('placed_at')">
                  {{ $t("flexben.requests.table.header.placed_on") }}
                  <img src="@/assets/images/arrow-down-fill.svg"
                       :class="{'rotate-180':(sortByField === 'placed_at' && reverseSort)}"/>
                </td>
                <td>{{ $t("flexben.requests.table.header.beneficiary") }}</td>
                <td class="w-120-p">{{ $t("flexben.requests.table.header.beneficiary_cnp") }}</td>
                <td v-if="isAdmin">{{ $t("flexben.requests.table.header.client") }}</td>
                <td>{{ $t("flexben.requests.table.header.benefit") }}</td>
                <td>{{ $t("flexben.requests.table.header.platform_source") }}</td>
                <td>{{ $t("flexben.requests.table.header.delivery_address") }}</td>
              </tr>
              </thead>
              <tbody>
              <template v-if="requests.length >0">
                <tr class="trhover"
                    v-for="(request, index) in requests" :key="index">
                  <td class="w-25-p">
                    <input :checked="checkedRequests.ids.includes(request.id)" type="checkbox"
                           @change="toggleCheck(index)"/>
                  </td>
                  <td>{{ request.id }}</td>
                  <td>{{ moment(request.placed_at, 'DD/MM/YYYY HH:mm') }}</td>
                  <td>{{ request.employee_name }}</td>
                  <td class="w-120-p">{{ request.employee_cnp }}</td>
                  <td v-if="isAdmin">{{ request.company_name }}</td>

                  <td>
                    <span :style="{backgroundColor: request.benefit_color}" class="color-bullet mr-1"></span>
                    {{ request.benefit_name }}
                  </td>
                  <td>
                    {{request.platform_source}}
                  </td>
                  <td>
                    <select class="form-control" v-model="request.employee_delivery_id">
                      <option v-for="(address, id) in filter_options.company_addresses" :value="id" :key="id">{{id}} -
                        {{address}}
                      </option>
                    </select>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr class="gri-cell text-center trhover">
                  <td :colspan="isAdmin ? 8:7" class="text-center">{{ $t("flexben.general.no_results_found") }}
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 container-bg-white">
      <RequestsSummary :isAdmin="isAdmin"
                       :summaryList="checkedRequests.summaryList"
                       :checkedIds="checkedRequests.ids"
                       :key="requestSummaryKey"
                       :showPlaceOrderBtn="true"
                       :showCompanyAddresses="true"
                       :showTotalValue="false"
                       :companyAddresses="filter_options.company_addresses"
                       @setDeliveryAddresses="setDeliveryAddresses"
                       @reRenderList="reRenderList()"
                       @placeOrder="placeOrder()"/>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import httpFlexbenService from "@/http/http-flexben";
import RequestsSummary from "@/components/flexben/requests/partials/RequestsSummary";
import Filters from "@/components/flexben/requests/partials/Filters";

export default {
  name: "ListAllRequests",
  components: {
    RequestsSummary,
    Filters,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
      required: true,
    },
    companyId: {
      type: Number,
    },
  },
  data: function () {
    return {
      httpService: httpFlexbenService.requests,
      dateFormat: 'dd/MM/yyyy',
      filter_options: {
        benefits: [],
        benefit_groups: [],
        companies: [],
        beneficiaries: [],
        company_addresses: [],
        departments: [],
        cost_centers: [],
        working_points: [],
        job_title_list: [],
      },
      searchString: '',
      reverseSort: false,
      sortByField: null,
      params: {
        benefit: null,
        company: null,
        beneficiary: null,
        company_address: null,
        department: null,
        cost_center: null,
        working_point: null,
        job_title: null,
        benefit_group: null,
        start_date: null,
        end_date: null,
        sort_by: null,
        sort_direction: null,
        selected_platform: null,
      },
      requests: {},
      checkedRequests: {
        ids: [],
        indexes: [],
        addresses: {},
        summaryList: {}
      },
      requestSummaryKey: 0,
      checkedAllModel: false
    };
  },
  created() {
    this.params.company = this.companyId;
    this._loadList();
  },
  methods: {
    setGeneralData() {
      this.$emit('setGeneralData');
    },
    sortBy(fieldName) {
      if (this.sortByField !== fieldName) {
        this.reverseSort = false;
      }
      this.params.sort_by = fieldName;
      this.params.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this._loadList(this.params)
      this.sortByField = fieldName;

      this.reverseSort = !this.reverseSort;
    },
    changePerPage(perPage) {
      this.params.page = 1;
      this.params.perPage = perPage;
      this._loadList();
    },
    pageChanged(pageNb) {
      this.params.page = pageNb;
      this._loadList();
    },
    search() {
      if (this.searchString.length >= 3) {
        this.resetFilters();
        this.params.search = this.searchString;
        this._loadList();
      } else if (this.searchString.length === 0) {
        this.resetFilters();
        this.params.search = null;
        this._loadList();
      }
    },
    moment(date, format) {
      return moment(date).format(format);
    },
    filterValues() {
      if (this.isAdmin) {
        this.$emit('setQuickFilterCompany', (this.params.company !== null) ? parseInt(this.params.company) : null);
      }
      this._loadList();
    },
    toggleCheck(index, triggerRerender = true) {
      const request = this.requests[index];

      if (this.checkedRequests.ids.includes(request.id)) {
        this.checkedRequests.ids.splice(this.checkedRequests.ids.indexOf(request.id), 1);
        this.checkedRequests.indexes.splice(this.checkedRequests.indexes.indexOf(index), 1);
        delete this.checkedRequests.addresses[request.id];

        delete this.checkedRequests.summaryList[request.company_id].benefits[request.benefit_id].values[request.id];

        if (!this.checkNotEmptyObj(this.checkedRequests.summaryList[request.company_id].benefits[request.benefit_id].values)) {
          delete this.checkedRequests.summaryList[request.company_id].benefits[request.benefit_id];
        }

        if (!this.checkNotEmptyObj(this.checkedRequests.summaryList[request.company_id].benefits)) {
          delete this.checkedRequests.summaryList[request.company_id];
        }
      } else {
        this.checkedRequests.ids.push(request.id);
        this.checkedRequests.indexes.push(index);
        this.checkedRequests.addresses[request.id] = request.employee_delivery_id;
        let companyObj;
        if (request.company_id in this.checkedRequests.summaryList) {
          companyObj = {...this.checkedRequests.summaryList[request.company_id]};
        } else {
          companyObj = {
            name: request.company_name,
            benefits: {}
          };
        }
        if (request.benefit_id in companyObj.benefits) {
          companyObj.benefits[request.benefit_id]['values'][request.id] = request.total_value;
        } else {
          companyObj.benefits[request.benefit_id] = {
            color: request.benefit_color,
            name: request.benefit_name,
            values: {}
          };
          companyObj.benefits[request.benefit_id]['values'][request.id] = request.total_value;
        }
        this.checkedRequests.summaryList[request.company_id] = companyObj;
      }
      if (triggerRerender) {
        this.requestSummaryKey++;
      }
    },
    checkAll() {
      if (!this.checkedAllModel) {
        this.checkedRequests.ids = [];
        this.checkedRequests.indexes = [];
        this.checkedRequests.addresses = {};
        this.checkedRequests.summaryList = {};
      } else {
        for (let index in this.requests) {
          if (this.checkedRequests.ids.includes(this.requests[index].id) && this.checkedAllModel) {
            continue;
          }
          this.toggleCheck(index, false);
        }
      }
      this.requestSummaryKey++;
    },
    reRenderList() {
      this.checkedRequests.ids = [];
      this.checkedRequests.indexes = [];
      this.checkedRequests.addresses = {};
      this.checkedRequests.summaryList = {};
      this.setGeneralData();
      this._loadList();
    },
    placeOrder() {
      let selectedFilters = {
        company: (this.params.company) ? this.filter_options.companies[this.params.company] : null,
        benefit: (this.params.benefit) ? this.filter_options.benefits[this.params.benefit] : null,
        beneficiary: (this.params.beneficiary) ? this.filter_options.beneficiaries[this.params.beneficiary] : null,
        company_address: (this.params.company_address) ? this.filter_options.company_addresses[this.params.company_address] : null,
        department: (this.params.department) ? this.filter_options.departments[this.params.department] : null,
        cost_center: (this.params.cost_center) ? this.filter_options.cost_centers[this.params.cost_center] : null,
        working_point: (this.params.working_point) ? this.filter_options.working_points[this.params.working_point] : null,
        job_title: (this.params.job_title) ? this.filter_options.job_title_list[this.params.job_title] : null,
        benefit_group: (this.params.benefit_group) ? this.filter_options.benefit_groups[this.params.benefit_group] : null,
        start_date: (this.params.start_date) ? moment(this.params.start_date).format('DD/MM/YYYY') : null,
        end_date: (this.params.end_date) ? moment(this.params.end_date).format('DD/MM/YYYY') : null,
      };
      this.$emit('placeOrder', this.checkedRequests.addresses, selectedFilters, this.filter_options.company_addresses);
    },
    resetFilters() {
      this.params.benefit = null;
      this.params.company = null;
      this.params.beneficiary = null;
      this.params.company_address = null;
      this.params.department = null;
      this.params.cost_center = null;
      this.params.working_point = null;
      this.params.job_title = null;
      this.params.benefit_group = null;
      this.params.start_date = null;
      this.params.end_date = null;
      this.params.sort_by = null;
      this.params.sort_direction = null;
      this.params.selected_platform = null;
    },
    setDeliveryAddresses(addressId) {
      let reqIndex;
      for (reqIndex in this.checkedRequests.indexes) {
        this.requests[reqIndex].employee_delivery_id = parseInt(addressId);
        this.checkedRequests.addresses[this.requests[reqIndex].id] = parseInt(addressId);
      }
    },
    _loadList() {
      this._toggleLoading(true);
      this.httpService.cardList(this.params)
        .then(function (response) {
          this.requests = this._setPreviouslySetAddresses(response.data.list);
          this.filter_options = response.data.filters;
          this.checkedAllModel = false;
          this._toggleLoading(false);
        }.bind(this))
        .catch(function (error) {
        }.bind(this));
    },
    _toggleLoading(value, loaderError = false) {
      this.$emit('toggleLoading', value, loaderError)
    },
    _setPreviouslySetAddresses(requests) {
      if (this.checkNotEmptyObj(this.checkedRequests.addresses)) {
        requests.forEach(function (request) {
          if (request.id in this.checkedRequests.addresses) {
            request.employee_delivery_id = this.checkedRequests.addresses[request.id];
          }
        }.bind(this));
      }
      return requests;
    }
  }
}
</script>
