<template>
  <div class="import-details" :class="(initialLoading) && 'hide'">
    <a class="go-back cursor-pointer mb-2" @click="backToList()">{{ $t("flexben.general.go_back") }}</a>
    <div class="row">
      <div class="col-12 flexben-page-title d-flex">
        <span class="text-24 text-bold my-auto">{{ $t('flexben.import_history.titles.' + importData.type, {date: moment(importData.created_at,'DD/MM/YYYY HH:mm:ss')}) }}</span>
        <button class="ml-md-auto btn btn-themed" @click="downloadExport()">{{ $t('flexben.general.btn.export') }}</button>
      </div>
    </div>

    <div class="container-bg-white mt-2">
      <div class="row px-3 pt-2">
        <div class="col-12 text-28">
          {{$t("flexben.import_history.import_summary")}}
        </div>
        <div class="col-12">
          <div class="table-responsive hover-table">
            <table class="table table-striped">
              <tbody>
              <tr class="trhover gri-cell">
                <td class="w-25">{{$t("flexben.import_history.import_on")}}:</td>
                <td class="w-75">{{ moment(importData.created_at,'DD/MM/YYYY') }}</td>
              </tr>
              <tr class="trhover ">
                <td class="w-25">{{$t("flexben.import_history.imported_status")}}:</td>
                <td class="w-75"><span :class="'import-status-'+importData.status">{{$t("flexben.import.statuses." + importData.status)}}</span>
                </td>
              </tr>
              <tr class="trhover gri-cell">
                <td class="w-25">{{$t("flexben.import_history.imported_type")}}:</td>
                <td class="w-75 d-inline-flex align-items-center">
                  <div v-if="importData.type ==='beneficiary'"
                       class="import-type-icon import-type-icon-beneficiary"></div>
                  <div v-if="importData.type ==='update_beneficiary'"
                       class="import-type-icon import-type-icon-beneficiary"></div>

                  <div v-if="importData.type ==='budget'"
                       class="import-type-icon import-type-icon-timesheet"></div>

                  <div v-if="importData.type ==='time_sheet'"
                       class="import-type-icon import-type-icon-budget"></div>
                  <span class="ml-2 text-capitalize">{{ $t('flexben.import.types.'+importData.type) }}</span></td>
              </tr>
              <tr class="trhover ">
                <td class="w-25">{{$t("flexben.import_history.total_number_of_items")}}:</td>
                <td class="w-75">{{ (importData.status==='complete' && typeof importData.data.summary !== 'undefined') ?
                  importData.data.summary.total_items : '-' }}
                </td>
              </tr>
              <tr class="trhover gri-cell">
                <td class="w-25">{{$t("flexben.import_history.new_items")}}:</td>
                <td class="w-75">
                  {{ (importData.status==='complete' && typeof importData.data.summary !== 'undefined') ?
                  importData.data.summary.total_new_items : '-' }}
                </td>
              </tr>
              <tr class="trhover ">
                <td class="w-25">{{$t("flexben.import_history.updated_items")}}:</td>
                <td class="w-75">
                  {{ (importData.status==='complete' && typeof importData.data.summary !== 'undefined') ?
                  importData.data.summary.total_update_items : '-' }}
                </td>
              </tr>
              <tr class="trhover gri-cell" v-if="importData.type ==='time_sheet'">
                <td class="w-25">{{$t("flexben.import_history.timesheet_imported_for_month")}}:</td>
                <td class="w-75">{{ $t("flexben.general.months."+ importData.ts_month) }} {{ importData.ts_year }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-24 text-bold mt-3 mb-1">
        {{$t("flexben.import_history.import_filers")}}
      </div>
    </div>
    <div class="container-bg-white">
      <div class="row p-3">
        <div class="border-box col-12 col-lg-3">
          <img src="@/assets/images/filter.svg"/>
          <span class="ml-3">{{$t("flexben.import.summary.filter_entries")}}</span>
        </div>
        <div class="border-box col-12 col-xl-9">
          <div class="row">
            <div class="border-box col-12 col-md-4 form-group">
              <label for="department" class=" col-form-label">{{$t("flexben.import.table_headings.department")}}</label>
              <select id="department" class="form-control w-100"
                      @change="filtersValues()"
                      v-model="filters_values.department"
                      :disabled="filters.company_id === null">
                <option value="" selected>-</option>
                <option v-for="(item, value) in filters.departments"
                        :key="value"
                        :value="value">{{ item }}
                </option>
              </select>
            </div>
            <div class="border-box col-12 col-md-4 form-group">
              <label for="job_title" class=" col-form-label">{{$t("flexben.import.table_headings.job_title")}}</label>
              <select id="job_title" class="form-control w-100"
                      @change="filtersValues()"
                      v-model="filters_values.job_title"
                      :disabled="filters.company_id === null">
                <option value="" selected>-</option>
                <option v-for="(item, value) in filters.job_title_list"
                        :key="value"
                        :value="value">{{ item }}
                </option>
              </select>
            </div>
            <div class="border-box col-12 col-md-4 form-group">
              <label for="cost_center"
                     class=" col-form-label">{{$t("flexben.import.table_headings.cost_center")}}</label>
              <select id="cost_center" class="form-control w-100"
                      @change="filtersValues()"
                      v-model="filters_values.cost_center"
                      :disabled="filters.company_id === null">
                <option value="" selected>-</option>
                <option v-for="(item, value) in filters.cost_centers"
                        :key="value"
                        :value="value">{{ item }}
                </option>
              </select>
            </div>
            <div class="border-box col-12 col-md-4 form-group">
              <label for="working_point"
                     class=" col-form-label">{{$t("flexben.import.table_headings.working_point")}}</label>
              <select id="working_point" class="form-control w-100"
                      @change="filtersValues()"
                      v-model="filters_values.working_point"
                      :disabled="filters.company_id === null">
                <option value="" selected>-</option>
                <option v-for="(item, value) in filters.working_points"
                        :key="value"
                        :value="value">{{ item }}
                </option>
              </select>
            </div>
            <div class="border-box col-12 col-md-4 form-group">
              <label for="group" class=" col-form-label">{{$t("flexben.import.table_headings.group")}}</label>
              <select id="group" class="form-control w-100"
                      @change="filtersValues()"
                      v-model="filters_values.benefit_category_code"
                      :disabled="filters.company_id === null">
                <option value="" selected>-</option>
                <option v-for="(item, value) in filters.benefit_categories"
                        :key="value"
                        :value="value">{{ item }}
                </option>
              </select>
            </div>
            <div class="border-box col-12 col-md-4 form-group">
              <label for="status" class="col-form-label">{{$t("flexben.general.status")}}</label>
              <select @change="filtersValues()" id="status" class="form-control w-100"
                      v-model="filters_values.status">
                <option :value="'all'">{{$t("flexben.general.all")}}</option>
                <option v-for="(status, index) in filters.statuses"
                        :key="index"
                        :value="status">{{ $t("flexben.general.status_type."+status) }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-bg-white mt-3">
      <div class="row p-3">
        <div class="col-12 text-24 text-bold">
          {{$t("flexben.import.entries")}}
        </div>
        <div class="col-12 mt-3">
          <TableTypeBeneficiary v-if="importData.type ==='beneficiary'"
                                :importItems="importItems.data">
          </TableTypeBeneficiary>
          <TableTypeUpdateBeneficiary v-if="importData.type ==='update_beneficiary'"
                                :importItems="importItems.data">
          </TableTypeUpdateBeneficiary>

          <TableTypeTimesheet v-if="importData.type ==='time_sheet'"
                              :importData="importData"
                              :importItems="importItems.data">
          </TableTypeTimesheet>
          <TableTypeBudget v-if="importData.type ==='budget'"
                           :importItems="importItems.data">
          </TableTypeBudget>
        </div>
        <div class="col-12 p-0">
          <listPagination
            :currentPage="importItems.current_page"
            :totalItems="importItems.total"
            :startItem="importItems.from"
            :endItem="importItems.to"
            :itemsPerPage="importItems.perPage"
            @pageChanged="pageChanged"
            @changePerPage="changePerPage">
          </listPagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableTypeBudget from "./TableTypeBudget";
import TableTypeTimesheet from "./TableTypeTimesheet";
import TableTypeBeneficiary from "./TableTypeBeneficiary";
import TableTypeUpdateBeneficiary from "./TableTypeUpdateBeneficiary";
import moment from "moment";
import i18n from "../../../../plugins/language/i18n";
import httpFlexbenService from "@/http/http-flexben.js";
import utils from "@/plugins/utils";

export default {
  name: "ImportHistoryDetails",
  components: {TableTypeBudget, TableTypeTimesheet, TableTypeBeneficiary,TableTypeUpdateBeneficiary},
  props: {
    importId: {
      default: null,
      required: true
    }
  },
  mounted() {
    if (typeof this.importId === "undefined" || this.importId == null) {
      this.backToList();
      this.$parent.loading = false;
    } else {
      this._getImportData();
    }
  },
  data() {
    return {
      initialLoading: true,
      filters: {
        departments: {},
        benefit_categories: {},
        job_title_list: {},
        cost_centers: {},
        working_points: {},
        statuses: [],
      },
      importData: {
        data: {
          summary: {
            total_items: '',
            total_new_items: '',
            total_update_items: '',
          }
        }
      },
      importItems: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      params: {},
      filters_values: {
        department: '',
        job_title: '',
        cost_center: '',
        working_point: '',
        benefit_category_code: '',
        status: 'all',
      },
      httpExportServices: httpFlexbenService.exports,
    };
  },
  methods: {
    backToList() {
      this.$emit('backToList');
    },
    _getImportData() {
      this._toggleLoading(true);
      this.$parent.httpService.getOne(this.importId, this.params)
        .then(function (response) {
          if (this.initialLoading) {
            this.filters = {
              departments: response.data.filters.departments.values,
              benefit_categories: response.data.filters.benefit_categories.values,
              job_title_list: response.data.filters.job_title_list.values,
              cost_centers: response.data.filters.cost_centers.values,
              working_points: response.data.filters.working_points.values,
              statuses: response.data.filters.statuses.values,
            };
            this.importData = response.data.import;
          }
          this.importItems = response.data.items;
          this.initialLoading = false;
          this._toggleLoading(false);
        }.bind(this))
        .catch(function (error) {
          this._toggleLoading(true, true);
        }.bind(this))

    },
    filtersValues() {
      if (!this.initialLoading) {
        let paramsBag = {};
        let filterValues = this.filters_values;

        if (filterValues.department !== '') {
          paramsBag.department = filterValues.department;
        }
        if (filterValues.job_title !== '') {
          paramsBag.job_title = filterValues.job_title;
        }
        if (filterValues.cost_center !== '') {
          paramsBag.cost_center = filterValues.cost_center;
        }
        if (filterValues.working_point !== '') {
          paramsBag.working_point = filterValues.working_point;
        }
        if (filterValues.benefit_category_code !== '') {
          paramsBag.benefit_category_code = filterValues.benefit_category_code;
        }
        if (this.filters_values.status !== '' && this.filters_values.status !== 'all') {
          paramsBag.status = this.filters_values.status;
        }
        this.searchString = '';
        this.sortField = '';
        this.sortDirection = 'asc';
        this.reverseSort = false;
        this.params = paramsBag;
        this._getImportData();
      }
    },
    pageChanged(page) {
      this.params.page = page;
      this._getImportData();
    },
    changePerPage(perPage) {
      this.params.perPage = perPage;
      this._getImportData();
    },
    moment(date, format) {
      if (date === null) {
        return '-';
      }
      return moment(date).format(format);
    },
    _toggleLoading(loading, loaderError = false) {
      this.$emit('toggleLoading', loading, loaderError);
    },
    downloadExport() {
      this._toggleLoading(true);
      this.httpExportServices.import(this.importId, {
        lang: i18n.locale,
      })
        .then(function (response) {
          utils.generateDownloadFromReponse(response);
          this._toggleLoading(false);
        }.bind(this))
        .catch(function (error) {
          this._toggleLoading(true, true);
        }.bind(this));
    },
  },
}
</script>
