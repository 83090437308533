<template>
  <div :class="{show: openModal }"
       class="modal fade">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header text-center no-border">
          <h3 v-if="modalAction === 'form_submit'" :key="'confirm_modal_title'" class="mx-auto">
            {{$t("flexben.clients.modal.are_you_sure_you_want_to_save_changes")}}
          </h3>
          <h3 v-if="modalAction === 'run_ppp3_command'" :key="'confirm_modal_title'" class="mx-auto">
            {{$t("flexben.clients.modal.run_ppp3_topup_comand")}}
          </h3>
          <h3 v-if="modalAction === 'go_back'" :key="'confirm_modal_title'" class="mx-auto">
            {{$t("flexben.clients.modal.the_information_has_been_changed_are_you_sure_you_want_to_leave")}}
          </h3>
          <h3 v-if="modalAction === 'end'" :key="'confirm_modal_title'" class="mx-auto my-0">
            {{ $t('flexben.general.status_type.'+(formSuccess ? 'success' : 'failed')) }}
          </h3>
        </div>
        <div v-if="modalAction === 'end' && endMessage.length>0"
             class="modal-body text-center bg-white" :class="{'text-danger':!formSuccess}">
          <div class="m-auto">
            {{ endMessage }}
          </div>
        </div>

        <div class="modal-footer mb-2  no-border">
          <button v-if="modalAction === 'end'" type="button"
                  @click="$emit('closeModal')"
                  class="btn save-btn float-right m-auto">
            {{$t("flexben.clients.modal.continue")}}
          </button>
          <button v-if="modalAction !== 'end'" type="button"
                  @click="$emit('closeModal')"
                  class="btn mr-auto text-themed">
            {{$t("flexben.general.btn.cancel")}}
          </button>
          <button v-if="modalAction === 'go_back'" type="button"
                  @click="$emit('confirmAction', 'go_back')"
                  class="btn save-btn">
            {{$t("flexben.clients.modal.confirm")}}
          </button>
          <button v-if="modalAction === 'form_submit'" type="button"
                  @click="$emit('confirmAction','form_submit')"
                  class="btn save-btn">
            {{$t("flexben.general.btn.continue")}}
          </button>
          <button v-if="modalAction === 'run_ppp3_command'" type="button"
                  @click="$emit('confirmAction','run_ppp3_command')"
                  class="btn save-btn">
            {{$t("flexben.general.btn.continue")}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientConfirmModal",
  props: {
    openModal: {
      type: Boolean,
      default: false,
      required: true
    },
    formSuccess: {
      type: Boolean,
      default: false,
      required: true
    },
    modalAction: {
      type: String,
      default: '',
      required: true
    },
    endMessage: {
      type: String,
      default: '',
    },
  },
}
</script>
