<template>
  <div class="container-bg-white mt-3">
    <div class="p-3 w-100">
      <div v-if="importType !== 'update_beneficiaries'" class="text-28 text-bold">
        {{ $t('flexben.import.select',{ type:$t('flexben.import.types.' + importType).toLowerCase() }) }}
      </div>
      <div v-else class="text-28 text-bold">
        {{ $t('flexben.import.select_update_beneficiaries') }}
      </div>
      <div class="mt-3 tab-col-info" v-for="(heading, index) in table_headings" :key="index">
        <strong>Col {{ ++index }}:</strong>
        {{ heading }}
      </div>
    </div>
    <div class="px-3 hover-table table-responsive" v-if="importType === 'beneficiary'">
      <table class="table no-min-width table-striped">
        <thead>
        <tr>
          <td>{{ $t('flexben.import.table_headings.up_ro_address_id') }}</td>
          <td>{{ $t('flexben.import.table_headings.address') }}</td>
        </tr>
        </thead>
        <tbody>
        <template v-if="clientData.addresses">
          <tr v-for="(item, index) in clientData.addresses" :key="index" class="trhover">
            <td>{{item.up_ro_address_id}}</td>
            <td>{{item.address}}</td>
          </tr>
        </template>
        <template v-else>
          <tr class="gri-cell text-center trhover">
            <td colspan="2">{{ $t("flexben.general.no_results_found") }}</td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <div class="mt-3 px-3">
      <FormErrorList :errorBag="errorBag"></FormErrorList>
    </div>
    <div class="p-3 d-inline-flex w-100">
      <div class="w-50">
        <div class="form-group">
          <label class="btn save-btn text-white px-4 col-form-label" for="import_file">{{
            $t('flexben.import.buttons.upload_file')
            }}</label>
          <input id="import_file" class="hide" type="file"
                 accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                 @change="uploadFile($event)"
                 ref="file_input"
                 :key="fileInputKey">
        </div>

        <div class="ml-2">{{importFileName !=='' ? importFileName : $t('flexben.import.no_file_uploaded')}}</div>
      </div>
      <div class="w-50">
        <a :href="exampleLink" target="_blank" class="btn btn-themed-green float-right text-white">
          {{ $t('flexben.import.buttons.download_template') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/language/i18n";
import FormErrorList from "../../../fragments/FormErrorList";

export default {
  name: "UploadImportFile",
  components: {FormErrorList},
  props: {
    clientData: {
      type: Object,
      required: true
    },
    importType: {
      type: String,
      default: function () {
        return '';
      },
      required: true
    },
    importFileName: {
      type: String,
      default: function () {
        return '';
      },
      required: true
    },
    errorBag: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {
    table_headings: function () {
      if (this.importType === 'beneficiaries') {
        return [
          this.$t('flexben.import.table_headings.first_name'),
          this.$t('flexben.import.table_headings.last_name'),
          this.$t('flexben.import.table_headings.invitation_email'),
          this.$t('flexben.import.table_headings.phone_number'),
          this.$t('flexben.import.table_headings.cnp'),
          this.$t('flexben.import.table_headings.invitation_channel') + ' (email/sms)',
          this.$t('flexben.import.table_headings.benefit_group'),
          this.$t('flexben.import.table_headings.employee_number'),
          this.$t('flexben.import.table_headings.department'),
          this.$t('flexben.import.table_headings.job_title'),
          this.$t('flexben.import.table_headings.cost_center'),
          this.$t('flexben.import.table_headings.working_point'),
          this.$t('flexben.import.table_headings.underage_children_nr'),
          this.$t('flexben.import.table_headings.up_ro_address_id'),
          this.$t('flexben.import.table_headings.up_dejun_enable_auto_topup') + ' (' + this.$t('flexben.general.no').toUpperCase() + '/' +
          this.$t('flexben.general.yes').toUpperCase() + ')',
          this.$t('flexben.import.table_headings.up_dejun_ticket_value'),
          this.$t('flexben.import.table_headings.up_dejun_ticket_count'),
          this.$t('flexben.import.table_headings.income_tax_percent'),
          this.$t('flexben.import.table_headings.cass_percent'),
          this.$t('flexben.import.table_headings.cas_percent'),
          this.$t('flexben.import.table_headings.fixed_benefit_group'),
          this.$t('flexben.import.table_headings.ppp3_deduction_limit'),
          this.$t('flexben.import.table_headings.ppp3_personal_deduction_limit'),
          this.$t('flexben.import.table_headings.medical_insurance_deduction_limit'),
          this.$t('flexben.import.table_headings.thresholds.gym_membership'),
        ];
      } else if(this.importType === 'update_beneficiaries') {
        return [
          //TODO:de afisat coloanele din cerinta
          this.$t('flexben.import.table_headings.cnp'),
          this.$t('flexben.import.table_headings.first_name'),
          this.$t('flexben.import.table_headings.last_name'),
          this.$t('flexben.import.table_headings.invitation_email'),
          this.$t('flexben.import.table_headings.phone_number'),
          this.$t('flexben.import.table_headings.invitation_channel') + ' (email/sms)',
          this.$t('flexben.import.table_headings.benefit_group'),
          this.$t('flexben.import.table_headings.employee_number'),
          this.$t('flexben.import.table_headings.department'),
          this.$t('flexben.import.table_headings.job_title'),
          this.$t('flexben.import.table_headings.cost_center'),
          this.$t('flexben.import.table_headings.working_point'),
          this.$t('flexben.import.table_headings.underage_children_nr'),
          this.$t('flexben.import.table_headings.up_ro_address_id'),
          this.$t('flexben.import.table_headings.up_dejun_enable_auto_topup') + ' (' + this.$t('flexben.general.no').toUpperCase() + '/' + this.$t('flexben.general.yes').toUpperCase() + ')',
          this.$t('flexben.import.table_headings.up_dejun_ticket_value'),
          this.$t('flexben.import.table_headings.up_dejun_ticket_count'),
          this.$t('flexben.import.table_headings.income_tax_percent'),
          this.$t('flexben.import.table_headings.cass_percent'),
          this.$t('flexben.import.table_headings.cas_percent'),
          this.$t('flexben.import.table_headings.fixed_benefit_group'),
          this.$t('flexben.import.table_headings.ppp3_deduction_limit'),
          this.$t('flexben.import.table_headings.ppp3_personal_deduction_limit'),
          this.$t('flexben.import.table_headings.medical_insurance_deduction_limit'),
          this.$t('flexben.import.table_headings.thresholds.gym_membership'),
        ];
      }
      else if (this.importType === 'offer_beneficiaries') {
        return [
          this.$t('flexben.import.table_headings.first_name'),
          this.$t('flexben.import.table_headings.last_name'),
          this.$t('flexben.import.table_headings.email'),
          this.$t('flexben.import.table_headings.phone_number'),
          this.$t('flexben.import.table_headings.invitation_channel') + ' (email/sms)',
          this.$t('flexben.import.table_headings.cnp')
        ];
      } else if (this.importType === 'budgets') {
        return [
          this.$t('flexben.import.table_headings.first_name'),
          this.$t('flexben.import.table_headings.last_name'),
          this.$t('flexben.import.table_headings.cnp'),
          this.$t('flexben.import.table_headings.total_budget') + ' Lei',
          this.$t('flexben.import.table_headings.expiration_date'),
          this.$t('flexben.import.table_headings.ppp3_budget'),
          this.$t('flexben.import.table_headings.ppp3_expiration_date'),
        ];
      } else if (this.importType === 'telework_budgets') {
        return [
          this.$t('flexben.import.table_headings.first_name'),
          this.$t('flexben.import.table_headings.last_name'),
          this.$t('flexben.import.table_headings.cnp'),
          this.$t('flexben.import.table_headings.total_budget') + ' Lei'
        ];
      } else if (this.importType === 'time_sheets') {
        return [
          this.$t('flexben.import.table_headings.first_name'),
          this.$t('flexben.import.table_headings.last_name'),
          this.$t('flexben.import.table_headings.cnp'),
          this.$t('flexben.import.table_headings.working_days'),
        ];
      } else {
        return [];
      }
    },
    exampleLink() {
      let baseUrl = process.env.VUE_APP_FLEXBEN_BASE;
      let lang = i18n.locale;

      if (this.importType === 'beneficiaries') {
        return baseUrl + 'import/beneficiaries/example/' + lang;
      } else if (this.importType === 'update_beneficiaries'){
        return baseUrl + 'import/update_beneficiaries/example/' + lang;
      } else if (this.importType === 'budgets') {
        return baseUrl + 'import/budgets/example/' + lang;
      } else if (this.importType === 'time_sheets') {
        return baseUrl + 'import/time-sheets/example/' + lang;
      } else if (this.importType === 'telework_budgets') {
        return baseUrl + 'import/telework-budgets/example/' + lang;
      } else if (this.importType === 'offer_beneficiaries') {
        return baseUrl + 'import/offer-beneficiaries/example/' + lang;
      }else if (this.importType === 'ppp3') {
        return baseUrl + 'import/ppp3/example/' + lang;
      } else {
        return '#';
      }
    }
  },
  data: function () {
    return {
      fileInputKey: 0
    };
  },
  methods: {
    uploadFile($event) {
      this.$emit('uploadFile', $event)
    },
    clearInputFileValue() {
      this.$refs.file_input.value = null;
    }
  }
}
</script>
<style>
.tab-col-info {
  display: inline-block;
  width: 25%;
}

@media (max-width: 1350px) {
  .tab-col-info {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .tab-col-info {
    width: 100%;
  }
}
</style>
