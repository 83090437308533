<template>
  <div class="show modal fade">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header w-100 no-border pb-0">
          <h3 class="float-left">{{ $t('flexben.general.edit') }} {{ $t('flexben.import.types.beneficiary') }}</h3>
          <span class="float-right my-auto" :class="'import-status-item-'+itemData.status">{{ $t('flexben.import.item_statuses.'+itemData.status) }}</span>
        </div>
        <div class="modal-body py-0 bg-white">
          <div class="row text-danger" v-if="itemData.invalid_reasons !== null">
            <div class="col-12 mb-1"
                 v-html="getInvalidReasons(itemData.invalid_reasons)">
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 form-fields-required col-form-label" for="first_name">
                {{ $t('flexben.import.table_headings.first_name') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="first_name"
                     :class="{'border-danger':checkInErrorBag('first_name')||checkInErrorBag('name_mix')}"
                     v-model.trim="itemData.first_name">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 form-fields-required col-form-label" for="last_name">
                {{ $t('flexben.import.table_headings.last_name') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="last_name"
                     :class="{'border-danger':checkInErrorBag('last_name')||checkInErrorBag('name_mix')}"
                     v-model.trim="itemData.last_name">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 form-fields-required col-form-label" for="invitation_email">
                {{ $t('flexben.import.table_headings.invitation_email') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="invitation_email"
                     :class="{'border-danger':checkInErrorBag('email')}"
                     v-model.trim="itemData.email">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 form-fields-required col-form-label" for="phone_number">
                {{ $t('flexben.import.table_headings.phone_number') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="phone_number"
                     :class="{'border-danger':checkInErrorBag('phone_number')}"
                     v-model.trim="itemData.phone_number">
            </div>
            <div class="col-md-6 col-xl-4 form-group mb-0">
              <label for="invitation_channel" class="form-fields-required col-form-label">
                {{ $t('flexben.import.table_headings.invitation_channel') }}</label>
              <select name="invitation_channel" id="invitation_channel"
                      class="form-control w-100 m-0"
                      :class="{'border-danger':checkInErrorBag('invitation_channel')}"
                      v-model="itemData.invitation_channel">
                <option :value="null">-</option>
                <option v-for="(item, index) of importFormOptions.invitation_channels"
                        :key="index"
                        :value="item">{{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="modal-footer mb-2 no-border w-100 d-inline">
          <button class="btn text-danger float-left" @click="dropImportItem">
            <font-awesome-icon icon="trash-alt" class="mr-2"/>
            {{ $t('flexben.general.delete') }}
          </button>
          <button class="btn save-btn float-right" @click="updateImportItem">{{ $t('flexben.general.btn.save') }}
          </button>
          <button class="btn float-right text-themed" @click="closeModal">{{ $t('flexben.general.btn.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditBeneficiaryModal",
  props: {
    item_data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    },
    filters: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    },
    importFormOptions: {
      required: true,
      type: Object,
      default: function () {
        return {
          top_up_fixed_values: {
            enable: false,
            values: []
          },
          top_up_set_ticket_nr: {
            enable: false,
            ticket_value: null,
          },
          up_dejun_enabled: false,
          invitation_channels: []
        };
      }
    },
  },
  computed: {
    itemData: function () {
      return this.item_data;
    }
  },
  methods: {
    updateImportItem() {
      this.$emit('updateImportItem');
    },
    closeModal() {
      this.$emit('closeModal');
    },
    dropImportItem() {
      this.$emit('dropImportItem');
    },
    getInvalidReasons(invalidReasons) {
      return this.$parent.getInvalidReasons(invalidReasons)
    },
    checkInErrorBag(field) {
      if (this.itemData.invalid_reasons == null) {
        return false;
      }

      let objKeys = Object.keys(this.itemData.invalid_reasons);
      return objKeys.includes(field);
    }
  }
}
</script>
