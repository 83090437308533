<template>
  <div v-show="!initialLoading" class="col-12">
    <div class="container-bg-white offer-container">
      <div class="row p-3 justify-content-around" id="filterGroup">
          <div class="filter-container-raport-oferte justify-content-center align-content-center d-flex">
            <img src="@/assets/images/filter.svg"/>
            <span class="align-items-center d-flex ml-3">{{$t("flexben.general.filter")}}</span>
          </div>
          <div class="filter-labels-container-raport-oferte">
            <div class="labels-section d-flex flex-column">
              <div class="label-for-interval-first">{{$t("flexben.reports.inputLabels.startDateOffer")}}</div>
              <div class="label-for-interval-second">{{$t("flexben.reports.inputLabels.endDateOffer")}}</div>
            </div>
            <div class="d-flex flex-column">
              <div class="first-date">
                <div class="col-12 col-md-6 form-group mb-0">
                  <label class="col-form-label" for="start_date_1">{{$t("flexben.reports.inputLabels.from")}}</label>
                  <div class="w-100 d-inline-flex">
                    <label for="start_date_1" class="my-auto mr-2 col-form-label">
                      <font-awesome-icon icon="calendar-alt" class="text-24"/>
                    </label>
                    <vue-datepicker :format="dateFormat"
                                    :inputClass="'form-control-d m-0 w-100 background-white'"
                                    v-model="startDate"
                                    valueType="format"
                                    :class="'w-100'"
                                    key="start_date"
                                    id="start_date">
                    </vue-datepicker>
                    <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                         @click="function (){startDate=null;}"/>
                  </div>
                </div>
                <div class="col-12 col-md-6 form-group mb-0">
                  <label class="col-form-label" for="start_date_2">{{$t("flexben.reports.inputLabels.to")}}</label>
                  <div class="w-100 d-inline-flex">
                    <label for="start_date_2" class="my-auto mr-2 col-form-label">
                      <font-awesome-icon icon="calendar-alt" class="text-24"/>
                    </label>
                    <vue-datepicker :format="dateFormat"
                                    :inputClass="'form-control-d m-0 w-100 background-white'"
                                    v-model="startDateTwo"
                                    valueType="format"
                                    :class="'w-100'"
                                    key="start_date_two"
                                    id="start_date_two">
                    </vue-datepicker>
                    <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                         @click="function (){startDateTwo=null;}"/>
                  </div>
                </div>
              </div>

              <div class="end-date-cl">
                <div class="col-12 col-md-6 form-group mb-0">
                  <label class="col-form-label" for="end_date_1">{{$t("flexben.reports.inputLabels.from")}}</label>
                  <div class="w-100 d-inline-flex">
                    <label for="end_date_1" class="my-auto mr-2 col-form-label">
                      <font-awesome-icon icon="calendar-alt" class="text-24"/>
                    </label>
                    <vue-datepicker :format="dateFormat"
                                    :inputClass="'form-control-d m-0 w-100 background-white'"
                                    v-model="endDate"
                                    valueType="format"
                                    :class="'w-100'"
                                    key="end_date"
                                    id="end_date">
                    </vue-datepicker>
                    <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                         @click="function (){endDate=null;}"/>
                  </div>
                </div>
                <div class="col-12 col-md-6 form-group mb-0">
                  <label class="col-form-label" for="end_date_2">{{$t("flexben.reports.inputLabels.to")}}</label>
                  <div class="w-100 d-inline-flex">
                    <label for="end_date_2" class="my-auto mr-2 col-form-label">
                      <font-awesome-icon icon="calendar-alt" class="text-24"/>
                    </label>
                    <vue-datepicker :format="dateFormat"
                                    :inputClass="'form-control-d m-0 w-100 background-white'"
                                    v-model="endDateTwo"
                                    valueType="format"
                                    :class="'w-100'"
                                    key="end_date_two"
                                    id="end_date_two">
                    </vue-datepicker>
                    <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                         @click="function (){endDateTwo=null;}"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row table-striped raport-oferte-dropdown-container">
            <div class="col-12 col-md-4 mb-0">
              <label for="partner_type" class="col-form-label">{{ $t('flexben.reports.offers.fields.partner_type') }}</label>
              <select class="form-control" v-model="partnerTypeSelect"  id="partner_type">
                <option :value="null"> {{$t("flexben.general.all")}}</option>
                <option v-for="type in filterOptions.partnerTypes" :key="type" :value="type">
                  {{type}}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-4 mb-0">
              <label for="categories" class="col-form-label">{{ $t('flexben.reports.offers.fields.category') }}</label>
              <select class="form-control" v-model="category"  id="categories">
                <option :value="null"> {{$t("flexben.general.all")}}</option>
                <option  v-for="(item, index) in filterOptions.categories" :key="index" :value="item">
                  {{item.name[locale]}}
                </option>
              </select>
            </div>

            <div class="col-12 col-md-4 mb-0">
              <label for="partners" class="col-form-label">{{ $t('flexben.reports.offers.fields.partner') }}</label>
              <select class="form-control" v-model="partnerSelect"  id="partners">
                <option :value="null"> {{$t("flexben.general.all")}}</option>
                <option v-for="partner in filterOptions.partners" :key="partner.id" :value="partner.name">
                  {{partner.name}}
                </option>
              </select>
            </div>

            <div v-if="data.partner_type === 'affiliated'" class="col-12 col-md-4 mb-0">
              <label for="channel_type" class="col-form-label">{{ $t('flexben.reports.offers.fields.affiliate_channel') }}</label>
              <select class="form-control" v-model="channelTypeSelect"  id="channel_type">
                <option :value="null"> {{$t("flexben.general.all")}}</option>
                <option v-for="type in filterOptions.channelTypes" :key="type" :value="type">
                  {{type}}
                </option>
              </select>
            </div>

            <div v-if="data.channel_type === 'platform'" class="col-12 col-md-4 mb-0">
              <label for="channel_platform_type" class="col-form-label">{{ $t('flexben.reports.offers.fields.affiliate_platform') }}</label>
              <select class="form-control" v-model="channelPlatformTypeSelect"  id="channel_platform_type">
                <option :value="null"> {{$t("flexben.general.all")}}</option>
                <option v-for="type in filterOptions.channelPlatformTypes" :key="type" :value="type">
                  {{type}}
                </option>
              </select>
            </div>

            <div v-if="data.channel_type === 'commercial'" class="col-12 col-md-4 mb-0">
              <label for="channel" class="col-form-label">{{ $t('flexben.reports.offers.fields.affiliate_channel_name') }}</label>
              <select class="form-control" v-model="channelCommercialIdSelect"  id="channel">
                <option :value="null"> {{$t("flexben.general.all")}}</option>
                <option v-for="channel in filterOptions.channels" :key="channel.commercial_identifier" :value="channel.name">
                  {{channel.name}}
                </option>
              </select>
            </div>

            <div class="col-12 col-md-4 mb-0">
              <label for="offers_status" class="col-form-label">{{ $t('flexben.reports.offers.fields.offer_status') }}</label>
              <select class="form-control" v-model="offerStatusSelect"  id="offers_status">
                <option :value="null"> {{$t("flexben.general.all")}}</option>
                <option v-for="status in filterOptions.offerStatuses" :key="status" :value="status">
                  {{status}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    <div :class="{ offerNoDataExport: !download_offers }">
      <p class="text-center text-danger font-weight-bold p-5">{{ $t("flexben.offers.export.no_data_to_export") }}</p>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import httpFlexbenService from "@/http/http-flexben";
import moment from "moment";
import i18n from "@/plugins/language/i18n";
import Cookies from "js-cookie";
import utils from "@/plugins/utils";

export default {
  name: "OffersReport",
  components: {
    'vue-datepicker': Datepicker,
  },
  props: {
    isAdmin: {
      required: true,
      default: true
    }
  },
  data:function () {
    return {
      initialLoading: true,
      httpService: httpFlexbenService.reports.offers,
      dateFormat: 'dd/MM/yyyy',
      momentFormat: 'YYYY-MM-DD',
      download_offers : false,
      filterOptions: {
        partnerTypes: [],
        categories: [],
        partners: [],
        channels: [],
        channelTypes: [],
        channelPlatformTypes: [],
        offerStatuses: [],
      },
      startDate: null,
      startDateTwo: null,
      endDate: null,
      endDateTwo: null,
      partnerSelect : null,
      partnerTypeSelect : null,
      category : null,
      offerStatusSelect : null,
      channelCommercialIdSelect : null,
      channelPlatformTypeSelect : null,
      channelTypeSelect: null,

      data: {
        start_date: null,
        start_date_two: null,
        end_date: null,
        end_date_two: null,
        partner: null,
        partner_type: null,
        category: null,
        offer_status: null,
        channel: null,
        channel_type: null,
        channel_platform_type: null,
      },
      reportItems: [],
      errors: {
        show: false,
        message: null,
      }
    }
  },
  watch: {
    data: {
      handler: function (val) {
        this._getOptions();
      },
      deep: true
    },
    startDate: {
      handler: function (val) {
        this.data.start_date = (val === null) ? null : moment(val).format(this.momentFormat);
      }
    },
    startDateTwo: {
      handler: function (val) {
        this.data.start_date_two = (val === null) ? null : moment(val).format(this.momentFormat);
      }
    },
    endDate: {
      handler: function (val) {
        this.data.end_date = (val === null) ? null : moment(val).format(this.momentFormat);
      }
    },
    endDateTwo: {
      handler: function (val) {
        this.data.end_date_two = (val === null) ? null : moment(val).format(this.momentFormat);
      }
    },
    partnerSelect: {
      handler: function (val) {
        this.data.partner = (val === null) ? null : val;
      }
    },
    partnerTypeSelect: {
      handler: function (val) {
        this.data.partner_type = (val === null) ? null : val;
      }
    },
    category: {
      handler: function (val) {
        this.data.category = (val === null) ? null : val.id;
      }
    },
    offerStatusSelect: {
      handler: function (val) {
        this.data.offer_status = (val === null) ? null : val;
      }
    },
    channelCommercialIdSelect: {
      handler: function (val) {
        this.data.channel = (val === null) ? null : val;
      }
    },
    channelTypeSelect: {
      handler: function (val) {
        this.data.channel_type = (val === null) ? null : val;
      }
    },
    channelPlatformTypeSelect: {
      handler: function (val) {
        this.data.channel_platform_type = (val === null) ? null : val;
      }
    },
  },
  created() {
    this._getOptions();
  },
  computed: {
    locale: () => {
      return i18n.locale;
    }
  },
  methods: {
    _getOptions() {
      if (this.data.partner_type !== 'affiliated') {
        this.data.channel = null;
        this.data.channel_type = null;
      }else {
        if (this.data.channel_type === 'commercial') {
          this.data.channel_platform_type = null;
        }else if (this.data.channel_type === 'platform') {
          this.data.channel = null;
        }
      }
      this.httpService.getOptions(this.data)
        .then((response) => {
            this.filterOptions.channels = response.data.channels;
            this.filterOptions.partners = response.data.partners;
            this.filterOptions.partnerTypes = response.data.partner_types;
            this.filterOptions.categories = response.data.categories;
            this.filterOptions.channelTypes = response.data.channel_types;
            this.filterOptions.channelPlatformTypes = response.data.channel_platform_type;
            this.filterOptions.offerStatuses = response.data.offer_status;
            this.initialLoading = false;
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        });
    },
    _validateFilters() {
      this.errors.show = false;
      this.errors.message = null;
      if (this.data.start_date === null && this.data.start_date_two === null && this.data.end_date === null && this.data.end_date_two === null) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.no_date_selected');
      } else if ((this.data.start_date === null && this.data.start_date_two != null) || (this.data.start_date != null && this.data.start_date_two === null) ) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.no_start_date_selected');
      }else if ((this.data.end_date === null && this.data.end_date_two != null) || (this.data.end_date != null && this.data.end_date_two === null) ) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.no_end_date_selected');
      }
      if (moment(this.data.start_date).isAfter(moment(this.data.start_date_two))) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.endDateBeforeStartDateOne');
      }
      if (moment(this.data.end_date).isAfter(moment(this.data.end_date_two))) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.endDateBeforeStartDateTwo');
      }
      this.$emit('setErrors', this.errors.show, this.errors.message);
    },
    download() {
      this._validateFilters();
      if (this.errors.show) {
        return;
      }
      if (this.data.partner_type !== 'affiliated') {
        this.data.channel = null;
        this.data.channel_type = null;
      }
      let payload = {...this.data}
      payload.filtersInfo = {
        startDate: {
          value: (this.startDate === null) ? null : moment(this.startDate).format('DD/MM/YYYY')
        },
        startDateTwo: {
          value: (this.startDateTwo === null) ? null : moment(this.startDateTwo).format('DD/MM/YYYY')
        },
        endDate: {
          value: (this.endDate === null) ? null : moment(this.endDate).format('DD/MM/YYYY')
        },
        endDateTwo: {
          value: (this.endDateTwo === null) ? null : moment(this.endDateTwo).format('DD/MM/YYYY')
        },
        partner: {
          value: this.partnerSelect ? this.partnerSelect : null
        },
        partner_type: {
          value: this.partnerTypeSelect ? this.partnerTypeSelect : null
        },
        category: {
          value: this.category ? this.category.name[this.locale] : null
        },
        offer_status: {
          value: this.offerStatusSelect ? this.offerStatusSelect : null
        },
        channel: {
          value: (this.partnerTypeSelect === 'affiliated' && this.channelTypeSelect === 'commercial') ? this.channelCommercialIdSelect : null
        },
        channel_type: {
          value: (this.partnerTypeSelect === 'affiliated') ? this.channelTypeSelect : null
        },
        channel_platform_type: {
          value: (this.channelTypeSelect === 'platform') ? this.channelPlatformTypeSelect : null
        },
      };
      payload.lang = this.$store.state.language;

      this._toggleLoading(true);
      this.httpService.download(payload)
        .then((response) => {
          if (response.data.success === false) {
            console.log('Could not download report.' + response.data.message);
            this._toggleLoading(false);
            return;
          }else {
            if (response.data.nrOfRows === 0) {
                this.download_offers = true;
                this._toggleLoading(false);
                return;
            }else {
              this.download_offers = false;
            }
          }

          utils.generateDownloadFromReponse(response);
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        });
    },
    _toggleLoading(loading, loaderError = null) {
      this.$emit('toggleLoading', loading, loaderError);
    },

  }
}
</script>
