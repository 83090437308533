<template>
  <div class="flexben-beneficiaries mb-4">
    <div :class="initialLoading && 'hide'">
      <div class="row">
        <div class="col-md-6 flexben-page-title">
          <h1>{{ $t("flexben.beneficiaries.title") }}</h1>
          <p>{{ countActive }} {{ $t("flexben.beneficiaries.count") }}</p>
        </div>
        <div class="col-md-6 text-right">
          <button v-if="!isAdmin"
                  class="btn btn-themed-o px-4 my-md-2" @click="goToImport()">
            {{ $t('flexben.import.buttons.chose_beneficiaries') }}
          </button>
          <button class="btn btn-themed px-4 my-1 my-md-2 h-auto ml-2" @click="addBeneficiary()">
            {{ $t("flexben.benefit.add_beneficiary") }}
          </button>
        </div>
      </div>
      <div class="container-bg-white">
        <div class="row p-3">
          <div class="col-12 col-xl-3 mb-2 mb-xl-0">
            <img src="@/assets/images/filter.svg"/>
            <span class="ml-3">{{$t("flexben.general.filter")}}</span>
          </div>

          <div class="col-12 col-md-4 col-xl-3" v-if="isAdmin">
            <div class="form-group mb-1">
              <label class="col-form-label pt-xl-0" for="client">
                {{ $t("flexben.beneficiaries.fields.client") }}</label>
              <multiselect v-model="filters.company"
                           :options="filter_options.companies"
                           :selected-label="''"
                           :option-height="35"
                           label="name"
                           track-by="id"
                           :multiple="false"
                           :searchable="true"
                           :allow-empty="false"
                           :show-labels="false"
                           :placeholder="$t('flexben.general.search')"
                           ref="multiselect_companies">
              </multiselect>
            </div>
          </div>
          <div class="col-12 col-md-4 col-xl-3">
            <div class="form-group mb-1 mb-md-0">
              <label class="col-form-label" for="status">{{$t("flexben.general.status")}}</label>
              <select id="status" class="form-control w-100"
                      v-model="filters.status">
                <option value="all">-</option>
                <option v-for="(status, index) in filter_options.statuses"
                        :key="index"
                        :value="status">{{ $t("flexben.general.status_type." + status) }}
                </option>
              </select>
            </div>
          </div>
          <template v-if="selectedCompany.type_code!=='special_offers'">
            <div class="col-12 col-md-4 col-xl-3">
              <div class="form-group mb-1">
                <label class="col-form-label pt-xl-0" for="department">{{
                  $t("flexben.beneficiaries.fields.department")
                  }}</label>
                <select id="department" class="form-control w-100"
                        v-model="filters.department"
                        :disabled="filter_options.company_id === null">
                  <option :value="null" selected>-</option>
                  <option v-for="(item, value) in filter_options.departments"
                          :key="value"
                          :value="value">{{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3">
              <div class="form-group mb-1">
                <label class="col-form-label pt-xl-0" for="job_title">{{ $t("flexben.beneficiaries.fields.job_title")
                  }}</label>
                <select id="job_title" class="form-control w-100"
                        v-model="filters.job_title"
                        :disabled="filter_options.company_id === null">
                  <option :value="null" selected>-</option>
                  <option v-for="(item, value) in filter_options.job_title_list"
                          :key="value"
                          :value="value">{{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3">
              <div class="form-group mb-1">
                <label class="col-form-label" :class="{'pt-xl-0':!isAdmin}" for="cost_center">{{
                  $t("flexben.beneficiaries.fields.cost_center") }}</label>
                <select id="cost_center" class="form-control w-100"
                        v-model="filters.cost_center"
                        :disabled="filter_options.company_id === null">
                  <option :value="null" selected>-</option>
                  <option v-for="(item, value) in filter_options.cost_centers"
                          :key="value"
                          :value="value">{{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3">
              <div class="form-group mb-1">
                <label class="col-form-label" for="working_point">{{ $t("flexben.beneficiaries.fields.working_point")
                  }}</label>
                <select id="working_point" class="form-control w-100"
                        v-model="filters.working_point"
                        :disabled="filter_options.company_id === null">
                  <option :value="null" selected>-</option>
                  <option v-for="(item, value) in filter_options.working_points"
                          :key="value"
                          :value="value">{{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3">
              <div class="form-group mb-1">
                <label class="col-form-label" for="group">{{ $t("flexben.beneficiaries.fields.group") }}</label>
                <select id="group" class="form-control w-100"
                        v-model="filters.benefit_category_code"
                        :disabled="filter_options.company_id === null">
                  <option :value="null" selected>-</option>
                  <option v-for="(item, value) in filter_options.benefit_categories"
                          :key="value"
                          :value="value">{{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3">
              <div class="form-group mb-1 mb-md-0">
                <label class="col-form-label" for="delivery_address">{{
                  $t("flexben.beneficiaries.fields.up_ro_address_id")
                  }}</label>
                <select id="delivery_address" class="form-control w-100"
                        v-model="filters.delivery_address"
                        :disabled="filter_options.company_id === null">
                  <option :value="null" selected>-</option>
                  <option v-for="(item, value, index) in filter_options.delivery_addresses"
                          :key="index"
                          :value="value">{{ value }} - {{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3">
              <div class="form-group mb-1 mb-md-0">
                <label class="col-form-label" for="fixed_benefit_group">
                  {{ $t('flexben.import.table_headings.fixed_benefit_group') }}
                </label>
                <select id="status" class="form-control w-100"
                        v-model="filters.fixed_benefit_group"
                        :disabled="filter_options.company_id === null">
                  <option :value="null">-</option>
                  <option value="not_set">{{ $t('flexben.general.none') }}</option>
                  <option v-for="(item, value) in filter_options.fixed_benefit_groups"
                          :key="value"
                          :value="value">{{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3 ml-auto text-right pt-2">
              <button class="btn mt-md-4 d-inline-flex align-content-center justify-content-center"
                      :class="{'btn-themed-o':massAction.show, 'btn-themed-gray-o':!massAction.show}"
                      @click="massActionExpand()">
                <font-awesome-icon class="mr-1 text-20" icon="file-excel"/>
                {{ $t('flexben.beneficiaries.mass_actions.btn_bulk_actions')}}
              </button>
            </div>
          </template>
        </div>
      </div>
      <mass-actions
        :showCard="massAction.show"
        :massActionIds="massAction.ids"
        :company="parseInt(filter_options.company_id)"
        :companyName="massAction.companyName"
        :filters="filters"
        @toggleLoading="toggleLoading"
        @massActionReloadList="massActionReloadList"
        @setMassActionIds="setMassActionIds"></mass-actions>

      <div class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-6 text-bold"><h3 class="p-0 mb-0 mt-2">{{ $t("flexben.beneficiaries.fields.beneficiary")
            }}</h3></div>
          <div class="col-6">
            <input type="text" @keyup.enter="search()" v-model="searchString"
                   :placeholder="$t('flexben.general.search')"
                   class="formedituser search-input float-right"/>
          </div>
          <div class="col-12 mt-3">
            <div class="table-responsive hover-table">
              <table class="table table-striped">
                <thead>
                <tr class="">
                  <td class="w-50-p"><input type="checkbox" class="form-check"
                                            @click="checkItems(0)"
                                            :disabled="filter_options.company_id===null"
                                            :checked="massAction.checkAll"/></td>
                  <td class="cursor-pointer" @click="sortBy('name')">
                    {{ $t("flexben.general.name") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class=" (sortField === 'name' && reverseSort) ?'rotate-180':''"/>
                  </td>
                  <td class="cursor-pointer" @click="sortBy('company_name')"
                      v-if="isAdmin">
                    {{ $t("flexben.beneficiaries.fields.client_company") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class=" (sortField === 'company_name' && reverseSort) ?'rotate-180':''"
                    />
                  </td>
                  <td class="cursor-pointer" @click="sortBy('invitation_email')">
                    {{ $t("flexben.beneficiaries.fields.invitation_email") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class=" (sortField === 'invitation_email' && reverseSort) ?'rotate-180':''"/>
                  </td>
                  <td class="cursor-pointer" @click="sortBy('phone_number')">
                    {{ $t("flexben.beneficiaries.fields.telephone") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class=" (sortField === 'phone_number' && reverseSort) ?'rotate-180':''"/>
                  </td>
                  <td>{{ $t("flexben.beneficiaries.fields.cnp") }}</td>
                  <td>{{ $t("flexben.beneficiaries.fields.employee_id") }}</td>
                  <td class="cursor-pointer" @click="sortBy('benefit_category_code')">
                    {{ $t("flexben.beneficiaries.fields.group") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class=" (sortField === 'benefit_category_code' && reverseSort) ?'rotate-180':''"/>
                  </td>
                  <td>{{ $t("flexben.beneficiaries.fields.up_ro_address_id") }}</td>
                  <td class="cursor-pointer" @click="sortBy('status')">
                    {{ $t("flexben.general.status") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class=" (sortField === 'status' && reverseSort) ?'rotate-180':''"/>
                  </td>
                  <td></td>
                </tr>
                </thead>
                <tbody v-if="!loading && beneficiaries.data.length > 0">
                <tr class="trhover parent" v-for="(beneficiary, index) in beneficiaries.data"
                    :key="index">
                  <td class="w-50-p">
                    <input
                      v-if="beneficiary.status!=='pending_delete' && beneficiary.status!=='deleted'"
                      type="checkbox" class="form-check"
                      @click="checkItems(beneficiary.id)"
                      :disabled="filter_options.company_id===null"
                      :checked="massAction.ids.includes(beneficiary.id)"/></td>
                  <td>{{beneficiary.name}}</td>
                  <td v-if="isAdmin">{{beneficiary.company_name}}</td>
                  <td>{{beneficiary.invitation_email}}</td>
                  <td>{{beneficiary.phone_number}}</td>
                  <td>{{beneficiary.cnp}}</td>
                  <td>{{ (beneficiary.employee_number!==null) ? beneficiary.employee_number : '-' }}</td>
                  <td>{{ beneficiary.benefit_category_code }}</td>
                  <td>{{ (beneficiary.up_ro_address_id!==null) ?beneficiary.up_ro_address_id :'-' }}</td>
                  <td><span :class="'beneficiary-'+beneficiary.status">{{ $t("flexben.general.status_type."+beneficiary.status) }}</span>
                  </td>
                  <td>
                    <a class="btn action-button" :href="'/multibeneficii/beneficiaries/'+ beneficiary.id +'/edit'">
                      {{$t("flexben.general.btn.see_more")}}
                    </a>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr class="gri-cell text-center trhover">
                  <td :colspan="isAdmin?12:10" class="text-center">{{ $t("flexben.general.no_results_found") }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 p-0">
            <listPagination
              :currentPage="beneficiaries.current_page"
              :totalItems="beneficiaries.total"
              :startItem="beneficiaries.from"
              :endItem="beneficiaries.to"
              :itemsPerPage="beneficiaries.perPage"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage"></listPagination>
          </div>
        </div>
      </div>

    </div>
    <SelectClientTypeModal :openModal="openModal"
                       :companies="filter_options.companies"
                       :noClientSelected="noClientSelected"
                       @closeModal="closeModal"
                       @goToCreate="goToCreate"></SelectClientTypeModal>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben.js";
import SelectClientTypeModal from "./../../fragments/SelectClientTypeModal";
import MassActions from "./partials/MassActions";
import Multiselect from 'vue-multiselect';

export default {
  name: "BeneficiariesIndex",
  components: {
    SelectClientTypeModal,
    'mass-actions': MassActions,
    Multiselect,
  },
  data() {
    return {
      httpService: httpFlexbenService.beneficiaries,
      initialLoading: true,
      loading: true,
      loaderError: false,
      searchString: '',
      sortField: '',
      sortDirection: 'asc',
      reverseSort: false,

      noClientSelected: false,
      //populated automatically if user type=client else wait for select
      openModal: false,
      isAdmin: false,
      selectedCompany: {
        id: null,
        name: null,
        type: null,
        type_code: null,
      },

      filters: {
        sort_by: null,
        sort_direction: null,
        page: null,
        search: null,
        company: null,
        department: null,
        job_title: null,
        cost_center: null,
        working_point: null,
        benefit_category_code: null,
        delivery_address: null,
        status: 'all',
        fixed_benefit_group: null
      },
      filters_values: {
        company: null,
        department: null,
        job_title: null,
        cost_center: null,
        working_point: null,
        benefit_category_code: null,
        delivery_address: null,
        status: 'all',
        fixed_benefit_group: null
      },
      filter_options: {
        company_id: null,
        companies: {},
        departments: {},
        job_title_list: {},
        cost_centers: {},
        working_points: {},
        benefit_categories: {},
        delivery_addresses: {},
        statuses: {},
        fixed_benefit_groups: {},
      },
      beneficiaries: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1
      },
      massAction: {
        show: false,
        ids: [],
        checkAll: false,
        companyName: null
      },
      countActive: 0,
    }
  },
  watch: {
    filters: {
      handler: function (val) {
        this.filter_options.companies.forEach((value) => {
         if(value.id === val.company) {
           this.massAction.companyName = value.name;
         }
        });
        this._loadList();
      },
      deep: true
    },
    filters_values: {
      handler: function (val) {
        if (this.isAdmin && val.company !== '' && val.company !== null) {
          this.massAction.companyName = this.filter_options.companies[val.company];
        }
      },
      deep: true
    }
  },
  created() {
    let userRole = this.flxUserLevel().userLevel;
    const employment = this.$store.getters.flx_activeEmployment;
    if (userRole === 'client' && employment != null) {
      this.filters.company = employment.company_id;
      this.isAdmin = false;
    } else if (userRole === 'admin') {
      if (typeof localStorage.getItem('flexbenCompanyId') !== "undefined" && localStorage.getItem('flexbenCompanyId') !== null) {
        this.filters.company = this.filters.company = localStorage.getItem('flexbenCompanyId');
        localStorage.removeItem('flexbenCompanyId');
      }
      this.isAdmin = true;
    } else {
      window.location.href = '/';
      return;
    }

    this._loadList();
  },
  methods: {
    _loadList(overwriteFilters = null) {
      this.loading = true;
      if(this.isAdmin && this.filters.company && this.filters.company.id){
        this.filters.company = this.filters.company.id;
      }
      this.httpService.loadList((overwriteFilters == null) ? this.filters : overwriteFilters)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          this.filter_options = {
            company_id: data.filter_options.company_id,
            companies: data.filter_options.companies,
            departments: data.filter_options.departments,
            job_title_list: data.filter_options.job_title_list,
            cost_centers: data.filter_options.cost_centers,
            working_points: data.filter_options.working_points,
            benefit_categories: data.filter_options.benefit_categories,
            delivery_addresses: data.filter_options.delivery_addresses,
            statuses: data.filter_options.statuses,
            fixed_benefit_groups: data.filter_options.fixed_benefit_groups,
          };
          this.selectedCompany = data.filter_options.selected_company === null ? {
            id: null,
            name: null,
            type: null,
            type_code: null,
          } : data.filter_options.selected_company;

          this.beneficiaries = {
            data: data.items.data,
            current_page: data.items.current_page,
            to: data.items.to,
            perPage: data.items.perPage,
            lastPage: data.items.lastPage,
            total: data.items.total
          };
          if (this.initialLoading) {
            this.isAdmin = data.is_admin;
            // force set client logged in client company_id
            if (!data.is_admin) {
              this.selectedCompany.id = data.filter_options.company_id;
            }

            this.countActive = data.count_active;
            if (!this.isAdmin) {
              let companyNames = Object.values(data.filter_options.companies);
              this.massAction.companyName = companyNames[0];
            }
          }

          this.initialLoading = false;
          this.loading = false;
        })
        .catch((error) => {
          this.beneficiaries = {
            data: [],
            current_page: 1,
            to: 1,
            perPage: 1,
            lastPage: 1,
            total: 1
          };
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          }
        });
    },
    sortBy(fieldName) {
      if (this.sortField !== fieldName) {
        this.reverseSort = false;
      }
      this.filters.page = null;
      this.filters.sort_by = fieldName;
      this.filters.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this._loadList()
      this.sortField = fieldName;
      this.reverseSort = !this.reverseSort;
    },
    search() {
      if (this.searchString.length >= 3) {
        this.filters.search = this.searchString;
      } else if (this.searchString.length === 0) {
        this.filters.search = null;
      }
      this._loadList();
    },
    changePerPage(perPage) {
      this.filters.page = null;
      this.filters.perPage = perPage;
      this._loadList();
    },
    pageChanged(pageNb) {
      this.filters.page = pageNb;
    },
    addBeneficiary() {
      if (this.isAdmin) {
        this.openModal = true;
        this.selectedCompany.id = null;
      } else {
        this.goToCreate();
      }
    },
    goToCreate() {
      if (this.selectedCompany !== null) {
        localStorage.setItem('selectedCompanyId', this.selectedCompany.id);
        window.location.href = '/multibeneficii/beneficiaries/new';
      } else {
        this.noClientSelected = true;
      }
    },
    closeModal() {
      if (this.isAdmin) {
        this.selectedCompany.id = null;
      }
      this.openModal = false;
    },
    goToImport() {
      sessionStorage.setItem('flxQuickImport', 'beneficiaries');
      window.location = '/multibeneficii/import-new';
    },
    checkItems(itemId) {
      if (this.filter_options.company_id === null) {
        return;
      }
      //toggle all in page
      if (itemId === 0) {
        this.massAction.checkAll = !this.massAction.checkAll;
        let item;
        for (item of this.beneficiaries.data) {
          if (item.status === 'pending_delete' || item.status === 'deleted') {
            continue;
          }
          if (this.massAction.checkAll) {
            if (!this.massAction.ids.includes(item.id)) {
              this.massAction.ids.push(item.id);
            }
          } else {
            if (this.massAction.ids.includes(item.id)) {
              this.massAction.ids.splice(this.massAction.ids.indexOf(item.id), 1);
            }
          }
        }
      } else {
        if (this.massAction.ids.includes(itemId)) {
          this.massAction.ids.splice(this.massAction.ids.indexOf(itemId), 1);
          this.massAction.checkAll = false;
        } else {
          this.massAction.ids.push(itemId);
        }
      }
      this.massAction.show = this.massAction.ids.length > 0;
    },
    massActionExpand() {
      this.massAction.show = !this.massAction.show;
    },
    toggleLoading(loading, loaderError = false) {
      this.loading = loading;
      this.loaderError = loaderError;
    },
    massActionReloadList() {
      this.massAction.checkAll = false;
      this.massAction.ids = [];
      this._loadList();
    },
    setMassActionIds(ids) {
      this.massAction.ids = ids;
    }
  },
};
</script>
