<template>
  <div class="mb-4">
    <div v-show="showTopButtons" class="row">
      <div class="col-12 col-md-4 flexben-page-title">
        <h1>{{$t("flexben.requests.title")}}</h1>
      </div>
      <div class="col-12 col-md-8 text-md-right" v-if="!isAdmin">
        <a href="/multibeneficii/client/"
           class="btn btn-link py-2 px-0 mr-2"
           :class="companySettings.up_dejun_auto ? 'text-success' : 'text-danger'">
          {{ $t('flexben.requests.auto_statuses.'+(companySettings.up_dejun_auto ? 'dejun_on' : 'dejun_off')) }}</a>
        <a href="/multibeneficii/client/?tab=up_gift"
           class="btn btn-link py-2 px-0"
           :class="companySettings.up_gift_auto ? 'text-success' : 'text-danger'">
          {{ $t('flexben.requests.auto_statuses.'+(companySettings.up_gift_auto ? 'gift_on' : 'gift_off')) }}</a>
        <br>
        <a href="/multibeneficii/client/?tab=up_vacation"
           class="btn btn-link py-2 px-0 mr-2"
           :class="companySettings.up_vacation_auto ? 'text-success' : 'text-danger'">
          {{ $t('flexben.requests.auto_statuses.'+(companySettings.up_vacation_auto ? 'vacation_on' : 'vacation_off'))
          }}</a>
        <a href="/multibeneficii/client/?tab=up_culture"
           class="btn btn-link py-2 px-0 mr-2"
           :class="companySettings.up_culture_auto ? 'text-success' : 'text-danger'">
          {{ $t('flexben.requests.auto_statuses.'+(companySettings.up_culture_auto ? 'culture_on' : 'culture_off'))
          }}</a>

      </div>
    </div>
    <RequestTypesButtons v-show="showTopButtons"
                         :requests-type="requestsType"
                         :requestsTotals="requestsTotals"
                         @changeType="changeType"/>
    <TopUpRequestsSteps v-if="requestsType==='top_up'"
                        :isAdmin="isAdmin"
                        :key="requestsContentKey"
                        @setGeneralData="setGeneralData"
                        @setQuickFilterCompany="setQuickFilterCompany"/>
    <CardRequestsSteps v-if="requestsType==='card'"
                       :isAdmin="isAdmin"
                       :key="requestsContentKey"
                       @setGeneralData="setGeneralData"
                       @setQuickFilterCompany="setQuickFilterCompany"/>
    <NotLegislatedRequestsList v-if="requestsType==='not_legislated'"
                               :isAdmin="isAdmin"
                               :key="requestsContentKey"
                               @setGeneralData="setGeneralData"
                               @setQuickFilterCompany="setQuickFilterCompany"
                               @toggleTopButtons="toggleTopButtons"/>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import TopUpRequestsSteps from "@/components/flexben/requests/top_up/TopUpRequestsSteps";
import NotLegislatedRequestsList from "@/components/flexben/requests/not_legislated/NotLegislatedRequestsList";
import CardRequestsSteps from "@/components/flexben/requests/card/CardRequestsSteps";
import httpFlexbenService from "@/http/http-flexben";
import RequestTypesButtons from "@/components/flexben/requests/partials/RequestTypesButtons";

export default {
  components: {
    RequestTypesButtons,
    TopUpRequestsSteps,
    CardRequestsSteps,
    NotLegislatedRequestsList
  },
  data() {
    return {
      isAdmin: false,
      loading: false,
      loaderError: false,
      companyId: null,
      httpService: httpFlexbenService.requests,
      companySettings: {
        cam_budget_taxation: false,
        gross_budget_taxation: false,
        up_dejun_auto: false,
        up_gift_auto: false,
        up_culture_auto: false,
        up_vacation_auto: false
      },
      showTopButtons: true,
      requestsType: null,
      requestsTotals: {
        card: 0,
        not_legislated: 0,
        top_up: 0,
      },
      requestsContentKey: 0
    }
  },
  created() {
    let permission = this.flxUserLevel();
    if (!permission.hasAccess) {
      window.location.href = '/company';
    }

    const employment = this.$store.getters.flx_activeEmployment;
    if (permission.userLevel === 'client' && employment != null) {
      this.companyId = employment.company_id;
      this.isAdmin = false;
      this.setGeneralData();
    } else {
      if (typeof localStorage.getItem('flexbenCompanyId') !== "undefined" && localStorage.getItem('flexbenCompanyId') !== null) {
        this.companyId = localStorage.getItem('flexbenCompanyId');
        localStorage.removeItem('flexbenCompanyId');
      }
      this.isAdmin = true;
      this.setGeneralData();
    }
  },
  methods: {
    changeType(type) {
      if (type === this.requestsType) {
        this.requestsContentKey++;
      } else {
        this.requestsType = type;
      }
    },
    setGeneralData() {
      this.loading = true;
      this.httpService.getGeneralData()
        .then(function (response) {
          this.requestsTotals = response.data.total_counts;
          if (!this.isAdmin) {
            this.companySettings = response.data.company_settings;
          }
          if (this.requestsType === null) {
            this.requestsType = 'top_up';
          }
          this.loading = false;
        }.bind(this))
        .catch(function (error) {
        }.bind(this));
    },
    placeOrder() {
      sessionStorage.setItem('flexbenQuickPlaceOrder', true);
      this.loading = true;
      window.location.href = '/multibeneficii/orders';
    },
    setQuickFilterCompany(companyId) {
      this.companyId = companyId;
    },
    toggleTopButtons() {
      this.showTopButtons = !this.showTopButtons;
    }
  },
};
</script>
