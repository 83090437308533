<template>
  <div class="col-12">
    <div v-show="!initialLoading">
      <div class="container-bg-white">
        <div class="row p-3" id="filterGroup">
          <div class="col-12 col-xl-3 mb-2 mb-xl-0">
            <img src="@/assets/images/filter.svg"/>
            <span class="ml-3">{{$t("flexben.general.filter")}}</span>
          </div>
          <div class="col-12 col-xl-9">
            <div class="row">
              <div class="col-12 col-md-4 form-group mb-0" v-if="!isAdmin"></div>
              <div class="col-12 col-md-4 form-group mb-0">
                <label class="col-form-label" for="start_date">{{ $t("flexben.reports.inputLabels.startDate") }}</label>
                <div class="w-100 d-inline-flex">
                  <label for="start_date" class="my-auto mr-2 col-form-label">
                    <font-awesome-icon icon="calendar-alt" class="text-24"/>
                  </label>
                  <vue-datepicker :format="dateFormat"
                                  :inputClass="'form-control-d m-0 w-100 background-white'"
                                  v-model="startDate"
                                  :class="'w-100'"
                                  key="start_date"
                                  id="start_date">
                  </vue-datepicker>
                  <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                       @click="function (){startDate=null;}"/>
                </div>
              </div>
              <div class="col-12 col-md-4 form-group mb-0">
                <label class="col-form-label" for="end_date">{{ $t("flexben.reports.inputLabels.endDate") }}</label>
                <div class="w-100 d-inline-flex">
                  <label for="end_date" class="my-auto mr-2 col-form-label">
                    <font-awesome-icon icon="calendar-alt" class="text-24"/>
                  </label>
                  <vue-datepicker :format="dateFormat"
                                  :inputClass="'form-control-d m-0 w-100 background-white'"
                                  v-model="endDate"
                                  :class="'w-100'"
                                  key="end_date"
                                  id="end_date">
                  </vue-datepicker>
                  <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                       @click="function (){endDate=null;}"/>
                </div>
              </div>
              <div class="col-12 col-md-4 form-group-multiselect mb-0" v-if="isAdmin">
                <label for="select_company" class="col-form-label">{{ $t("flexben.requests.filter.field.client")
                  }}</label>
                <multiselect :multiple="false"
                             :class="{'from-control':true}"
                             label="name"
                             trackBy="column_nr"
                             id="select_company"
                             openDirection="bottom"
                             :closeOnSelect="true"
                             :searchable="true"
                             :placeholder="$t('flexben.general.search')"
                             selectLabel=""
                             deselectLabel=""
                             selectedLabel=""
                             v-model="companySelect"
                             :options="filterOptions.companies">
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-12">
            <hr/>
          </div>
          <div class="col-12 col-md-6 col-lg-4 col-xl-3 form-group-multiselect">
            <label for="select_employee_data"
                   class="col-form-label">{{ $t('flexben.reports.filters.employeeData') }}</label>
            <multiselect :multiple="true"
                         :class="{'from-control':true}"
                         label="name"
                         trackBy="column_nr"
                         id="select_employee_data"
                         group-values="options"
                         group-label="groupLabel"
                         :group-select="true"
                         :selectGroupLabel="$t('flexben.general.btn.add_all')"
                         :deselectGroupLabel="$t('flexben.general.btn.remove_all')"
                         selectGroupLabelText="sss"
                         selectLabel=""
                         deselectLabel=""
                         selectedLabel=""
                         openDirection="bottom"
                         :disabled="isDisabledFilters"
                         :closeOnSelect="false"
                         :searchable="false"
                         :placeholder="''"
                         v-model="modelData.employee_columns"
                         :options="filterOptions.employee_columns">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single"
                          v-if="values.length && !isOpen">{{ $t('flexben.general.nr_options_selected',{nr: values.length})}}</span>
              </template>
            </multiselect>
          </div>
          <div class="col-12 col-md-6 col-lg-4 col-xl-3 form-group-multiselect">
            <label for="select_info_ticket" class="col-form-label">{{ $t('flexben.reports.filters.ticketInfo')
              }}</label>
            <multiselect :multiple="true"
                         :class="{'from-control':true}"
                         label="name"
                         trackBy="column_nr"
                         id="select_info_ticket"
                         group-values="options"
                         group-label="groupLabel"
                         :group-select="true"
                         :selectGroupLabel="$t('flexben.general.btn.add_all')"
                         :deselectGroupLabel="$t('flexben.general.btn.remove_all')"
                         selectLabel=""
                         deselectLabel=""
                         selectedLabel=""
                         openDirection="bottom"
                         :disabled="isDisabledFilters"
                         :closeOnSelect="false"
                         :searchable="false"
                         :placeholder="'-'"
                         v-model="modelData.legislated_benefit_columns"
                         :options="filterOptions.legislated_benefit_columns">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single"
                          v-if="values.length && !isOpen">{{ $t('flexben.general.nr_options_selected',{nr: values.length})}}</span>
              </template>
            </multiselect>
          </div>
          <div class="col-12 col-md-6 col-lg-4 col-xl-3 form-group-multiselect">
            <label for="select_info_other_benefit" class="col-form-label">
              {{ $t('flexben.reports.filters.benefitInfo') }}</label>
            <multiselect :multiple="true"
                         :class="{'from-control':true}"
                         label="name"
                         trackBy="column_nr"
                         id="select_info_other_benefit"
                         group-values="options"
                         group-label="groupLabel"
                         :group-select="true"
                         :selectGroupLabel="$t('flexben.general.btn.add_all')"
                         :deselectGroupLabel="$t('flexben.general.btn.remove_all')"
                         selectLabel=""
                         deselectLabel=""
                         selectedLabel=""
                         openDirection="bottom"
                         :disabled="isDisabledFilters"
                         :closeOnSelect="false"
                         :searchable="true"
                         :placeholder="$t('flexben.general.search')"
                         v-model="modelData.not_legislated_benefit_columns"
                         :options="filterOptions.not_legislated_benefit_columns">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single"
                          v-if="values.length && !isOpen">{{ $t('flexben.general.nr_options_selected',{nr: values.length})}}</span>
              </template>
              <template slot="noResult">{{$t('flexben.general.no_results_found')}}</template>
            </multiselect>
          </div>
          <div class="col-12 col-md-6 col-lg-4 col-xl-3 form-group-multiselect">
            <label for="select_info_other_benefit" class="col-form-label">
              {{ $t('flexben.reports.filters.total_taxes') }}</label>
            <multiselect :multiple="true"
                         :class="{'from-control':true}"
                         label="name"
                         trackBy="column_nr"
                         id="select_info_other_benefit"
                         group-values="options"
                         group-label="groupLabel"
                         :group-select="true"
                         :selectGroupLabel="$t('flexben.general.btn.add_all')"
                         :deselectGroupLabel="$t('flexben.general.btn.remove_all')"
                         selectLabel=""
                         deselectLabel=""
                         selectedLabel=""
                         openDirection="bottom"
                         :disabled="isDisabledFilters"
                         :closeOnSelect="false"
                         :searchable="true"
                         :placeholder="'-'"
                         v-model="modelData.total_taxes_columns"
                         :options="filterOptions.total_taxes_columns">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single"
                          v-if="values.length && !isOpen">{{ $t('flexben.general.nr_options_selected',{nr: values.length})}}</span>
              </template>
              <template slot="noResult">{{$t('flexben.general.no_results_found')}}</template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="container-bg-white mt-3" v-show="isShowReport">
        <div class="row p-3">
          <div class="col-12 col-md-4 col-xl-4">
            <span class="text-bold">{{ $t('flexben.reports.inputLabels.startDate') }}</span>
            <br>{{ (lockedModelData.startDate !== null) ? moment(lockedModelData.startDate, 'DD/MM/YYYY'):'-' }}
          </div>
          <div class="col-12 col-md-4 col-xl-4">
            <span class="text-bold">{{ $t('flexben.reports.inputLabels.endDate') }}</span>
            <br>
            {{ (lockedModelData.endDate !== null) ? moment(lockedModelData.endDate, 'DD/MM/YYYY'):'-' }}
          </div>
          <div class="col-12 col-md-4 col-xl-4" v-if="isAdmin">
            <span class="text-bold">{{ $t('flexben.reports.filters.company') }}</span>
            <br>
            {{ lockedModelData.company ? lockedModelData.company : '-' }}
          </div>
          <div class="col-12 mt-2">
            <div class="table-responsive scaled-table hover-table mb-0">
              <table class="table table-striped">
                <thead>
                <tr>
                  <template v-for="(headerCell, key, index) in columnNames">
                    <td :key="index">
                      {{ headerCell }}
                    </td>
                  </template>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(rowColumns, key, index) in reportData"
                    class="trhover" :class="{'gri-cell': index%2 !== 0}"
                    :key="key">
                  <td v-for="(columnValue, index2) in rowColumns"
                      :key="index2">
                    {{ (null === columnValue || columnValue.length === 0) ? '-' : columnValue }}
                  </td>
                </tr>
                <tr v-if="reportData.length === 0" class="trhover gri-cell">
                  <td :colspan="columnNames.length" class="text-center ">
                    {{ $t("flexben.general.no_results_found") }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import httpFlexbenService from "@/http/http-flexben";
import utils from "@/plugins/utils";

export default {
  name: "CustomReport",
  components: {
    'vue-datepicker': Datepicker,
  },
  props: {
    isAdmin: {
      required: true,
      default: false
    },
    company: {
      required: true,
      default: null
    },
  },
  data: () => {
    return {
      initialLoading: true,
      httpService: httpFlexbenService.reports.custom,
      dateFormat: 'dd/MM/yyyy',
      momentFormat: 'YYYY-MM-DD',
      isShowReport: false,
      errors: {
        show: false,
        message: null,
      },
      isDisabledFilters: true,
      requestPayload: {},
      columnNames: [],
      reportData: [],
      filterOptions: {
        companies: [],
        employee_columns: [
          {
            groupLabel: 'Select all',
            options: [],
          }
        ],
        legislated_benefit_columns: [
          {
            groupLabel: 'Select all',
            options: [],
          }
        ],
        not_legislated_benefit_columns: [
          {
            groupLabel: 'Select all',
            options: [],
          }
        ],
        total_taxes_columns: [
          {
            groupLabel: 'Select all',
            options: [],
          }
        ],
      },
      startDate: null,
      endDate: null,
      companySelect: null,
      modelData: {
        company: null,
        companyName: null,
        date_interval: {
          startDate: {fieldName: 'startDate', name: 'Start date', value: null},
          endDate: {fieldName: 'endDate', name: 'End date', value: null},
        },
        employee_columns: [],
        legislated_benefit_columns: [],
        not_legislated_benefit_columns: [],
        total_taxes_columns: [],
      },
      lockedModelData: {
        startDate: null,
        endDate: null,
        company: null,
      },
      params: {},
      filters: {
        period: {}
      },
      cache: {},
    }
  },
  watch: {
    'modelData.company': {
      handler(val) {
        if (this.isAdmin) {
          this.$emit('setCompany', val);
        }
      },
    },
    startDate: {
      handler: function (val) {
        this.modelData.date_interval.startDate.value = (val === null) ? null : moment(val).format('yyyy-MM-DD');
        this._getDynamicFieldsData();
      }
    },
    endDate: {
      handler: function (val) {
        this.modelData.date_interval.endDate.value = (val === null) ? null : moment(val).format('yyyy-MM-DD');
        this._getDynamicFieldsData();
      }
    },
    companySelect: {
      handler: function (val) {
        if (val == null) {
          this.modelData.company = null;
        } else {
          this.modelData.company = val.value;
        }
        this.$emit('setCompany', this.modelData.company)
        this._getDynamicFieldsData();
      }
    },
  },
  created() {
    if (!this.isAdmin) {
      this.modelData.company = this.company;
    }
    this._setDefaultEmployeeColumns();
    this._getFilterOptions();
  },
  updated() {
    if (!this.isAdmin) {
      this.modelData.company = this.company;
    }
  },
  methods: {
    filterValues() {
      this._validateRequest();
      if (this.errors.show) {
        return;
      }

      console.log(this.modelData)
      this.lockedModelData.startDate = this.modelData.date_interval.startDate.value;
      this.lockedModelData.endDate = this.modelData.date_interval.endDate.value;
      this.lockedModelData.company = this.companySelect ? this.companySelect.name : '-';

      let payload = {...this.modelData};
      payload.company = this.companySelect ? this.companySelect.value : null;
      payload.companyName = this.companySelect ? this.companySelect.name : null;

      this._toggleLoading(true);
      this.httpService.getReportData(payload)
        .then((response) => {
          this._setReportColumns();
          this.reportData = response.data.reports;
          this._toggleLoading(false);
        })
        .catch((error) => {
          console.log(error)
          this._setReportColumns(true);
          this._toggleLoading(true, true);
        });
    },
    download() {
      this._validateRequest();
      if (this.errors.show) {
        return;
      }
      this._toggleLoading(true);

      let payload = {...this.modelData};
      payload.company = this.companySelect ? this.companySelect.value : null;
      payload.companyName = this.companySelect ? this.companySelect.name : null;
      this.httpService.download(payload)
        .then((response) => {
          utils.generateDownloadFromReponse(response);
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(true, true);
        });
    },
    _setReportColumns(empty = false) {
      if (empty) {
        this.columnNames = [];
        return;
      }
      let columns = [];
      if (this.isAdmin) {
        columns[0] = this.$t('flexben.reports.filters.company');
      }
      for (let groupKey in this.modelData) {
        if (['company', 'companyName', 'date_interval'].includes(groupKey)) {
          continue;
        }
        for (let obj of this.modelData[groupKey]) {
          if (obj.column_nr) {
            columns[obj.column_nr] = obj.name;
          }
        }
      }

      this.columnNames = columns.filter(function () {
        return true;
      });
    },
    _getFilterOptions() {
      this._toggleLoading(true);
      let params = {
        startDate: this.modelData.date_interval.startDate.value,
        endDate: this.modelData.date_interval.endDate.value,
        company: this.modelData.company,
        withBenefits: this.withBenefits ? 1 : 0,
      };

      this.httpService.getOptions(params)
        .then((response) => {
          if (this.withBenefits) {
            // columns nr order between 1-9 employee data fields
            this._setEmployeeInfoOptions();
            // columns nr order between 10-46 info legislated benefits fields
            this._setLegislatedBenefitsOptions();
            // columns nr order between 47-50 legislated_benefits fields
            this._setTotalTaxesOptions();
            // columns nr order between 51=<n non_legislated_benefits fields
            this._setNonLegislatedBenefitsOptions(51, response.data.not_legislated_benefits);
          } else {
            if (this.filterOptions.companies.length === 0) {
              this.filterOptions.companies = response.data.companies;
              this.initialLoading = false;
            }
          }

          this._toggleLoading(false);
        })
        .catch((error) => {
          console.log(error)
          this.initialLoading = false;
          this._toggleLoading(true, true);
        });
    },
    _resetInfoTicketFields() {
      this.modelData.legislated_benefit_columns = [];
      this._setLegislatedBenefitsOptions(moment(this.modelData.date_interval.startDate.value).format('MM-YYYY') !== moment(this.modelData.date_interval.endDate.value).format('MM-YYYY'));
    },
    _validateRequest() {
      this.errors.show = false;
      this.errors.message = null;
      if (this._validateMinimumColumnSelected() && !this._validateDateFields()) {
        this.isShowReport = true;
      }
      this.$emit('setErrors', this.errors.show, this.errors.message);
    },
    _setEmployeeInfoOptions() {
      let options = [];
      if (this.isAdmin) {
        options.push({
          fieldName: 'company',
          name: this.$t('flexben.reports.custom.fields.beneficiary.company'),
          column_nr: 0,
          $isDisabled: true
        });
      }
      options = options.concat([
        {
          fieldName: 'name',
          name: this.$t('flexben.reports.custom.fields.beneficiary.name'),
          column_nr: 1,
          $isDisabled: true
        },
        {
          fieldName: 'cnp',
          name: this.$t('flexben.reports.custom.fields.beneficiary.cnp'),
          column_nr: 2,
          $isDisabled: true
        },
        {
          fieldName: 'cost_center',
          name: this.$t('flexben.reports.custom.fields.beneficiary.cost_center'),
          column_nr: 3
        },
        {
          fieldName: 'employee_number',
          name: this.$t('flexben.reports.custom.fields.beneficiary.employee_number'),
          column_nr: 4
        },
        {
          fieldName: 'department',
          name: this.$t('flexben.reports.custom.fields.beneficiary.department'),
          column_nr: 5
        },
        {fieldName: 'job_title', name: this.$t('flexben.reports.custom.fields.beneficiary.job_title'), column_nr: 6},
        {
          fieldName: 'working_point',
          name: this.$t('flexben.reports.custom.fields.beneficiary.workstation'),
          column_nr: 7
        },
        {
          fieldName: 'benefit_category_code',
          name: this.$t('flexben.reports.custom.fields.beneficiary.benefit_category_code'),
          column_nr: 8
        },
        {
          fieldName: 'underage_children_nr',
          name: this.$t('flexben.reports.custom.fields.beneficiary.underage_children_nr'),
          column_nr: 9
        }
      ]);
      this.filterOptions.employee_columns = [{
        groupLabel: '~ ' + this.$t('flexben.general.select_all') + ' ~',
        options: options
      }];
    },
    _setLegislatedBenefitsOptions() {
      let optionsDejun = [
        {
          fieldName: 'dejun_timesheet_ticket_count',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.dejun.timesheet_ticket_count'),
          column_nr: 10
        },
        {
          fieldName: 'dejun_total_nr_tickets',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.dejun.total_nr_tickets'),
          column_nr: 11
        },
        {
          fieldName: 'dejun_total_value_cas_cass',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.dejun.total_value_cas_cass'),
          column_nr: 12
        },
        {
          fieldName: 'dejun_total_value_tax',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.dejun.total_value_tax'),
          column_nr: 13
        },
        {
          fieldName: 'dejun_total_value',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.dejun.total_value'),
          column_nr: 14
        }
      ];
      let optionsVacation = [
        {
          fieldName: 'vacation_total_nr_tickets',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.vacation.total_nr_tickets'),
          column_nr: 15
        },
        {
          fieldName: 'vacation_total_value_cas_cass',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.vacation.total_value_cas_cass'),
          column_nr: 16
        },
        {
          fieldName: 'vacation_total_value_tax',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.vacation.total_value_tax'),
          column_nr: 17
        },
        {
          fieldName: 'vacation_total_value',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.vacation.total_value'),
          column_nr: 18
        }
      ];
      let optionsCulture = [
        // monthly
        {
          fieldName: 'culture_total_month_nr_tickets',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.culture.total_month_nr_tickets'),
          column_nr: 19
        },
        {
          fieldName: 'culture_total_month_value_cas_cass',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.culture.total_month_value_cas_cass'),
          column_nr: 20
        },
        {
          fieldName: 'culture_total_month_value_tax',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.culture.total_month_value_tax'),
          column_nr: 21
        },
        {
          fieldName: 'culture_total_month_value',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.culture.total_month_value'),
          column_nr: 22
        },
        // occasionally
        {
          fieldName: 'culture_total_occasion_nr_tickets',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.culture.total_occasion_nr_tickets'),
          column_nr: 23
        },
        {
          fieldName: 'culture_total_occasion_value_cas_cass',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.culture.total_occasion_value_cas_cass'),
          column_nr: 24
        },
        {
          fieldName: 'culture_total_occasion_value_tax',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.culture.total_occasion_value_tax'),
          column_nr: 25
        },
        {
          fieldName: 'culture_total_occasion_value',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.culture.total_occasion_value'),
          column_nr: 26
        },
        // Culture - total
        {
          fieldName: 'culture_total_nr_tickets',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.culture.total_nr_tickets'),
          column_nr: 27
        },
        {
          fieldName: 'culture_total_value_cas_cass',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.culture.total_value_cas_cass'),
          column_nr: 28
        },
        {
          fieldName: 'culture_total_value_tax',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.culture.total_value_tax'),
          column_nr: 29
        },
        {
          fieldName: 'culture_total_value',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.culture.total_value'),
          column_nr: 30
        }
      ];
      let optionsGift = [
        // Legal events
        {
          fieldName: 'gift_total_legal_events_nr_tickets',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_legal_events_nr_tickets'),
          column_nr: 31
        },
        {
          fieldName: 'gift_total_legal_events_value_cas_cass',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_legal_events_value_cas_cass'),
          column_nr: 32
        },
        {
          fieldName: 'gift_total_legal_events_value_tax',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_legal_events_value_tax'),
          column_nr: 33
        },
        {
          fieldName: 'gift_total_legal_events_value',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_legal_events_value'),
          column_nr: 34
        },
        // Company events
        {
          fieldName: 'gift_total_company_events_nr_tickets',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_company_events_nr_tickets'),
          column_nr: 35
        },
        {
          fieldName: 'gift_total_company_events_value_cas_cass',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_company_events_value_cas_cass'),
          column_nr: 36
        },
        {
          fieldName: 'gift_total_company_events_value_tax',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_company_events_value_tax'),
          column_nr: 37
        },
        {
          fieldName: 'gift_total_company_events_value',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_company_events_value'),
          column_nr: 38
        },
        // STIM
        {
          fieldName: 'gift_total_stim_nr_tickets',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_stim_nr_tickets'),
          column_nr: 39
        },
        {
          fieldName: 'gift_total_stim_value_cas_cass',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_stim_value_cas_cass'),
          column_nr: 40
        },
        {
          fieldName: 'gift_total_stim_value_tax',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_stim_value_tax'),
          column_nr: 41
        },
        {
          fieldName: 'gift_total_stim_value',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_stim_value'),
          column_nr: 42
        },
        // Totals
        {
          fieldName: 'gift_total_nr_tickets',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_nr_tickets'),
          column_nr: 43
        },
        {
          fieldName: 'gift_total_value_cas_cass',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_value_cas_cass'),
          column_nr: 44
        },
        {
          fieldName: 'gift_total_value_tax',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_value_tax'),
          column_nr: 45
        },
        {
          fieldName: 'gift_total_value',
          name: this.$t('flexben.reports.custom.fields.legislated_benefits.gift.total_value'),
          column_nr: 46
        }
      ];

      this.filterOptions.legislated_benefit_columns = [
        {
          groupLabel: '~ ' + this.$t('flexben.general.select_all') + ' - optiunile TDejun ~',
          options: optionsDejun
        },
        {
          groupLabel: '~ ' + this.$t('flexben.general.select_all') + ' - optiunile TVacation ~',
          options: optionsVacation
        },
        {
          groupLabel: '~ ' + this.$t('flexben.general.select_all') + ' - optiunile TCulture ~',
          options: optionsCulture
        },
        {
          groupLabel: '~ ' + this.$t('flexben.general.select_all') + ' - optiunile TGift ~',
          options: optionsGift
        }
      ];
    },
    _setTotalTaxesOptions() {
      this.filterOptions.total_taxes_columns = [{
        groupLabel: '~ ' + this.$t('flexben.general.select_all') + ' ~',
        options: [
          {
            fieldName: 'total_sum_taxes',
            name: this.$t('flexben.reports.custom.fields.benefit_totals.sum_taxes'),
            column_nr: 47
          },
          {fieldName: 'total_tax', name: this.$t('flexben.reports.custom.fields.benefit_totals.tax'), column_nr: 48},
          {
            fieldName: 'total_cas_cass',
            name: this.$t('flexben.reports.custom.fields.benefit_totals.cas_cass'),
            column_nr: 49
          },
          {fieldName: 'total_cam', name: this.$t('flexben.reports.custom.fields.benefit_totals.cam'), column_nr: 50}
        ]
      }];
    },
    _setNonLegislatedBenefitsOptions(iteratorStart, rawOptions) {
      let options = [];
      let index, rawOption, name = '';
      for (index in rawOptions) {
        rawOption = rawOptions[index];
        if (typeof options[rawOption.benefit_sub_type] === 'undefined') {
          options[rawOption.benefit_sub_type] = {
            groupLabel: '~ ' + this.$t('flexben.general.select_all') + ' - optiunile ' + this.$t('flexben.benefit.sub_types.' + rawOption.benefit_sub_type) + ' ~',
            // sub_types
            options: []
          };
        }

        name = '';
        if (rawOption.option === null) {
          name += this.$t('flexben.reports.inputLabels.totals');
        }
        name += this.$t('flexben.reports.custom.fields.not_legislated_benefits.' + rawOption.scope) + ' ' + this.$t('flexben.benefit.sub_types.' + rawOption.benefit_sub_type) + ' - ' + rawOption.benefit_name;
        options[rawOption.benefit_sub_type].options.push({
          fieldName: rawOption.benefit_id + '-' + rawOption.scope + (rawOption.option !== null ? ('-' + rawOption.option) : ''),
          name: name,
          column_nr: parseInt(iteratorStart) + parseInt(index),
          benefit_id: (typeof rawOption.benefit_id == 'number') ? rawOption.benefit_id : null,
          scope: rawOption.scope,
          benefit_sub_type: rawOption.benefit_sub_type,
        })
      }
      this.filterOptions.not_legislated_benefit_columns = Object.values(options);
    },

    _validateDateFields() {
      if (this.modelData.date_interval.startDate.value === null ||
        this.modelData.date_interval.endDate.value === null) {
        this.errors.message = this.$t('flexben.reports.errors.no_date_selected');
        this.errors.show = true;
        return this.errors.show;
      }
      this.errors.show = moment(this.modelData.date_interval.endDate.value)
        .isBefore(moment(this.modelData.date_interval.startDate.value));
      if (this.errors.show) {
        this.errors.message = this.$t('flexben.reports.errors.endDateBeforeStartDate');
      }

      return this.errors.show;
    },
    _validateMinimumColumnSelected() {
      let requestedCols = this.modelData.employee_columns.map((item) => {
        return item.fieldName;
      });

      if (!requestedCols.includes('name') || !requestedCols.includes('cnp')) {
        this.errors.message = this.$t('flexben.reports.errors.minimum_columns');
        this.errors.show = true;
        return false;
      }
      return true;
    },
    _setDefaultEmployeeColumns() {
      let columns = [];
      if (this.isAdmin) {
        columns.push({
          fieldName: 'company',
          column_nr: 0,
          name: this.$t('flexben.reports.standard.fields.company'),
          $isDisabled: true
        });
      }
      this.modelData.employee_columns = columns.concat([
        {
          fieldName: 'name',
          name: this.$t('flexben.reports.custom.fields.beneficiary.name'),
          column_nr: 1,
          $isDisabled: true
        },
        {
          fieldName: 'cnp',
          name: this.$t('flexben.reports.custom.fields.beneficiary.cnp'),
          column_nr: 2,
          $isDisabled: true
        },
      ]);
    },
    _toggleLoading(loading, loaderError = false) {
      this.$emit('toggleLoading', loading, loaderError);
    },
    _getDynamicFieldsData() {
      this.isDisabledFilters = this.withBenefits = (this.modelData.date_interval.startDate.value === null || this.modelData.date_interval.endDate.value === null);
      this._setDefaultEmployeeColumns();
      this._resetInfoTicketFields();
      if (this.isDisabledFilters) {
        this.modelData.budget_columns = [];
        this.modelData.legislated_benefit_columns = [];
        this.modelData.not_legislated_benefit_columns = [];
        this.modelData.total_taxes_columns = [];
      } else {
        this.withBenefits = true;
        this._getFilterOptions();
      }
    }
  }
}
</script>
