<template>
  <b-tab title="STIM" :key="childIndex">
    <div class="row ml-1 mr-2 mt-4">
      <div class="col-12 col-md-6">
        <span class="text-18 text-bold">STIM</span>
      </div>
      <div class="col-12 col-md-6 mt-2 mt-md-0">
        <div class="d-flex float-md-right" v-if="isAdmin">
          <label for="stim_enable_toggle" class="ml-md-auto mb-0 mr-4 mr-2 col-form-label p-0">
            {{$t("flexben.clients.partial.enable_benefit_for_client")}}</label>
          <b-form-checkbox id="stim_enable_toggle" switch
                           v-model="childConfig.is_active">
          </b-form-checkbox>
        </div>
      </div>
    </div>
    <div class="row benefits-order-info mx-3 mt-3 pt-3">
      <div class="col-12 col-md-3">
        <img src="@/assets/images/flexben/utils/benefit-info.svg" alt=""/>
      </div>
      <div class="col-12 col-md-3">
        <p>{{$t("flexben.general.fiscal_treatment")}}</p>
        <p class="text-16 text-bold" v-if=" Object.keys(parentBenefit.global_variable_codes).length === 3">
          {{$t("flexben.benefit.deductibility.none")}}
        </p>
        <p class="text-16 text-bold" v-else-if="Object.keys(parentBenefit.global_variable_codes).length < 3 && Object.keys(parentBenefit.global_variable_codes).length > 0">
          {{$t("flexben.benefit.deductibility.partial")}}
        </p>
        <p class="text-16 text-bold" v-else-if=" Object.keys(parentBenefit.global_variable_codes).length === 0">
          {{$t("flexben.benefit.deductibility.complete")}}
        </p>
      </div>
      <div class="col-6 col-md-2">
        <p>{{$t("flexben.general.income_tax")}}</p>
        <p class="text-16 text-bold">{{ parentBenefit.global_variable_codes.includes('impozit-venit') ?
          globalVariables['impozit-venit'].value : 0 }}%</p>
      </div>
      <div class="col-6 col-md-2">
        <p>CAS</p>
        <p class="text-16 text-bold">{{ parentBenefit.global_variable_codes.includes('cas') ?
          globalVariables['cas'].value : 0 }}%</p>
      </div>
      <div class="col-6 col-md-2">
        <p>CASS</p>
        <p class="text-16 text-bold">{{ parentBenefit.global_variable_codes.includes('cass') ?
          globalVariables['cass'].value : 0 }}%</p>
      </div>
      <div class="col-12 col-md-6 col-xl-3 offset-xl-3 mt-3">
          <p>{{$t("flexben.general.last_annual_date_for_orders")}}</p>
          <p class="text-16 text-bold">
            {{parentBenefit.end_use_day_month.day}}/{{parentBenefit.end_use_day_month.month}}/{{parentBenefit.end_use_day_month.year}}</p>
      </div>
    </div>
    <div class="row mx-1 mt-4">
      <div class="col-12 col-xl-3">
        <h3 class="my-0 p-0 pt-1">{{$t("flexben.general.details")}}</h3>
      </div>
      <div class="col-12 col-md-6 col-xl-4 pr-2">
        <div class="form-group">
          <label for="stim_internal_benefit_name" class="col-form-label pt-0">{{$t("flexben.clients.partial.internal_benefit_name_visible_by_employees")}}</label>
          <input type="text" v-model="childConfig.internal_benefit_name" class="form-control"
                 id="stim_internal_benefit_name">
        </div>

      </div>
      <div class="col-12 col-md-6 col-xl-4 pr-2">
        <div class="form-group">
          <label for="stim_max_topup_value" class="col-form-label pt-0">{{$t("flexben.general.top_up_limit")}}</label>
          <input type="number" v-model="childConfig.max_topup_value" min="0" class="form-control"
                 id="stim_max_topup_value">
        </div>
      </div>
    </div>
  </b-tab>
</template>

<script>
  import {BFormCheckbox, BTab} from "bootstrap-vue";

  export default {
    components: {
      "b-tab": BTab,
      "b-form-checkbox": BFormCheckbox,
    },
    name: "SubTabUpStim",
    props: {
      childConfig: {
        type: Object,
        default: function () {
          return {};

        },
        required: true
      },
      globalVariables: {
        type: Object,
        default: function () {
          return {};
        },
        required: true
      },
      childIndex: {
        required: true
      },
      parentBenefit: {
        type: Object,
        default: function () {
          return {};

        },
        required: true
      },
      isAdmin: {
        type: Boolean,
        default: true,
        required: true
      },
    },
    methods: {}
  }
</script>
