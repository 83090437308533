<template>
  <div class="px-3 mt-3">
    <div class="row">
      <div class="col-12 text-24 mb-3">
        {{$t("flexben.clients.partial.taxSettings.title")}}
      </div>
      <div class="col-12 col-lg-6 col-xl-4">
        <div class="mt-1 pr-1 d-inline-flex align-items-center">
          <label for="apply_taxes" class="d-inline-block ml-auto mb-0 mr-4 col-form-label p-0">
            {{$t("flexben.clients.partial.taxSettings.applyTaxes")}}
          </label>
          <b-form-checkbox class="d-inline-block"
                           switch
                           v-model="taxSettings.apply_all_taxes">
          </b-form-checkbox>
          <div class="ml-2">
            <span :id="'info_taxes_'+benefitId"
                  class="d-inline-block flexben-tooltip-icon ml-2 text-bold">i</span>
            <b-tooltip :target="'info_taxes_'+benefitId" variant="flexben" placement="topleft">
              <div v-html="$t('flexben.clients.partial.taxSettings.tooltip')"
                   class="text-left"></div>
            </b-tooltip>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {BFormCheckbox, BTooltip} from "bootstrap-vue";

export default {
  name: "BenefitTaxSettings",
  components: {
    "b-form-checkbox": BFormCheckbox,
    "b-tooltip": BTooltip,
  },
  props: {
    taxSettings : {
      type: Object,
      default: function () {
        return {
          apply_all_taxes: true,

        };
      },
      required: true
    },
    benefitId : {
      type: Number,
      required: true
    }
  },
}
</script>

<style scoped>

</style>
