<template>
  <div v-show="!initialLoading" class="col-12">
    <div class="container-bg-white">
      <div class="row p-3" id="filterGroup">
        <div class="col-12 col-xl-3 mb-2 mb-xl-0">
          <img src="@/assets/images/filter.svg"/>
          <span class="ml-3">{{$t("flexben.general.filter")}}</span>
        </div>
        <div class="col-12 col-xl-9">
          <div class="row table-striped">
            <div class="col-12 col-md-4 form-group mb-0">
              <label class="col-form-label" for="start_date">{{$t("flexben.reports.inputLabels.startDate")}}</label>
              <div class="w-100 d-inline-flex">
                <label for="start_date" class="my-auto mr-2 col-form-label">
                  <font-awesome-icon icon="calendar-alt" class="text-24"/>
                </label>
                <vue-datepicker :format="dateFormat"
                                :inputClass="'form-control-d m-0 w-100 background-white'"
                                v-model="startDate"
                                valueType="format"
                                :class="'w-100'"
                                key="start_date"
                                id="start_date">
                </vue-datepicker>
                <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                     @click="function (){startDate=null;}"/>
              </div>
            </div>
            <div class="col-12 col-md-4 form-group mb-0">
              <label class="col-form-label" for="end_date">{{$t("flexben.reports.inputLabels.endDate")}}</label>
              <div class="w-100 d-inline-flex">
                <label for="end_date" class="my-auto mr-2 col-form-label">
                  <font-awesome-icon icon="calendar-alt" class="text-24"/>
                </label>
                <vue-datepicker :format="dateFormat"
                                :inputClass="'form-control-d m-0 w-100 background-white'"
                                v-model="endDate"
                                valueType="format"
                                :class="'w-100'"
                                key="end_date"
                                id="end_date">
                </vue-datepicker>
                <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                     @click="function (){endDate=null;}"/>
              </div>
            </div>
            <div class="col-12 col-md-4 form-group-multiselect mb-0" v-if="isAdmin">
              <label for="select_company" class="col-form-label">{{ $t("flexben.reports.filters.client")
                }}</label>

              <multiselect :multiple="false"
                           :class="{'from-control':true}"
                           label="name"
                           trackBy="value"
                           id="select_company"
                           openDirection="bottom"
                           :closeOnSelect="true"
                           :searchable="true"
                           :placeholder="$t('flexben.general.search')"
                           selectLabel=""
                           deselectLabel=""
                           selectedLabel=""
                           v-model="companySelect"
                           :options="filterOptions.companies"
                           ref="multiselect_companies">
              </multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import httpFlexbenService from "@/http/http-flexben";
import moment from "moment";
import utils from "@/plugins/utils";

export default {
  name: "UpBenefitsReport",
  components: {
    'vue-datepicker': Datepicker,
  },
  props: {
    isAdmin: {
      required: true,
      default: true
    },
    company: {
      required: true,
      default: null
    },
  },
  data:function () {
    return {
      initialLoading: true,
      httpService: httpFlexbenService.reports.upBenefits,
      dateFormat: 'dd/MM/yyyy',
      momentFormat: 'YYYY-MM-DD',
      startDate: null,
      endDate: null,
      companySelect: null,
      filterOptions: {
        companies: [],
      },
      modelData: {
        startDate: null,
        endDate: null,
        company: null,
      },
      errors: {
        show: false,
        message: null,
      }
    }
  },
  watch: {
    modelData: {
      handler: function (val) {
        this._getOptions();
      },
      deep: true
    },
    startDate: {
      handler: function (val) {
        this.modelData.startDate = (val === null) ? null : moment(val).format('yyyy-MM-DD');
      }
    },
    endDate: {
      handler: function (val) {
        this.modelData.endDate = (val === null) ? null : moment(val).format('yyyy-MM-DD');
      }
    },
    companySelect: {
      handler: function (val) {
        this.modelData.company = (val === null) ? null : val.value;
        this.$emit('setCompany', this.modelData.company)
      }
    }
  },
  created() {
    if (!this.isAdmin) {
      this.modelData.company = this.company;
    }
    this._getOptions();
  },
  methods: {
    _getOptions() {
      this._toggleLoading(true);
      let params = {
        startDate: this.modelData.startDate,
        endDate: this.modelData.endDate,
        company: this.modelData.company,
      };
      this.httpService.getOptions(params)
        .then((response) => {
          this.initialLoading = false;
          if (this.filterOptions.companies.length === 0) {
            this.filterOptions.companies = response.data.companies;
          }

          this._toggleLoading(false);
        })
        .catch((error) => {
          console.log(error);
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        });
    },

    _validateFilters() {
      this.errors.show = false;
      this.errors.message = null;
      if (this.modelData.startDate === null || this.modelData.endDate === null) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.no_date_selected');
      } else if (moment(this.modelData.startDate).isAfter(moment(this.modelData.endDate))) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.endDateBeforeStartDate');
      }

      this.$emit('setErrors', this.errors.show, this.errors.message);
    },
    download() {
      this._validateFilters();
      if (this.errors.show) {
        return;
      }
      let payload = {...this.modelData}
      console.log(payload)
      payload.filtersInfo = {
        startDate: {
          label: this.$t('flexben.reports.inputLabels.startDate'),
          value: (this.modelData.startDate === null) ? null : moment(this.modelData.startDate).format('DD/MM/YYYY')
        },
        endDate: {
          label: this.$t('flexben.reports.inputLabels.endDate'),
          value: (this.modelData.endDate === null) ? null : moment(this.modelData.endDate).format('DD/MM/YYYY')
        },
        company: {
          label: this.$t('flexben.reports.filters.company'),
          value: this.companySelect ? this.companySelect.name : null
        }
      };
      this._toggleLoading(true);

      this.httpService.download(payload)
        .then((response) => {
          if (response.data.success === false) {
            console.log('Could not download report.' + response.data.message);
            this._toggleLoading(false);
            return;
          }

          utils.generateDownloadFromReponse(response);
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        });
    },

    _toggleLoading(loading, loaderError = null) {
      this.$emit('toggleLoading', loading, loaderError);
    },

  }
}
</script>
