<template>
  <div class="mb-4">
    <div class="partners-list" v-show="(!showForm && !initialLoading)">
      <div class="row">
        <div class="col-4 col-md-4 flexben-page-title">
          <h1>{{ $t("flexben.partner.title") }}</h1>
          <p>{{ $t("flexben.partner.description") }}</p>
        </div>
        <div class="col-md-4 col-md-4">
          <label for="select_type" class="col-form-label pt-0">{{ $t("flexben.partner.filters.title_type") }}</label>
          <select class="form-control" v-model="params.type" id="select_type" @change=_loadList>
            <option :value="null">-</option>
            <option v-for="(type, key) in partnerTypes" :key="key" :value="type">

              {{$t("flexben.partner.filters." + type)}}
            </option>
          </select>
        </div>
        <div class="col-4 col-md-4">
          <button class="btn btn-themed px-4 float-right my-2" @click="openForm('create')">
            {{$t("flexben.partner.new")}}
          </button>
        </div>
      </div>
      <div class="container-bg-white">
        <div class="row p-3">
          <div class="col-6 mt-3 text-bold">
            <h3 class="mt-0 pt-1 pl-0">{{ $t("flexben.partner.title") }}</h3>
          </div>
          <div class="col-6 mt-3">
            <input type="text"
                   v-model="searchString"
                   v-on:keyup.enter="search()"
                   :placeholder="$t('flexben.general.search')"
                   class="formedituser search-input float-right"/>
          </div>
          <div class="col-12">
            <div class="table-responsive hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td>{{ $t("flexben.general.image") }}</td>
                  <td>{{ $t("flexben.partner.fields.name") }}</td>
                  <td>{{ $t("flexben.partner.fields.cif") }}</td>
                  <td>{{ $t("flexben.offers.fields.partner_types") }}</td>
                  <td>{{ $t("flexben.partner.fields.iban") }}</td>
                  <td></td>
                </tr>
                </thead>

                <tbody v-if="list.data.length >0">
                <tr v-for="(item, index) in list.data" :key="index" class="trhover">
                  <td><img :src="item.image_url" alt="" width="50"></td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.cif }}</td>
                  <td>{{$t('flexben.offers.partner_types.'+item.type)}}</td>
                  <td>{{ (item.iban) ? item.iban : '-' }}</td>
                  <td class="action-group text-right">
                    <button type="button" class="btn action-button"
                            @click="openForm('update', index)">{{$t("flexben.general.btn.see_more")}}
                    </button>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr class="trhover gri-cell">
                  <td :colspan="4" class="text-center">
                    {{ $t("flexben.general.no_results_found") }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 p-0">
            <listPagination
              :activePage="list.current_page"
              :currentPage="list.current_page"
              :totalItems="list.total"
              :startItem="list.from"
              :endItem="list.to"
              :itemsPerPage="list.perPage"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage"></listPagination>
          </div>
        </div>
      </div>
    </div>
    <PartnerForm v-if="showForm" :formAction="formAction" :itemId="itemId" @clearForm="clearForm"/>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
    <confirm-modal :action="modal.action"
                   :formSuccess="modal.formSuccess"
                   :message="modal.message"
                   :canDelete="modal.canDelete"
                   ref="confirm-modal"
                   @confirmAction="inactivatePartner">
    </confirm-modal>
  </div>

</template>

<script>
import PartnerForm from "@/components/flexben/partners/PartnerForm";
import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import ConfirmModal from "@/components/flexben/partners/modals/ConfirmModal";

export default {
  name: "PartnerList",
  components: {
    PartnerForm,
    "confirm-modal":ConfirmModal,
  },
  data: () => {
    return {
      initialLoading: true,
      loading: false,
      loaderError: false,
      httpService: httpFlexbenService.partnersService,
      locale: i18n.locale,
      searchString: '',
      partnerType: '',
      reverseSort: false,
      sortByField: null,
      partnerTypes: [],
      params: {
        page: 1,
        perPage: 20,
        sort_by: null,
        sort_direction: null,
        type: null,
        search: null
      },
      list: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      formAction: '',
      itemId: null,
      showForm: false,
      modal: {
        action: '',
        formSuccess: true,
        message: '',
        canDelete: true,
      },
    }
  },
  created() {
    let permissions = this.flxUserLevel();
    if (permissions.hasAccess && permissions.userLevel === 'admin') {
      this._loadList();
    } else {
      window.location.href = '/company';
    }
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  methods: {
    _loadList() {
      this.loading = true;
      this.httpService.list(this.params)
        .then((response) => {
          this.list = response.data.partners;
          this.partnerTypes = response.data.options.type;
          this.loading = false;
          this.initialLoading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.loaderError = true;
          this.initialLoading = false;
        });
    },
    inactivatePartner() {
      this.loading = true;
      this.httpService.changeStatus(this.params)
        .then((response) => {
          this.list = response.data.partners;
          this.partnerTypes = response.data.options.type;
          this.loading = false;
          this.initialLoading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.loaderError = true;
          this.initialLoading = false;
        });
    },
    sortBy(fieldName) {
      if (this.sortByField !== fieldName) {
        this.reverseSort = false;
      }
      this.params.sort_by = fieldName;
      this.params.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this._loadList(this.params)
      this.sortByField = fieldName;

      this.reverseSort = !this.reverseSort;
    },
    changePerPage(perPage) {
      this.params.page = 1;
      this.params.perPage = perPage;
      this._loadList();
    },
    pageChanged(pageNb) {
      this.params.page = pageNb;
      this._loadList();
    },
    search() {
      if (this.searchString.length >= 3) {
        this.params.search = this.searchString;
        this._loadList();
      } else if (this.searchString.length === 0) {
        this.params.search = null;
        this._loadList();
      }
    },
    openForm(action, index = null) {
      this.formAction = action;
      if (action === 'update') {
        this.itemId = this.list.data[index].id;
        this.showForm = true;
      }

      if (action === 'create') {
        this.itemId = null;
        this.showForm = true;
      }
    },
    clearForm() {
      this.formAction = '';
      this.showForm = false;
      this.itemId = null;
      this.resetFilters();
      this._loadList();
    },
    resetFilters() {
      this.params = {
        page: 1,
        perPage: 20,
        sort_by: null,
        sort_direction: null,
        type: null,
        search: null
      };
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
  }
}
</script>
