<template>
  <div :class="{show: openModal }"
       class="modal fade">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header text-center no-border pb-0">
          <h3 v-if="modalAction === 'form_submit'" :key="'confirm_modal_title'" class="mx-auto">
            {{$t("flexben.benefit_groups.modal.confirm_save")}}
          </h3>
          <h3 v-else-if="modalAction === 'delete'" :key="'confirm_modal_title'" class="mx-auto">
            {{$t("flexben.benefit_groups.modal.confirm_delete")}}
          </h3>
          <h3 v-else-if="modalAction === 'go_back'" :key="'confirm_modal_title'" class="mx-auto">
            {{$t("flexben.benefit_groups.modal.confirm_leave")}}
          </h3>
          <h3 v-else-if="modalAction === 'end'" :key="'confirm_modal_title'" class="mx-auto">
            {{ $t('flexben.general.status_type.'+(formSuccess ? 'success' : 'failed')) }}
          </h3>
        </div>
        <div class="modal-body text-center bg-white">
          <div class="m-auto" v-if="modalAction === 'end' && endMessage.length>0">
            {{ endMessage }}
          </div>
          <div class="m-auto" v-if="modalAction === 'delete'">
            {{$t("flexben.benefit_groups.modal.disclaimer_delete")}}
          </div>
        </div>

        <div class="modal-footer mb-2  no-border">
          <button v-if="modalAction === 'end'" type="button"
                  @click="$emit('confirmAction', 'end')"
                  class="btn save-btn float-right m-auto">
            {{$t("flexben.general.btn.continue")}}
          </button>
          <button v-if="modalAction !== 'end'" type="button"
                  @click="$emit('closeModal')"
                  class="btn mr-auto text-themed">
            {{$t("flexben.general.btn.cancel")}}
          </button>
          <button v-if="modalAction === 'go_back'" type="button"
                  @click="$emit('confirmAction', 'go_back')"
                  class="btn save-btn">
            {{$t("flexben.general.btn.confirm")}}
          </button>
          <button v-if="modalAction === 'form_submit' || modalAction === 'delete'" type="button"
                  @click="$emit('confirmAction','form_submit')"
                  class="btn save-btn">
            {{$t("flexben.general.btn.continue")}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientConfirmModal",
  props: {
    openModal: {
      type: Boolean,
      default: false,
      required: true
    },
    formSuccess: {
      type: Boolean,
      default: false,
      required: true
    },
    modalAction: {
      type: String,
      default: '',
      required: true
    },
    endMessage: {
      type: String,
      default: '',
    },
  },
}
</script>
