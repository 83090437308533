<template>
  <div>
    <ListAllRequests v-if="step===1"
                     :isAdmin="isAdmin"
                     :companyId="companyId"
                     @setQuickFilterCompany="setQuickFilterCompany"
                     @setGeneralData="setGeneralData"
                     @placeOrder="placeOrder"
                     @toggleLoading="toggleLoading"/>
    <ListPlaceOrderResult v-if="step===2"
                          :selectedRequestToOrder="selectedRequestToOrder"
                          :selectedFilters="selectedFilters"
                          :isAdmin="isAdmin"
                          :companyId="companyId"
                          @reStartFlow="reStartFlow"
                          @setGeneralData="setGeneralData"
                          @displaySuccessScreen="displaySuccessScreen"
                          @toggleLoading="toggleLoading"/>
    <SuccessPlaceOrders v-if="step===3"
                        :countPlacedOrders="successfulOrderTotal"
                        :scope="'top_up'"
                        :isAdmin="isAdmin"
                        @reStartFlow="reStartFlow"/>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import ListAllRequests from "@/components/flexben/requests/top_up/partials/ListAllRequests";
import ListPlaceOrderResult from "@/components/flexben/requests/top_up/partials/ListPlaceOrderResult";
import SuccessPlaceOrders from "@/components/flexben/requests/partials/SuccessPlaceOrders";

export default {
  name: "TopUpRequestsSteps",
  components: {
    SuccessPlaceOrders,
    ListAllRequests,
    ListPlaceOrderResult
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
      required: true,
    },
    companyId: {
      type: Number,
    },
  },
  data: function () {
    return {
      loading: false,
      loaderError: false,
      httpService: httpFlexbenService.requests,
      selectedRequestToOrder: [],
      selectedFilters: {
        company: null,
        benefit: null,
        beneficiary: null,
        start_date: null,
        end_date: null,
      },
      step: 1,
      successfulOrderTotal: 0
    };
  },
  methods: {
    setGeneralData() {
      this.$emit('setGeneralData');
    },
    placeOrder(requestIds, selectedFilters) {
      if (requestIds.length > 0 && this.companyId !== null) {
        this.scrollToTop();
        this.selectedRequestToOrder = requestIds;
        this.selectedFilters = selectedFilters;
        this.step = 2;
      }
    },
    toggleLoading(value, loaderError = false) {
      this.loading = value;
      this.loaderError = loaderError;
    },
    setQuickFilterCompany(companyId) {
      this.$emit('setQuickFilterCompany', companyId);
    },
    reStartFlow() {
      this.step = 1;
      this.setGeneralData();
    },
    displaySuccessScreen(successfulOrderTotal) {
      this.successfulOrderTotal = successfulOrderTotal;
      this.step = 3;
    }
  }
}
</script>
