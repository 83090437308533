<template>
  <div class="row p-3">
    <div class="col-12">
      <div class="row mx-auto background-grey pb-3 pb-md-0">
        <div class="col-12 col-xl-2">
          <img src="@/assets/images/flexben/utils/benefit-info.svg" alt="info" class="mt-3"/>
        </div>
        <div class="col-12 col-md">
          <div class="my-1 my-md-3">{{$t("flexben.benefit.fiscal_treatment")}}</div>
          <div class="my-md-3 text-bold text-16" :key="'deductibility'"
               v-if="this.formData.global_variable_codes.includes('impozit-venit') &&
        this.formData.global_variable_codes.includes('cas') &&
        this.formData.global_variable_codes.includes('cass')">
            {{$t("flexben.benefit.deductibility.3")}}
          </div>

          <div class="my-md-3 text-bold text-16" :key="'deductibility'"
               v-else-if="this.formData.global_variable_codes.includes('impozit-venit') ||
        this.formData.global_variable_codes.includes('cas') ||
        this.formData.global_variable_codes.includes('cass')">
            {{$t("flexben.benefit.deductibility.2")}}
          </div>
          <div class="my-md-3 text-bold text-16" :key="'deductibility'"
               v-else>
            {{$t("flexben.benefit.deductibility.1")}}
          </div>
        </div>
        <div class="col-sm-4 col-md">
          <div class="my-1 my-md-3">{{$t("flexben.general.income_tax")}}</div>
          <div class="text-bold text-16" :key="'impozit-venit'"
               :class="{'cursor-pointer': !formData.is_legislated}"
               @click="addGlobalVariable('impozit-venit')">
            {{ (formData.global_variable_codes.includes('impozit-venit') || formData.status==='draft') ? taxes['impozit-venit'].value : 0 }}
            {{ parseVarType('impozit-venit') }}
            <input type="checkbox" v-if="!formData.is_legislated"
                   :value="'impozit-venit'"
                   :checked="formData.global_variable_codes.includes('impozit-venit')" >
          </div>

        </div>
        <div class="col-sm-4 col-md">
          <div class="my-1 my-md-3">CAS</div>
          <div class="text-bold text-16" :key="'cas'"
               :class="{'cursor-pointer': !formData.is_legislated}"
               @click="addGlobalVariable('cas')">
            {{ (formData.global_variable_codes.includes('cas') || formData.status==='draft') ? taxes['cas'].value : 0 }}
            {{ parseVarType('cas') }}
            <input type="checkbox" v-if="!formData.is_legislated"
                   :value="'cas'"
                   :checked="formData.global_variable_codes.includes('cas')" >
          </div>

        </div>
        <div class="col-sm-4 col-md">
          <div class="my-1 my-md-3">CASS</div>
          <div class="text-bold text-16" :key="'cass'"
               :class="{'cursor-pointer': !formData.is_legislated}"
               @click="addGlobalVariable('cass')">
            {{ (formData.global_variable_codes.includes('cass') || formData.status==='draft') ? taxes['cass'].value : 0 }}
            {{ parseVarType('cass') }}
            <input type="checkbox" v-if="!formData.is_legislated"
                   :value="'cass'"
                   :checked="formData.global_variable_codes.includes('cass')">
          </div>

        </div>
      </div>
      <div class="row px-3 mb-3" v-if="withEvents">
        <div class="col-12 background-grey">
          <BenefitEventsTable :benefitEvents="formData.events"></BenefitEventsTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BenefitEventsTable from "@/components/flexben/benefits/partials/BenefitEventsTable";

export default {
  name: "BenefitFiscalAvailability",
  components: {
    BenefitEventsTable
  },
  props: {
    formData: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    taxes: {
      required: true,
      type: Object,
      default: function () {
        return {
          'impozit-venit': {type: '', name: '', value: 0},
          'cas': {type: '', name: '', value: 0},
          'cass': {type: '', name: '', value: 0},
        }
      }
    },
    withEvents: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  methods: {
    addGlobalVariable(variableCode) {
      if (!this.formData.is_legislated){
        if (this.formData.global_variable_codes.includes(variableCode)) {
          let index = this.formData.global_variable_codes.indexOf(variableCode);
          this.formData.global_variable_codes.splice(index, 1);
        } else {
          this.formData.global_variable_codes.push(variableCode);
        }
      }
    },
    parseVarType(key) {
      if (typeof this.taxes[key] !== 'undefined' && this.taxes[key].type === 'percent') {
        return '%';
      } else if (typeof this.taxes[key] !== 'undefined' && this.taxes[key].type === 'numeric') {
        return '&';
      } else if (typeof this.taxes[key] !== 'undefined' && this.taxes[key].type === 'text') {
        return '*';
      } else {
        return '%';
      }
    },
  }
}
</script>
