<template>
  <div>
    <div v-if="!initialLoading">
      <div class="row">
        <div class="col-12 col-md-6 flexben-page-title d-flex flex-row align-items-center">
          <h1 class="mr-2">{{ requestData.benefit.name }}</h1>
          <span class="flx-type-badge mr-1">{{ $t("flexben.benefit.delivery_types."+requestData.metas.delivery_type.value) }}</span>
          <span v-if="requestData.for_demo" class="text-danger text-18 text-bold ml-2"> - Companie DEMO</span>
        </div>
        <div class="col-12 col-md-6 text-right">
          <button v-if="requestData.status==='new'" class="btn text-danger mb-1" @click="openRejectModal()">
            <font-awesome-icon icon="trash" class="mr-1"/>
            {{ $t("flexben.requests.btn.reject_request") }}
          </button>
          <button v-if="requestData.status==='await_voucher_upload'" class="btn text-danger mb-1" @click="openRejectModal()">
            <font-awesome-icon icon="trash" class="mr-1"/>
            {{ $t("flexben.requests.btn.reject_request") }}
          </button>
          <button v-if="requestData.status==='new'" class="btn btn-themed-o mr-1 mb-1" @click="markPending()">
            {{ $t("flexben.requests.btn.mark_pending") }}
          </button>
          <button :disabled="requestData.status==='await_top_up' && requestData.benefit_voucher_id!=null"
                  class="btn btn-themed mb-1" @click="confirmPlaceOrder()">
            {{ $t("flexben.requests.btn.approve_request") }}
          </button>
        </div>
      </div>
      <div v-if="requestData.status==='await_top_up' && requestData.benefit_voucher_id!=null" class="row">
        <div class="col-12 text-danger text-center">
          {{ $t('flexben.requests.delivering_auto') }}
        </div>
      </div>
      <div v-if="errorPlacingOrder" class="my-3">
        <div class="alert alert-danger alert-dismissible mb-0 text-danger">
          <a type="button" class="close btn" data-dismiss="alert" @click="errorPlacingOrder = false">&times;</a>
          {{ $t("flexben.general.error_ups") }}
        </div>
      </div>
      <div class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-12 d-flex flex-row">
            <h3 class="m-0 pl-0">{{ $t("flexben.requests.title_placed_at",{number:requestData.id}) }} {{
              moment(requestData.placed_at, 'DD/MM/YYYY HH:mm') }}</h3>
            <span class="beneficiary-request-status my-auto"
                  :class="'flx-'+requestData.status">{{ $t('flexben.requests.summary.status.' + requestData.status) }}
                    </span>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.requests.fields.value') }}</label>
            <div class="form-control disabled">{{ requestData.total_value }}</div>
          </div>

          <div v-if="requestData.metas.delivery_type.value==='card'"
               class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.requests.fields.card_request')}}</label>
            <div class="form-control disabled">{{ $t('flexben.general.'+((typeof
              requestData.metas.request_card!=='undefined')?'yes':'no')) }}
            </div>
          </div>
          <div v-if="requestData.metas.delivery_type.value==='card'"
               class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.requests.fields.card_series')}}</label>
            <div class="form-control disabled">{{ requestData.metas.card_series.value }}</div>
          </div>

          <div v-if="(requestData.metas.delivery_type.value==='digital_code' || requestData.metas.delivery_type.value==='digital_file')
           && options.has_voucher_stock"
               class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label" for="consume_voucher_stock">{{
              $t('flexben.requests.fields.consume_voucher_stock')}}</label>

            <div class="d-flex flex-row pt-2">
              <span>{{ $t('flexben.general.no') }}</span>
              <b-form-checkbox id="consume_voucher_stock"
                               class="ml-3 mr-3"
                               switch
                               v-model="formData.consume_voucher_stock">
              </b-form-checkbox>
              <span>{{ $t('flexben.general.yes') }}</span>
            </div>
          </div>

          <div v-if="requestData.metas.delivery_type.value==='digital_code'"
               class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.requests.fields.voucher_code')}}</label>
            <input type="text" class="form-control" v-model="formData.voucher_code"
                   :disabled="formData.consume_voucher_stock"
                   :class="{'border-danger':(Object.keys(errorsBag).includes('voucher_code'))}"/>
          </div>

          <div v-if="requestData.metas.delivery_type.value==='digital_file'"
               class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.requests.fields.voucher_file')}}</label>
            <input type="file" class="form-control p-1" @change="processFile($event)"
                   :disabled="formData.consume_voucher_stock"
                   :class="{'border-danger':(Object.keys(errorsBag).includes('voucher_file'))}"/>
          </div>

          <div v-if="requestData.metas.delivery_type.value==='physical'"
               class="col-12 col-md-6 col-xl-8 form-group mb-0">
            <label class="col-form-label">{{ $t('flexben.requests.fields.physical_label')}}</label>
            <div class="py-2">
              {{ $t('flexben.requests.fields.physical_text')}}
            </div>
          </div>
        </div>
      </div>
      <RequestBenefitDynamicFields :requestData="requestData"/>
      <RequestEmployeeInfo :employee="requestData.company_employee"/>
      <div class="row mt-3">
        <div class="col-12 text-right">
          <button v-if="requestData.status==='new'" class="btn text-danger mb-1" @click="openRejectModal()">
            <font-awesome-icon icon="trash" class="mr-1"/>
            {{ $t("flexben.requests.btn.reject_request") }}
          </button>
          <button v-if="requestData.status==='new'" class="btn btn-themed-o mr-1 mb-1" @click="markPending()">
            {{ $t("flexben.requests.btn.mark_pending") }}
          </button>
          <button :disabled="requestData.status==='await_top_up' && requestData.benefit_voucher_id!=null"
                  class="btn btn-themed mb-1" @click="confirmPlaceOrder()">
            {{ $t("flexben.requests.btn.approve_request") }}
          </button>
        </div>
      </div>
    </div>
    <RejectModal :rejectData="rejectData"
                 :showDisableTopUps="false"
                 @confirmAction="rejectRequests()"
                 ref="reject-modal"/>
    <ConfirmMassPlaceOrderModal @placeOrder="placeOrder" ref="confirm_po_modal"/>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import RejectModal from "@/components/flexben/requests/modals/RejectModal";
import RequestBenefitDynamicFields
  from "@/components/flexben/requests/not_legislated/partials/RequestBenefitDynamicFields";
import RequestEmployeeInfo from "@/components/flexben/requests/not_legislated/partials/RequestEmployeeInfo";
import ConfirmMassPlaceOrderModal from "@/components/flexben/requests/modals/ConfirmMassPlaceOrderModal";
import {BFormCheckbox} from "bootstrap-vue";

export default {
  name: "VoucherRequestDetails",
  components: {
    ConfirmMassPlaceOrderModal,
    RejectModal,
    RequestBenefitDynamicFields,
    RequestEmployeeInfo,
    "b-form-checkbox": BFormCheckbox,
  },
  props: {
    detailsId: {
      required: true,
      type: Number
    }
  },
  data: function () {
    return {
      initialLoading: true,
      loading: false,
      loaderError: false,
      httpService: httpFlexbenService.requests,
      requestData: {},
      options: {
        has_voucher_stock: false
      },
      rejectData: {
        ids: [],
        reject_reason: ''
      },
      formData: {
        voucher_code: null,
        voucher_file: null,
        consume_voucher_stock: false,
      },
      errorPlacingOrder: false,
      errorsBag: {},
      invalidOrderReason: null
    };
  },
  created() {
    this.rejectData.ids.push(this.detailsId);
    this._getRequestDetails();
  },
  methods: {
    goBack(withReRender = false) {
      this.$emit('hideDetails', withReRender)
    },
    openRejectModal() {
      this.$refs['reject-modal'].openModal();
    },
    rejectRequests() {
      this.loading = true;
      this.$refs['reject-modal'].closeModal();
      let payload = {...this.rejectData};

      this.httpService.rejectRequests(payload)
        .then(function (response) {
          this.loading = false;
          this.goBack(true);
        }.bind(this))
        .catch(function (error) {
          if (500 <= error.response.status && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false;
          }
        }.bind(this));
    },
    placeOrder(action) {
      this.$refs['confirm_po_modal'].closeModal();
      this.errorPlacingOrder = false;
      let payload = this._preparePayload(action);
      if (!this.errorPlacingOrder) {
        this.loading = true;
        if (action === 'order') {
          this.invalidOrderReason = null;
          this.httpService.orderVoucher(this.detailsId, payload)
            .then((response) => {
              this.loading = false;
              if (response.data.status === 'success') {
                this.goBack(true);
              } else {
                this.invalidOrderReason = this.$t('flexben.requests.errors.no_voucher_stocks')
              }
            })
            .catch((error) => {
              if (error.response.status >= 500 && error.response.status < 600) {
                this.loaderError = true;
              } else {
                this.errorPlacingOrder = true;
                this.loading = false;
              }
            });
        } else if (action === 'mark_pending') {
          this.httpService.voucherMarkPending(this.detailsId)
            .then(function (response) {
              this.loading = false;
              this.goBack(true);
            }.bind(this))
            .catch(function (error) {
              if (error.response.status >= 500 && error.response.status < 600) {
                this.loaderError = true;
              } else {
                this.errorPlacingOrder = true;
                this.loading = false;
              }
            }.bind(this));
        } else {
          this.loading = false;
        }
      } else {
        this.scrollToTop();
      }
    },
    processFile(event) {
      this.formData.voucher_file = event.target.files[0]
    },
    confirmPlaceOrder() {
      this.$refs['confirm_po_modal'].openModal('order');
    },
    markPending() {
      this.$refs['confirm_po_modal'].openModal('mark_pending');
    },
    _getRequestDetails() {
      this.loading = true;
      this.httpService.getRequestDetails(this.detailsId)
        .then((response) => {
          this.requestData = response.data.details;
          this.options = response.data.options;
          this.loading = false;
          this.initialLoading = false;
        })
        .catch((error) => {
          this.goBack();
        });
    },
    _preparePayload(action) {
      let formData = new FormData();
      if (action === 'order') {
        if (!this.formData.consume_voucher_stock) {
          if (this.requestData.metas.delivery_type.value === 'digital_code') {
            if (this.formData.voucher_code) {
              formData.append('voucher_value', this.formData.voucher_code);
            } else {
              this.errorsBag.voucher_code = 'required';
              this.errorPlacingOrder = true;
            }

          }
          if (this.requestData.metas.delivery_type.value === 'digital_file') {
            if (this.formData.voucher_file) {
              formData.append('voucher_value', this.formData.voucher_file);
            } else {
              this.errorsBag.voucher_file = 'required';
              this.errorPlacingOrder = true;
            }
          }
        }
        if (this.requestData.metas.delivery_type.value === 'digital_code' || this.requestData.metas.delivery_type.value === 'digital_file') {
          formData.append('consume_voucher_stock', this.formData.consume_voucher_stock ? 1 : 0);
        }
      }

      return formData;
    }
  }
}
</script>
