<template>
  <div :class="{'mt-3':!inModal, 'px-3':!inModal}">
    <div class="row">
      <div class="col-12">
        <span class="text-28 text-bold">{{ $t('flexben.clients.dynamic_translations.title') }}</span>
      </div>
    </div>
    <div class="row" v-for="(translation, transKey, index) in translations" :key="transKey+index">
      <div v-for="(lang, langIndex) in available_languages" :key="langIndex"
           class="col-12 col-md-6 form-group">
        <label :for="transKey+lang"
               class="col-form-label pt-0">{{ $t('flexben.clients.dynamic_translations.'+transKey) }} ({{
          lang }}) </label>
        <textarea :id="transKey+lang"
                  rows="3"
                  maxlength="250"
                  class="form-control-textarea w-100 my-0"
                  v-model="translation[lang]"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BenefitTranslations",
  props: {
    translations: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
    inModal: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  data: function () {
    return {
      available_languages: ['ro', 'en']
    };
  },
}
</script>
