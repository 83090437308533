<template>
  <div class="container-bg-white mt-4">
    <div class="row px-3 pt-3">
      <div class="col-12">
        <h3 class="my-0 py-1 pl-0">{{$t("flexben.beneficiaries.time_sheet")}}</h3>
      </div>
      <div class="col-12 px-3 mb-1 text-danger"
           v-if="checkNotEmptyObj(errorBag)"
           v-html="getInvalidReasons(errorBag)">
      </div>

      <div class="col-md-6 col-xl-4 form-group">
        <label for="working_days" class="col-form-label">{{ $t('flexben.import.table_headings.working_days') }}</label>
        <input type="number" id="working_days"
               v-model="timeSheetData.working_days"
               min="0"
               :disabled="!form_options.up_dejun_enabled"
               :max="form_options.max_month_working_days"
               :class="{'border-danger':checkInErrorBag('working_days')}"
               class="form-control w-100">
      </div>
      <div class="col-md-6 col-xl-4 form-group">
        <div class="d-inline-flex w-100">
          <label for="ts_month" class="w-70 cursor-pointer col-form-label">{{ $t('flexben.general.month') }}</label>
          <label for="ts_year" class="w-30 cursor-pointer col-form-label">{{ $t('flexben.general.year') }}</label>
        </div>

        <div class="d-inline-flex w-100">
          <font-awesome-icon icon="calendar-alt" class="my-auto mr-2 text-24 flx-calendar-icon"/>
          <div class="d-inline-flex w-100">
            <select name="ts_month" id="ts_month" class="w-65 form-control mr-2"
                    :disabled="!form_options.up_dejun_enabled"
                    :class="{'border-danger':checkInErrorBag('month')}"
                    v-model="timeSheetData.month">
              <option :value="null">{{ $t('flexben.general.month')}}</option>
              <option :value="1">{{$t("flexben.general.months.1")}}</option>
              <option :value="2">{{$t("flexben.general.months.2")}}</option>
              <option :value="3">{{$t("flexben.general.months.3")}}</option>
              <option :value="4">{{$t("flexben.general.months.4")}}</option>
              <option :value="5">{{$t("flexben.general.months.5")}}</option>
              <option :value="6">{{$t("flexben.general.months.6")}}</option>
              <option :value="7">{{$t("flexben.general.months.7")}}</option>
              <option :value="8">{{$t("flexben.general.months.8")}}</option>
              <option :value="9">{{$t("flexben.general.months.9")}}</option>
              <option :value="10">{{$t("flexben.general.months.10")}}</option>
              <option :value="11">{{$t("flexben.general.months.11")}}</option>
              <option :value="12">{{$t("flexben.general.months.12")}}</option>
            </select>
            <input type="number" name="ts_year" id="ts_year"
                   :disabled="!form_options.up_dejun_enabled"
                   :class="{'border-danger':checkInErrorBag('year')}"
                   v-model="timeSheetData.year"
                   :min="currentYear" class="w-35 form-control">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-4 form-group">
        <label for="ts_last_update" class="col-form-label">{{ $t('flexben.import.table_headings.updated_at') }}</label>
        <div class="d-inline-flex w-100">
          <font-awesome-icon icon="calendar-alt" class="my-auto mr-2 text-24 flx-calendar-icon"/>
          <input type="text" id="ts_last_update" disabled class="form-control w-100 m-0"
                 :value="moment(timeSheetData.updated_at,'DD/MM/YYYY')">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "BeneficiaryTimeSheet",
  components: {},
  props: {
    timeSheetData: {
      type: Object,
      required: true,
    },
    errorBag: {
      default: function () {
        return {}
      },
      required: true,
    },
    form_options: {
      type: Object,
      required: true,
      default: function () {
        return {
          max_month_working_days: 0,
          top_up_fixed_values: {
            enable: false,
            values: [],
          },
          up_dejun_enabled: false
        };
      },
    },
  },
  data() {
    return {
      currentYear: (new Date()).getFullYear()
    };
  },
  methods: {
    getInvalidReasons(invalidReasons) {
      let errorList = [];
      let indexKey, errorField;
      for (errorField in invalidReasons) {
        if (typeof invalidReasons[errorField] === 'string') {
          errorList.push(this.$t('flexben.formFieldError', {
            field: this.$t('flexben.import.table_headings.' + errorField),
            reason: this.$t('flexben.formErrorReasons.' + invalidReasons[errorField])
          }))
        } else {
          for (indexKey in invalidReasons[errorField]) {
            if (invalidReasons[errorField][indexKey] === 'exceeding_max_working_days_month') {
              errorList.push(this.$t('flexben.formFieldError', {
                field: this.$t('flexben.import.table_headings.' + errorField),
                reason: this.$t('flexben.formErrorReasons.' + invalidReasons[errorField][indexKey], {
                  days_nr: this.form_options.max_month_working_days
                })
              }))
            } else if (errorField === 'miss_match_last_top_up_day') {
              //NOTE corner case push
              errorList.push(this.$t('flexben.import.invalid_reasons.miss_match_last_top_up_day', {'date': invalidReasons[errorField][indexKey]}))
            } else {
              errorList.push(this.$t('flexben.formFieldError', {
                field: this.$t('flexben.import.table_headings.' + errorField),
                reason: this.$t('flexben.formErrorReasons.' + invalidReasons[errorField][indexKey])
              }))
            }
          }
        }
      }
      return errorList.join('<br>');
    },
    moment(date, format) {
      if (date === null) {
        return '-';
      }
      return moment(date).format(format);
    },
    checkInErrorBag(field) {
      if (!this.checkNotEmptyObj(this.errorBag)) {
        return false;
      }

      let objKeys = Object.keys(this.errorBag);
      return objKeys.includes(field);
    }
  }
}
</script>
