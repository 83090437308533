<template>
  <b-tab :key="'all_auto_top_ups_history'" :active="activeTab === 'all_auto_top_ups_history'"
         :title="$t('flexben.beneficiaries.all_history_title')" class="container-bg-white mt-0">
    <div class="row px-3 pb-2 ml-1">
      <b-tabs v-model="tabKeys[activeTab]" nav-class="flexben-tabs">
        <b-tab key="tme_history" :title="$t('flexben.beneficiaries.tme_auto_top_up_history')">
          <template #title>
            <span :class="['custom-tab-btn', { 'active-tab-btn': isActiveTab('tme_history') }]">
              {{ $t('flexben.beneficiaries.tme_auto_top_up_history') }}
            </span>
          </template>
          <TabTMEHistory
          :autoTopupHistoryTME = "allHistory.autoTopupHistoryTME"
          />
        </b-tab>
        <b-tab key="ppp3_history" :title="$t('flexben.beneficiaries.ppp3_auto_top_up_history')">
          <template #title>
            <span :class="['custom-tab-btn', { 'active-tab-btn': isActiveTab('ppp3_history') }]">
              {{ $t('flexben.beneficiaries.ppp3_auto_top_up_history') }}
            </span>
          </template>
          <TabPPP3History
          :autoTopupHistoryPPP3="allHistory.autoTopupHistoryPPP3" />
        </b-tab>
        <b-tab key="insurance_history" :title="$t('flexben.beneficiaries.insurance_auto_top_up_history')">
          <template #title>
            <span :class="['custom-tab-btn', { 'active-tab-btn': isActiveTab('insurance_history') }]">
              {{ $t('flexben.beneficiaries.insurance_auto_top_up_history') }}
            </span>
          </template>
          <TabInsuranceHistory
           :autoTopupHistoryMedicalInsurance="allHistory.autoTopupHistoryMedicalInsurance"
          />
        </b-tab>
      </b-tabs>
    </div>
  </b-tab>
</template>

<script>
import TabTMEHistory from './TabTMEHistory.vue';
import TabPPP3History from './TabPPP3History.vue';
import TabInsuranceHistory from './TabInsuranceHistory.vue';
import { BTab, BTabs } from 'bootstrap-vue';

export default {
  components: {
    BTab,
    BTabs,
    TabTMEHistory,
    TabPPP3History,
    TabInsuranceHistory
  },
  data() {
    return {
      activeTab: null,
      tabKeys: {
        tme_history: 0,
        ppp3_history: 1,
        insurance_history: 2,
      },
    };
  },
  props:{
    allHistory:{}
  },
  methods: {
    isActiveTab(key) {
      return this.tabKeys[this.activeTab] === this.tabKeys[key];
    },
  }
};
</script>

<style scoped>
.custom-tab-btn {
  background-color: #f6b23f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 60px;
  font-size: 12px;
  cursor: pointer;
  margin-right: 5px;
  display: inline-block;
}

.custom-tab-btn:hover {
  background-color: #e67e22;
}

.active-tab-btn {
  background-color: #e67e22;
  color: white;
}
</style>
