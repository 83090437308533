<template>
  <div :class="{show: openModal}"
       class="modal fade">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header text-center no-border pb-0">
          <h3 v-if="modalAction === 'form_submit'" :key="'confirm_modal_title'" class="mx-auto">
            <template v-if="options.saveAs==='draft'">{{$t("flexben.benefit.modal.save_draft")}}</template>
            <template v-if="options.saveAs==='publish'">{{$t("flexben.benefit.modal.publish")}}</template>
            <template v-if="options.saveAs==='save'">{{$t("flexben.benefit.modal.confirm_save")}}</template>
          </h3>
          <h3 v-else-if="modalAction === 'go_back'" :key="'confirm_modal_title'" class="mx-auto">
            {{$t("flexben.benefit.modal.confirm_leave")}}
          </h3>
          <h3 v-else-if="modalAction === 'delete'" :key="'confirm_modal_title'" class="mx-auto">
            {{$t("flexben.benefit.modal.confirm_delete")}}
          </h3>
          <h3 v-else-if="modalAction === 'status_change'" :key="'confirm_modal_title'" class="mx-auto">
            {{$t("flexben.benefit.modal.confirm_status",{action:$t("flexben.benefit.modal.status_actions."+options.statusChange)})}}
          </h3>
          <h3 v-else-if="modalAction === 'end'" :key="'confirm_modal_title'" class="mx-auto">
            {{ $t('flexben.general.status_type.'+(formSuccess ? 'success' : 'failed')) }}
          </h3>
        </div>
        <div class="modal-body text-center bg-white text-16" :class="{'text-danger':!formSuccess}"
             v-if="message.length>0" v-html="message">
        </div>
        <div class="modal-footer mb-2  no-border">
          <button v-if="modalAction === 'end'" type="button"
                  @click="$emit('confirmAction', 'end')"
                  class="btn save-btn float-right m-auto">
            {{$t("flexben.general.btn.continue")}}
          </button>
          <button v-if="modalAction !== 'end'" type="button"
                  @click="$emit('closeModal')"
                  class="btn mr-auto text-themed">
            {{$t("flexben.general.btn.cancel")}}
          </button>
          <button v-if="modalAction === 'go_back'" type="button"
                  @click="$emit('confirmAction', 'go_back')"
                  class="btn save-btn">
            {{$t("flexben.general.btn.confirm")}}
          </button>
          <button v-if="modalAction === 'form_submit' || modalAction === 'delete' ||modalAction==='status_change'"
                  type="button"
                  @click="$emit('confirmAction',modalAction)"
                  class="btn save-btn">
            {{$t("flexben.general.btn.continue")}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: {
    openModal: {
      type: Boolean,
      default: false,
      required: true
    },
    formSuccess: {
      type: Boolean,
      default: false,
      required: true
    },
    modalAction: {
      type: String,
      default: '',
      required: true
    },
    message: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: function () {
        return {
          saveAs: '',
          statusChange: '',
        };
      },
    },
  },
}
</script>
