<template>
  <div>
    <div class="table-responsive hover-table flexben-category-benefit-table">
      <table class="table table-striped">
        <thead>
        <tr>
          <td :class="{'cursor-pointer':showPagination}" @click="sortBy('name')">
            {{ $t("flexben.categories.table.benefit") }}
            <img v-if="showPagination" src="@/assets/images/arrow-down-fill.svg"
                 :style="this.sortField === 'name' && this.reverseSort ? 'transform: rotate(180deg)' : '' "/>
          </td>
          <td :class="{'cursor-pointer':showPagination}" @click="sortBy('created_at')">
            {{ $t("flexben.categories.table.date_created") }}
            <img v-if="showPagination" src="@/assets/images/arrow-down-fill.svg"
                 :style="this.sortField === 'created_at' && this.reverseSort  ? 'transform: rotate(180deg)':''"/>
          </td>
          <td>
            {{ $t("flexben.categories.table.date_categories") }}
          </td>
          <td :class="{'cursor-pointer':showPagination}" @click="sortBy('status')">
            {{ $t("flexben.categories.table.date_status") }}
            <img v-if="showPagination" src="@/assets/images/arrow-down-fill.svg"
                 :style="this.sortField === 'status' && this.reverseSort ?  'transform: rotate(180deg)':''"/>
          </td>
          <td></td>
        </tr>
        </thead>
        <tbody v-if="this.benefits.data.length>0">
        <tr v-for="(item, index) in this.benefits.data" :key="index"
            :class="(index %2 ===0) && 'gri-cell'" class="trhover">
          <td>
            <span class="color-bullet mr-2" :style="'background-color: ' + item.color"></span>
            {{ item.name }}
          </td>
          <td>{{ moment(item.created_at, 'DD/MM/yyyy') }}</td>
          <td class="flx-benefit-categories">
            <template v-if="item.categories.length > 0">
                  <span class="flx-category text-ellipsis-15 mr-1" v-for="category in item.categories " :key="category.id">
                      {{ category.name[locale] }}
                  </span>
            </template>
          </td>
          <td>
            <span :class="'benefit-status-'+item.status.toLocaleLowerCase()" class="benefit-status m-0">{{ $t("flexben.general.status_type." + item.status.toLocaleLowerCase()) }}</span>
          </td>
          <td>
            <div class="text-right mr-2">
              <img class="cursor-pointer" alt="x" @click="unAssignBenefit(index)"
                   src="@/assets/images/flexben/table-remove-button.svg"/>
            </div>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr class="trhover">
          <td colspan="5" class="text-center"> {{ $t("flexben.general.no_results_found") }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <listPagination v-show="showPagination"
                    :activePage="benefits.current_page"
                    :currentPage="benefits.current_page"
                    :totalItems="benefits.total"
                    :startItem="benefits.from"
                    :endItem="benefits.to"
                    :itemsPerPage="benefits.perPage"
                    @pageChanged="pageChanged"
                    @changePerPage="changePerPage"></listPagination>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    benefits: {
      type: Object,
      default: function () {
        return {
          data: [],
          current_page: 1,
          to: 1,
          perPage: 1,
          lastPage: 1,
          total: 1,
        };
      },
      required: true,
    },
    locale: {
      type: String,
      default: 'ro',
      required: true,
    },
    showPagination: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  data() {
    return {
      sortField: '',
      sortDirection: null,
      reverseSort: false,
      filer_values: {
        sortField: '',
        sortDirection: 'asc',
        page: 1,
        perPage: 20,
      },
    }
  },
  methods: {
    sortBy(fieldName) {
      if (this.sortField !== fieldName) {
        this.reverseSort = false;
      }

      this.filer_values.sort_by = fieldName;
      this.filer_values.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this.getAssignedBenefits();
      this.sortField = fieldName;
      this.reverseSort = !this.reverseSort;
    },
    pageChanged(pageNb) {
      this.filer_values.page = pageNb;
      this.getAssignedBenefits();
    },
    changePerPage(perPage) {
      this.filer_values.page = 1;
      this.filer_values.perPage = perPage;
      this.getAssignedBenefits();
    },
    getAssignedBenefits() {
      this.$emit('getAssignedBenefits', this.filer_values)
    },
    moment(date, format) {
      return moment(date).format(format);
    },
    unAssignBenefit(index) {
      this.$emit('unAssignBenefit', index)
    }
  }
  ,
}
</script>
