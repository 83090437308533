<template>
  <div v-show="!initialLoading" class="col-12">
    <div class="container-bg-white">
      <div class="row p-3" id="filterGroup">
        <div class="col-12 col-xl-3 mb-2 mb-xl-0">
          <img src="@/assets/images/filter.svg"/>
          <span class="ml-3">{{$t("flexben.general.filter")}}</span>
        </div>
        <div class="col-12 col-xl-9">
          <div class="row">
            <div class="col-12 col-md-4 form-group-multiselect mb-0">
              <label for="select_company" class="col-form-label">{{ $t("flexben.reports.filters.client")
                }}</label>

              <multiselect :multiple="false"
                           :class="{'from-control':true}"
                           label="name"
                           trackBy="value"
                           id="select_company"
                           openDirection="bottom"
                           :closeOnSelect="true"
                           :searchable="true"
                           :placeholder="$t('flexben.general.search')"
                           selectLabel=""
                           deselectLabel=""
                           selectedLabel=""
                           v-model="filter_values.company"
                           :options="filterOptions.companies"
                           ref="multiselect_companies">
              </multiselect>
            </div>
            <div class="col-12 col-md-4 form-group mb-0">
              <label for="campaign_types" class="col-form-label form-fields-required">{{
                $t("flexben.reports.filters.campaign_types")
                }}</label>
              <select name="campaign_types" id="campaign_types" class="form-control"
                      :class="{'border-danger':errors.fields.includes('campaign_type')}"
                      v-model="filter_values.campaign_type">
                <option :value="null">-</option>
                <option v-for="(item, index) of filterOptions.campaign_types" :value="item"
                        :key="index">
                  {{ $t('flexben.reports.data_tracking.campaign_type.'+item) }}
                </option>
              </select>
            </div>

            <div class="col-12 col-md-4 form-group-multiselect mb-0">
              <label for="select_categories" class="col-form-label">{{ $t("flexben.reports.filters.categories")
                }}</label>

              <multiselect :disabled="!filter_values.campaign_type"
                           :multiple="true"
                           :class="{'from-control':true}"
                           label="text"
                           trackBy="value"
                           id="select_categories"
                           openDirection="bottom"
                           :closeOnSelect="false"
                           :searchable="true"
                           :placeholder="$t('flexben.general.search')"
                           selectLabel=""
                           deselectLabel=""
                           selectedLabel=""
                           v-model="filter_values.categories"
                           :options="filterOptionsCategory"
                           ref="select_categories">
              </multiselect>

            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4 form-group mb-0">
              <label for="campaign_track_levels" class="col-form-label form-fields-required">{{
                $t("flexben.reports.filters.campaign_track_levels")
                }}</label>
              <select name="campaign_track_levels" id="campaign_track_levels" class="form-control"
                      :class="{'border-danger':errors.fields.includes('campaign_track_level')}"
                      v-model="filter_values.campaign_track_level">
                <option :value="null">-</option>
                <option v-for="(item, index) of filterOptions.campaign_track_levels" :value="item"
                        :key="index">{{ $t('flexben.reports.data_tracking.campaign_track_levels.'+item) }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-4 form-group mb-0">
              <label for="date_groups" class="col-form-label form-fields-required">{{
                $t("flexben.reports.filters.date_groups")
                }}</label>
              <select name="date_groups" id="date_groups" class="form-control"
                      :class="{'border-danger':errors.fields.includes('date_group')}"
                      v-model="dateGroup">
                <option :value="null">-</option>
                <option v-for="(item, index) of filterOptions.date_groups" :value="item"
                        :key="index">{{ $t('flexben.reports.data_tracking.date_groups.'+item) }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="dateGroup==='day'" class="row">
            <div class="col-12 col-md-4 form-group mb-0">
              <label class="col-form-label form-fields-required" for="start_date">{{$t("flexben.reports.inputLabels.startDate")}}</label>
              <div class="w-100 d-inline-flex">
                <label for="start_date" class="my-auto mr-2 col-form-label">
                  <font-awesome-icon icon="calendar-alt" class="text-24"/>
                </label>
                <vue-datepicker :format="dateFormat"
                                :inputClass="{'border-danger':errors.fields.includes('start_date'),'form-control-d m-0 w-100 background-white':true}"
                                v-model="filter_values.start_date"
                                valueType="format"
                                :class="'w-100'"
                                key="start_date"
                                id="start_date">
                </vue-datepicker>
                <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                     @click="function (){filter_values.start_date=null;}"/>
              </div>
            </div>
            <div class="col-12 col-md-4 form-group mb-0">
              <label class="col-form-label form-fields-required" for="end_date">{{$t("flexben.reports.inputLabels.endDate")}}</label>
              <div class="w-100 d-inline-flex">
                <label for="end_date" class="my-auto mr-2 col-form-label">
                  <font-awesome-icon icon="calendar-alt" class="text-24"/>
                </label>
                <vue-datepicker :format="dateFormat"
                                :inputClass="{'border-danger':errors.fields.includes('end_date'),'form-control-d m-0 w-100 background-white':true}"
                                v-model="filter_values.end_date"
                                :disabled="!filter_values.start_date"
                                valueType="format"
                                :disabledDates="endDateDisableUntil"
                                :class="'w-100'"
                                key="end_date"
                                id="end_date">
                </vue-datepicker>
                <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                     @click="function (){filter_values.end_date=null;}"/>
              </div>
            </div>
          </div>
          <div v-if="dateGroup==='month'" class="row">
            <div class="col-12 col-md-3 form-group mb-0">
              <label for="start_month" class="col-form-label form-fields-required">{{
                $t("flexben.reports.filters.start_month")
                }}</label>
              <select name="start_month" id="start_month" class="form-control"
                      :class="{'border-danger':errors.fields.includes('start_month')}"
                      v-model="filter_values.start_month">
                <option :value="null">-</option>
                <option v-for="(item, index) of months" :value="item"
                        :key="index">{{ item }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-3 form-group mb-0">
              <label for="end_month" class="col-form-label form-fields-required">{{
                $t("flexben.reports.filters.end_month")
                }}</label>
              <select name="end_month" id="end_month" class="form-control"
                      :class="{'border-danger':errors.fields.includes('end_month')}"
                      v-model="filter_values.end_month">
                <option :value="null">-</option>
                <option v-for="(item, index) of months" :value="item"
                        :key="index">{{ item }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-3 form-group mb-0">
              <label for="start_year" class="col-form-label form-fields-required">{{
                $t("flexben.reports.filters.start_year")
                }}</label>
              <select name="start_year" id="start_year" class="form-control"
                      :class="{'border-danger':errors.fields.includes('start_year')}"
                      v-model="filter_values.start_year">
                <option :value="null">-</option>
                <option v-for="(item, index) of years" :value="item"
                        :key="index">{{ item }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-3 form-group mb-0">
              <label for="end_year" class="col-form-label form-fields-required">{{
                $t("flexben.reports.filters.end_year")
                }}</label>
              <select name="end_year" id="end_year" class="form-control"
                      :class="{'border-danger':errors.fields.includes('end_year')}"
                      v-model="filter_values.end_year">
                <option :value="null">-</option>
                <option v-for="(item, index) of years" :value="item"
                        :key="index">{{ item }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="dateGroup==='year'" class="row">
            <div class="col-12 col-md-3 form-group mb-0">
              <label for="start_year" class="col-form-label form-fields-required">{{
                $t("flexben.reports.filters.start_year")
                }}</label>
              <select name="start_year" id="start_year" class="form-control"
                      :class="{'border-danger':errors.fields.includes('start_year')}"
                      v-model="filter_values.start_year">
                <option :value="null">-</option>
                <option v-for="(item, index) of years" :value="item"
                        :key="index">{{ item }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-3 form-group mb-0">
              <label for="end_year" class="col-form-label form-fields-required">{{
                $t("flexben.reports.filters.end_year")
                }}</label>
              <select name="end_year" id="end_year" class="form-control"
                      :class="{'border-danger':errors.fields.includes('end_year')}"
                      v-model="filter_values.end_year">
                <option :value="null">-</option>
                <option v-for="(item, index) of years" :value="item"
                        :key="index">{{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-bg-white mt-3">
      <div class="row p-3">
        <div class="col-12 mt-2 ">
          <apex-chart v-if="dataFiltered"
                      type="line"
                      :options="chart_data.options"
                      :series="chart_data.series"
                      :stroke=" {
                        curve: 'stepline'
                      }"></apex-chart>
          <div v-else class="text-center">Filtrati</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import utils from "@/plugins/utils";

export default {
  name: "CashOutReport",
  components: {
    'vue-datepicker': Datepicker,
    'apex-chart': VueApexCharts,
  },
  props: {
    isAdmin: {
      required: true,
      default: false
    },
  },
  data: function () {
    return {
      initialLoading: false,
      httpService: httpFlexbenService.reports.tracking,
      currentYear: new Date().getFullYear(),
      errors: {
        show: false,
        message: null,
        fields: [],
      },
      dateFormat: 'dd/MM/yyyy',
      momentFormat: 'YYYY-MM-DD',
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      years: this.generateRange(2022, new Date().getFullYear()),
      dateGroup: null,
      filterOptions: {
        campaign_types: [],
        data_locations: [],
        data_actions: [],
        campaign_track_levels: [],
        categories: {
          benefit: [],
          offer: []
        },
        date_groups: [],
        companies: [],
      },
      filter_values: {
        company: null,
        campaign_type: null,
        data_location: null,
        data_action: null,
        date_group: null,

        campaign_track_level: null,
        start_date: null,
        end_date: null,
        start_month: null,
        start_year: null,
        end_month: null,
        end_year: null,
        categories: [],
      },
      dataFiltered: false,
      chart_data: {
        options: {},
        series: {},
      }
    }
  },
  computed: {
    endDateDisableUntil: function () {
      if (this.filter_values.start_date) {
        let startDate = moment(this.filter_values.start_date);
        let minDate = moment(this.filter_values.start_date);
        minDate = minDate.add(30, 'day');
        return {to: startDate.toDate(), from: minDate.toDate()};
      }
      return {to: moment().toDate(), from: moment().toDate()};
    },

    filterOptionsCategory: function () {
      this._resetSelectedCategories();
      if (this.filter_values.campaign_type) {
        const currLang = this.$store.state.language;

        return this.filterOptions.categories[this.filter_values.campaign_type].map((item) => {
          item.text = item.text[currLang];
          return item;
        });
      }
      return [];
    }
  },
  watch: {
    dateGroup: {
      handler: function (val) {
        this._resetDateValues();
        this.filter_values.date_group = val;
      }
    },
    'filter_values.start_date': {
      handler: function (val) {
        this.filter_values.end_date = null;
      }
    },
  },
  created() {
    this.initialLoading = false;
    this._getOptions();
  },
  methods: {
    filterValues() {
      this._validateFilters();
      if (this.errors.show) {
        return;
      }
      this.dataFiltered = false;
      this._toggleLoading(true);
      this.httpService.list(this._buildPayload())
        .then((response) => {
          this.dataFiltered = true;
          this.chart_data.options = {
            xaxis: {
              categories: response.data.headers
            }
          }
          this.chart_data.series = response.data.items;
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(true, true);
        })
    },
    download() {
      this._validateFilters();
      if (this.errors.show) {
        return;
      }

      this._toggleLoading(true);
      this.httpService.download(this._buildPayload())
        .then((response) => {
          utils.generateDownloadFromReponse(response);
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(true, true);
        })
    },
    _toggleLoading(loading, loaderError = null) {
      this.$emit('toggleLoading', loading, loaderError);
    },
    _validateFilters() {
      this.errors.show = false;
      this.errors.message = null;
      this.errors.fields = [];

      if (this.filter_values.campaign_type === null) {
        this.errors.fields.push('campaign_type');
      }
      if (this.filter_values.campaign_track_level === null) {
        this.errors.fields.push('campaign_track_level');
      }

      if (this.filter_values.date_group === null) {
        this.errors.fields.push('date_group');
      } else {
        let fields = [];
        if (this.filter_values.date_group === 'day') {
          fields = ['start_date', 'end_date']
        }
        if (this.filter_values.date_group === 'month') {
          fields = ['start_month', 'end_month', 'start_year', 'end_year']

          if (this.filter_values['end_year'] < this.filter_values['start_year']) {
            this.errors.fields.push('start_year');
            this.errors.fields.push('end_year');
          } else if (this.filter_values['end_year'] === this.filter_values['start_year'] &&
            this.filter_values['end_month'] < this.filter_values['start_month']) {
            this.errors.fields.push('end_month');
            this.errors.fields.push('start_month');
          }
        }
        if (this.filter_values.date_group === 'year') {
          fields = ['start_year', 'end_year']

          if (this.filter_values['end_year'] < this.filter_values['start_year']) {
            this.errors.fields.push('start_year');
            this.errors.fields.push('end_year');
          }
        }
        for (let field of fields) {
          if (this.filter_values[field] === null) {
            this.errors.fields.push(field);
          }
        }
      }

      if (this.errors.fields.length > 0) {
        this.errors.show = true;
        this.errors.message = 'Alegeti campurile obligatorii'
      } else {
        this.errors.show = false;
      }
      this.$emit('setErrors', this.errors.show, this.errors.message);
    },
    _buildPayload() {
      let data = {...this.filter_values};
      if (data.date_group === 'day') {
        data.start_date = moment(data.start_date).format(this.momentFormat);
        data.end_date = moment(data.end_date).format(this.momentFormat);
      }
      data.lang = this.$store.state.language;
      data.categories = data.categories.map((item) => item.value);
      return data;
    },
    _getOptions() {
      this._toggleLoading(true);
      this.httpService.getOptions()
        .then((response) => {
          this.filterOptions = response.data;

          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        });
    },
    _resetSelectedCategories() {
      this.filter_values.categories = [];
    },
    _resetDateValues() {
      this.filter_values.start_date = null;
      this.filter_values.end_date = null;
      this.filter_values.start_month = null;
      this.filter_values.start_year = null;
      this.filter_values.end_month = null;
      this.filter_values.end_year = null;
    }
  }
}
</script>
