<template>
  <section class="container-bg-white mt-3">
    <div class="row p-3">
      <div class="col-12">
        <h3 class="m-0 p-0 text-capitalize">{{ $t('flexben.general.beneficiary') }}</h3>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.first_name') }}</label>
        <div class="form-control w-100 m-0">
          {{ employee.first_name }}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.last_name') }}</label>
        <div class="form-control w-100 m-0">
          {{ employee.last_name }}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.phone_number') }}</label>
        <div class="form-control w-100 m-0">
          {{ employee.phone_number }}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.cnp') }}</label>
        <div class="form-control w-100 m-0">
          {{ employee.cnp }}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.benefit_group') }}</label>
        <div class="form-control w-100 m-0">
          {{ employee.benefit_category_code }}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.employee_number') }}</label>
        <div class="form-control w-100 m-0">
          {{ employee.employee_number }}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.department') }}</label>
        <div class="form-control w-100 m-0">
          {{ employee.department }}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.job_title') }}</label>
        <div class="form-control w-100 m-0">
          {{ employee.job_title }}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.underage_children_nr') }}</label>
        <div class="form-control w-100 m-0">
          {{ employee.underage_children_nr }}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.cost_center') }}</label>
        <div class="form-control w-100 m-0">
          {{ employee.cost_center }}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.working_point') }}</label>
        <div class="form-control w-100 m-0">
          {{ employee.working_point }}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group m-0">
        <label class="col-form-label">{{ $t('flexben.import.table_headings.up_ro_address_id') }}</label>
        <div class="w-100">
          {{ employee.company_address }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "RequestEmployeeInfo",
  props: {
    employee: {
      required: true,
      type: Object
    }
  }
}
</script>
