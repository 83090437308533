<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6 form-group">
        <label v-if="!fields.is_parent" class="col-form-label pt-0 text-bold" for="child_sub_type">
          {{ $t('flexben.benefit.fields.child_sub_type')}}</label>
        <select v-if="!fields.is_parent" class="form-control" name="child_sub_type" id="child_sub_type"
                v-model="fields.child_sub_type">
          <option :value="null">-</option>
          <option :value="child_sub_type.id" v-for="child_sub_type in options.child_sub_types"
                  :key="child_sub_type.name">{{$t(child_sub_type.name)}}
          </option>
        </select>
        <div v-else class="form-control disabled">
          {{ $t('flexben.benefit.fields.is_parent')}}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 form-group">
        <label for="min_order_value" class="col-form-label">{{ $t('flexben.benefit.fields.min_order_value')
          }}</label>
        <input id="min_order_value" type="number" class="form-control" min="0" v-model="fields.min_order_value">
      </div>
      <div class="col-12 text-danger">{{ $t('flexben.benefit.fields.info_contract_number') }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BenefitPpp3FormFields",
  props: {
    fields: {
      type: Object,
      default: function () {
        return {
          min_order_value: 100,
          child_sub_type : null
        };
      },
      required: true,
      child_sub_type: null
    },
    options: {
      type : Object,
      default: function () {
        return {};
      },
      required: true
    },
    errorsBag: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    }
  },
}
</script>
