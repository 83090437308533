import httpService from '@/http/http-flexben.js';
import {BModal, VBModal} from "bootstrap-vue";
import moment from "moment";

export default {
  name: "OrderDetail",
  components: {
    "b-modal": BModal,
  },
  directives: {
    "b-modal": VBModal
  },
  props: {
    order: {
      type: Object,
      required: true,
      default: () => {
        return {
          benefitColor: '',
          benefitName: '',
          companyName: '',
          created_at: '',
          distinct_benefit_names: null,
          id: null,
          order_number: '',
          status: '',
          total_value: null,
          type: '',
        }
      }
    },
  },
  data() {
    return {
      httpService: httpService.orders,
      orderId: null,
      orderRequests: [],
      sortByField: null,
      reverseSort: null,
      params: {},
      searchString: '',
      downloadUrl: null,
    }
  },
  methods: {
    showOrderDetail(orderId) {
      // noinspection TypeScriptValidateTypes
      if (this.orderId !== orderId) {
        this.sortByField = null;
        this.reverseSort = null;
        this.searchString = '';
        this.params = {};
        this.orderId = orderId;
      }
      this.downloadUrl = null;

      this.toggleLoading(true);
      this.httpService.getOrderRequestByOrderId(orderId, this.params)
        .then((result) => {
          this.orderRequests = {
            current_page: result.data.current_page,
            data: result.data.data,
            from: result.data.from,
            lastPage: result.data.lastPage,
            perPage: result.data.perPage,
            to: result.data.to,
            total: result.data.total,
          };

          this.downloadUrl = this.httpService.getOrderExportUrl(orderId);
          this.orderRequests.data = result.data.data;
          this.toggleLoading(false);
        })
        .catch((error) => {
          this.toggleLoading(true, true)
        });
      this.$refs['order-detail-modal'].show();
    },
    closeModal() {
      this.$refs['order-detail-modal'].hide();
    },
    sortBy(fieldName) {
      if (this.sortByField !== fieldName) {
        this.reverseSort = false;
      }
      if (typeof this.params.page !== 'undefined') {
        delete this.params.page;
      }
      this.params.sort_by = fieldName;
      this.params.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this.showOrderDetail(this.orderId)
      this.sortByField = fieldName;

      this.reverseSort = !this.reverseSort;
    },
    pageChanged(page) {
      this.orderRequests.current_page = page;
      this.params.page = page;

      this.showOrderDetail(this.orderId);
    },
    changePerPage(perPage) {
      this.params.page = 1;
      this.params.per_page = perPage;
      this.orderRequests.perPage = perPage;

      this.showOrderDetail(this.orderId);
    },
    search() {
      this.params = {};
      if (this.searchString.length >= 3) {
        this.params.search = this.searchString;
        this.showOrderDetail(this.orderId);
      } else if (this.searchString.length === 0) {
        this.showOrderDetail(this.orderId);
      }
    },
    moment(date, format) {
      if (date === null) {
        return '-';
      }
      return moment(date).format(format);
    },
    toggleLoading(loading, errorLoader = false) {
      this.$emit('toggleLoading', loading, errorLoader)
    }
  }
}
