<template>
  <div class="mb-4">
    <a class="btn text-themed go-back" @click="goBack()">{{ $t("flexben.general.go_back") }}</a>
    <div class="row" v-if="checkNotEmptyObj(errorsBag)">
      <div class="col-12">
        <div class="alert alert-danger alert-dismissible mb-0">
          <button type="button" class="close" data-dismiss="alert" @click="closeErrorButton = false">&times;</button>
          <form-error-list :errorBag="errorsBag"></form-error-list>
        </div>
      </div>
    </div>
    <div v-if="!initialLoading">
      <div class="row px-3 mb-2">
        <div class="col-12 col-md-6 pl-0 d-flex align-self-center flexben-page-title">
          <h1 class="m-0">{{ (formAction==='create') ? $t("flexben.categories.new"): $t("flexben.categories.edit")
            }}</h1>
        </div>
        <div class="col-12 col-md-6 my-2 pr-0">
          <div class="float-right">
            <div class="float-right">
              <button v-if="formAction==='update'"
                      class="btn mx-1 text-danger delete_btn" @click="modalAct('delete')">{{
                $t("flexben.categories.delete") }}
              </button>
              <button class="btn ml-1 save-btn" @click="modalAct('form_submit')">{{ $t("flexben.categories.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container-bg-white">
        <div class="row p-3">
          <div class="col-12">
            <h3 class="mt-0">{{ $t("flexben.benefit.details") }}</h3>
          </div>
          <div class="col-12 col-xl-4 d-flex justify-content-center align-items-start mb-3 mb-xl-0 mx-auto">
            <div class="image-container cursor-pointer d-flex align-items-center mx-auto"
                 @click="$refs.cat_image.click()">
              <div class="image-changed" v-if="image_changed">{{$t("flexben.general.image_changed")}}</div>
              <img class="img-fluid cursor-pointer upload-image"
                   :class="{'img-border-danger':(Object.keys(errorsBag).includes('image'))}"
                   :src="(formData.image !== null) ? image_preview : formData.image_url"/>
              <div class="upload-image-middle-container my-auto">
                <div class="upload-image-text">{{$t("flexben.general.upload_image")}}</div>
              </div>
              <input type="file" class="hide" ref="cat_image" accept=".png,.jpeg,.jpg,.svg,.jfif"
                     @change="setImageFile($event)">
            </div>
          </div>

          <div class="col-12 col-xl-8">
            <div class="row">
              <div class="col-12 col-md-6 form-group" v-for="(obj, index) in formData.name" :key="'name_'+index">
                <label :for="'name_'+index" class="form-fields-required col-form-label pt-0">
                  {{ $t("flexben.offers.fields.name") }} - {{obj.lang_code}}</label>
                <input :id="'name_'+index" type="text" class="form-control"
                       v-model="obj.value"
                       :class="{'border-danger':(Object.keys(errorsBag).includes('name|'+obj.lang_code))}">
              </div>
              <div class="col-12 col-md-6 form-group" v-for="(obj, index) in formData.description" :key="'de_'+index">
                <label :for="'description_'+index" class="form-fields-required col-form-label pt-0">{{
                  $t("flexben.offers.fields.description")
                  }} - {{obj.lang_code}}</label>
                <textarea :id="'description_'+index" rows="3"
                          class="form-control-textarea w-100 my-0"
                          v-model="obj.value"
                          :class="{'border-danger':(Object.keys(errorsBag).includes('description|'+obj.lang_code))}"/>
              </div>
              <div class="col-12 col-md-6 form-group">
                <label for="company" class="col-form-label pt-0">{{ $t("flexben.categories.filters.company") }}</label>
                <select class="form-control" v-model="formData.company" id="company">
                  <option :value="null" selected>-</option>
                  <option v-for="(company) in options.companies" :key="company.id" :value="company.id">

                    {{company.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!formData.company" class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-12">
            <h3>{{ $t("flexben.categories.benefits") }}</h3>
          </div>
          <div class="col-12">
            <category-benefits-table :benefits="assignedBenefits"
                                     :locale="locale"
                                     :showPagination="formAction==='update'"
                                     @getAssignedBenefits="getAssignedBenefits"
                                     @unAssignBenefit="unAssignBenefit"></category-benefits-table>
          </div>

          <div class="col-md-5">
            <div class="form-group text-max-sm-center d-flex align-items-center">
              <select v-model="benefitIndexToAdd" class="form-control mr-3"
                      :disabled="options.benefits.length === 0">
                <option :value="null">{{ $t('flexben.categories.option_default') }}</option>
                <template v-for="(benefit, index) in options.benefits">
                  <option v-if="!formData.assigned_benefit_ids.includes(benefit.id)"
                          :key="benefit.id"
                          :value="index">{{ benefit.name }}
                  </option>
                </template>

              </select>
              <button class="btn btn-themed" @click="assignBenefit()"
                      :disabled="options.benefits.length === formData.assigned_benefit_ids.length">
                {{$t("flexben.categories.table.assign") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal :openModal="modal.open"
                   :modalAction="modal.action"
                   :formSuccess="modal.formSuccess"
                   :endMessage="modal.endMessage"
                   @closeModal="closeModal"
                   @confirmAction="confirmAction"/>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import FormErrorList from "@/components/fragments/FormErrorList";
import ConfirmModal from "@/components/flexben/categories/modals/ConfirmModal";
import CategoryBenefitsTable from "@/components/flexben/categories/partials/CategoryBenefitsTable";

export default {
  name: "BenefitCategoryForm",
  components: {
    "form-error-list": FormErrorList,
    'confirm-modal': ConfirmModal,
    'category-benefits-table': CategoryBenefitsTable,
  },
  props: {
    formAction: {
      type: String,
      required: true
    },
    itemId: {
      type: Number,
    }
  },
  data: function () {
    return {
      initialLoading: false,
      loading: false,
      loaderError: false,
      httpService: httpFlexbenService.categoriesService,
      locale: i18n.locale,
      formData: {
        id: null,
        name: [],
        description: [],
        image: null,
        image_url: httpFlexbenService.globalVariablesService.baseImagePreview,
        benefits: [],
        assigned_benefit_ids: [],
        company: null,
        company_name: null
      },
      image_preview: null,
      image_changed: false,
      isFormModified: false,
      errorsBag: {},
      modal: {
        action: '',
        open: false,
        formSuccess: false,
        endMessage: '',
      },
      options: {
        benefits: [],
        available_languages: [],
        companies: []
      },
      benefitIndexToAdd: null,
      assignedBenefits: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      }
    };
  },
  watch: {
    formData: {
      deep: true,
      handler: function (data) {
        this.isFormModified = true;
      }
    }
  },
  created() {
    if (this.formAction === 'create') {
      this._getOptions();
    } else if (this.formAction === 'update' && this.itemId) {
      this._getItem();
    } else {
      this.goBack();
    }
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  methods: {
    goBack() {
      if (this.isFormModified) {
        this.modalAct('go_back')
      } else {
        this.$emit('clearForm');
      }
    },
    modalAct(action) {
      this.modal.action = action;
      this.modal.open = true;
    },
    closeModal() {
      this.modal.action = '';
      this.modal.open = false;
    },
    confirmAction(action) {
      this.modal.endMessage = '';
      if (action === 'go_back') {
        this.closeModal();
        this._clearForm();
        this.goBack();
      }
      if (action === 'form_submit') {
        this.loading = true;
        this.errorBag = {};
        this.modal.open = false;
        if (this.modal.action === 'form_submit') {
          if (this.formAction === 'update') {
            this._updateItem();
          } else {
            this._createItem();
          }
        }
      }
      if (this.modal.action === 'delete') {
        this._deleteItem();
      }
      if (action === 'end') {
        this.closeModal();
        if (this.modal.formSuccess) {
          this._clearForm();
          this.$emit('clearForm', true);
        }
      }
    },
    _getOptions() {
      this.loading = true;
      this.httpService.options()
        .then(function (response) {
          this._setFormOptions(response.data);
          if (this.initialLoading) {
            this.initialLoading = false;
          }
          this.loading = false;
        }.bind(this))
        .catch(function (error) {
          this.loading = false;
        }.bind(this))
        .then(function () {
          this.isFormModified = false;
        }.bind(this));
    },
    _getItem() {
      this.loading = true;
      this.httpService.details(this.itemId)
        .then(function (response) {
          this._setFormOptions(response.data);
          if (this.initialLoading) {
            this.initialLoading = false;
          }
          this.loading = false;
        }.bind(this))
        .catch(function (error) {
          this.loading = false;
        }.bind(this))
        .then(function () {
          this.isFormModified = false;
        }.bind(this));
    },
    setImageFile() {
      this.errorsBag = {};
      if (this.$refs.cat_image.accept.indexOf(this.$refs.cat_image.files[0].type.split('/')[1]) > 0) {

        let reader = new FileReader();
        reader.readAsDataURL(this.$refs.cat_image.files[0]);
        reader.onload = evt => {
          let img = new Image();
          img.onload = () => {
            if (img.width !== img.height) {
              this.errorsBag = {image: 'image_square'};
            } else if (img.width !== 500 || img.height !== 500) {
              this.errorsBag = {image: 'image_500_500'};
            } else {
              this.formData.image = this.$refs.cat_image.files[0];
              this.image_changed = true;
              if (typeof this.formData.image !== "undefined") {
                this.image_preview = URL.createObjectURL(this.formData.image);
              }
            }
          }
          img.src = evt.target.result;
        }
      } else {
        this.errorsBag = {image: 'image_type_supported'}
      }
    },
    _setFormOptions(data) {
      this.options.available_languages = data.available_languages;
      this.options.benefits = data.benefits;
      this.options.companies = data.companies;

      let objToPush, langCode;
      let formData = {...this.formData};
      for (let index in data.available_languages) {
        langCode = data.available_languages[index];
        if (this.formAction === 'create') {
          objToPush = {
            lang_code: langCode,
            value: ''
          };
          formData.name.push({...objToPush});
          formData.description.push({...objToPush});
        } else {
          formData.name.push({
            lang_code: langCode,
            value: data.item.name[langCode]
          });
          formData.description.push({
            lang_code: langCode,
            value: data.item.description[langCode]
          });
        }
      }

      if (this.formAction === 'update') {
        formData.id = data.item.id;
        if (data.item.image_url) {
          formData.image_url = data.item.image_url;
          formData.company = data.item.company_id;
          formData.company_name = data.item.company_name;
        }
        formData.benefits = data.item.benefits;
        formData.assigned_benefit_ids = data.item.assigned_benefit_ids;
        this.assignedBenefits = data.item.benefits;
      }

      this.formData = formData;
      this.isFormModified = false;
    },
    _clearForm() {
      this.isFormModified = false;
      this.formData = {
        id: null,
        name: [],
        description: [],
        benefits: [],
        image: null,
        company: null,
        image_url: httpFlexbenService.globalVariablesService.baseImagePreview,
      };
    },
    _prepareFormData() {
      let formData = new FormData();
      formData.append('image', this.formData.image);
      let name = {}, description = {},
        index = 0;
      for (index in this.formData.name) {
        name[this.formData.name[index].lang_code] = this.formData.name[index].value;
      }
      for (index in this.formData.description) {
        description[this.formData.description[index].lang_code] = this.formData.description[index].value;
      }
      formData.append('name', JSON.stringify(name));
      formData.append('company', JSON.stringify(this.formData.company));
      formData.append('description', JSON.stringify(description));
      formData.append('assigned_benefit_ids', JSON.stringify(this.formData.assigned_benefit_ids));
      return formData;
    },
    _createItem() {
      this.errorsBag = {};
      this.loading = true;
      this.httpService.create(this._prepareFormData())
        .then(function (response) {
          this.modal.formSuccess = true;
          this.modal.action = 'end';
          this.loading = false;
          this.modal.open = true;
        }.bind(this))
        .catch(function (error) {
          this.modalFormSuccess = false;
          if (error.response.status === 400) {
            this.errorsBag = error.response.data.error_fields;
            this.loading = false;
          } else if (500 <= error.response.status && error.response.status < 600) {
            this.loaderError = true;
          }
        }.bind(this));
    },
    _updateItem() {
      this.errorsBag = {};
      this.loading = true;
      this.httpService.update(this.formData.id, this._prepareFormData())
        .then(function (response) {
          this.modal.formSuccess = true;
          this.modal.action = 'end';
          this.loading = false;
          this.modal.open = true;
        }.bind(this))
        .catch(function (error) {
          this.modalFormSuccess = false;
          if (error.response.status === 400) {
            this.errorsBag = error.response.data.error_fields;
            this.loading = false;
          } else if (500 <= error.response.status && error.response.status < 600) {
            this.loaderError = true;
          }
        }.bind(this));
    },
    _deleteItem() {
      this.errorsBag = {};
      this.httpService.delete(this.formData.id)
        .then(function (response) {
          this.modal.formSuccess = true;
          this.modal.action = 'end';
          this.loading = false;
          this.modal.open = true;
        }.bind(this))
        .catch(function (error) {
          this.modalFormSuccess = false;
          if (error.response.status === 400) {
            this.errorsBag = error.response.error_fields;
            this.loading = false;
          } else if (500 <= error.response.status && error.response.status < 600) {
            this.loaderError = true;
          }else{
            this.modalAction = 'end';
            this.loading = false;
            this.modalOpen = true;
          }
        }.bind(this));
    },

    assignBenefit() {
      let benefit = this.options.benefits[this.benefitIndexToAdd];
      if (!this.formData.assigned_benefit_ids.includes(benefit.id)) {
        if (this.formAction === 'update') {
          this.loading = true;
          this.httpService.attachBenefitToCategory({
            benefitId: benefit.id,
            categoryId: this.formData.id
          }).then(function (response) {
            this.getAssignedBenefits();
            this.formData.assigned_benefit_ids.push(benefit.id);
            this.benefitIndexToAdd = null;
          }.bind(this));
        } else {
          this.formData.assigned_benefit_ids.push(benefit.id);
          this.assignedBenefits.data.push(benefit);
          this.benefitIndexToAdd = null;
        }
      }
    },
    unAssignBenefit(index) {
      if (this.assignedBenefits.data[index]) {
        let benefit = this.assignedBenefits.data[index];
        if (this.formAction === 'update') {
          this.loading = true;
          this.httpService.detachBenefitFromCategories(this.formData.id, benefit.id)
            .then(function (response) {
              this.getAssignedBenefits();
              let abIndex = this.formData.assigned_benefit_ids.indexOf(benefit.id);
              this.formData.assigned_benefit_ids.splice(abIndex, 1);
              this.assignedBenefits.data.splice(index, 1);
            }.bind(this));
        } else {
          let abIndex = this.formData.assigned_benefit_ids.indexOf(benefit.id);
          this.formData.assigned_benefit_ids.splice(abIndex, 1);
          this.assignedBenefits.data.splice(index, 1);
          this.benefitIndexToAdd = null;
        }
      }
    },
    getAssignedBenefits(params = {}) {
      if (this.formAction === 'update') {
        this.loading = true;
        this.httpService.getAssignedBenefits(this.itemId, params)
          .then(function (response) {
            this.assignedBenefits.data = response.data.data;
            this.assignedBenefits.current_page = response.data.current_page;
            this.assignedBenefits.to = response.data.to;
            this.assignedBenefits.perPage = response.data.perPage;
            this.assignedBenefits.lastPage = response.data.lastPage;
            this.assignedBenefits.total = response.data.total;
            this.loading = false;
          }.bind(this))
          .catch(function (error) {
            this.loading = true;
          }.bind(this));
      }
    }
  }
}
</script>
