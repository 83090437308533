<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :viewBox="viewBox">
    <g transform="translate(-19.828)">
      <g transform="translate(19.828)">
        <path :class="filterClass"
              d="M19.828,0V4.964l7.2,7.71v5.88l5.825,3.361V12.673l7.2-7.71V0ZM31.136,12v6.95l-2.4-1.384V12l-6.4-6.858h15.2Zm7.2-8.571H21.541V1.713h16.79V3.427Z"
              transform="translate(-19.828)"/>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "FilterIcon",
    props: {
      filterClass: {
        type: String,
        default: 'filter-icon-color',
      },
      width: {
        type: Number,
        default: 20.217,
      },
      height: {
        type: Number,
        default: 21.914,
      }
    },
    data: function () {
      return {
        viewBox: '0 0 20.217 21.914'
      };
    },
    created() {
      this.viewBox = '0 0 ' + this.width + ' ' + this.height;
    }
  }
</script>

<style>
  .filter-icon-color {
    fill: black;
  }
</style>
