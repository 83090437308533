<template>
  <div>
    <div v-if="dataLoaded">
      <div v-if="!isMobile" class="menu">
        <a
        @click="pushRoute('company')"
        class="cursor-pointer" 
          :class="{ logo: !flexben.hasAccess, 'flx-logo': flexben.hasAccess }"
        >
          <img src="@/assets/images/logo-up.png" v-if="!flexben.hasAccess" />
          <img
            src="@/assets/images/flexben/flexben-logo.svg"
            v-if="flexben.hasAccess"
          />
        </a>
        <MenuItems
          :company="this.customer"
          :user="this.user"
          :menuSelected="this.menuSelected"
          ref="menu_items"
        />
      </div>

      <div v-if="isMobile" class="menu-mobile p-0 w-100" :class="{'open-menu':showMobileMenu}">
        <div class="p-3 w-100 d-flex m-0 box-sizing-bb border-bottom">
          <div
            class="openNotifications"
            @click="showModalPopup('modal_popup_mobileNotifications', true)"
          >
            <div v-if="this.readMessagesCount > 0" class="unread-notifications">
              <span>{{ this.readMessagesCount }}</span>
            </div>
            <img src="@/assets/images/clopotel-alb.svg" />
          </div>

          <div id="modal_popup_mobileNotifications" class="modal-popup">
            <div class="modal-popup-content">
              <a
                @click="
                  showModalPopup('modal_popup_mobileNotifications', false)
                "
                class="close-button"
              >
                <img src="@/assets/images/close-button.svg" />
              </a>
              <div class="notifications">
                <NotificationsBox :notifications="this.companyMessages" />
              </div>
            </div>
          </div>

          <div>
            <a href="#">{{ this.customer.name | titleCase }}</a>
          </div>
          <div class="ml-auto close-menu" @click="openMenu(false)">
            <img src="@/assets/images/x.svg" />
          </div>
        </div>
        <div class="px-3">
          <MenuItems
            :company="this.customer"
            :user="this.user"
            :menuSelected="this.menuSelected"
            ref="menu_items"
          />
        </div>
      </div>

      <div class="container-right">
        <div
          class="barTop"
          :class="menuSelected.dashboard ? 'view' : 'bar-top-white'"
        >
          <div class="logo-mobile" v-if="menuSelected.dashboard">
            <img src="@/assets/images/logo-up.png" />
          </div>
          <div
            class="menu-white"
            v-if="menuSelected.dashboard"
            @click="openMenu(true)"
          >
            <img src="@/assets/images/menu-burger-alb.svg" />
          </div>

          <div class="mobile-nav" v-if="!menuSelected.dashboard">
            <div class="arrow-back" @click="goBack()">
              <img src="@/assets/images/arrow-back.svg" />
            </div>
            <div class="page-title">
              <h3>{{ this.getPageTitle() }}</h3>
            </div>
            <div class="menu-white" @click="openMenu(true)">
              <img src="@/assets/images/menu-burger-gri.svg" />
            </div>
          </div>
          <TopBar
            :company="this.customer"
            :user="this.user"
            :companyMessages="this.companyMessages"
            @languageChanged="languageChanged"
          />
        </div>

        <div
          :class="{
            'container-fluid flexben-container': isFlexbenComponent,
            content: !isFlexbenComponent,
            'dashboard-beneficiary': this.menuSelected.dashboard
          }"
        >
          <CompanySearch
            :customer="this.customer"
            v-if="
              this.menuSelected.companySearch &&
              (this.company.role === 'FAC' ||
              this.company.role === 'FLX' ||
              this.company.role === 'HLP' ||
                this.company.role === 'FLS')
            "
          />
          <Dashboard
            :customer="this.customer"
            :user="this.user"
            v-if="
              this.menuSelected.dashboard &&
              this.company.role !== 'FAC' &&
              this.company.role !== 'FLX' &&
              this.company.role !== 'FLS'
            "
          />
          <Beneficiaries
            :customer="this.customer"
            :userRole="this.company.role"
            :user="this.user"
            v-if="this.menuSelected.beneficiaries"
          />
          <DeliveryPoints
            :customer="this.customer"
            v-if="this.menuSelected.deliveryPoints"
          />
          <Invoices
            :customer="this.customer"
            v-if="this.menuSelected.invoices"
          />
          <Orders :customer="this.customer" v-if="this.menuSelected.orders" />
          <NewOrder
            :customer="this.customer"
            :userRole="this.company.role"
            :user="this.user"
            v-if="this.menuSelected.newOrder"
          />
          <CompanySettings
            :companyDetails="this.customer"
            :user="this.user"
            :userRole="this.company.role"
            v-if="this.menuSelected.account && this.company.role !== 'FAC'"
          />
          <CompanySettingsIntegrator
            :companyDetails="this.customer"
            :user="this.user"
            :userRole="this.company.role"
            v-if="this.menuSelected.account && this.company.role === 'FAC'"
          />
          <LinkFrame
            :urlAddress="this.config.faq"
            :key="linkFrameIndex"
            v-if="this.menuSelected.faq && this.company.role !== 'FAC'"
          />
          <Contact
            :customer="this.customer"
            v-if="this.menuSelected.contact && this.company.role !== 'FAC'"
          />
          <LinkFrame
            :urlAddress="this.config.terms"
            v-if="this.menuSelected.terms && this.company.role !== 'FAC'"
          />
          <LinkFrame
            :urlAddress="this.config.gdpr"
            v-if="this.menuSelected.gdpr && this.company.role !== 'FAC'"
          />
          <Notifications
            :companyDetails="this.customer"
            :user="this.user"
            :notifications="this.companyMessages"
            v-if="this.menuSelected.notifications"
          />
          <ManageCompany
            :companyDetails="this.customer"
            :user="this.user"
            :userType="this.company.role"
            v-if="this.menuSelected.manageCompany"
          />
          <!-- Flexben Menu items -->
          <FlexBenDashboard
            v-if="this.menuSelected.flexben"
            :customer="this.customer"
            :user="this.user"
          />
          <FlexBenConfiguration v-if="this.menuSelected.flexbenConfiguration" />
          <FlexbenAffiliationChannelsList v-if="this.menuSelected.flexbenChannelsList" />
          <FlexBenCategories v-if="this.menuSelected.flexbenCategories" />
          <FlexBenClients v-if="this.menuSelected.flexbenClients" />
          <FlexBenEditClient v-if="this.menuSelected.flexbenEditClient" />
          <FlexBenBenefits v-if="this.menuSelected.flexbenBenefits" />
          <FlexBenBeneficiaries v-if="this.menuSelected.flexbenBeneficiaries" />
          <FlexBenBeneficiary
            v-if="this.menuSelected.flexbenNewBeneficiary"
            :edit="false"
          />
          <FlexBenBeneficiary
            v-if="this.menuSelected.flexbenEditBeneficiary"
            :edit="true"
          />
          <FlexBenRequests v-if="this.menuSelected.flexbenRequests" />
          <FlexBenRequestsHistoryList
            v-if="this.menuSelected.flexbenRequestsHistoryList"
          />
          <FlexBenOrders v-if="this.menuSelected.flexbenOrders" />
          <FlexbenImportHistory v-if="this.menuSelected.flexbenImportHistory" />
          <FlexbenImportIndex v-if="this.menuSelected.flexbenImportNew" />
          <FlexbenBenefitGroupList
            v-if="this.menuSelected.flexbenBenefitGroupList"
          />
          <FlexbenFixedBenefitsList
            v-if="this.menuSelected.flexbenFixedBenefitsList"
          />
          <FlexbenOfferCategoryList
            v-if="this.menuSelected.flexbenOfferCategoryList"
          />
          <FlexbenOfferList v-if="this.menuSelected.flexbenOfferList" />
          <FlexbenPartnerList v-if="this.menuSelected.flexbenPartnerList" />
          <FlexbenBrandList v-if="this.menuSelected.flexbenBrandList" />
          <FlexBenReportTypes v-if="this.menuSelected.flexbenReportsList" />
          <LinkFrame
            :urlAddress="this.config.terms_multiben"
            v-if="this.menuSelected.flexbenTerms && this.company.role !== 'FAC'"
          />
          <FlexbenBenefitVoucherList
            v-if="this.menuSelected.flexbenBenefitVoucherList"
          />
        </div>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>
<style>
.box-sizing-bb {
  box-sizing: border-box !important;
}
</style>
<script>
import MenuItems from "@/components/company/customer/MenuItems.vue";
import TopBar from "@/components/company/TopBar.vue";
const Beneficiaries = () =>
  import("@/components/company/customer/Beneficiaries.vue");
const Dashboard = () => import("@/components/company/customer/Dashboard.vue");
const Invoices = () => import("@/components/company/customer/Invoices.vue");
const Orders = () => import("@/components/company/customer/Orders.vue");
const NewOrder = () => import("@/components/company/customer/NewOrder.vue");
const DeliveryPoints = () =>
  import("@/components/company/customer/DeliveryPoints.vue");
const CompanySearch = () =>
  import("@/components/company/customer/CompanySearch.vue");
const Contact = () => import("@/components/company/customer/Contact.vue");
const CompanySettings = () =>
  import("@/components/company/CompanySettings.vue");
import CompanySettingsIntegrator from "@/components/company/integrator/CompanySettings.vue";

const LinkFrame = () => import("@/components/beneficiary/LinkFrame.vue");
const NotificationsBox = () => import("@/components/NotificationsBox.vue");
const Notifications = () =>
  import("@/components/company/customer/Notifications.vue");
const ManageCompany = () => import("@/components/company/ManageCompany.vue");
import httpServiceCustomer from "@/http/http-customer.js";

import httpServiceUser from "@/http/http-user.js";
import Cookies from "js-cookie";
import httpService from "@/http/http-common.js";
import FlexBenDashboard from "@/components/flexben/dashboard/Dashboard";
import FlexBenConfiguration from "@/components/flexben/configuration/ConfigurationIndex";
import FlexBenCategories from "@/components/flexben/categories/CategoriesIndex.vue";
import FlexBenClients from "@/components/flexben/clients/ClientsIndex.vue";
import FlexBenEditClient from "@/components/flexben/clients/EditClient.vue";
import FlexBenBenefits from "@/components/flexben/benefits/BenefitsIndex.vue";
import FlexBenBeneficiaries from "@/components/flexben/beneficiaries/BeneficiariesIndex.vue";
import FlexBenBeneficiary from "@/components/flexben/beneficiaries/BeneficiaryAdministration.vue";
import FlexBenRequests from "@/components/flexben/requests/RequestsIndex.vue";
import FlexBenRequestsHistoryList from "@/components/flexben/requests/RequestsHistoryList.vue";
import FlexBenOrders from "@/components/flexben/orders/OrdersIndex.vue";
import FlexbenImportHistory from "@/components/flexben/import_history/ImportHistory.vue";
import FlexbenImportIndex from "@/components/flexben/import/ImportIndex.vue";
import FlexbenBenefitGroupList from "@/components/flexben/benefit_groups/BenefitGroupList.vue";
import FlexbenFixedBenefitsList from "@/components/flexben/fixed_benefits/FixedBenefitsList.vue";
import FlexbenOfferCategoryList from "@/components/flexben/offer_category/OfferCategoriesList.vue";
import FlexbenOfferList from "@/components/flexben/offers/OfferList.vue";
import FlexbenPartnerList from "@/components/flexben/partners/PartnerList.vue";
import FlexbenBrandList from "@/components/flexben/brands/List.vue";
import FlexBenReportTypes from "@/components/flexben/reports/ReportTypes.vue";
import FlexbenBenefitVoucherList from "@/components/flexben/benefit_voucher_stocks/BenefitVoucherList.vue";
import FlexbenAffiliationChannelsList from "@/components/flexben/affiliate_partners/channels/ChannelsList.vue";
import httpFlexbenService from "@/http/http-flexben.js";

export default {
  components: {
    MenuItems,
    TopBar,
    LinkFrame,
    Dashboard,
    Beneficiaries,
    DeliveryPoints,
    Invoices,
    Orders,
    NewOrder,
    CompanySettings,
    CompanySettingsIntegrator,
    CompanySearch,
    Contact,
    NotificationsBox,
    Notifications,
    ManageCompany,
    FlexBenDashboard,
    FlexBenConfiguration,
    FlexBenCategories,
    FlexBenClients,
    FlexBenEditClient,
    FlexBenBenefits,
    FlexBenBeneficiaries,
    FlexBenBeneficiary,
    FlexBenRequests,
    FlexBenOrders,
    FlexbenImportHistory,
    FlexbenImportIndex,
    FlexbenBenefitGroupList,
    FlexbenFixedBenefitsList,
    FlexbenOfferCategoryList,
    FlexbenOfferList,
    FlexbenPartnerList,
    FlexbenBrandList,
    FlexBenRequestsHistoryList,
    FlexBenReportTypes,
    FlexbenBenefitVoucherList,
    FlexbenAffiliationChannelsList
  },
  props: {
    user: Object,
    company: Object
  },
  data() {
    return {
      userLink: "",
      token: "",
      customer: null,
      customerMessages: null,
      dataLoaded: false,
      menuSelected: {
        dashboard: false,
        orders: false,
        newOrder: false,
        beneficiaries: false,
        deliveryPoints: false,
        invoices: false,
        faq: false,
        account: false,
        contact: false,
        terms: false,
        gdpr: false,
        companySearch: false,
        notifications: false,
        manageCompany: false,
        flexben: false,
        flexbenConfiguration: false,
        flexbenClients: false,
        flexbenEditClient: false,
        flexbenBenefits: false,
        flexbenNewBeneift: false,
        flexbenEditBenefit: false,
        flexbenBenefitEditUpDejun: false,
        flexbenBenefitEditUpVacanta: false,
        flexbenBenefitEditUpCadou: false,
        flexbenBenefitEditUpCultura: false,
        flexbenBeneficiaries: false,
        flexbenNewBeneficiary: false,
        flexbenEditBeneficiary: false,
        flexbenRequests: false,
        flexbenRequestsHistoryList: false,
        flexbenOrders: false,
        flexbenImportHistory: false,
        flexbenImportNew: false,
        flexbenBenefitGroupList: false,
        flexbenFixedBenefitsList: false,
        flexbenOfferCategoryList: false,
        flexbenOfferList: false,
        flexbenPartnerList: false,
        flexbenBrandList: false,
        flexbenReportsList: false,
        flexbenTerms: false,
        flexbenFaq: false,
        flexbenBenefitVoucherList: false,
        flexbenChannelsList: false
      },
      flexben: {
        hasAccess: false
      },
      config: JSON.parse(sessionStorage.getItem("client_jsonFile")),
      isOpen: false,
      isOpenNotification: false,
      readMessagesCount: 0,
      linkFrameIndex: 1,
      companyMessages: [
        {
          dateIssued: "2021-04-28T21:00:00.000Z",
          dateRead: "2021-05-19T14:06:19.069Z",
          id: 1,
          lang: "ro",
          message:
            "Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere!",
          read: true,
          title: "Mesaj lung",
          user_id: 123799,
          validFrom: "2021-04-28T21:00:00.000Z",
          validTo: "2021-05-28T21:00:00.000Z"
        },
        {
          dateIssued: "2021-04-28T21:00:00.000Z",
          dateRead: "2021-05-19T14:06:19.069Z",
          id: 2,
          lang: "ro",
          message:
            "Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere!",
          read: true,
          title: "Mesaj lung",
          user_id: 123799,
          validFrom: "2021-04-28T21:00:00.000Z",
          validTo: "2021-05-28T21:00:00.000Z"
        },
        {
          dateIssued: "2021-04-28T21:00:00.000Z",
          dateRead: "2021-05-19T14:06:19.069Z",
          id: 3,
          lang: "ro",
          message:
            "Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere!",
          read: true,
          title: "Mesaj lung",
          user_id: 123799,
          validFrom: "2021-04-28T21:00:00.000Z",
          validTo: "2021-05-28T21:00:00.000Z"
        }
      ],
      isMobile: (document.body.clientWidth < 1004),
      showMobileMenu: false
    };
  },
  computed: {
    isFlexbenComponent: function () {
      return this.isFlexbenRoute();
    }
  },
  methods: {
    orderProducts() {
      if (this.customer.products) {
        this.customer.products.forEach((product) => {
          if (product.name === "Up Dejun") {
            product.order = 1;
          } else if (product.name === "Up Cadou") {
            product.order = 2;
          } else if (product.name === "Up Cadou STIM") {
            product.order = 3;
          } else if (product.name === "Up Vacanta") {
            product.order = 4;
          } else if (product.name === "Up Cultura") {
            product.order = 5;
          } else if (product.name === "Up Social") {
            product.order = 6;
          } else if (product.name === "Up Gradinita") {
            product.order = 7;
          } else {
            product.order = 8;
          }
        });

        this.customer.products.sort((a, b) => (a.order > b.order ? 1 : -1));
      }
    },
    loadCustomerData() {
      if (Cookies.get("userLink") !== null) {
        this.userLink = Cookies.get("userLink");
        this.token = Cookies.get("token");
      } else {
        this.$router.push({
          name: "login",
          params: {loginUserSelected: true}
        });
      }

      if (localStorage.getItem("customer_data")) {
        this.customer = JSON.parse(localStorage.getItem("customer_data"));
        this.dataLoaded = true;
      } else {
        this.showModalPopup("modal_popup_loading", true);
        httpServiceUser.userService
          .getCompanyData(this.company.company_id, Cookies.get("token"), "CST")
          .then(function (response) {
            return response;
          })
          .catch((error) => {
            this.dataLoaded = false;
            this.customerError = {
              code: error.response.status,
              description: "Error fetching customer"
            };
            this.showModalPopup("modal_popup_loading", false);
            window.location.href = "/?serverError=500";
          })
          .then((response) => {
            if (response.status == 200) {
              this.customer = response.data;
              this.customer.role = this.company.role;
              let canOrderUp = response.headers["x-up-order"];
              if (typeof canOrderUp !== "undefined") {
                this.customer.can_order_up =
                canOrderUp === "true" || canOrderUp;
                this.company.can_order_up = canOrderUp === "true" || canOrderUp;
              } else {
                this.customer.can_order_up = true;
                this.company.can_order_up = true;
              }

              this.orderProducts();
              localStorage.setItem(
                "customer_data",
                JSON.stringify(this.customer)
              );
              this.dataLoaded = true;

              // Flexben sync client after checking he has flexben
              let flexBenHeader = response.headers["x-up-flex"];
              let flxCookie = Cookies.get("flxCookie");
              if (typeof flexBenHeader !== "undefined" && flxCookie !== "admin") {
                Cookies.set("flxCookie", flexBenHeader, {
                  expires: response.data.expires_in / (24 * 60 * 60),
                  sameSite: "Strict"
                });
                this.flexbenSyncUser();
              }
            } else if (response.status == 400 || response.status == 403) {
              this.dataLoaded = false;
              this.customerError = {
                code: response.data,
                description: "Error fetching transactions",
              };
            } else {
              this.dataLoaded = false;
              this.customerError = { description: response.description };
            }
            this.showModalPopup("modal_popup_loading", false);
          })
          .catch((e)=>{
            console.log(e)
          });
      }
    },
    loadMessages() {
      let formData =
        "?user_id=" +
        this.customer.company_id +
        "&lang=" +
        localStorage.language;
      httpServiceCustomer.customerService
        .getMessages(formData, this.token)
        .then(function (response) {
          // handle success
          return response;
        })
        .then((response) => {
          // total no of messages X-Up-Count
          // no of read messages
          this.readMessagesCount = response.headers["x-up-count1"];
          // no of unread messages X-Up-Count2
          this.customerMessages = response.data;
        })
        .catch((error) => {
          // handle error
        });
    },
    openMenu(open) {
      this.showMobileMenu = open;
    },
    openUserMenu() {
      this.isOpen = !this.isOpen;
    },
    openNotifications() {
      this.loadMessages();
      this.isOpenNotification = !this.isOpenNotification;
    },
    isReadNotification(message) {
      message.read = true;
      httpServiceCustomer.customerService
        .markMessageAsRead(message, this.token)
        .catch((error) => {
          // handle success
        });
    },
    hide() {
      this.isOpen = false;
    },
    hideNotification() {
      this.isOpenNotification = false;
    },
    getPageTitle() {
      if (this.menuSelected.orders) {
        return "Orders";
      } else if (this.menuSelected.beneficiaries) {
        return "Beneficiaries";
      } else if (this.menuSelected.deliveryPoints) {
        return "Delivery points";
      } else if (this.menuSelected.invoices) {
        return "Invoices";
      } else if (this.menuSelected.newOrder) {
        return "New Order";
      } else if (this.menuSelected.faq) {
        return "FAQ";
      } else if (this.menuSelected.account) {
        return "My Account";
      } else if (this.menuSelected.contact) {
        return "Contact";
      } else if (this.menuSelected.terms) {
        return "Terms and conditions";
      } else if (this.menuSelected.gdpr) {
        return "Personal data policy";
      } else if (this.menuSelected.companySearch) {
        return "Company search";
      } else if (this.menuSelected.notifications) {
        return "Notifications";
      } else if (this.menuSelected.manageCompany) {
        return "Manage Company";
      } else if (this.menuSelected.flexben) {
        return "Multibeneficii";
      } else if (this.menuSelected.flexbenConfiguration) {
        return "Multibeneficii Configuration";
      } else if (this.menuSelected.flexbenCategories) {
        return "Multibeneficii Categories";
      } else if (this.menuSelected.flexbenClients) {
        return "Multibeneficii Clients";
      } else if (this.menuSelected.flexbenClients) {
        return "Multibeneficii Edit Client";
      } else if (this.menuSelected.flexbenBenefits) {
        return "Multibeneficii Benefits";
      } else if (this.menuSelected.flexbenBeneficiaries) {
        return "Multibeneficii Beneficiaries";
      } else if (this.menuSelected.flexbenNewBeneficiary) {
        return "Multibeneficii New Beneficiary";
      } else if (this.menuSelected.flexbenEditBeneficiary) {
        return "Multibeneficii Edit Beneficiary";
      } else if (this.menuSelected.flexbenRequests) {
        return "Multibeneficii Requests";
      } else if (this.menuSelected.flexbenRequestsHistoryList) {
        return "Multibeneficii Requests History";
      } else if (this.menuSelected.flexbenOrders) {
        return "Multibeneficii Orders";
      } else if (this.menuSelected.flexbenImportHistory) {
        return "Multibeneficii Import History";
      } else if (this.menuSelected.flexbenImportNew) {
        return "Multibeneficii New Import";
      } else if (this.menuSelected.flexbenBenefitGroupList) {
        return "Multibeneficii Group List";
      } else if (this.menuSelected.flexbenFixedBenefitsList) {
        return "Multibeneficii Fixed benefits";
      } else if (this.menuSelected.flexbenOfferCategoryList) {
        return "Multibeneficii Offer categories";
      } else if (this.menuSelected.flexbenOfferList) {
        return "Multibeneficii Offers";
      } else if (this.menuSelected.flexbenPartnerList) {
        return "Multibeneficii Partners";
      } else if (this.menuSelected.flexbenBrandList) {
        return "Multibeneficii Brand";
      }else if (this.menuSelected.flexbenReportsList) {
        return "Multibeneficii Reports";
      } else if (this.menuSelected.flexbenChannelsList) {
        return "Multibeneficii Affiliation Channels";
      } else {
        return "Dashboard";
      }
    },
    logout() {
      this.$store.commit('logout');
      Cookies.remove("userLink");
      Cookies.remove("token");
      Cookies.remove("flexbenClient");
      Cookies.remove("flxCookie");
      localStorage.removeItem("customer_data");
      sessionStorage.removeItem("flx_active_employments");
      sessionStorage.removeItem("flx_active_employment");
    },
    goBack() {
      let foundBackFunction = false;
      let childIndex = null;
      /*
       * check IF child component is Up MultiBeneficii and has goBack function
       * for display confirmation modal in case form was edited
       *
       * ELSE use existing go back functionality
       */
      for (var index in this.$children) {
        if (
          this.$children[index].$options._componentTag
            .toString()
            .toLowerCase()
            .includes("flexben") &&
          typeof this.$children[index].goBack !== "undefined"
        ) {
          foundBackFunction = true;
          childIndex = index;
          break;
        }
      }
      if (foundBackFunction && childIndex !== null) {
        this.$children[childIndex].goBack();
      } else {
        window.history.length > 1
          ? this.$router.go(-1)
          : this.$router.push("/");
      }
    },
    getExpireAmountDateFormat(date) {
      return moment(new Date(date), "YYYY-MM-DD").format("DD MMM YYYY");
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },

    languageChanged() {
      setTimeout(() => {
        this.config = JSON.parse(sessionStorage.getItem("client_jsonFile"));
        this.linkFrameIndex++;
      }, 1000);
    },
    flexbenSyncUser() {
      this.flexben.hasAccess = true;
      let syncData = {
        auth_token: this.token,
        link: this.userLink,
        role: this.flxUserLevel().userLevel,
        company: Cookies.getJSON("selectedCompany"),
      };
      httpFlexbenService.userService
        .syncUserData(syncData)
        .then((response) => {
          if (
            this.flxUserLevel().userLevel === "client" &&
            response.data.employments.length > 0
          ) {
            sessionStorage.setItem(
              "flx_active_employments",
              JSON.stringify(response.data.employments)
            );
            sessionStorage.setItem(
              "flx_active_employment",
              JSON.stringify(response.data.employments[0])
            );
          }

          if ( this.$refs['menu_items']){
            this.$refs['menu_items'].renderFlxMenu(this.flexben.hasAccess);
          }
          })
        .catch((error) => {
          console.log(error);
          console.log ("error multiben sync");
          Cookies.remove("flxCookie");
        });
    },
  },
  mounted() {
    // prevent click outside event with popupItem.
    this.loadCustomerData();

    // flexben sync for admin
    let permission = this.flxUserLevel();
    this.flexben.hasAccess = permission.hasAccess;
    if (permission.hasAccess && permission.userLevel === "admin") {
      this.flexbenSyncUser();
    }
  //  if (this.company.role === "CST" && this.checkNpsSurvey("nps_client") < 4){
  //     this.showModalPopup("modal_popup_nps", true);
  //   }
  },
  created() {
    switch (this.$route.name) {
      case "customer_dashboard":
        this.menuSelected.dashboard = true;
        break;
        case "customer_orders":
        this.menuSelected.orders = true;
        break;
      case "customer_invoices":
        this.menuSelected.invoices = true;
        break;
      case "customer_new_order":
        this.menuSelected.newOrder = true;
        break;
      case "customer_beneficiaries":
        this.menuSelected.beneficiaries = true;
        break;
      case "delivery_points":
        this.menuSelected.deliveryPoints = true;
        break;
      case "customer_services":
        this.menuSelected.services = true;
        break;
      case "customer_faq":
        this.menuSelected.faq = true;
        break;
      case "customer_account":
        this.menuSelected.account = true;
        break;
      case "customer_contact":
        this.menuSelected.contact = true;
        break;
      case "customer_terms":
        this.menuSelected.terms = true;
        break;
      case "customer_gdpr":
        this.menuSelected.gdpr = true;
        break;
      case "company_search":
        this.menuSelected.companySearch = true;
        break;
      case "customer_notifications":
        this.menuSelected.notifications = true;
        break;
      case "manage_company":
        this.menuSelected.manageCompany = true;
        break;
      case "flexben":
        this.menuSelected.flexben = true;
        break;
      case "flexben_configuration":
        this.menuSelected.flexbenConfiguration = true;
        break;
      case "flexben_categories":
        this.menuSelected.flexbenCategories = true;
        break;

      case "flexben_client":
        this.menuSelected.flexbenClient = true;
        break;
      case "flexben_clients":
        this.menuSelected.flexbenClients = true;
        break;
      case "flexben_edit_client":
        this.menuSelected.flexbenEditClient = true;
        break;
      case "flexben_benefits":
        this.menuSelected.flexbenBenefits = true;
        break;
      case "flexben_beneficiaries":
        this.menuSelected.flexbenBeneficiaries = true;
        break;
      case "flexben_beneficiary_new":
        this.menuSelected.flexbenNewBeneficiary = true;
        break;
      case "flexben_beneficiary_edit":
        this.menuSelected.flexbenEditBeneficiary = true;
        break;
      case "flexben_requests":
        this.menuSelected.flexbenRequests = true;
        break;
      case "flexben_requests_history_list":
        this.menuSelected.flexbenRequestsHistoryList = true;
        break;
      case "flexben_orders":
        this.menuSelected.flexbenOrders = true;
        break;
      case "flexben_import_history":
        this.menuSelected.flexbenImportHistory = true;
        break;
      case "flexben_import_new":
        this.menuSelected.flexbenImportNew = true;
        break;
      case "flexben_benefit_group_list":
        this.menuSelected.flexbenBenefitGroupList = true;
        break;
      case "flexben_fixed_benefits_list":
        this.menuSelected.flexbenFixedBenefitsList = true;
        break;
      case "flexben_offer_category_list":
        this.menuSelected.flexbenOfferCategoryList = true;
        break;
      case "flexben_offer_list":
        this.menuSelected.flexbenOfferList = true;
        break;
      case "flexben_partner_list":
        this.menuSelected.flexbenPartnerList = true;
        break;
      case "flexben_brand_list":
        this.menuSelected.flexbenBrandList = true;
        break;
      case "flexben_report_list":
        this.menuSelected.flexbenReportsList = true;
        break;
      case "flexben_faq":
        this.menuSelected.flexbenFaq = true;
        break;
      case "flexben_terms":
        this.menuSelected.flexbenTerms = true;
        break;
      case "flexben_benefit_voucher_list":
        this.menuSelected.flexbenBenefitVoucherList = true;
        break;
      case "flexben_channels_list":
        this.menuSelected.flexbenChannelsList = true;
        break;
      default:
        //todo remove condition after testing
        if (this.user.id === 57647) {
          this.menuSelected.flexbenBenefits = true;
        } else if (this.company.role === "FAC" || this.company.role === "FLX" || this.company.role === "HLP") {
          this.menuSelected.companySearch = true;
        } else {
          this.menuSelected.dashboard = true;
        }
    }
    if (!sessionStorage.getItem("client_jsonFile")) {
      httpService.commonsService.getConfigFile(localStorage.language, "client");
    }
  }
};
</script>
