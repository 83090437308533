<template>
  <section class="container-bg-white mt-3">
    <div class="row px-3 pt-3">
      <div class="col-12">
        <h3 class="m-0 p-0">{{$t("flexben.requests.title_specific_info")}}</h3>
      </div>

    </div>

    <div class="row px-3 py-2">
      <div v-if="requestData.benefit.partner_id!==null" class="col-12">
        <img class="partner-badge mr-2" src="@/assets/images/flexben/utils/new-beneficiary-icon.png"
             alt="company_icon">{{requestData.benefit.partner.name}} - {{requestData.benefit.partner.iban}}
      </div>
      <div v-else class="col-12">
        {{ $t("flexben.requests.no_benefit_partner") }}
    </div>
    </div>

    <div class="row px-3 py-2">
      <div class="col-2">
        <h3 class="m-0 p-0">{{$t("flexben.requests.fields.personal_use")}}</h3>
      </div>
      <div class="col-2">
        <b-form-checkbox
          class="mx-3"
          switch
          :disabled="true"
          v-model="requestData.benefit.personal_use">
        </b-form-checkbox>
      </div>

    </div>

    <div v-if="benefitDynamicFormFields.length>0" class="row px-3 pb-3">
      <div v-for="(field, index) in benefitDynamicFormFields" :key="index"
           class="col-12 col-md-6 col-xl-4 form-group mb-0">
        <label class="col-form-label" :for="index+field.key">
          <template v-if="typeof field.extra.label == 'object'">
            {{ field.extra.label[$store.state.language] }}
          </template>
          <template v-else>
            {{ field.extra.label }}
          </template>
          ({{field.extra.type}}{{
          (field.extra.type==='input')?' '+field.extra.inputType :'' }})</label>
        <div
          v-if="field.extra.type !== 'checklist' &&
           field.extra.type!=='BsSwitch' &&
            field.extra.type!=='vueMultiSelect'&&
             field.extra.type!=='textArea'&&
             (field.extra.type==='input'&& field.extra.inputType!=='date')&&
             (field.extra.type==='input'&& field.extra.inputType!=='datetime')&&
              field.extra.type!=='radios'"
          class="form-control disabled">{{ field.value }}
        </div>

        <div v-if="field.extra.type === 'radios'">
          <label v-for="(item, index) in field.extra.values" :key="index" class="mr-2">
            <input type="radio" :checked="(field.value===item.value)" disabled class="mr-1"/>{{item.name}}</label>
        </div>
        <div v-if="field.extra.type === 'checklist'"
             class="form-control disabled">{{ getChecklistValues(field) }}
        </div>
        <div v-if="field.extra.type === 'BsSwitch'"
             class="form-control disabled">{{ getBsSwitchValue(field) }}
        </div>

        <div v-if="field.extra.type === 'vueMultiSelect'"
             class="form-control disabled">{{ getVueMultiSelectValues(field.value) }}
        </div>

        <div v-if="field.extra.type==='input'&& field.extra.inputType==='date'"
             class="form-control disabled">{{ moment(field.value, 'DD/MM/YYYY') }}
        </div>

        <div v-if="field.extra.type==='input'&& field.extra.inputType==='datetime'"
             class="form-control disabled">{{ moment(field.value, 'DD/MM/YYYY HH:mm', 'YYYY-MM-DDThh:mm') }}
        </div>
        <div v-if="field.extra.type==='select'"
             class="form-control disabled">{{ field.value ? field.value : '-' }}
        </div>

        <textarea v-if="field.extra.type === 'textArea'"
                  :id="index+field.key" rows="5"
                  class="form-control-textarea disabled m-0"
                  v-model="field.value">
        </textarea>
      </div>
    </div>
  </section>
</template>

<script>
import {BFormCheckbox} from "bootstrap-vue";
export default {
  name: "RequestBenefitDynamicFields",
  components: {
    "b-form-checkbox": BFormCheckbox,
  },
  props: {
    requestData: {
      type: Object,
      required: true
    }
  },
  computed: {
    benefitDynamicFormFields: function (val) {
      let benefitDynamicFormFields = [];
      let ignoredMetaKeys = ['subscription_period_code', 'delivery_type', 'request_card', 'voucher_billing_value', 'card_series', 'voucher_file_path', 'voucher_code'];
      for (let key in this.requestData.metas) {
        if (!ignoredMetaKeys.includes(key)) {
          benefitDynamicFormFields.push(this.requestData.metas[key]);
        }
      }
      return benefitDynamicFormFields;
    }
  },
  methods: {
    getVueMultiSelectValues(list) {
      if (list.length === 0) {
        return '-';
      }
      let returnVal = '';
      for (let index in list) {
        returnVal += list[index].value;
        if (index != list.length - 1) {
          returnVal += ', ';
        }
      }
      return returnVal;
    },
    getChecklistValues(field) {
      let returnVal = '';
      for (let index in field.value) {
        for (let valuesIndex in field.extra.values) {
          if (field.extra.values[valuesIndex].value === field.value[index]) {
            returnVal += field.extra.values[valuesIndex].name;
            if (index != field.value.length - 1) {
              returnVal += ', ';
            }
          }
        }
      }
      return returnVal;
    },
    getBsSwitchValue(field) {
      if (!field.value && typeof field.extra.leftText !== 'undefined') {
        return field.extra.leftText;
      } else if (field.value && typeof field.extra.rightText !== 'undefined') {
        return field.extra.rightText;
      } else {
        return this.$t('flexben.general.' + (field.value ? 'yes' : 'no'))
      }
    },
  }
}
</script>
