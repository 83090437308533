<template>
  <b-modal id="modal-1"
           ref="translation_modal"
           :hide-header-close="true"
           headerClass="border-0 text-center"
           bodyClass="modal-body no-border background-white text-center "
           footerClass="modal-footer border-top-0"
           size="lg"
           centered>
    <template v-slot:modal-header></template>
    <template v-slot:default>
      <div :class="{
        'text-danger':message.error,
        'text-success':!message.error,
      }"
           v-show="message.show">{{ message.text }}
      </div>
      <benefit-translations :translations="benefitTranslations" :inModal="true"></benefit-translations>
    </template>
    <template v-slot:modal-footer>
      <button class="btn text-themed mr-auto" @click="closeModal()">{{ $t('flexben.general.btn.close') }}</button>
      <button class="btn btn-themed " @click="confirm()">{{ $t('flexben.general.btn.confirm') }}</button>
    </template>
  </b-modal>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import {BModal} from "bootstrap-vue";
import BenefitTranslations from "../partials/BenefitTranslations";

export default {
  name: "PartnerBenefitsTranslationsModal",
  components: {
    'b-modal': BModal,
    'benefit-translations': BenefitTranslations
  },
  props: {
    clientId: {
      type: Number,
      required: true
    },
  },
  data: function () {
    return {
      httpService: httpFlexbenService.clients.translations,
      activeBenefit: null,
      benefitTranslations: {},
      message: {
        show: false,
        error: false,
        text: null,
      }
    };
  },
  methods: {
    openModal(benefitId) {
      this.activeBenefit = benefitId;
      this.message.show = false;
      this.message.error = false;
      this.message.text = null;

      this.toggleLoading(true);
      this.httpService.getByClientBenefit(this.clientId, this.activeBenefit)
        .then(function (response) {
          this.$refs['translation_modal'].show();
          this.benefitTranslations = response.data.data;
          this.toggleLoading(false);
        }.bind(this))
        .catch(function (error) {
          this.toggleLoading(true, true);
        }.bind(this));
    },
    closeModal() {
      this.clearBenefitId();
    },
    confirm() {
      this._updateTrans();
    },
    clearBenefitId() {
      this.$refs['translation_modal'].hide();
      this.$emit('clearBenefitId');
    },
    _updateTrans() {
      this.toggleLoading(true);
      this.httpService.updateByClientBenefit(this.clientId, this.activeBenefit, this.benefitTranslations)
        .then(function (response) {
          if (response.data.status === 'error') {
            this.message.show = true;
            this.message.error = true;
            this.message.text = this.$t('flexben.clients.dynamic_translations.error_save');
          } else {
            this.message.show = true;
            this.message.error = false;
            this.message.text = 'Success';
          }

          this.toggleLoading(false);
        }.bind(this))
        .catch(function (error) {
          this.toggleLoading(true, true);
        }.bind(this));
    },
    toggleLoading(loading, loaderError = false) {
      this.$emit('toggleLoading', loading, loaderError)
    },
  },
}
</script>
