<template>
  <div class="flexben-dashboard mb-5">
    <AdminDashboard v-if="userIsAdmin" :customer="customer"></AdminDashboard>
    <ClientDashboard v-if="userIsClient" :customer="customer" ></ClientDashboard>
  </div>
</template>

<script>
  import AdminDashboard  from "./partials/AdminDashboard";
  import ClientDashboard from "./partials/ClientDashboard";

  export default {
    name:'Dashboard',
    components: {
      AdminDashboard,
      ClientDashboard
    },
    props     : {
      customer: {
        type: Object,
      },
      user: {
        type: Object,
      }
    },
    data() {
      return {
        userIsAdmin : false,
        userIsClient: false,
        companyId:null,
      };
    },
    mounted() {
      if (!this.flxUserLevel().hasAccess) {
        // this.$router.push({name: 'company'});
        window.location = '/customer/dashboard';
      } else {
        if (this.flxUserLevel().userLevel === 'admin') { this.userIsAdmin = true;}
        if (this.flxUserLevel().userLevel === 'client') { this.userIsClient = true;}
      }
    }
  };
</script>

<style scoped src="@/assets/styles/flexben.css"></style>
<style lang="scss">
  .flexben-dashboard {
    .circle-holder {
      display : inline-block;
      margin  : 0 calc((100% - 250px) / 2);

      .circle-badge {
        $badgeSizing  : 250px;
        border        : 2px solid #F59100;
        border-radius : 50%;
        min-height    : $badgeSizing;
        max-height    : $badgeSizing;
        height        : $badgeSizing;
        min-width     : $badgeSizing;
        max-width     : $badgeSizing;
        width         : $badgeSizing;

        &-number { color : #F59100; font-size : 35px; font-weight : 600;}

        &-title {}

        &-sub {}

        &-icon {}
      }
    }
  }
</style>
