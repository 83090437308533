<template>
  <div>
    <hr>
    <div class="row">
      <div class="col-6 col-md-6 form-group">
        <span id="threshold_description"
              class="d-inline-block flexben-tooltip-icon ml-2 mr-2 text-bold">i</span>
        <b-tooltip :target="'threshold_description'" variant="flexben" placement="topleft">
          <div class="text-left">{{$t('flexben.benefit.thresholds.tooltip', {value:'gym_membership'})}}</div>
        </b-tooltip>

        <label for="threshold" class="form-fields-required text-bold mb-0 mr-4 col-form-label p-0">
          {{ $t('flexben.benefit.thresholds.' + 'gym_membership') }}</label>

        <b-form-checkbox id="threshold" class="float-right"
                         @change="$emit('addThreshold', 'gym_membership', gymMembership)"
                         v-model="gymMembership"
                         switch></b-form-checkbox>
      </div>
    </div>
    <hr>
    <div class="row">

      <div class="col-6 col-md-6 form-group">
        <label for="delivery_type" class="col-form-label">{{ $t('flexben.benefit.fields.subscription_periods')
          }}</label>
        <div class="w-100 d-inline-flex align-items-center">
          <select class="form-control mr-1" id="delivery_type" v-model="subscriptionPeriodValue">
            <option :value="null">-</option>
            <template v-for="(option, index) in options.subscription_periods">
              <option v-if="!fields.added_periods.includes(option)"
                      :value="option"
                      :key="index">{{$t('flexben.benefit.subscription_periods.'+option)}}
              </option>
            </template>
          </select>
          <button class="btn px-1 py-0 text-20 text-themed" :class="{disabled:(!subscriptionPeriodValue)}"
                  @click="addPeriodValue()">
            <font-awesome-icon icon="plus"/>
          </button>
        </div>


      </div>


      <div class="col-12 col-md-6">
        <div v-for="(period_field, index) in fields.added_period_fields" :key="period_field.code"
             class="form-group mb-0">
          <label :for="period_field.code" class="form-fields-required col-form-label">{{$t('flexben.benefit.subscription_periods.'+period_field.code)}}
            (Lei)</label>
          <div class="w-100 d-inline-flex align-items-center">
            <input type="number" v-model="period_field.value" :id="period_field.code" class="form-control mr-1">
            <img class="cursor-pointer" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                 @click="removePeriodValue(index)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BFormCheckbox, BTooltip} from "bootstrap-vue";

export default {
  name: "BenefitSubscriptionFormFields",
  components: {
    'b-tooltip': BTooltip,
    "b-form-checkbox": BFormCheckbox,
  },
  props: {
    fields: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
    errorsBag: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
    options: {
      type: Object,
      default: function () {
        return {subscription_periods: []};
      },
      required: true
    },
  },
  data: function () {
    return {
      subscriptionPeriodValue: null,
      gymMembership: false
    };
  },
  created() {
    this.gymMembership = this.getGymMembership();
  },
  methods: {
    addPeriodValue() {
      if (this.subscriptionPeriodValue) {
        this.fields.added_periods.push(this.subscriptionPeriodValue);
        this.fields.added_period_fields.push({
          code: this.subscriptionPeriodValue,
          value: 0,
        });
        this.subscriptionPeriodValue = null;
      }
    },
    removePeriodValue(index) {
      let sIndex = this.fields.added_periods.indexOf(this.fields.added_period_fields[index].code)
      this.fields.added_periods.splice(sIndex, 1);
      this.fields.added_period_fields.splice(index, 1);
    },
    getGymMembership() {
      if (!this.fields.thresholds.length) {
        return false;
      }
      return this.fields.thresholds.find(function (item) {return item.name === 'gym_membership'}) ?
        this.fields.thresholds.find(function (item) {return item.name === 'gym_membership'})['value'] : false
    }
  }
}
</script>
<style>
.flexben-tooltip-icon {
  font-family: "Times New Roman", sans-serif;
  color: gray;
  border: 1px solid gray;
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  cursor: pointer;
  line-height: initial;
}
</style>
