<template>
  <div class="mb-4">
    <div class="channels-list" v-show="(!showForm && !initialLoading)">
      <div class="row">
        <div class="col-4 col-md-4 flexben-page-title">
          <h1>{{ $t("flexben.affiliate_channels.title") }}</h1>
          <p>{{ $t("flexben.affiliate_channels.description") }}</p>
        </div>

        <div class="col-8">
          <button class="btn btn-themed px-4 float-right my-2" @click="openForm('create')">
            {{$t("flexben.affiliate_channels.btns.new")}}
          </button>
        </div>
      </div>
      <div class="container-bg-white">
        <div class="row p-3">
          <div class="col-6 mt-3 text-bold">
            <h3 class="mt-0 pt-1 pl-0">{{ $t("flexben.affiliate_channels.title") }}</h3>
          </div>
          <div class="col-6 mt-3">
            <input type="text"
                   v-model="searchString"
                   v-on:keyup.enter="search()"
                   :placeholder="$t('flexben.general.search')"
                   class="search-input float-right"/>
          </div>
          <div class="col-12">
            <div class="table-responsive hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td>{{ $t("flexben.affiliate_channels.fields.name") }}</td>
                  <td>{{ $t("flexben.affiliate_channels.fields.social_platform") }}</td>
                  <td>{{ $t("flexben.affiliate_channels.fields.specific_identifier") }}</td>
                  <td>{{ $t("flexben.affiliate_channels.fields.commercial_identifier") }}</td>
                  <td>{{ $t("flexben.affiliate_channels.fields.invitation_url") }}</td>
                </tr>
                </thead>

                <tbody v-if="list.data.length">
                <tr v-for="(item, index) in list.data" :key="index" class="trhover">
                  <td>{{ item.name }}</td>
                  <td>{{ item.social_platform }}</td>
                  <td>{{ item.specific_identifier }}</td>
                  <td>{{ item.commercial_identifier ? item.commercial_identifier : '-' }}</td>
                  <td>
                    <a :href="item.url" class="btn-themed px-3" target="_blank">Link</a>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr class="trhover gri-cell">
                  <td :colspan="20" class="text-center">
                    {{ $t("flexben.general.no_results_found") }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 p-0">
            <listPagination
              :activePage="list.current_page"
              :currentPage="list.current_page"
              :totalItems="list.total"
              :startItem="list.from"
              :endItem="list.to"
              :itemsPerPage="list.perPage"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage"></listPagination>
          </div>
        </div>
      </div>
    </div>
    <CreateChannelForm v-if="showForm" :socialPlatforms="socialPlatforms" @clearForm="clearForm"/>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
  </div>
</template>

<script>
import CreateChannelForm from "@/components/flexben/affiliate_partners/create/CreateChannelForm";
import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
export default {
  name: "ChannelsList",
  components: {CreateChannelForm},
  data: () => {
    return {
      initialLoading: true,
      loading: false,
      loaderError: false,
      httpService: httpFlexbenService.affiliationChannels,
      locale: i18n.locale,
      searchString: '',
      showForm: false,
      formAction: '',
      socialPlatforms: [],
      list: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      params: {
        page: 1,
        perPage: 20,
        sort_by: null,
        sort_direction: null,
        search: null
      },
      modal: {
        action: '',
        formSuccess: true,
        message: '',
        canDelete: true,
      },
    }
  },
  created() {
    this._loadList();
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  methods: {
    _loadList() {
      this.loading = true;
      this.httpService.list(this.params)
        .then((response) => {
          this.list = response.data.channels;
          this.socialPlatforms = response.data.options.social_platforms;
          this.loading = false;
          this.initialLoading = false;
        })
        .catch((error) => {
          this.loading = false;
            if (error.response.status !== 401) {
              this.loaderError = true;
            }
            this.initialLoading = false;
        });
    },
    sortBy(fieldName) {
      if (this.sortByField !== fieldName) {
        this.reverseSort = false;
      }
      this.params.sort_by = fieldName;
      this.params.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this._loadList(this.params)
      this.sortByField = fieldName;

      this.reverseSort = !this.reverseSort;
    },
    changePerPage(perPage) {
      this.params.page = 1;
      this.params.perPage = perPage;
      this._loadList();
    },
    pageChanged(pageNb) {
      this.params.page = pageNb;
      this._loadList();
    },
    search() {
      if (this.searchString.length >= 3) {
        this.params.search = this.searchString;
        this._loadList();
      } else if (this.searchString.length === 0) {
        this.params.search = null;
        this._loadList();
      }
    },
    openForm(action, index = null) {
      this.formAction = action;
      //TODO: improve, add edit
      if (action === 'update') {
        this.itemId = this.list.data[index].id;
        this.showForm = true;
      }

      if (action === 'create') {
        this.itemId = null;
        this.showForm = true;
      }
    },
    clearForm() {
      this.formAction = '';
      this.showForm = false;
      this.itemId = null;
      this.resetFilters();
      this._loadList();
    },
    resetFilters() {
      this.params = {
        page: 1,
        perPage: 20,
        sort_by: null,
        sort_direction: null,
        type: null,
        search: null
      };
    },
  }
}
</script>
