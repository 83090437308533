<template>
  <div class="row px-3">
    <div class="col-12">
      <div class="row" v-for="(values, key, index) in valuesObj" :key="index">
        <div class="col-12 col-xl-4">
          <h3 v-if="index === 0" class="mt-0 pl-0">{{$t("flexben.benefit."+(isFuture ? 'future_values' : 'current_values'))}}</h3>
        </div>
        <div class="col-12 col-md-6 col-xl-4 form-group">
          <label class="w-100 col-form-label pt-0" :for="key">
            {{$t("flexben.benefit.fields." + (isFuture ? ('new_' + key) : key))}}
          </label>
          <input class="form-control" :class="{disabled: !isFuture}"
                 :disabled="!isFuture"
                 :id="key" type="number"
                 v-model.number="values.value">
        </div>
        <div class="col-12 col-md-6 col-xl-4 form-group">
          <label class="col-form-label pt-0" for="valid_from_1">{{ $t("flexben.benefit.fields.valid_form") }}</label>
          <div class="d-inline-flex w-100 align-items-center" v-if="isFuture">
            <label for="valid_from_1" class="my-auto mr-2 col-form-label">
              <font-awesome-icon icon="calendar-alt" class="text-24 flx-calendar-icon"/>
            </label>
            <datepicker :format="dateFormat"
                        class="w-95"
                        :inputClass="{
                          'form-control m-0 w-100': true,
                          'background-white': true
                        }"
                        popover-align="top"
                        v-model="values.valid_from"
                        :disabledDates="futureValuesDisableUntil"
                        :key="1"
                        :value-type="dateFormat"
                        id="valid_from_1"></datepicker>
            <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                 @click="values.valid_from=null"/>
          </div>
          <div v-else class="form-control d-inline-block disabled" disabled="">
            {{ moment(values.valid_from,'DD/MM/yyyy') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  name: "LegislatedSpecificValues",
  components: {
    datepicker: Datepicker
  },
  props: {
    isFuture: {
      type: Boolean,
      required: true,
      default: false
    },
    valuesObj: {
      type: Object,
      required: true,
      default: function () {
        return {};
      }
    },
    futureValuesDisableUntil: {
      type: Object,
      required: true,
      default: function () {
        return {};
      }
    },
    dateFormat: {
      required: true,
      type: String,
      default: 'dd/MM/yyyy'
    }
  },
  methods: {
    moment(date, format) {
      return moment(date).format(format);
    },
  }
}
</script>
