<template>
  <div class="table-responsive hover-table">
    <table class="table table-striped">
      <thead>
      <tr>
        <td class="`text-capitalize`">{{$t("flexben.import.types.beneficiary")}}</td>
        <td>{{$t("flexben.general.status")}}</td>
        <td>{{$t("flexben.import.table_headings.cnp")}}</td>
        <td>{{$t("flexben.import.table_headings.budget")}}</td>
        <td>{{$t("flexben.import.table_headings.expiration_date")}}</td>
        <td>{{$t("flexben.import.table_headings.ppp3_budget")}}</td>
        <td>{{$t("flexben.import.table_headings.ppp3_expiration_date")}}</td>
      </tr>
      </thead>
      <tbody>
      <template v-if="import_items.length">
        <tr v-for="(item, index) in import_items" :key="index"
            class="trhover">
          <td>{{item.last_name}} {{item.first_name}}</td>
          <td><span :class="'import-status-item-'+item.status">{{ $t('flexben.general.status_type.'+item.status) }}</span>
          </td>
          <td>{{item.cnp}}</td>
          <td>{{item.budget}}</td>
          <td>{{item.expiration_date}}</td>
          <td>{{item.ppp3_budget}}</td>
          <td>{{item.ppp3_expiration_date}}</td>
        </tr>
      </template>
      <template v-else>
        <tr class="gri-cell text-center trhover">
          <td colspan="6">{{ $t("flexben.general.no_results_found") }}</td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TableTypeBudget",
  props: {
    importItems: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    }
  },
  computed: {
    import_items: function () {
      return this.importItems;
    }
  }
}
</script>
