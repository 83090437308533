<template>
  <b-tab v-if="childConfig.type === 'company_events'" :title="$t('flexben.clients.partial.specific_company_events')"
         :key="childIndex">
    <div class="row ml-1 mr-2 mt-4">
      <div class="col-12 col-md-6">
        <span class="text-18 text-bold">{{$t("flexben.clients.partial.specific_company_events")}}</span>
      </div>
      <div class="col-12 col-md-6 mt-2 mt-md-0">
        <div class="float-md-right d-flex" v-if="isAdmin">
          <label for="specific_events_toggle" class="ml-md-auto mb-0 mr-4 mr-2 col-form-label p-0">
            {{$t("flexben.clients.partial.enable_benefit_for_client")}}
          </label>
          <b-form-checkbox id="specific_events_toggle"
                           class=""
                           switch
                           v-model="childConfig.is_active">
          </b-form-checkbox>
        </div>
      </div>
    </div>
    <div class="row benefits-order-info mt-3 mx-3 pt-3">
      <div class="col-12 col-md-3">
        <img src="@/assets/images/flexben/utils/benefit-info.svg" alt=""/>
      </div>
      <div class="col-12 col-md-3">
        <p>{{$t("flexben.general.fiscal_treatment")}}</p>
        <p class="text-16 text-bold" v-if=" Object.keys(parentBenefit.global_variable_codes).length === 3">
          {{$t("flexben.benefit.deductibility.none")}}
        </p>
        <p class="text-16 text-bold"
           v-else-if="Object.keys(parentBenefit.global_variable_codes).length < 3 && Object.keys(parentBenefit.global_variable_codes).length > 0">
          {{$t("flexben.benefit.deductibility.partial")}}
        </p>
        <p class="text-16 text-bold" v-else-if=" Object.keys(parentBenefit.global_variable_codes).length === 0">
          {{$t("flexben.benefit.deductibility.complete")}}
        </p>
      </div>
      <div class="col-6 col-md-2">
        <p>{{$t("flexben.general.income_tax")}}</p>
        <p class="text-16 text-bold">{{ parentBenefit.global_variable_codes.includes('impozit-venit') ?
          globalVariables['impozit-venit'].value : 0 }}%</p>
      </div>
      <div class="col-6 col-md-2">
        <p>CAS</p>
        <p class="text-16 text-bold">{{ parentBenefit.global_variable_codes.includes('cas') ?
          globalVariables['cas'].value : 0 }}%</p>
      </div>
      <div class="col-6 col-md-2">
        <p>CASS</p>
        <p class="text-16 text-bold">{{ parentBenefit.global_variable_codes.includes('cass') ?
          globalVariables['cass'].value : 0 }}%</p>
      </div>
      <div class="col-12 mt-3"></div>
      <div class="col-12 col-md-6 col-xl-3 offset-xl-3">
        <p>{{$t("flexben.general.last_annual_date_for_orders")}}</p>
        <p class="text-16 text-bold">
          {{parentBenefit.end_use_day_month.day}}/{{parentBenefit.end_use_day_month.month}}/{{parentBenefit.end_use_day_month.year}}</p>
      </div>
      <div class="col-12 col-md-6 col-xl-3">
        <p>{{$t("flexben.benefit.fields.maximum_legal_value_per_event")}}</p>
        <p class="text-16 text-bold">{{ parentBenefit.data.values.maximum_legal_value_per_event.value }} Lei</p>
      </div>
    </div>
    <div class="row mt-5 mx-1">
      <div class="col-6">
        <span class="my-1 px-0 text-18 text-bold">{{$t("flexben.clients.partial.define_company_events")}}</span>
      </div>
      <div class="col-6">
        <button type="button" class="btn btn-themed float-right"
                @click="openModalForm('add')">
          {{ $t("flexben.clients.partial.add") }}
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="container-fluid py-0 sub-tab-table">
          <div class="table-responsive hover-table">
            <table class="table no-min-width" :key="tableKey">
              <thead>
              <tr>
                <td>{{ $t("flexben.general.event") }}</td>
                <td>{{ $t("flexben.general.event_date") }}</td>
                <td>{{ $t("flexben.general.top_up_limit")}}</td>
                <td>{{ $t("flexben.clients.partial.gender_availability")}}</td>
                <td>{{ $t("flexben.clients.partial.for_birthdays") }}</td>
                <td>{{ $t("flexben.general.status") }}</td>
                <td></td>
              </tr>
              </thead>
              <tbody>
              <template v-if="parentBenefit.events.length > 0">
                <tr v-for="(event, index) in parentBenefit.events"
                    class="trhover" :class="(index%2 ===0) && 'gri-cell'" :key="index">
                  <td>
                    <span class="mobile-icon pr-2 flx-calendar-icon"><font-awesome-icon icon="calendar-alt"/></span>
                    {{event.name}}
                  </td>
                  <td v-if="event.on_birthday" :key="'event_date_'+index">{{ $t('flexben.clients.table.on_birthday')
                    }}
                  </td>
                  <td v-else :key="'event_date_'+index">{{ (parseInt(event.event_date.day) < 10) ?
                    "0"+event.event_date.day :event.event_date.day }}/{{ (parseInt(event.event_date.month) < 10) ?
                    "0"+event.event_date.month :event.event_date.month }}/{{ current_year }}
                  </td>
                  <td>{{ event.maximum_topup_limit }} Lei</td>
                  <td>{{ getGenderAvailability(event.gender_availability)}}</td>
                  <td>{{ event.on_birthday ? $t("flexben.general.yes") : $t("flexben.general.no") }}</td>
                  <td>
                    <span :class="event.is_active ?  'occasion-active':'occasion-inactive'">
                       {{ event.is_active ? $t("flexben.general.status_type.active") : $t("flexben.general.status_type.inactive") }}
                    </span>
                  </td>
                  <td>
                    <button type="button" class="btn action-button"
                            @click="openModalForm('update', index)">
                      {{$t("flexben.general.btn.see_more")}}
                    </button>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr class="trhover gri-cell">
                  <td colspan="7" class="text-center bold">
                    {{$t("flexben.general.no_events")}}
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <CompanyEventModal v-if="openModal"
                       :type="'company_event'"
                       :openModal="openModal"
                       :formData="formData"
                       :modalAction="modalAction"
                       :errorBag="errorBag"
                       @closeModal="closeModal"
                       @submitForm="submitForm"
                       @toggleStatus="toggleStatus"
    ></CompanyEventModal>
  </b-tab>
</template>

<script>
import {BFormCheckbox, BTab} from "bootstrap-vue";
import httpFlexbenService from "@/http/http-flexben.js";
import CompanyEventModal from "../modals/CompanyEventModal";
import moment from "moment";

export default {
  components: {
    CompanyEventModal,
    "b-tab": BTab,
    "b-form-checkbox": BFormCheckbox,
  },
  name: "SubTabUpCompanyEvents",
  props: {
    childConfig: {
      type: Object,
      default: function () {
        return {};

      },
      required: true
    },
    globalVariables: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
    childIndex: {
      required: true
    },
    parentBenefit: {
      type: Object,
      default: function () {
        return {
          events: []
        };
      },
      required: true
    },
    clientId: {
      default: null,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: true,
      required: true
    },
  },
  data() {
    return {
      httpService: httpFlexbenService.clients.events,
      topLevelParent: this.$parent.$parent.$parent.$parent.$parent.$parent,
      openModal: false,
      modalAction: '',
      formData: {
        type: Object,
        default: function () {
          return {
            occasionId: null,
            occasionIndex: null,
            name: '',
            is_active: true,
            maximum_topup_limit: 0,
            date: {
              day: null,
              month: null,
              year: null,
            },
            is_birthday: false,
            gender_availability: {
              male: true,
              female: true,
            },
          };
        },
        required: true
      },
      errorBag: {},
      current_year: moment().year(),
      tableKey: 0
    }
  },
  methods: {
    openModalForm(modalAction, occasionIndex = null) {
      if (modalAction === 'add') {
        this.modalAction = 'add';
        this.formData = {
          occasionId: null,
          occasionIndex: null,
          name: '',
          is_active: true,
          maximum_topup_limit: 0,
          date: {
            day: null,
            month: null,
            year: null,
          },
          is_birthday: false,
          gender_availability: {
            male: true,
            female: true,
          },
        };

        this.openModal = true;
      }

      if (modalAction === 'update') {
        let event = this.parentBenefit.events[occasionIndex];
        this.modalAction = 'update';
        let genderAvailability;
        if (event.gender_availability === 'MALE') {
          genderAvailability = {
            male: true,
            female: false,
          };
        } else if (event.gender_availability === 'FEMALE') {
          genderAvailability = {
            male: false,
            female: true,
          };
        } else {
          genderAvailability = {
            male: true,
            female: true,
          };
        }
        this.formData = {
          occasionId: event.id,
          occasionIndex: occasionIndex,
          name: event.name,
          is_active: event.is_active,
          maximum_topup_limit: event.maximum_topup_limit,
          date: event.event_date,
          is_birthday: event.on_birthday,
          gender_availability: genderAvailability,
        };
        this.openModal = true;
      }
    },
    closeModal() {
      this.topLevelParent.loading = false;
      this.openModal = false;
      this.errorBag = {};
      this.formData = {
        occasionId: null,
        name: '',
        is_active: '',
        maxTopUpLimit: 0,
        date: null,
        is_birthday: false,
        gender_availability: {
          male: true,
          female: true,
        },
      };
    },
    toggleStatus() {
      this.formData.is_active = !this.formData.is_active;
    },
    submitForm() {
      this.topLevelParent.loading = true;
      this.errorBag = {};
      this.openModal = false
      if (this.modalAction === 'add') {
        this._addOccasion()
      }
      if (this.modalAction === 'update') {
        this._updateOccasion()
      }
    },
    _addOccasion() {
      let formData = {
        is_active: this.formData.is_active,
        name: this.formData.name,
        event_date: this.formData.date,
        maximum_topup_limit: this.formData.maximum_topup_limit,
        on_birthday: this.formData.is_birthday,
        gender_availability: this._getGenderAvailability()
      };
      this.httpService.createEvent(this.clientId, this.parentBenefit.id, formData)
        .then(function (response) {
          this.parentBenefit.events.push(response.data);
          this.tableKey++; // forces re-render of table for status change
          this.topLevelParent.loading = false;
          this.closeModal();
        }.bind(this))
        .catch(function (error) {
          this.topLevelParent.loading = false;
          this.openModal = true;
          this.errorBag = error.response.data.error_fields;
        }.bind(this));
    },
    _updateOccasion() {
      let formData = {
        is_active: this.formData.is_active,
        name: this.formData.name,
        event_date: this.formData.date,
        maximum_topup_limit: this.formData.maximum_topup_limit,
        on_birthday: this.formData.is_birthday,
        gender_availability: this._getGenderAvailability()
      };
      let event = this.parentBenefit.events[this.formData.occasionIndex];
      this.httpService.updateEvent(this.clientId, this.parentBenefit.id, event.id, formData)
        .then(function (response) {
          this.parentBenefit.events[this.formData.occasionIndex] = response.data;
          this.tableKey++; // forces re-render of table for status change
          this.topLevelParent.loading = false;
          this.closeModal();
        }.bind(this))
        .catch(function (error) {
          this.topLevelParent.loading = false;
          this.openModal = true;
          this.errorBag = error.response.data.error_fields;
        }.bind(this));
    },
    _getGenderAvailability() {
      if (this.formData.gender_availability.male && this.formData.gender_availability.female) {
        return 'BOTH';
      } else if (!this.formData.gender_availability.male && this.formData.gender_availability.female) {
        return 'FEMALE';
      } else if (this.formData.gender_availability.male && !this.formData.gender_availability.female) {
        return 'MALE';
      } else {
        return 'BOTH';
      }
    },
    getGenderAvailability(gender) {
      if (gender === 'MALE') {
        return 'Barbati';
      } else if (gender === 'FEMALE') {
        return 'Femei';
      } else {
        return 'Ambele genuri';
      }
    }
  }
}
</script>
