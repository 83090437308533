<template>
  <div class="">
    <action-buttons :reportTypes="reportTypes"
                    :isAdmin="isAdmin"
                    :type="type"
                    :company="company"
                    @changeType="changeType"
                    @triggerFilter="triggerFilter"
                    @triggerDownload="triggerDownload"></action-buttons>

    <div v-if="errors.show" class="my-3">
      <div class="alert alert-danger alert-dismissible mb-0 text-danger">
        <a type="button" class="close btn" data-dismiss="alert" @click="errors.show=false">&times;</a>
        {{ errors.message }}
      </div>
    </div>
    <div class="mt-2"></div>
    <div v-if="componentName!=null"
         class="row">
      <component :is="componentName"
                 :isAdmin="isAdmin"
                 :company="company"
                 @toggleLoading="toggleLoading"
                 @setCompany="setCompany"
                 @setErrors="setErrors"
                 :ref="activeChildRef"></component>
    </div>
    <content-loader :loader-visible="loading" :loader-error="loaderError"></content-loader>
  </div>
</template>

<script>
import ActionButtons from "./partial/ActionButtons";
import CustomReport from "./Types/CustomReport";
import StandardReport from "./Types/StandardReport";
import WorldClassReport from "@/components/flexben/reports/Types/WorldClassReport";
import PointsPagoReport from "@/components/flexben/reports/Types/PointsPagoReport";
import CashOutReport from "@/components/flexben/reports/Types/CashOutReport";
import AllocatedBudgetReport from "./Types/AllocatedBudgetReport";
import ConsumedBudgetReport from "./Types/ConsumedBudgetReport";
import UnconsumedBudgetReport from "./Types/UnconsumedBugetReport";
import TrackingReport from "./Types/TrackingReport";
import OffersReport from "./Types/OffersReport";
import UpBenefitsReport from "./Types/UpBenefitsReport";

export default {
  name: "ReportTypes",
  components: {
    'action-buttons': ActionButtons,
    'custom-report': CustomReport,
    'standard-report': StandardReport,
    'allocated-budget-report': AllocatedBudgetReport,
    'consumed-budget-report': ConsumedBudgetReport,
    'unconsumed-budget-report': UnconsumedBudgetReport,
    'world-class-report': WorldClassReport,
    'points-pago-report': PointsPagoReport,
    'cash-out-report': CashOutReport,
    'tracking-report': TrackingReport,
    'offers-report': OffersReport,
    'upBenefits-report': UpBenefitsReport,
  },
  data: function () {
    return {
      type: null,
      componentName: null,
      company: null,
      isAdmin: false,
      activeChildRef: null,
      loading: false,
      loaderError: false,
      errors: {
        show: false,
        message: null,
      },
      reportTypes: {
        'standard': {
          for_admin: false,
          name: 'standard-report'
        },
        'custom': {
          for_admin: false,
          name: 'custom-report'
        },
        'world_class': {
          for_admin: true,
          name: 'world-class-report'
        },
        'offers': {
          for_admin: true,
          name: 'offers-report'
        },
        'points_pago': {
          for_admin: true,
          name: 'points-pago-report'
        },
        'cash_out': {
          for_admin: false,
          name: 'cash-out-report'
        },
        'consumed_budget': {
          for_admin: false,
          name: 'consumed-budget-report'
        },
        'unconsumed_budget': {
          for_admin: false,
          name: 'unconsumed-budget-report'
        },
        'allocated_budget': {
          for_admin: false,
          name: 'allocated-budget-report'
        },
        'data_tracking': {
          for_admin: true,
          name: 'tracking-report'
        },
        'upBenefits': {
          for_admin: false,
          name: 'upBenefits-report'
        },
      }
    }
  },
  watch: {
    type: {
      handler: function (val) {
        const componentType = this.reportTypes[val]
        if (!this.isAdmin && componentType.for_admin) {
          this.componentName = null;
        } else {
          this.componentName = componentType.name;
        }
      }
    }
  },
  created() {
    let permission = this.flxUserLevel();
    if (!permission.hasAccess) {
      window.location.href = '/';
      return;
    }

    const employment = this.$store.getters.flx_activeEmployment;

    if (permission.userLevel === 'client' && employment != null) {
      this.company = employment.company_id;
      this.isAdmin = false;
    } else if (permission.userLevel === 'admin') {
      this.isAdmin = true;
    } else {
      window.location.href = '/';
      return;
    }
    this.changeType('standard');
  },
  methods: {
    changeType(type) {
      if (this.type !== type) {
        this.type = type;
        if (type === null) {
          this.activeChildRef = null;
        } else {
          this.activeChildRef = 'component_' + type;
        }
      }
    },
    setCompany(company) {
      this.company = company;
    },
    toggleLoading(loading, loaderError = false) {
      this.loading = loading;
      this.loaderError = loaderError;
    },
    triggerFilter() {
      this.$refs[this.activeChildRef].filterValues();
    },
    triggerDownload() {
      this.$refs[this.activeChildRef].download();
    },
    setErrors(show, message = null) {
      this.errors = {
        show: show,
        message: message,
      }
    }
  }
}
</script>
