<template>
  <div class="row p-3">
    <div class="col-12 col-xl-3 mb-2 mb-xl-0">
      <img src="@/assets/images/filter.svg"/>
      <span class="ml-3">{{$t("flexben.general.filter")}}</span>
      <button class="btn text-themed-dark float-right border-themed-dark"
              @click="toggleFilters()">
        <font-awesome-icon icon="chevron-down" :class="collapsed?'btn-collapsed-off':'btn-collapsed-on'"/>
      </button>
    </div>
    <div class="col-12 col-xl-9">
      <div class="row mb-2 h-auto" ref="previous_filters" :class="{hide:collapsed}">
        <div class="col-12 col-md-4 form-group mb-0">
          <label class="col-form-label pt-xl-0">{{ $t("flexben.requests.filter.field.benefit")
            }}</label>
          <div class="form-control disabled">{{selectedFilters.benefit ? selectedFilters.benefit : '-'}}</div>
        </div>
        <div class="col-12 col-md-4 form-group mb-0" v-if="isAdmin">
          <label class="col-form-label pt-xl-0">{{ $t("flexben.requests.filter.field.client")
            }}</label>
          <div class="form-control disabled">{{selectedFilters.company}}</div>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label class="col-form-label pt-xl-0">
            {{ $t("flexben.requests.filter.field.beneficiary") }}</label>
          <div class="form-control disabled">{{selectedFilters.beneficiary ? selectedFilters.beneficiary : '-'}}
          </div>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label class="col-form-label pt-xl-0">
            {{ $t("flexben.requests.filter.field.working_point") }}</label>
          <div class="form-control disabled">{{selectedFilters.working_point ? selectedFilters.working_point : '-'}}
          </div>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label class="col-form-label">
            {{ $t("flexben.requests.filter.field.department") }}</label>
          <div class="form-control disabled">{{selectedFilters.department ? selectedFilters.department : '-'}}
          </div>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label class="col-form-label">
            {{ $t("flexben.requests.filter.field.job_title") }}</label>
          <div class="form-control disabled">{{selectedFilters.job_title ? selectedFilters.job_title : '-'}}
          </div>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label class="col-form-label">
            {{ $t("flexben.requests.filter.field.cost_center") }}</label>
          <div class="form-control disabled">{{selectedFilters.cost_center ? selectedFilters.cost_center : '-'}}
          </div>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label class="col-form-label">
            {{ $t("flexben.requests.filter.field.benefit_group") }}</label>
          <div class="form-control disabled">{{selectedFilters.benefit_group ? selectedFilters.benefit_group : '-'}}
          </div>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label class="col-form-label">
            {{ $t("flexben.requests.filter.field.delivery_address") }}</label>
          <div class="form-control disabled">{{selectedFilters.company_addresses ? selectedFilters.company_addresses :
            '-'}}
          </div>
        </div>
        <div class="col-12 col-md-4 offset-md-4 form-group mb-0">
          <label class="col-form-label">{{ $t("flexben.requests.filter.field.start_date") }}</label>
          <div class="form-control disabled">{{ selectedFilters.start_date ? selectedFilters.start_date : '-'}}
          </div>
        </div>
        <div class="col-12 col-md-4 form-group mb-0">
          <label class="col-form-label">{{ $t("flexben.requests.filter.field.end_date") }}</label>
          <div class="form-control disabled">{{ selectedFilters.end_date ? selectedFilters.end_date : '-'}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 offset-md-8 form-group mb-0">
          <label for="status" class="col-form-label pt-xl-0">{{ $t("flexben.requests.filter.field.status")
            }}</label>
          <select class="form-control w-100" id="status" v-model="params.status" @change="filterValues()">
            <option :value="null">-</option>
            <option value="valid">{{ $t('flexben.requests.summary.status.valid') }}</option>
            <option value="invalid">{{ $t('flexben.requests.summary.status.invalid') }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LockedFilters",
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
      required: true,
    },
    selectedFilters: {
      type: Object,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {collapsed: true}
  },
  methods: {
    filterValues() {
      this.$emit('filterValues')
    },
    toggleFilters() {
this.collapsed=!this.collapsed;
      // this.$refs['previous_filters'].classList.toggle('hide', !this.$refs['previous_filters'].classList.contains('hide'));
    }
  }
}
</script>
