<template>
  <div>
    <div v-if="this.listDataLoaded">
      <template>
        <div class="row mb-1 px-3" :class="{'mb-md-4': pendingOrders === 0}">
          <div class="col-12 pl-sm-0 flexben-page-title">
            <h1>{{ $tc("flexben.order.title", 1, {requestType: ''}) }}</h1>
            <p v-if="pendingOrders > 0">{{ $t("flexben.order.description", {count: pendingOrders}) }}</p>
          </div>
        </div>
        <div class="container-bg-white">
          <div class="row p-3">
            <div class="col-12 col-xl-3 mb-2 mb-xl-0">
              <img src="@/assets/images/filter.svg"/>
              <span class="ml-3">{{$t("flexben.general.filter")}}</span>
            </div>
            <div class="col-12 col-xl-9">
              <div class="row">
                <div v-if="isAdmin" class="col-12 col-md-4 form-group mb-0">
                  <label for="client" class="col-form-label">{{ $t("flexben.order.client") }}</label>
                  <select class="form-control flexben-input" v-model="params.client" id="client"
                          @change="filerList()">
                    <option :value="null">-</option>
                    <option v-for="(client, id, index) in filterValues.clients" :key="index" :value="id">
                      {{client}}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-4 form-group mb-0">
                  <label for="order_type" class="col-form-label">{{ $t("flexben.order.order_type") }}</label>
                  <select class="form-control flexben-input" v-model="params.order_type" id="order_type"
                          @change="filerList()">
                    <option :value="null">-</option>
                    <option v-for="type in filterValues.order_type" :key="type" :value="type">
                      {{$t('flexben.order.types.'+type)}}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-4 form-group mb-0">
                  <label for="status" class="col-form-label">{{ $t("flexben.general.status") }}</label>
                  <select type="text" class="form-control flexben-input" v-model="params.status" id="status"
                          @change="filerList()">
                    <option :value="null">-</option>
                    <option v-for="stat in filterValues.status" :key="stat" :value="stat">{{
                      $t('flexben.general.status_type.' + stat) }}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-4 form-group mb-0">
                  <label for="benefit" class="col-form-label">{{ $t("flexben.order.benefit") }}</label>
                  <select class="form-control flexben-input" v-model="params.benefit" id="benefit"
                          @change="filerList()">
                    <option :value="null">-</option>
                    <option v-for="(benefit, id, index) in filterValues.benefit" :key="index" :value="id">{{ benefit }}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-4 form-group mb-0" :class="{'offset-md-4':!isAdmin}">
                  <label for="start_date" class="col-form-label">{{ $t("flexben.order.date_between") }}</label>
                  <div class="d-flex w-100">
                    <font-awesome-icon icon="calendar-alt" class="mt-2 mr-2 flx-calendar-icon"/>
                    <datepicker :format="dateFormat"
                                class="w-100"
                                :inputClass="{
                                'form-control m-0 w-100': true,
                                'background-white': true
                                }"
                                v-model="filters.start_date"
                                @closed="filterDate('start_date')"
                                key="start_date"
                                :clearButton="false"
                                id="start_date"></datepicker>
                    <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                         @click="filterDate('start_date', true)"/>
                  </div>
                </div>
                <div class="col-12 col-md-4 form-group mb-0">
                  <label for="end_date" class="w-50 col-form-label">{{ $t("flexben.order.and") }}</label>
                  <div class="d-flex w-100">
                    <font-awesome-icon icon="calendar-alt" class="mt-2 mr-2 flx-calendar-icon"/>
                    <datepicker :format="dateFormat"
                                class="w-95"
                                :inputClass="{
                                'form-control m-0 w-100': true,
                                'background-white': true
                              }"
                                v-model="filters.end_date"
                                @closed="filterDate('end_date')"
                                key="end_date"
                                :clearButton="false"
                                id="end_date"></datepicker>
                    <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                         @click="filterDate('end_date', true)"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-bg-white">
          <div class="row p-3 mt-3">
            <div class="col-12 col-md-6">
              <h3 class="pl-0 mt-0">{{ $t("flexben.order.order_history") }}</h3>
            </div>
            <div class="col-12 col-md-6">
              <input type="text"
                     v-model="searchString"
                     v-on:keyup.enter="search()"
                     :placeholder="$t('flexben.general.search')"
                     class="formedituser search-input float-right"/>
            </div>
            <div class="col-12">
              <div class="table-responsive hover-table">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <td class="cursor-pointer" @click="sortBy('order_number')">
                      {{ $t("flexben.order.order_number") }}
                      <img src="@/assets/images/arrow-down-fill.svg"
                           :style="sortByField === 'order_number' && reverseSort ? 'transform: rotate(180deg)' : ''"/>
                    </td>
                    <td class="cursor-pointer" @click="sortBy('created_at')">
                      {{ $t("flexben.order.placed_at") }}
                      <img src="@/assets/images/arrow-down-fill.svg"
                           :style="sortByField === 'created_at' && reverseSort ? 'transform: rotate(180deg)' : ''"/>
                    </td>
                    <td class="cursor-pointer" @click="sortBy('client')" v-if="isAdmin">
                      {{ $t("flexben.order.client") }}
                      <img src="@/assets/images/arrow-down-fill.svg"
                           :style="sortByField === 'client' && reverseSort ? 'transform: rotate(180deg)' : ''"/>
                    </td>
                    <td class="cursor-pointer" @click="sortBy('type')">
                      {{ $t("flexben.order.type") }}
                      <img src="@/assets/images/arrow-down-fill.svg"
                           :style="sortByField === 'type' && reverseSort ? 'transform: rotate(180deg)' : ''"/>
                    </td>
                    <td class="cursor-pointer" @click="sortBy('benefit_name')">
                      {{ $t("flexben.order.benefit") }}
                      <img src="@/assets/images/arrow-down-fill.svg"
                           :style="sortByField === 'benefit_name' && reverseSort ? 'transform: rotate(180deg)' : ''"/>
                    </td>
                    <td class="cursor-pointer" @click="sortBy('total_value')">
                      {{ $t("flexben.order.total_requests_value") }}
                      <img src="@/assets/images/arrow-down-fill.svg"
                           :style="sortByField === 'total_value' && reverseSort ? 'transform: rotate(180deg)' : ''"/>
                    </td>
                    <td class="cursor-pointer" @click="sortBy('status')">
                      {{ $t("flexben.order.status") }}
                      <img src="@/assets/images/arrow-down-fill.svg"
                           :style="sortByField === 'status' && reverseSort ? 'transform: rotate(180deg)' : ''"/>
                    </td>
                    <td></td>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="orders.data.length > 0">
                    <tr class="trhover parent" v-for="(order, index) in orders.data" :key="order.id">
                      <td>
                        <span class="align-middle text-ellipsis-15">{{order.order_number}}</span>
                      </td>
                      <td>
                        <span>{{ moment(order.created_at, 'DD/MM/YYYY HH:mm')}}</span>
                      </td>
                      <td class="categories" v-if="isAdmin">
                        {{ order.company_name }}
                      </td>
                      <td>
                        <img v-if="order.type === 'top_up'"
                             :id="'type_tooltip_'+index"
                             src="@/assets/images/flexben/order/topUp.svg"
                             :alt="$t('flexben.requests.types.top_up')"/>
                        <img v-else-if="order.type==='card_request'"
                             :id="'type_tooltip_'+index"
                             src="@/assets/images/flexben/order/cardRequest.svg"
                             :alt="$t('flexben.requests.types.card_request')"/>
                        <img v-else-if="order.type==='not_legislated'"
                             :id="'type_tooltip_'+index"
                             src="@/assets/images/flexben/order/notLegislated.svg"
                             :alt="$t('flexben.requests.types.not_legislated')"/>
                        <b-tooltip :target="'type_tooltip_'+index" triggers="hover" variant="flexben"
                                   placement="topleft">
                          {{ $t('flexben.order.types.' +order.type) }}
                        </b-tooltip>
                      </td>
                      <td><span :style="{backgroundColor: order.benefit_color}" class="color-bullet mr-1"></span> {{
                        order.benefit_name }}
                      </td>
                      <td>{{ order.total_value }} Lei</td>
                      <td>
                      <span class="benefit-status m-0" :class="'benefit-status-'+order.status">
                        {{ $t('flexben.general.status_type.' + order.status) }}
                      </span>
                      </td>
                      <td>
                        <button class="btn action-button" @click="openOrderDetail(index)">
                          {{$t("flexben.general.btn.see_more")}}
                        </button>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr class="trhover gri-cell">
                      <td :colspan="isAdmin ? 8 : 7" class="text-center">{{ $t("flexben.general.no_results_found") }}
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12 p-0">
              <listPagination
                :activePage="orders.current_page"
                :currentPage="orders.current_page"
                :totalItems="orders.total"
                :startItem="orders.from"
                :endItem="orders.to"
                :itemsPerPage="orders.perPage"
                @pageChanged="pageChanged"
                @changePerPage="changePerPage"></listPagination>
            </div>
          </div>
        </div>
      </template>
      <order-detail :order="orderDetails" ref="orderDetail"
                    @toggleLoading="toggleLoading"></order-detail>
    </div>

    <content-loader :loader-visible="loading" :loader-error="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben.js";
import i18n from "@/plugins/language/i18n";
import Datepicker from "vuejs-datepicker";
import OrderDetail from "./OrderDetail";
import moment from "moment";
import {BTooltip, VBModal} from "bootstrap-vue";

export default {
  name: 'FlexbenOrders',
  components: {
    Datepicker,
    OrderDetail,
    'b-tooltip': BTooltip
  },
  directives: {
    "b-modal": VBModal
  },
  data() {
    return {
      httpService: httpFlexbenService.orders,
      globalVariables: httpFlexbenService.globalVariablesService.baseImagePreview,
      // filtering
      filterValues: {
        benefit: null,
        clients: null,
        order_type: null,
        type: null
      },
      //list
      loading: true,
      loaderError: false,
      params: {
        client: null,
        order_type: null,
        status: null,
        benefit: null,
        start_date: null,
        end_date: null,
        search: null,
        per_page: 20,
        page: 1
      },
      filters: {
        start_date: null,
        end_date: null,
      },
      orders: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      listDataLoaded: false,
      currentLang: i18n.locale,
      // search
      searchString: '',
      // sort
      reverseSort: null,
      sortByField: null,
      // pagination
      itemsPerPage: 10,
      currentPage: 1,
      startItem: 1,
      endItem: 1,
      entriesTotal: 0,
      createItem: '',
      dateFormat: 'dd/MM/yyyy',
      isAdmin: false,
      orderId: null,
      pendingOrders: 0,
      companyId: null,
      selectClient: null,
      closeError: true,
      errorBag: {},
      clear: false,
      orderDetails: {}
    }
  },
  created() {
    const permissions = this.flxUserLevel();
    if (!permissions.hasAccess) {
      window.location.href = '/company';
      return;
    }

    let employment = this.$store.getters.flx_activeEmployment;

    if (permissions.userLevel === 'client' && employment != null) {
      this.companyId = employment.company_id;
    } else if (permissions.userLevel === 'admin') {
      this.isAdmin = true;
      this.companyId = localStorage.getItem('flexbenCompanyId');
    } else {
      window.location.href = '/company';
      return;
    }
    this.params.client = this.companyId;
    this.loadList();
  },
  methods: {
    loadList() {
      this.loading = true;
      this.httpService.getOrders(this.params)
        .then(response => {
            this.orders.data = response.data.list.data;
            this.orders.current_page = response.data.list.current_page;
            this.orders.to = response.data.list.to;
            this.orders.perPage = response.data.list.perPage;
            this.orders.lastPage = response.data.list.lastPage;
            this.filterValues = {
              benefit: response.data.filters.benefit,
              clients: response.data.filters.clients,
              selectClients: response.data.filters.clients,
              order_type: response.data.filters.order_type,
              status: response.data.filters.status,
            };
            this.listDataLoaded = true;
            this.loading = false;
          }
        )
        .catch(error => {
          this.loading = true;
          this.loaderError = true;
        });
    },
    filerList() {
      this.loadList();
    },
    sortBy(fieldName) {
      if (this.sortByField !== fieldName) {
        this.reverseSort = false;
      }
      if (typeof this.params.page !== 'undefined') {
        delete this.params.page;
      }
      this.params.sort_by = fieldName;
      this.params.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this.loadList(this.params)
      this.sortByField = fieldName;

      this.reverseSort = !this.reverseSort;
    },
    changePerPage(perPage) {
      this.params.page = 1;
      this.params.perPage = perPage;
      this.loadList();
    },
    pageChanged(pageNb) {
      this.params.page = pageNb;
      this.loadList();
    },
    getImage(item) {
      if (item.image_url === null || item.image_url === undefined) {
        return httpFlexbenService.globalVariablesService.baseImagePreview;
      }
      return item.image_url;
    },
    search() {
      this.params = {};
      if (this.searchString.length >= 3) {
        this.params.search = this.searchString;
        this.loadList();
      } else if (this.searchString.length === 0) {
        this.loadList();
      }
    },
    openOrderDetail(index) {
      this.orderDetails = this.orders.data[index];
      this.$refs.orderDetail.showOrderDetail(this.orderDetails.id);
    },
    filterDate(key, clearDate = false) {
      if (clearDate) {
        this.filters[key] = null;
        this.params[key] = null;
      } else {
        this.params[key] = this.moment(this.filters[key], 'YYYY-MM-DD');
      }
      this.loadList();
    },
    moment(date, format) {
      if (date === null) {
        return '-';
      }
      return moment(date).format(format);
    },
    toggleLoading(loading, loaderError = false) {
      this.loaderError = loaderError;
      this.loading = loading;
    }
  }
};
</script>
