<template>
  <div class="import-progress">
    <div class="import-step-container">
      <template v-for="(item, index) in progress_items">
        <div v-if="(item.type==='item')" class="import-step-item" :key="index">
          <span class="step-nr" :class="item.status_class" v-if="!item.last_elem"></span>
          <template v-else>
            <img src="@/assets/images/flexben/progress_bar/smile-on.png" alt=""
                 v-if="item.image ==='on'"
                 class="step-nr-smile">
            <img src="@/assets/images/flexben/progress_bar/smile-off.png" alt=""
                 v-if="item.image ==='off'"
                 class="step-nr-smile">
          </template>
          <!--          <span class="step-nr-smile"-->
          <!--                :style="{backgroundImage:item.image}"-->
          <!--                :class="item.status_class" v-else></span>-->
          <span class="step-text" :class="item.status_class">{{$t('flexben.progress_bar.' + item.text)}}</span>
        </div>
        <div v-if="(item.type==='separator')" :key="index"
             :class="['import-step-separator', 'separator-'+item.index, item.status_class]"
             v-bind:style="{ width: item.width + '%', left: item.left + '%'}"></div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ImportProgressBar",
    props: {
      progressStep: {
        type: Number,
        default: 1,
        required: true,
      },
      progressBarItems: {
        type: Array,
        required: true,
        default: () => {
          return [];
        }
      }
    },
    computed: {
      progress_items: function () {
        let progressBarItems = {...this.progressBarItems};
        let index;
        let listLength = Object.keys(progressBarItems).length - 1;
        for (index in progressBarItems) {
          let item = progressBarItems[index];
          if (item.step < this.progressStep) {
            item.status_class = 'done';
          } else if (item.step === this.progressStep) {
            item.status_class = (item.type === 'item') ? 'current' : 'done';
          } else {
            item.status_class = '';
          }
          item.last_elem = false;
          if (listLength === parseInt(index)) {
            item.last_elem = true;
            if (item.status_class === '') {
              item.image = 'off';
            } else {
              item.image = 'on';
            }
          }
          progressBarItems[index] = item;
        }
        return progressBarItems;
      }
    },
  }
</script>

<style scoped lang="scss">
  .import-step-container {
    display: flex;
    position: relative;

    .import-step-separator {
      z-index: 1;
      margin-top: 14px;
      position: absolute;
      top: 0;
      height: 3px;
      background-color: #585858;

      &.done {
        background-color:  var(--color-f49400) !important;
      }
    }

    @media (max-width: 768px) {
      .import-step-separator {
        margin-top: 11px;
      }
    }
    @media (max-width: 555px) {
      .import-step-separator {
        margin-top: 9px;
      }
    }


    .import-step-item {
      width: 25%;
      z-index: 2;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-weight: 700;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .step-nr {
        height: 30px;
        width: 30px;
        background-color: white;
        border: 3px solid #585858;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        &.done {
          border: 3px solid var(--color-f49400);
          background-color: var(--color-f49400);
        }

        &.current {
          border: 3px solid var(--color-f49400);
          background-color: white;
        }
      }

      .step-nr-smile {
        height: 30px;
        width: 30px;
      }

      .step-text {
        margin-top: 5px;

        &.done, &.current {
          color:  var(--color-f49400);;
        }
      }

      @media (max-width: 768px) {
        .step-nr,
        .step-nr-smile {
          width: 25px;
          height: 25px;
        }

        .step-text {
          font-size: 1.5vw;
        }
      }
      @media (max-width: 555px) {
        .step-nr,
        .step-nr-smile {
          width: 21px;
          height: 21px;
        }
      }
    }
  }
</style>
