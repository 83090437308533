<template>
  <b-tab :title="$t('flexben.beneficiaries.budget_title')" :active="activeTab === 'budget'" :key="'budget'" class="container-bg-white">
    <div class="row px-3 pb-2">
      <div class="col-12">
        <h3 class="mb-0 py-1 pl-0">{{$t("flexben.general.entries")}}</h3>
      </div>
      <div class="col-12 mt-2 sub-tab-table">
        <div class="table-responsive hover-table">
          <table class="table no-min-width">
            <thead>
            <tr>
              <td>{{$t("flexben.beneficiaries.updated_at")}}</td>
              <td>{{$t("flexben.beneficiaries.fields.updated_budget_type")}}</td>
              <td>{{$t("flexben.beneficiaries.fields.previous_budget")}}</td>
              <td>{{$t("flexben.beneficiaries.fields.new_budget")}}</td>
              <td>{{$t("flexben.beneficiaries.fields.difference")}}</td>
              <td>{{$t("flexben.import.table_headings.expiration_date")}}</td>
              <td>{{$t("flexben.beneficiaries.fields.ppp3_budget")}}</td>
              <td>{{$t("flexben.beneficiaries.fields.ppp3_expiration_date")}}</td>
            </tr>
            </thead>
            <tbody>
            <template v-if="table_data.data.length >0">
              <tr class="trhover" v-for="(row, index) in table_data.data" :key="index">
               <td>{{ moment(row.updated_at,'DD/MM/YYYY HH:mm') }}</td>
                <td>{{ $t("flexben.beneficiaries.updated_budget_types."+row.type) }}</td>
                <td>{{ row.old_total_budget }} Lei</td>
                <td>{{ row.new_total_budget }} Lei</td>
                <td>{{ formattedBudgetDifference(row) }} Lei</td>
                <td>{{ moment(row.expiration_date,'DD/MM/YYYY') }}</td>
                <td>{{ row.ppp3_budget ? row.ppp3_budget+' Lei' : '' }}</td>
                <td>{{ row.ppp3_expiration_date ? moment(row.ppp3_expiration_date,'DD/MM/YYYY') : '' }}</td>
              </tr>
            </template>

            <template v-else>
              <tr class="gri-cell text-center trhover">
                <td colspan="100" class="text-center ">{{ $t("flexben.general.no_results_found") }}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 p-0">
        <listPagination
          :currentPage="table_data.current_page"
          :totalItems="table_data.total"
          :startItem="table_data.from"
          :endItem="table_data.to"
          :itemsPerPage="table_data.perPage"
          :defaultPerPage="10"
          @pageChanged="pageChanged"
          @changePerPage="changePerPage"></listPagination>
      </div>
    </div>
  </b-tab>
</template>

<script>
import {BTab} from "bootstrap-vue";
import moment from "moment";

export default {
  name: "TabBeneficiaryHistoryBudget",
  components: {
    "b-tab": BTab,
  },
  props: {
    activeTab: {
      type: String,
      default: '',
      required: true
    },
    tableData: {
      type: Object,
      default: function () {
        return {
          data: [],
          current_page: 1,
          to: 1,
          perPage: 1,
          lastPage: 1,
          total: 1,
        };
      },
      required: true
    },
  },
  computed: {
    table_data: function () {
      return this.tableData;
    }
  },
  methods: {
    pageChanged(page) {
      this.$emit('pageChanged', page);
    },
    changePerPage(perPage) {
      this.$emit('changePerPage', perPage, 'budget');
    },
    moment(date, format) {
      if (date === null) {
        return '-';
      }
      return moment(date).format(format);
    },
    formattedBudgetDifference(row) {
      const difference = row.new_total_budget - row.old_total_budget;
      return difference.toFixed(2);
    }
  }
}
</script>
