<template>
  <b-modal id="modal-1"
           ref="offer-modal"
           :hide-header-close="true"
           headerClass="border-0 text-center"
           bodyClass="modal-body no-border background-white text-center"
           footerClass="modal-footer border-top-0"
           centered>
    <template v-slot:modal-header>
      <h2 class="mb-0 mx-auto text-capitalize">{{$t("flexben.requests.modal.title")}}</h2>
    </template>
    <template v-slot:default>
      <div class="row" v-if="rejectData.ids.length > 0">
        <div class="col-12 text-center">
          {{ $tc("flexben.requests.modal.this_will_show_one_cart_type", rejectData.ids.length, { count:
          rejectData.ids.length }) }}
        </div>
        <div class="mx-auto col-12 col-md-10 form-group mt-3 text-left">
          <label class="text-14 m-0" for="reject_reason">{{ $t('flexben.requests.modal.field.reject_reason')
            }}</label>
          <textarea class="m-0 w-100 bg-white form-control-textarea"
                    rows="5"
                    maxlength="255"
                    id="reject_reason"
                    v-model="rejectData.reject_reason"></textarea>
        </div>
        <div v-if="showDisableTopUps"
             class="col-12 text-center">
          {{ $t('flexben.requests.modal.field.confirm_disable_auto_top_ups',{
          ppp3: $t('flexben.benefit.sub_types.ppp3'),
          medical_insurance: $t('flexben.benefit.sub_types.medical_insurance')
          }) }}
          <div class="d-flex mt-2">
            <span class="ml-auto">{{ $t('flexben.general.no') }}</span>
            <b-form-checkbox switch class="mx-3"
                             v-model="toggleDisableAutoTopUp"></b-form-checkbox>
            <span class="mr-auto">{{ $t('flexben.general.yes') }}</span>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="w-100">
        <button class="btn text-themed mx-auto mt-2" @click="closeModal()">{{ $t('flexben.general.btn.cancel') }}
        </button>
        <button class="btn btn-themed mx-auto px-4 float-right" @click="confirmAction()">{{
          $t('flexben.general.btn.confirm') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {BFormCheckbox, BModal} from "bootstrap-vue";

export default {
  name: "RejectModal",
  components: {
    'b-modal': BModal,
    'b-form-checkbox': BFormCheckbox,
  },
  props: {
    rejectData: {
      type: Object,
      required: true,
      default: function () {
        return {
          ids: [],
          reject_reason: ''
        };
      }
    },
    showDisableTopUps: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {toggleDisableAutoTopUp: false}
  },
  methods: {
    openModal() {
      this.toggleDisableAutoTopUp = false;
      this.$refs['offer-modal'].show();
    },
    closeModal() {
      this.$refs['offer-modal'].hide();
    },
    confirmAction() {
      this.$emit('confirmAction', this.toggleDisableAutoTopUp);
      this.$refs['offer-modal'].hide();
    }
  },
}
</script>
