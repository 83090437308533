<template>
  <b-tab :title="$t('flexben.beneficiaries.auto_top_ups')" :active="activeTab === 'auto_top_ups'" :key="'auto_top_ups'"
         :disabled="autoTopUps.length==0"
         class="container-bg-white">
    <div class="row px-3 pb-2">
      <div class="col-12">
        <h3 class="mb-0 py-1 pl-0">{{$t("flexben.general.entries")}}</h3>
      </div>
      <table class="table no-min-width">
        <thead>
        <tr>
          <td><input type="checkbox" class="form-check" v-model="allChecked"></td>
          <td>{{$t("flexben.clients.partial.benefit")}}</td>
          <td>{{$t("flexben.beneficiaries.request.amount")}}</td>
          <td>{{$t("flexben.beneficiaries.fields.contribution_type")}}</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in autoTopUps" :key="index">
          <td><input type="checkbox" class="form-check"
                     :checked="checkedIds.includes(row.id)"
                     @change="checkItem(row.id)"></td>
          <td>{{ row.benefit.name }}</td>
          <td>{{ row.ticket_value }} Lei</td>
          <td>{{ row.contribution_type }}</td>
        </tr>
        </tbody>
      </table>
      <div class="col-12">
        <button class="btn btn-themed" @click="disableTopUps()">{{ $t("flexben.general.btn.disable") }}</button>
      </div>
    </div>
  </b-tab>
</template>

<script>
import {BTab} from "bootstrap-vue";

export default {
  name: "TabBeneficiaryHistoryAutoTopUps",
  components: {
    "b-tab": BTab,
  },
  props: {
    activeTab: {
      type: String,
      default: '',
      required: true
    },
    autoTopUps: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data: () => {
    return {
      checkedIds: [],
      allChecked: false
    };
  },
  watch: {
    allChecked: {
      handler: function (val) {
        if (val) {
          let ids = [];
          for (let item of this.autoTopUps) {
            ids.push(item.id);
          }
          this.checkedIds = ids;
        } else {
          this.checkedIds = [];
        }
      }
    }
  },
  methods: {
    checkItem(id = null) {
      if (this.checkedIds.includes(id)) {
        const index = this.checkedIds.indexOf(id);
        this.checkedIds.splice(index, 1)
      } else {
        this.checkedIds.push(id);
      }
    },
    disableTopUps() {
      this.$emit('disableTopUps', this.checkedIds);
    }
  }
}
</script>
