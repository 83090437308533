<template>
  <div class="">
    <a class="btn text-themed go-back" @click="goBack()">{{ $t("flexben.general.go_back") }}</a>
    <div v-show="!initialLoading">
      <div class="row">
        <div class="col-12 col-md-6 flexben-page-title">
          <h1>{{ $t('flexben.affiliate_channels.btns.new') }}</h1>
        </div>
        <div class="col-12 col-md-6 text-right">
          <button class="btn btn-themed px-4"
                  @click="modalAct('form_submit')">
            {{ $t('flexben.general.btn.save') }}
          </button>
        </div>
      </div>
      <div class="row my-1" v-if="checkNotEmptyObj(errorsBag)">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible mb-0">
            <button type="button" class="close" data-dismiss="alert" @click="closeErrorButton = false">&times;</button>
            <ul class="text-danger list-unstyled mb-1">
              <li v-for="( errorField, index) in errorsBag" :key="index">
                {{errorField}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container-bg-white mt-3">
        <div class="row px-3">
          <div class="col-12">
            <h3 class="m-0 pl-0">{{ $t('flexben.general.details') }}</h3>
          </div>
          <div class="col-12 col-xl-8">
            <div class="row">
              <div class="col-12 col-md-6 form-group">


                <label for="platforms" class="col-form-label form-fields-required">{{ $t('flexben.affiliate_channels.modal.channel_type') }}</label>
                <select class="w-100" id="platforms" :class="{'border-danger':(Object.keys(errorsBag).includes('channelType'))}"
                        v-model="formData.channelType">
                  <option>-</option>
                  <option
                    v-for="(type, index) in channelTypes"
                    :key="index"
                    :value="type"
                  >
                    {{ $t('flexben.affiliate_channels.modal.channel_types.' + type) }}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-6 form-group">
                <label for="name" class="form-fields-required col-form-label">{{ $t('flexben.affiliate_channels.modal.name') }}</label>
                <input type="text" class="form-control" id="name" v-model="formData.name"
                       :class="{'border-danger':(Object.keys(errorsBag).includes('name'))}">
              </div>
              <div v-if="formData.channelType==='platform'" class="col-12 col-md-6 form-group">
                <label for="platforms" class="col-form-label form-fields-required">{{ $t('flexben.affiliate_channels.modal.social_platform') }}</label>
                <select class="w-100" id="platforms" :class="{'border-danger':(Object.keys(errorsBag).includes('socialPlatform'))}"
                        v-model="formData.socialPlatform">
                  <option
                    v-for="(platform, index) in socialPlatforms"
                    :key="index"
                    :value="platform"
                  >
                    {{ platform }}
                  </option>
                </select>
              </div>
              <div v-if="formData.channelType==='commercial'" class="col-12 col-md-6 form-group">
                <label for="commercial_identifier" class="form-fields-required col-form-label">{{ $t('flexben.affiliate_channels.modal.commercial_identifier')
                  }}</label>
                <input type="text" class="form-control " id="commercial_identifier" v-model="formData.commercialIdentifier"
                       :class="{'border-danger':(Object.keys(errorsBag).includes('commercialIdentifier'))}">
              </div>
              <div class="col-12 col-md-6 form-group">
                <label for="specific_identifier" class="col-form-label">{{ $t('flexben.affiliate_channels.modal.specific_identifier') }}</label>
                <input type="text" class="form-control" id="specific_identifier" v-model="formData.specificIdentifier"
                       :class="{'border-danger':(Object.keys(errorsBag).includes('specificIdentifier'))}">
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>

    <confirm-modal :action="modal.action"
                   :formSuccess="modal.formSuccess"
                   :message="modal.message"
                   :canDelete="modal.canDelete"
                   ref="confirm-modal"
                   @confirmAction="confirmAction">
    </confirm-modal>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
  </div>
</template>

<script>
import ConfirmModal from "@/components/flexben/partners/modals/ConfirmModal";
import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import moment from "moment";

export default {
  name: "CreateChannelForm",
  components: {
    "confirm-modal":ConfirmModal,
  },
  props: {
    socialPlatforms: {
      required: true,
      type: Array
    }
  },
  data: () => {
    return {
      initialLoading: true,
      loading: false,
      loaderError: false,
      httpService: httpFlexbenService.affiliationChannels,
      locale: i18n.locale,
      channelTypes: {platform:'platform',commercial:'commercial'},
      formData: {
        channelType: '',
        name: '',
        commercialIdentifier: '',
        socialPlatform: '',
        specificIdentifier: ''
      },
      errorsBag: {},
      isFormModified: false,
      modal: {
        action: '',
        formSuccess: true,
        message: '',
        canDelete: true,
      }
    };
  },
  watch: {
    formData: {
      deep: true,
      handler: function () {
        this.isFormModified = true;
      }
    }
  },
  created() {
    this.initialLoading = false;
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  methods: {
    goBack() {
      if (this.isFormModified) {
        this.modalAct('go_back')
      } else {
        this.$emit('clearForm');
      }
    },
    modalAct(action) {
      this.modal.message = '';
      this.modal.action = action;

      this.openModal();
    },
    confirmAction(action) {
      if (action === 'go_back') {
        this.isFormModified = false;
        this.goBack();
      }
      if (action === 'form_submit') {
        this._createItem();
        this.closeModal();
      }
      if (action === 'end') {
        this.closeModal();
        if (this.modal.formSuccess) {
          this.isFormModified = false;
          this.$emit('clearForm', true);
        }
      }
    },
    moment(date, format) {
      return moment(date).format(format);
    },
    _createItem() {
      this.loading = true;
      this.httpService.create(this.formData)
        .then(function (response) {
          this.modal.formSuccess = true;
          this.modal.action = 'end';
          this.loading = false;
          this.openModal();
        }.bind(this))
        .catch(function (error) {
          if (error.response.status === 400) {
            this.errorsBag = error.response.data.error_fields;
            this.modal.formSuccess = false;
            this.modal.message = this.$t('flexben.general.validation_errors');
            this.modal.action = 'end';
            this.loading = false;
            this.openModal();
          } else {
            this.loading = false;
            this.loaderError = true;
          }
        }.bind(this));
    },
    openModal() {
      this.$refs['confirm-modal'].openModal();
    },
    closeModal() {
      this.$refs['confirm-modal'].closeModal();
    },
  }

}
</script>
