<template>
  <div class="hover-table table-responsive">
    <table class="table table-striped">
      <thead>
      <tr>
        <td class="w-50-p">
          <input type="checkbox"
                 @change="checkForDelete('all')" v-model="check_all_checkbox"/>
        </td>
        <td>{{ $t('flexben.import.table_headings.name') }}</td>
        <td>{{ $t('flexben.import.table_headings.email') }}</td>
        <td>{{ $t('flexben.import.table_headings.phone_number') }}</td>
        <td>{{ $t('flexben.import.table_headings.invitation_channel'), }}</td>
        <td>{{ $t('flexben.import.table_headings.status_entry') }}</td>
        <td></td>
      </tr>
      </thead>
      <tbody>
      <template v-if="import_items.length">
        <tr v-for="(item, index) in import_items" :key="index" class="trhover">
          <td class="w-50-p">
            <input type="checkbox" :checked="importDeleteItemIds.includes(item.id)"
                   @change="checkForDelete(item.id)"/>
          </td>
          <td>{{ item.last_name }} {{ item.first_name }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.phone_number }}</td>
          <td>{{ item.invitation_channel }}</td>
          <td>
            <span
              :class="'import-status-item-'+item.status">{{ $t('flexben.import.item_statuses.'+item.status) }}</span>

            <span v-if="item.invalid_reasons !== null" :id="'invalid_tooltip_'+index"
                  class="ml-1 item-error-reason">!</span>
            <b-tooltip v-if="item.invalid_reasons !== null"
                       :target="'invalid_tooltip_'+index" variant="flexben"
                       placement="topleft">
              <div v-html="getInvalidReasons(item.invalid_reasons)"></div>
            </b-tooltip>
          </td>
          <td>
            <button class="btn action-button" @click="editItem(index)">
              {{$t("flexben.general.btn.see_more")}}
            </button>
          </td>
        </tr>
      </template>
      <template v-else>
        <tr class="gri-cell text-center trhover">
          <td colspan="1000">{{ $t("flexben.general.no_results_found") }}</td>
        </tr>
      </template>
      </tbody>

    </table>
  </div>
</template>

<script>
import {BTooltip} from "bootstrap-vue";

export default {
  name: "ImportTableBeneficiary",
  components: {
    'b-tooltip': BTooltip
  },
  props: {
    importItems: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    },
    importDeleteItemIds: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    }
  },
  computed: {
    import_items: function () {
      return this.importItems;
    }
  },
  data: function () {
    return {
      check_all_checkbox: false
    }
  },
  watch: {
    check_all_checkbox: {
      handler: function (val) {
        this.$emit('checkForDelete', val ? 'all' : 'none');
      },
    }
  },
  methods: {
    checkForDelete(itemId) {
      this.$parent.checkForDelete(itemId);
    },
    getInvalidReasons(invalidReasons) {
      let errorList = [];
      let indexKey, errorField;
      for (errorField in invalidReasons) {
        if (typeof invalidReasons[errorField] === 'string') {
          errorList.push(this.$t('flexben.formFieldError', {
            field: this.$t('flexben.import.table_headings.' + errorField),
            reason: this.$t('flexben.formErrorReasons.' + invalidReasons[errorField])
          }))
        } else {
          for (indexKey in invalidReasons[errorField]) {
            errorList.push(this.$t('flexben.formFieldError', {
              field: this.$t('flexben.import.table_headings.' + errorField),
              reason: this.$t('flexben.formErrorReasons.' + invalidReasons[errorField][indexKey])
            }))
          }
        }
      }
      return errorList.join('<br>');
    },
    editItem(itemIndex) {
      this.$emit('editItem', itemIndex)
    },
  }
}
</script>
