<template>
  <div class="mt-3">
    <b-tabs nav-class="flexben-tabs benefit-tabs border-bottom-0">
      <b-tab v-for="(childBenefit, index) of children"
             :title="$t('flexben.benefit.crm_codes.'+childBenefit.crm_code)"
             :active="childBenefit.crm_code==='up_gift_legislated'"
             :title-link-class="'mr-3 border-top-radius-5'"
             :key="index"
             :class="'container-alb mt-0 pt-0'">
        <div class="m-0">
          <BenefitFiscalAvailability :formData="childBenefit"
                                     :taxes="taxes"
                                     :withEvents="childBenefit.crm_code==='up_gift_legislated'"/>

          <BenefitPeriodAvailability :formAction="'update'"
                                     :formData="childBenefit"
                                     :futureValuesDisableUntil="futureValuesDisableUntil"
                                     :errorsBag="errorsBag"
                                     :dateFormat="dateFormat"/>
          <hr v-if="childBenefit.crm_code!=='up_gift_s_t_i_m'">
          <LegislatedSpecificValues v-if="childBenefit.crm_code!=='up_gift_s_t_i_m'"
                                    :isFuture="false"
                                    :futureValuesDisableUntil="futureValuesDisableUntil"
                                    :valuesObj="childBenefit.data.values"
                                    :dateFormat="dateFormat"/>
          <hr v-if="childBenefit.crm_code!=='up_gift_s_t_i_m'">
          <LegislatedSpecificValues v-if="childBenefit.crm_code!=='up_gift_s_t_i_m'"
                                    :isFuture="true"
                                    :futureValuesDisableUntil="futureValuesDisableUntil"
                                    :valuesObj="childBenefit.future_values.values"
                                    :dateFormat="dateFormat"/>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {BTab, BTabs} from 'bootstrap-vue';
import BenefitPeriodAvailability from "@/components/flexben/benefits/partials/BenefitPeriodAvailability";
import LegislatedSpecificValues from "@/components/flexben/benefits/partials/LegislatedSpecificValues";
import BenefitFiscalAvailability from "@/components/flexben/benefits/partials/BenefitFiscalAvailability";

export default {
  name: "BenefitGiftTabs",
  components: {
    'b-tabs': BTabs,
    'b-tab': BTab,
    BenefitPeriodAvailability,
    LegislatedSpecificValues,
    BenefitFiscalAvailability
  },
  props: {
    children: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    taxes: {
      required: true,
      type: Object,
      default: function () {
        return {
          'impozit-venit': {type: '', name: '', value: 0},
          'cas': {type: '', name: '', value: 0},
          'cass': {type: '', name: '', value: 0},
        }
      }
    },
    dateFormat: {
      required: true,
      type: String,
      default: 'dd/MM/yyyy'
    },
    futureValuesDisableUntil: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    },
    errorsBag: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    },
  }
}
</script>
