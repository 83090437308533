<template>
  <b-tab :title="$t('flexben.clients.partial.events_stated_by_law')"
         :active="(childIndex===0)" :key="childIndex">
    <div class="row ml-1 mr-2 mt-4">
      <div class="col-12 col-md-6">
        <div class="text-18 text-bold">{{ $t("flexben.clients.partial.events_stated_by_law") }}</div>
      </div>
      <div class="col-12 col-md-6 mt-2 mt-md-0">
        <div class="float-md-right d-flex" v-if="isAdmin">
          <label for="law_events_toggle" class="ml-md-auto mb-0 mr-4 mr-2 col-form-label p-0">
            {{$t("flexben.clients.partial.enable_benefit_for_client")}}
          </label>
          <b-form-checkbox id="law_events_toggle"
                           switch
                           v-model="childConfig.is_active">
          </b-form-checkbox>
        </div>
      </div>
    </div>
    <div class="row benefits-order-info mx-3 mt-3 pt-3">
      <div class="col-12 col-md-3">
        <img src="@/assets/images/flexben/utils/benefit-info.svg" alt=""/>
      </div>
      <div class="col-12 col-md-3">
        <p>{{$t("flexben.general.fiscal_treatment")}}</p>
        <p class="text-16 text-bold" v-if=" Object.keys(parentBenefit.global_variable_codes).length === 3">
          {{$t("flexben.benefit.deductibility.none")}}
        </p>
        <p class="text-16 text-bold"
           v-else-if="Object.keys(parentBenefit.global_variable_codes).length < 3 && Object.keys(parentBenefit.global_variable_codes).length > 0">
          {{$t("flexben.benefit.deductibility.partial")}}
        </p>
        <p class="text-16 text-bold" v-else-if=" Object.keys(parentBenefit.global_variable_codes).length === 0">
          {{$t("flexben.benefit.deductibility.complete")}}
        </p>
      </div>
      <div class="col-6 col-md-2">
        <p>{{$t("flexben.general.income_tax")}}</p>
        <p class="text-16 text-bold">{{ parentBenefit.global_variable_codes.includes('impozit-venit') ?
          globalVariables['impozit-venit'].value : 0 }}%</p>
      </div>
      <div class="col-6 col-md-2">
        <p>CAS</p>
        <p class="text-16 text-bold">{{ parentBenefit.global_variable_codes.includes('cas') ?
          globalVariables['cas'].value : 0 }}%</p>
      </div>
      <div class="col-6 col-md-2">
        <p>CASS</p>
        <p class="text-16 text-bold">{{ parentBenefit.global_variable_codes.includes('cass') ?
          globalVariables['cass'].value : 0 }}%</p>
      </div>
      <div class="col-12 col-md-6 col-xl-3 offset-xl-3">
        <p>{{$t("flexben.general.last_annual_date_for_orders")}}</p>
        <p class="text-16 text-bold">
          {{parentBenefit.end_use_day_month.day}}.{{parentBenefit.end_use_day_month.month}}.{{parentBenefit.end_use_day_month.year}}</p>
      </div>
      <div class="col-12 col-md-6 col-xl-3">
        <p>{{$t("flexben.benefit.fields.maximum_legal_value_per_event")}}</p>
        <p class="text-16 text-bold">{{ parentBenefit.data.values.maximum_legal_value_per_event.value }} Lei</p>
      </div>
    </div>
    <div class="row mx-1 mt-4">
      <div class="col-12">
        <span class="text-18 text-bold">{{$t("flexben.clients.partial.legislated_events")}}</span>
      </div>
      <div class="col-12 mt-2">
        <div class="container-fluid px-0 sub-tab-table">
          <div class="table-responsive hover-table">
            <table class="table no-min-width" :key="tableKey">
              <thead>
              <tr>
                <td>
                  {{ $t("flexben.general.event") }}
                </td>
                <td>
                  {{ $t("flexben.general.event_date") }}
                </td>
                <td>
                  {{ $t("flexben.general.top_up_limit")}}
                </td>
                <td>
                  {{ $t("flexben.clients.partial.gender_availability") }}
                </td>
                <td>
                  {{ $t("flexben.clients.partial.for_birthdays") }}
                </td>
                <td>
                  {{ $t("flexben.general.status") }}
                </td>
                <td></td>
              </tr>
              </thead>
              <tbody>
              <template v-if="parentBenefit.events.length > 0">
                <template v-for="(event, index) in parentBenefit.events">
                  <tr
                    v-if="typeof childConfig.legal_events_customization.hasOwnProperty(event.id) !=='undefined'"
                    :key="index"
                    class="trhover" :class="(index%2 ===0) && 'gri-cell'">
                    <td>
                      <div class="d-inline-flex">
                        <span class="mobile-icon pr-2 flx-calendar-icon"><font-awesome-icon icon="calendar-alt"/></span>
                        {{ $t('flexben.general.legal_events.'+ event.name) }}
                      </div>
                    </td>
                    <td>
                      {{ event.event_date.day }}/{{ event.event_date.month }}/{{ current_year }}
                    </td>
                    <td>
                      {{ childConfig.legal_events_customization[event.id].maximum_topup_limit }} Lei
                    </td>
                    <td>
                      {{ getGenderAvailability(event.gender_availability) }}
                    </td>
                    <td>
                      {{ event.on_birthday ? 'Da':'Nu' }}
                    </td>
                    <td>
                      <span class="cursor-pointer"
                            :class="childConfig.legal_events_customization[event.id].is_active ? 'occasion-active' : 'occasion-inactive'">
                      {{ childConfig.legal_events_customization[event.id].is_active ? $t('flexben.general.status_type.active') : $t('flexben.general.status_type.inactive') }}
                    </span>
                    </td>
                    <td>
                      <button type="button" class="btn action-button"
                              @click="openModalForm(index)">
                        {{$t("flexben.general.btn.see_more")}}
                      </button>
                    </td>
                  </tr>
                </template>
              </template>
              <template v-else>
                <tr class="gri-cell">
                  <td colspan="7" class="text-center">
                    {{$t("flexben.general.no_events")}}
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <company-event-modal v-if="openModal"
                         :type="'company_event'"
                         :openModal="openModal"
                         :formData="formData"
                         :modalAction="modalAction"
                         :errorBag="errorBag"
                         :isLegislated="true"
                         @closeModal="closeModal"
                         @submitForm="submitForm"
                         @toggleStatus="toggleStatus"
    ></company-event-modal>
  </b-tab>
</template>

<script>
import {BFormCheckbox, BTab} from "bootstrap-vue";
import moment from "moment";
import CompanyEventModal from "../modals/CompanyEventModal";
import httpFlexbenService from "@/http/http-flexben";

export default {
  components: {
    "b-tab": BTab,
    "b-form-checkbox": BFormCheckbox,
    "company-event-modal": CompanyEventModal
  },
  name: "SubTabUpLawEvents",
  props: {
    childConfig: {
      type: Object,
      default: function () {
        return {};

      },
      required: true
    },
    clientId: {
      default: null,
      required: true,
    },
    globalVariables: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
    childIndex: {
      required: true
    },
    parentBenefit: {
      type: Object,
      default: function () {
        return {};

      },
      required: true
    },
    isAdmin: {
      type: Boolean,
      default: true,
      required: true
    },
  },
  data: function () {
    return {
      httpService: httpFlexbenService.clients.events,
      topLevelParent: this.$parent.$parent.$parent.$parent.$parent.$parent,
      current_year: moment().year(),
      openModal: false,
      formData: {
        type: Object,
        default: function () {
          return {
            occasionId: null,
            occasionIndex: null,
            name: '',
            is_active: true,
            maximum_topup_limit: 0,
            maximum_topup_limit_per_minor: 0,
            date: {
              day: null,
              month: null,
              year: null,
            },
            is_birthday: false,
            gender_availability: {
              male: true,
              female: true,
            },
            has_limit_per_child: false,
          };
        },
        required: true
      },
      errorBag: {},
      modalAction: 'update',
      tableKey: 0,
    };
  },
  methods: {
    openModalForm(eventIndex) {
      let event = this.parentBenefit.events[eventIndex];
      this.modalAction = 'update';
      let genderAvailability;
      if (event.gender_availability === 'MALE') {
        genderAvailability = {
          male: true,
          female: false,
        };
      } else if (event.gender_availability === 'FEMALE') {
        genderAvailability = {
          male: false,
          female: true,
        };
      } else {
        genderAvailability = {
          male: true,
          female: true,
        };
      }
      this.formData = {
        occasionId: event.id,
        occasionIndex: eventIndex,
        name: this.$t('flexben.general.legal_events.' + event.name),
        is_active: this.childConfig.legal_events_customization[event.id].is_active,
        maximum_topup_limit: this.childConfig.legal_events_customization[event.id].maximum_topup_limit,
        maximum_topup_limit_per_minor: this.childConfig.legal_events_customization[event.id].maximum_topup_limit_per_minor,
        date: {
          day: parseInt(event.event_date.day),
          month: parseInt(event.event_date.month),
          year: event.event_date.year,
        },
        is_birthday: event.on_birthday,
        gender_availability: genderAvailability,
        has_limit_per_child: event.has_limit_per_child
      };
      this.openModal = true;
    },
    closeModal: function () {
      this.topLevelParent.loading = false;
      this.openModal = false;
      this.errorBag = {};
      this.formData = {
        occasionId: null,
        name: '',
        is_active: '',
        maxTopUpLimit: 0,
        date: null,
        is_birthday: false,
        gender_availability: {
          male: true,
          female: true,
        },
      };
    },
    submitForm() {
      this.topLevelParent.loading = true;
      this.errorBag = {};
      this.openModal = false

      let formData = {
        is_active: this.formData.is_active,
        name: this.formData.name,
        event_date: this.formData.date,
        maximum_topup_limit: this.formData.maximum_topup_limit,
        on_birthday: this.formData.is_birthday,
        gender_availability: this._getGenderAvailability(),
        maximum_topup_limit_per_minor: this.formData.maximum_topup_limit_per_minor,
      };

      let event = this.parentBenefit.events[this.formData.occasionIndex];
      this.httpService.updateEvent(this.clientId, this.parentBenefit.id, event.id, formData)
        .then(function (response) {
          this.childConfig.legal_events_customization[response.data.id].is_active = response.data.is_active;
          this.childConfig.legal_events_customization[response.data.id].maximum_topup_limit = response.data.maximum_topup_limit;
          this.childConfig.legal_events_customization[response.data.id].maximum_topup_limit_per_minor = response.data.maximum_topup_limit_per_minor;
          this.tableKey++; // forces re-render of table for status change
          this.topLevelParent.loading = false;
          this.closeModal();
        }.bind(this))
        .catch(function (error) {
          this.topLevelParent.loading = false;
          this.openModal = true;
          this.errorBag = error.response.data.error_fields;
        }.bind(this));
    },
    toggleStatus() {
      this.formData.is_active = !this.formData.is_active;
    },
    getGenderAvailability(gender) {
      if (gender === 'MALE') {
        return 'Barbati';
      } else if (gender === 'FEMALE') {
        return 'Femei';
      } else {
        return 'Ambele genuri';
      }
    },
    _getGenderAvailability() {
      if (this.formData.gender_availability.male && this.formData.gender_availability.female) {
        return 'BOTH';
      } else if (!this.formData.gender_availability.male && this.formData.gender_availability.female) {
        return 'FEMALE';
      } else if (this.formData.gender_availability.male && !this.formData.gender_availability.female) {
        return 'MALE';
      } else {
        return 'BOTH';
      }
    },
  }
}
</script>
