<template>
  <b-tab :title="$t('flexben.beneficiaries.timesheets')" :active="activeTab === 'time_sheet'" :key="'time_sheet'"
         class="container-bg-white">
    <div class="row px-3 pb-2">
      <div class="col-12">
        <h3 class="mb-0 py-1 pl-0">{{$t("flexben.general.entries")}}</h3>
      </div>
      <div class="col-12 mt-2 sub-tab-table">
        <div class="table-responsive hover-table">
          <table class="table no-min-width">
            <thead>
            <tr>
              <td>{{$t("flexben.beneficiaries.updated_at")}}</td>
              <td>{{$t("flexben.general.month")}}</td>
              <td>{{$t("flexben.beneficiaries.days_worked")}}</td>
              <td>{{$t("flexben.beneficiaries.consumed_days")}}({{$t("flexben.general.days")}})</td>
              <td>{{$t("flexben.beneficiaries.last_top_up_date")}}</td>
            </tr>
            </thead>
            <tbody>
            <template v-if="table_data.data.length >0">
              <tr v-for="(row, index) in table_data.data" :key="index">
                <td>{{ moment(row.updated_at, 'DD/MM/YYYY') }}</td>
                <td>{{ (row.month!==null)&& $t('flexben.general.months.'+row.month) }} {{ row.year }}</td>
                <td>{{ row.working_days }}</td>
<!--                <td>{{ row.consumed_days!=0 ? $t('flexben.general.yes') : $t('flexben.general.no') }}</td>-->
                <td>{{ row.consumed_days}} </td>
                <td>{{ moment(row.last_top_up_date, 'DD/MM/YYYY') }}</td>
              </tr>
            </template>
            <template v-else>
              <tr class="trhover">
                <td colspan="100" class="text-center">{{ $t("flexben.general.no_results_found") }}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 p-0">
        <listPagination
          :currentPage="table_data.current_page"
          :totalItems="table_data.total"
          :startItem="table_data.from"
          :endItem="table_data.to"
          :itemsPerPage="table_data.perPage"
          :defaultPerPage="10"
          @pageChanged="pageChanged"
          @changePerPage="changePerPage"
        ></listPagination>
      </div>
    </div>

  </b-tab>
</template>

<script>
  import {BTab} from "bootstrap-vue";
  import moment from "moment";

  export default {
    name: "TabBeneficiaryHistoryTimeSheets",
    components: {
      "b-tab": BTab,
    },
    props: {
      activeTab: {
        type: String,
        default: '',
        required: true
      },
      tableData: {
        type: Object,
        default: function () {
          return {
            data: [],
            current_page: 1,
            to: 1,
            perPage: 1,
            lastPage: 1,
            total: 1,
          };
        },
        required: true
      },
    },
    computed: {
      table_data: function () {
        return this.tableData;
      }
    },
    methods: {
      pageChanged(page) {
        this.$emit('pageChanged', page);
      },
      changePerPage(perPage) {
        this.$emit('changePerPage', perPage, 'time_sheet');
      },
      moment(date, format) {
        if (date === null) {
          return '-';
        }
        return moment(date).format(format);
      },
    }
  }
</script>
