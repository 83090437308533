<template>
  <div class="pb-4">
    <div class="container-fluid" v-if="!initialLoading">
      <div class="row px-3">
        <div class="col-12 p-0">
          <img class="flx-round-5 w-100" src="@/assets/images/flexben/banner-flexben.png"/>
        </div>
      </div>

      <div v-if="flxModuleCan('flexible_benefits')" class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-12  col-xl-8 d-inline-flex">
            <img class="img-fluid float-left mr-3" src="@/assets/images/flexben/dashboard-client/check-top.svg"
                 alt="check-top"/>
            <div class="my-auto">
              <div class="text-bold text-18">{{$tc("flexben.dashboard.you_have_new_top_up_request", count_request,
                {count: count_request})}}
              </div>
              <div v-if="count_request">{{$t("flexben.dashboard.go_to_requests_page_to_validate_or_reject_them")}}</div>
            </div>
          </div>
          <div class="col-12 col-xl-4 justify-content-center align-self-center mt-2 mt-xl-0">
            <button @click="goTo('requests')" class="btn btn-themed float-right">
              {{$t("flexben.dashboard.manage_request")}}
              <span class="button-arrow button-arrow-right"></span>
            </button>
          </div>
        </div>
      </div>

      <div class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-xl-4">
            <div class="circle-wrapper">
              <p class="circle-badge-number text-70"> {{ activeBeneficiaries }}</p>
              <p class="m-0 text-center" v-html="$t('flexben.dashboard.active_beneficiaries')"></p>
              <p class="circle-flexben-icon">
                <img class="img-fluid" src="@/assets/images/flexben/dashboard-client/beneficiaires.svg"
                     alt="beneficiaires"/>
              </p>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="circle-wrapper">
              <p class="circle-badge-number">{{ Math.floor(allocatedYearBudget) }}<span
                class="text-sup text-themed-dark">{{ flexbenUtils.getSupDecimals(allocatedYearBudget) }}</span></p>
              <p class="m-0 text-center" v-html="$t('flexben.dashboard.total_budgets')"></p>
              <p class="circle-flexben-icon">
                <img class="img-fluid" src="@/assets/images/flexben/dashboard-client/business-and-finance.svg"
                     alt="business and finance"/>
              </p>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="circle-wrapper">
              <p class="circle-badge-number">{{ Math.floor(unspentBudget) }}<span
                class="text-sup text-themed-dark">{{ flexbenUtils.getSupDecimals(unspentBudget) }}</span></p>
              <p class="m-0 text-center" v-html="$t('flexben.dashboard.unspent_budgets')"></p>
              <p class="circle-flexben-icon">
                <img class="img-fluid" src="@/assets/images/flexben/dashboard-client/hands-and-gestures.svg"
                     alt="hands and gestures"/>
              </p>
            </div>
          </div>
        </div>
      </div>


      <div class="row justify-content-md-center px-3 mt-3 mt-md-5 client-dashboard-menu">
        <div class="col-12 text-center">
          <h1>{{$t("flexben.dashboard.what_would_you_like_to_do")}}</h1>
        </div>
        <div
          class="col-12 col-md-5 container-bg-white text-center my-2 py-md-5 client-menu-box d-flex"
          @mouseover="menu_images.configure_benefits_image = menu_images.configure_benefits_hover"
          @mouseleave="menu_images.configure_benefits_image = menu_images.configure_benefits"
          @click="goTo('config')">
          <div class="justify-content-center align-self-center w-100">
            <img :src="menu_images.configure_benefits_image" class="mr-3" alt="configure benefits"
                 id="configure-benefits"/>
            {{$t("flexben.dashboard.configure_benefits")}}
          </div>
        </div>
        <div class="col-md-auto"></div>
        <div
          class="col-12 col-md-5 container-bg-white text-center my-2 py-md-5  client-menu-box d-flex"
          @click="goTo('manage')">
          <div class="justify-content-center align-self-center w-100">
            <img src="@/assets/images/flexben/dashboard-client/manage-benefits.svg" class="mr-3" alt="manage benefits"
                 id="manage-benefits">
            {{$t("flexben.dashboard.manage_beneficiaries")}}
          </div>
        </div>
        <div
          class="col-12 col-md-5 container-bg-white text-center my-2 py-md-5 client-menu-box d-flex"
          @mouseover="menu_images.import_files_image = menu_images.import_files_hover"
          @mouseleave="menu_images.import_files_image = menu_images.import_files"
          @click="goTo('import')">
          <div class="justify-content-center align-self-center w-100">
            <img :src="menu_images.import_files_image" class="mr-3" alt="import files" id="import-files"/>
            {{$t("flexben.dashboard.import_files")}}
          </div>
        </div>
        <div v-if="flxModuleCan('flexible_benefits')" class="col-md-auto"></div>
        <div v-if="flxModuleCan('flexible_benefits')"
          class="col-12 col-md-5 container-bg-white text-center my-2 py-md-5 client-menu-box d-flex"
          @mouseover="menu_images.manage_orders_image = menu_images.manage_orders_hover"
          @mouseleave="menu_images.manage_orders_image = menu_images.manage_orders"
          @click="goTo('requests')">
          <div class="justify-content-center align-self-center w-100 text-uppercase">
            <img :src="menu_images.manage_orders_image" class="mr-3" alt="manage orders" id="manage-orders"/>
            {{$t("flexben.dashboard.manage_request")}}
          </div>
        </div>
      </div>

      <div class="row justify-content-md-center px-3 mt-3 mt-md-5 client-dashboard-menu">
        <div class="col-12 text-center">
          <h1>{{$t("flexben.dashboard.require_help")}}</h1>
        </div>
      </div>
      <div class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-12 col-md-4 mt-2 d-flex">
            <font-awesome-icon icon="phone" class="mr-1 contact-ico"/>
            <a href="tel:+0745627544" target="_blank" class="text-themed">074 562 75 44</a><b class="mx-1">/</b>
            <a href="tel:+0742172603" target="_blank" class="text-themed">074 217 26 03</a>
          </div>
          <div class="col-12 col-md-4 mt-2 d-flex">
            <font-awesome-icon icon="envelope" class="mr-1 contact-ico ml-md-auto"/>
            <a href="mailto:multibeneficii@upromania.ro" target="_blank" class="text-themed mr-md-auto">multibeneficii@upromania.ro</a>
          </div>
          <div class="col-12 col-md-4 mt-2 d-flex align-items-right">
            <font-awesome-icon icon="link" class="mr-1 contact-ico ml-md-auto"/>
            <a href="https://upromania.ro/companii/up-multibeneficii" target="_blank" class="text-themed">www.upromania.ro</a>
          </div>
        </div>
      </div>
    </div>
    <content-loader :loaderVisible="initialLoading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben.js";
import flexbenUtils from "../../../../plugins/flexben_utils";

export default {
  data() {
    return {
      initialLoading: true,
      loaderError: false,
      clientsService: httpFlexbenService.clients,
      flexbenUtils: flexbenUtils,
      activeBeneficiaries: 0.00,
      allocatedYearBudget: 0.00,
      unspentBudget: 0,
      count_request: 0,
      companyId: null,
      menu_images: {
        configure_benefits_image: require('@/assets/images/flexben/dashboard-client/configure-benefits.svg'),
        configure_benefits: require('@/assets/images/flexben/dashboard-client/configure-benefits.svg'),
        configure_benefits_hover: require('@/assets/images/flexben/dashboard-client/configure-benefits-hover.svg'),
        import_files_image: require('@/assets/images/flexben/dashboard-client/import-files.svg'),
        import_files: require('@/assets/images/flexben/dashboard-client/import-files.svg'),
        import_files_hover: require('@/assets/images/flexben/dashboard-client/import-files-hover.svg'),
        manage_orders_image: require('@/assets/images/flexben/dashboard-client/manage-orders.svg'),
        manage_orders: require('@/assets/images/flexben/dashboard-client/manage-orders.svg'),
        manage_orders_hover: require('@/assets/images/flexben/dashboard-client/manage-orders-hover.svg'),
      }
    };
  },
  created() {
    let permission = this.flxUserLevel();
    const employment = this.$store.getters.flx_activeEmployment;

    if (permission.userLevel === 'client' && employment != null) {
      this.companyId = employment.company_id;
      this.getClientData();
    } else {
      window.location = '/company'
    }
  },
  methods: {
    getClientData() {
      this.clientsService
        .overview(this.companyId)
        .then(result => {
          this.activeBeneficiaries = result.data.active_beneficiaries;
          this.allocatedYearBudget = result.data.allocated_year_budget;
          this.unspentBudget = result.data.unspent_budget;
          this.count_request = result.data.count_request;
          this.initialLoading = false;
        });
    },
    goTo(page) {
      let link;
      switch (page) {
        case 'requests':
          link = '/multibeneficii/requests';
          break;
        case 'config':
          link = '/multibeneficii/client';
          break;
        case 'manage':
          link = '/multibeneficii/beneficiaries'
          break;
        case 'import':
          link = '/multibeneficii/import-new';
          break;
        case 'orders':
          link = '/multibeneficii/orders';
          break;
        default:
          link = '/multibeneficii/requests';
      }
      window.location.href = link;
    },
  },
}
</script>
<style scoped lang="scss">
.circle-badge-number {
  color: #F59100;
  font-size: 40px;
  font-weight: 600;

  .text-sup {
    font-size: 20px;
  }
}

.circle-wrapper {
  /* circle styles */
  width: 300px;
  height: 300px;
  border: 4px solid #F2F0F1;
  border-radius: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1em auto;
}

@media (min-width: 1200px) and (max-width: 1350px) {
  .circle-wrapper {
    width: 260px;
    height: 260px;
  }
}

.button-arrow {
  border: solid white;
  margin-left: 10px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.button-arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.circle-flexben-icon {
  max-width: 50px;
}

.client-dashboard-menu .client-menu-box {
  font-size: 23px;
}

.client-dashboard-menu .client-menu-box:hover {
  background-color: #F59100 !important;
  cursor: pointer;
}

@media (min-width: 1100px) and (max-width: 1200px) {
  .circle-badge-number {
    font-size: 38px;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .circle-badge-number {
    font-size: 36px;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 960px) {
  .circle-badge-number {
    font-size: 30px;
    margin-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .text-max-sm-center {
    text-align: center;
  }
  .mb-max-sm-3 {
    margin-bottom: 1rem !important;
  }
  .client-dashboard-menu {
    .client-menu-box {
      min-height: 80px;
      font-size: 16px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .circle-wrapper {
    margin: 0.5em auto;
  }
}


</style>
